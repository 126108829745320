import { addDays, format } from "date-fns";
import { ko } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import { numberWithCommas } from "../utils/numberWithCommas";

export function useGetMoimContent(
  content?: any | null,
  moimStartAt?: string | null,
  moimEndAt?: string | null
) {
  const { t, i18n } = useTranslation();

  const price = !content?.price
    ? ""
    : i18n.resolvedLanguage.includes("ko")
    ? numberWithCommas(content?.price) + t("common.currency") + "/인"
    : t("common.currency") + numberWithCommas(content?.price) + "/Person";

  const purePrice = !content?.price
    ? ""
    : i18n.resolvedLanguage.includes("ko")
    ? numberWithCommas(content?.price) + t("common.currency")
    : t("common.currency") + numberWithCommas(content?.price);

  const formattedMoimStartAt = !moimStartAt
    ? ""
    : format(
        new Date(moimStartAt),
        i18n.resolvedLanguage.includes("ko")
          ? "yyyy년 MM월 dd일 (iiiiii) aa h:mm"
          : "dd, MM, yyyy (iiiiii) aa h:mm",
        { locale: i18n.resolvedLanguage.includes("ko") ? ko : undefined }
      );

  const formattedMoimStartHour = !moimStartAt
    ? ""
    : format(new Date(moimStartAt), "aa h:mm", {
        locale: i18n.resolvedLanguage.includes("ko") ? ko : undefined,
      });

  const formattedMoimEndHour = !moimEndAt
    ? ""
    : " ~ " +
      format(new Date(moimEndAt), "aa h:mm", {
        locale: i18n.resolvedLanguage.includes("ko") ? ko : undefined,
      });

  const formattedMoimStartAndEndHour =
    formattedMoimStartHour + formattedMoimEndHour;

  const joinDeadline = !moimStartAt
    ? ""
    : format(
        addDays(new Date(moimStartAt), -1),
        i18n.resolvedLanguage.includes("ko")
          ? "yy년 MM월 dd일 (iiiiii) aa h:mm"
          : "dd, MM, yy (iiiiii) aa h:mm",
        { locale: i18n.resolvedLanguage.includes("ko") ? ko : undefined }
      );

  return {
    price,
    purePrice,
    formattedMoimStartAt,
    formattedMoimStartAndEndHour,
    joinDeadline,
  };
}
