import axios from "axios";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useNavigate, useParams } from "react-router-dom";
import { useContentListContext } from "../../../providers/ContentListProvider";
import { useScreenWidthContext } from "../../../providers/ScreenWidthProvider";
import { copyText } from "../../common/utils/copyText";
import { SCREENS } from "../../routes/utils/router";
import {
  useGetContentQuery,
  useIncrementContentReadCountMutation,
} from "../../../lib/apollo/graphql/generated";
import {
  getLocalStorageData,
  setLocalStorageData,
} from "../../common/utils/localStorageHelpers";

function useContent() {
  const { isMobile } = useScreenWidthContext();
  const navigate = useNavigate();
  const params = useParams();

  const [wrapperRef, inView, entry] = useInView({
    threshold: isMobile ? 0 : 0.2,
  });
  const [bottomRef, bottomInView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
    onChange: async (inView, entry) => {
      if (inView) {
        const localStorageReads = getLocalStorageData("read") || [];

        const isRead = localStorageReads?.includes(params?.contentId);

        if (!isRead) {
          setLocalStorageData("read", [
            ...localStorageReads,
            params?.contentId,
          ]);

          try {
            const ipResult = (
              await axios.get("https://geolocation-db.com/json/")
            )?.data;

            const {
              IPv4,
              city,
              country_code,
              country_name,
              latitude,
              longitude,
              state,
            } = ipResult;

            incrementContentReadCount({
              variables: {
                contentId: params?.contentId!,
                contentReadInput: {
                  latitude,
                  longitude,
                  state,
                  countryCode: country_code,
                  countryName: country_name,
                  ipV4Address: IPv4,
                  city,
                },
              },
            });
          } catch (err) {}
        }
      }
    },
  });

  const [showFloatingButtons, setShowFloatingButtons] = useState(false);

  const { onThemeChange, onFieldChange } = useContentListContext();

  const { loading, data } = useGetContentQuery({
    variables: {
      contentId: params?.contentId!,
    },
  });

  const [incrementContentReadCount] = useIncrementContentReadCountMutation();

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [inView, showFloatingButtons]);

  function handleScroll() {
    const vh = window.innerHeight;

    if (window.scrollY > vh * 0.3 && inView && !showFloatingButtons) {
      setShowFloatingButtons(true);
    }

    if (
      ((window.scrollY <= vh * 0.3 && inView) || !inView) &&
      showFloatingButtons
    ) {
      setShowFloatingButtons(false);
    }
  }

  function onThemeClick(themeId: string) {
    onThemeChange(themeId);
    onFieldChange(null);
    navigate(SCREENS.CONTENTS_LIST.path!);
  }

  function onFieldClick(fieldId: string) {
    onFieldChange(fieldId);
    onThemeChange(null);
    navigate(SCREENS.CONTENTS_LIST.path!);
  }

  function onScrollIconClick() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  function onShareClick() {
    copyText(window.location.href, "주소가 복사되었습니다!");
  }

  return {
    refs: {
      wrapperRef,
      bottomRef,
    },
    models: {
      loading,
      data: data?.getContent,
      showFloatingButtons,
    },
    operations: {
      onThemeClick,
      onFieldClick,
      onScrollIconClick,
      onShareClick,
    },
  };
}

export default useContent;
