import React from "react";
import { useTranslation } from "react-i18next";
import styled, { ThemedComponentProps } from "styled-components";
import { ModalTitle } from "../../../common/styles/commonStyles";

function PrivacyPolicy() {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <ModalTitle>{t("auth.privacyPolicy1")}</ModalTitle>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  minWidth: 500,
  padding: theme.spacing.small,
}));

export default PrivacyPolicy;
