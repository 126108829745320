import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ThemedComponentProps } from "styled-components";
import useMainMoimsSection from "./MainMoimsSection.hook";
import MoimThumbnail from "../../common/components/MoimThumbnail";
import { FetchMoreButton, Grid } from "../../common/styles/commonStyles";
import { MainWrapper } from "../common/mainCommonStyles";

function MainMoimsSection() {
  const { t } = useTranslation();

  const {
    refs: { fetchMoreRef },
    models: { loading, data },
  } = useMainMoimsSection();

  if (loading) return <div />;

  return data && data?.length > 0 ? (
    <Wrapper>
      <Title>{t("main.moimSectionTitle")}</Title>
      <Subtitle>{t("main.moimSectionSubtitle")}</Subtitle>
      <ThumbnailsWrapper>
        {data?.map((moim) => (
          <MoimThumbnail key={moim?.id + "main"} moim={moim!} />
        ))}
      </ThumbnailsWrapper>
      <FetchMoreButton ref={fetchMoreRef} />
    </Wrapper>
  ) : null;
}

const Wrapper = styled(MainWrapper)<any>(({ theme }: ThemedComponentProps) => ({
  padding: `${theme.spacing.section * 2}px ${theme.spacing.section}px`,

  [theme.media.down["DESKTOP"]]: {
    padding: `${theme.spacing.section}px ${theme.spacing.regular}px`,
  },
}));

const Title = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: 40,
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing.small,

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.head3,
  },
}));

const Subtitle = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.body3,
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing.section * 2,

  [theme.media.down["DESKTOP"]]: {
    marginBottom: theme.spacing.section,
  },
}));

const ThumbnailsWrapper = styled(Grid)<any>(
  ({ theme }: ThemedComponentProps) => ({
    width: "100%",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: `${theme.spacing.section}px ${theme.spacing.default}px`,
    gridAutoRows: "auto",

    [theme.media.down["DESKTOP"]]: {
      gridTemplateColumns: "1fr",
      gap: theme.spacing.section,
    },
  })
);

export default MainMoimsSection;
