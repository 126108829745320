import React from "react";
import { ScrollRestoration } from "react-router-dom";
import Layout from "../../common/components/Layout";
import Content from "../components/Content";
import ContentRecommendSection from "../components/ContentRecommendSection";

function ContentContainer() {
  return (
    <Layout>
      <ScrollRestoration />
      <Content />
      <ContentRecommendSection />
    </Layout>
  );
}

export default ContentContainer;
