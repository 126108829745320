import React from "react";
import { useTranslation } from "react-i18next";
import styled, { ThemedComponentProps, useTheme } from "styled-components";
import { MoimStatus } from "../../../../../lib/apollo/graphql/generated";
import { Col, Row, SaveButton } from "../../../../common/styles/commonStyles";

interface UpdateMoimRequestModalProps {
  status?: MoimStatus;
  onCancel?: () => void;
  onSubmit?: () => void;
}

const DESCRIPTIONS = [
  {
    title: "updateMoimRequest.warning1Title",
    descriptions: [
      "updateMoimRequest.warning1Description1",
      "updateMoimRequest.warning1Description2",
    ],
  },
  {
    title: "updateMoimRequest.warning2Title",
    descriptions: ["updateMoimRequest.warning2Description1"],
  },
  {
    title: "updateMoimRequest.warning3Title",
    descriptions: ["updateMoimRequest.warning3Description1"],
  },
];

function UpdateMoimRequestModal({
  status,
  onCancel,
  onSubmit,
}: UpdateMoimRequestModalProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  const isBeforeSubmit = !status || status === MoimStatus.Editing;

  return (
    <Wrapper>
      {isBeforeSubmit ? (
        <>
          <TitleWrapper>
            <Title>{t("updateMoimRequest.title")}</Title>
            <div>{t("updateMoimRequest.subtitle")}</div>
          </TitleWrapper>

          <DescriptionsWrapper>
            {DESCRIPTIONS.map((item) => (
              <Description key={item.title}>
                <div style={{ marginBottom: theme.spacing.xxSmall2 }}>
                  <strong>{t(item.title)}</strong>
                </div>
                {item.descriptions.map((description) => (
                  <div key={description}>· {t(description)}</div>
                ))}
              </Description>
            ))}
          </DescriptionsWrapper>
        </>
      ) : (
        <>
          <TitleWrapper>
            <Title>{t("updateMoimRequest.requestDone")}</Title>
          </TitleWrapper>
          <TitleWrapper style={{ gap: theme.spacing.xxSmall2 }}>
            <div>
              <strong>{t("updateMoimRequest.requestDoneMessage1")}</strong>
            </div>
            <Description>
              {t("updateMoimRequest.requestDoneMessage2")}
            </Description>
          </TitleWrapper>
        </>
      )}

      <ButtonsRow>
        {isBeforeSubmit && (
          <CancelButton onClick={onCancel}>{t("common.cancel")}</CancelButton>
        )}
        <SubmitButton onClick={onSubmit}>
          {t(isBeforeSubmit ? "common.request" : "common.confirm")}
        </SubmitButton>
      </ButtonsRow>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  maxWidth: 466,
  width: "100%",

  [theme.media.down["DESKTOP"]]: {
    maxWidth: `calc(100vw - ${theme.spacing.regular * 4}px)`,
    width: `calc(100vw - ${theme.spacing.regular * 4}px)`,
  },
}));

const TitleWrapper = styled(Col)<any>(({ theme }: ThemedComponentProps) => ({
  marginBottom: theme.spacing.section,
  gap: theme.spacing.small,
  alignItems: "center",
  fontSize: theme.font.body3,

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.caption1,
  },
}));

const Title = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.body1,
  fontWeight: "bold",
}));

const DescriptionsWrapper = styled(Col)<any>(
  ({ theme }: ThemedComponentProps) => ({
    gap: theme.spacing.default,
    alignItems: "flex-start",
    marginBottom: theme.spacing.section,
  })
);

const Description = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.caption1,

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.caption2,
  },
}));

const ButtonsRow = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  gap: theme.spacing.small,
  alignItems: "center",
  justifyContent: "space-between",
}));

const CancelButton = styled(SaveButton)<any>(
  ({ theme }: ThemedComponentProps) => ({
    border: `1px solid ${theme.color.border}`,
    backgroundColor: theme.color.white,
    fontWeight: "bold",
    flex: 1,
    color: theme.color.black,
  })
);

const SubmitButton = styled(SaveButton)<any>(
  ({ theme }: ThemedComponentProps) => ({
    fontWeight: "bold",
    flex: 1,
  })
);

export default UpdateMoimRequestModal;
