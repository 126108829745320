import React, { CSSProperties } from "react";
import { LazyLoadImage, ScrollPosition } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { COMMON_ALT } from "../../../utilities/constants";

interface LazyImageProps {
  uri: string;
  width?: string | number;
  height?: string | number;
  objectFit?: "fill" | "contain" | "cover" | "none" | "scale-down";
  style?: CSSProperties;
  alt?: string;
}

/**
 * Lazily loaded image component using react-lazy-load-image-component
 * doc : https://github.com/Aljullu/react-lazy-load-image-component
 */
function LazyImage({
  uri,
  width = "100%",
  height = "100%",
  objectFit = "cover",
  style,
  alt,
}: LazyImageProps) {
  return (
    <LazyLoadImage
      effect="blur"
      alt={alt || COMMON_ALT}
      src={uri}
      width={width}
      height={height}
      style={{ objectFit, ...style }}
    />
  );
}

export default LazyImage;
