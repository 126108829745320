import { differenceInYears } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuthContext } from "../../../../providers/AuthProvider";
import { PAY_METHOD } from "../../../../utilities/constants";
import {
  useGetMoimQuery,
  useGetRefundPolicyQuery,
  useJoinMoimMutation,
} from "../../../../lib/apollo/graphql/generated";

function useJoinMoimContainer() {
  const params = useParams();
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();

  const { userProfile, toggleSignIn } = useAuthContext();

  const [payMethod, setPayMethod] = useState(PAY_METHOD.CREDIT_CARD);
  const [agreed, setAgreed] = useState(false);

  const { loading, data } = useGetMoimQuery({
    variables: {
      moimId: params?.moimId!,
    },
  });

  const { loading: policyLoading, data: policyData } = useGetRefundPolicyQuery({
    variables: {
      languageCode: i18n.resolvedLanguage,
    },
  });

  const [joinMoim, { loading: joinLoading }] = useJoinMoimMutation({
    onCompleted: () => {
      navigate("/joined/" + data?.getMoim?.id);
    },
    onError: ({ message }) => {
      if (message.toLowerCase().includes("persons")) {
        toast.error(t("error.exceedingMaxPersons"), {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    },
  });

  function onPayMethodClick(method: string) {
    setPayMethod(method);
  }

  function onAgreeClick() {
    setAgreed(!agreed);
  }

  function isJoinButtonDisabled() {
    if (!userProfile) return true;

    const { host, genderRestriction, participants, maxAge, maxPersons } =
      data?.getMoim!;

    if (userProfile?.id === host?.id) return true;

    const { gender, dateOfBirth } = userProfile;

    return (
      (!!genderRestriction && gender === genderRestriction) ||
      (!!maxAge &&
        differenceInYears(new Date(), new Date(dateOfBirth)) > maxAge) ||
      (!!maxPersons && participants?.length >= maxPersons)
    );
  }

  function onJoinClick() {
    if (!userProfile) {
      toggleSignIn(true);
    }

    joinMoim({
      variables: {
        moimId: data?.getMoim?.id!,
      },
    });
  }

  return {
    models: {
      loading: loading || policyLoading,
      data: data?.getMoim,
      policyData: policyData?.getRefundPolicy,
      payMethod,
      agreed,
      joinLoading,
    },
    operations: {
      onPayMethodClick,
      onAgreeClick,
      isJoinButtonDisabled,
      onJoinClick,
    },
  };
}

export default useJoinMoimContainer;
