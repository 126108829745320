import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LazyImage from "./LazyImage";
import clockIcon from "../../../assets/icons/clock.svg";
import lockIcon from "../../../assets/icons/lock_fill.svg";
import pinIcon from "../../../assets/icons/pin.svg";
import { MoimStatus } from "../../../lib/apollo/graphql/generated";
import { useGetMoimContent } from "../hook/useGetMoimContent";
import { Col, DefaultButton, Row } from "../styles/commonStyles";
import { getMoimcontentByLanguage } from "../utils/getMoimContentByLanguage";
import styled, {
  DefaultTheme,
  ThemedComponentProps,
  ThemeProps,
  useTheme,
} from "styled-components";

interface MoimThumbnailProps {
  moim: {
    __typename?: "Moim";
    id: string;
    moimStartAt?: any | null;
    status: MoimStatus;
    createdAt: any;
    updatedAt: any;
    maxPersons?: number | null;
    minPersons?: number | null;
    place?: {
      __typename?: "Place";
      id: string;
      name?: string | null;
      address?: string | null;
      addressDetail?: string | null;
      isPrivate: boolean;
    } | null;
    coverPhoto?: { __typename?: "Media"; id: string; uri: string } | null;
    moimContents?: Array<{
      __typename?: "MoimContent";
      id: string;
      languageCode: string;
      title?: string | null;
      introduction?: string | null;
      detail?: string | null;
      price?: number | null;
      createdAt: any;
      updatedAt: any;
    } | null> | null;
  };
  showStatus?: boolean;
  isHost?: boolean;
}

function MoimThumbnail({
  moim,
  showStatus = false,
  isHost = false,
}: MoimThumbnailProps) {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const { coverPhoto, moimContents, moimStartAt, place, status, maxPersons } =
    moim;

  const content = getMoimcontentByLanguage(i18n.resolvedLanguage, moimContents);

  const { price, formattedMoimStartAt } = useGetMoimContent(
    content,
    moimStartAt
  );

  return (
    <div>
      <DefaultButton
        onClick={() => {
          navigate("/moim/" + moim?.id);
        }}
      >
        <CoverPhotoWrapper>
          {coverPhoto?.uri ? (
            <LazyImage uri={coverPhoto?.uri} style={{ objectFit: "cover" }} />
          ) : (
            <CoverPhotoNone />
          )}
          <CoverPhotoStatusChip
            style={{
              backgroundColor:
                status === MoimStatus.Listed
                  ? theme.color.orange
                  : theme.color.border,
            }}
          >
            {status === MoimStatus.Listed
              ? t("moim." + status)
              : t("updateMoim.preview")}
          </CoverPhotoStatusChip>
        </CoverPhotoWrapper>
        <Title>{content?.title}</Title>
        {/* <Price>{price}</Price> */}
        <Spacer />
        <InfoSection>
          <InfoRow>
            <InfoIcon src={clockIcon} />
            <div>{formattedMoimStartAt}</div>
          </InfoRow>
          <InfoRow>
            <InfoIcon src={pinIcon} />
            <div>{place?.address}</div>
          </InfoRow>
        </InfoSection>
      </DefaultButton>
      {showStatus && (
        <Status
          status={status}
          onClick={() => {
            if (
              status === MoimStatus.Editing ||
              status === MoimStatus.Rejected
            ) {
              navigate("/moim/" + moim?.id + "/host");
            } else if (status !== MoimStatus.Reviewing) {
              navigate("/moim/participants/" + moim?.id);
            }
          }}
        >
          {status === MoimStatus.Reviewing && <LockIcon />}
          <div>
            {t("moim." + status) +
              (status === MoimStatus.Rejected ? t("moim.goToEdit") : "")}
          </div>
        </Status>
      )}
    </div>
  );
}

const CoverPhotoWrapper = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    position: "relative",
    width: "100%",
    height: 272,
    marginBottom: theme.spacing.default,
  })
);

interface CoverPhotoStatusChipProps extends ThemedComponentProps {
  status: MoimStatus;
}

const CoverPhotoStatusChip = styled.div<any>(
  ({ theme, status }: CoverPhotoStatusChipProps) => ({
    position: "absolute",
    left: theme.spacing.normal,
    bottom: theme.spacing.normal,
    padding: `${theme.spacing.xxSmall2}px ${theme.spacing.default}px`,
    fontSize: theme.font.caption1,
    fontWeight: "bold",
  })
);

const CoverPhotoNone = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  height: "100%",
  backgroundColor: theme.color.green,
}));

const Title = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.body2,
  fontWeight: "bold",
  marginBottom: theme.spacing.xxSmall2,
}));

const Price = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.caption1,
  fontWeight: "bold",
}));

const Spacer = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  height: 1,
  backgroundColor: theme.color.border,
  margin: `${theme.spacing.default}px 0px`,
}));

const InfoSection = styled(Col)<any>(({ theme }: ThemedComponentProps) => ({
  alignItems: "flex-start",
  width: "100%",
  gap: theme.spacing.small,
  fontSize: theme.font.caption1,
}));

const InfoRow = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  alignItems: "center",
  gap: theme.spacing.xxSmall2,
}));

const InfoIcon = styled.img<any>(() => ({
  width: 14,
  height: 14,
}));

interface StatusProps extends ThemedComponentProps {
  status: MoimStatus;
}

const Status = styled(Row)<any>(({ theme, status }: StatusProps) => ({
  width: "fit-content",
  cursor: "pointer",
  alignItems: "center",
  justifyContent: "center",
  padding: `${theme.spacing.normal}px ${theme.spacing.section}px`,
  gap: theme.spacing.xxSmall,
  fontSize: theme.font.body3,
  fontWeight: "bold",
  marginTop: theme.spacing.middle,
  ...getStatusStyle(status, theme),
}));

const LockIcon = styled.img.attrs(() => ({
  src: lockIcon,
}))<any>(() => ({
  width: 20,
  height: 20,
}));

function getStatusStyle(status: MoimStatus, theme: DefaultTheme) {
  switch (status) {
    case MoimStatus.Editing:
    case MoimStatus.Reviewing: {
      return {
        backgroundColor: theme.color.white,
        border: `1px solid ${theme.color.black}`,
        color: theme.color.black,
      };
    }
    case MoimStatus.Rejected: {
      return {
        backgroundColor: "#f6421c",
      };
    }

    default: {
      return {
        backgroundColor: theme.color.green,
        color: theme.color.white,
      };
    }
  }
}

export default MoimThumbnail;
