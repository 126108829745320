import { useState } from "react";
import { useInView } from "react-intersection-observer";
import { useGetHomeMoimsQuery } from "../../../lib/apollo/graphql/generated";

function useMainMoimsSection() {
  const [fetchingMore, setFetchingMore] = useState(false);

  const { ref: fetchMoreRef } = useInView({
    threshold: 0.2,
    onChange: onEndReached,
  });

  const { loading, data, fetchMore } = useGetHomeMoimsQuery({
    variables: {
      first: 6,
    },
  });

  function onEndReached(inView: boolean) {
    if (inView && !fetchingMore && data?.getHomeMoims?.pageInfo?.hasNextPage) {
      setFetchingMore(true);

      fetchMore({
        variables: {
          first: 6,
          after: data?.getHomeMoims?.pageInfo?.endCursor,
        },
      }).finally(() => {
        setFetchingMore(false);
      });
    }
  }

  return {
    refs: {
      fetchMoreRef,
    },
    models: {
      loading,
      data: data?.getHomeMoims?.edges,
    },
  };
}

export default useMainMoimsSection;
