import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import useEditAccountContainer from "./EditAccountContainer.hook";
import { firebaseAuth } from "../../../../lib/firebase";
import { useScreenWidthContext } from "../../../../providers/ScreenWidthProvider";
import {
  CommonInputErrorMessage,
  SaveButton,
} from "../../../common/styles/commonStyles";
import {
  UpdateMoimInput,
  UpdateMoimInputSection,
  UpdateMoimLabel,
  UpdateMoimWrapper,
} from "../../../moim/updateMoim/common/updateMoimCommonStyles";

function EditAccountContainer() {
  const { t } = useTranslation();
  const theme = useTheme();

  const { isPc } = useScreenWidthContext();

  const {
    refs: { newPasswordInputRef, confirmPasswordInputRef },
    models: { state, updating },
    operations: {
      onInputChange,
      onFocus,
      onBlur,
      onKeyDown,
      isSubmitDisabled,
      onSubmit,
    },
  } = useEditAccountContainer();

  const firebaseUser = firebaseAuth.currentUser;

  const memoizedEmailInput = useMemo(
    () => (
      <UpdateMoimInputSection>
        <UpdateMoimLabel>{t("profile.emailAccount")}</UpdateMoimLabel>
        <UpdateMoimInput disabled defaultValue={firebaseUser?.email} />
      </UpdateMoimInputSection>
    ),
    [firebaseUser?.email]
  );

  const memoizedPasswordInput = useMemo(
    () => (
      <UpdateMoimInputSection>
        <UpdateMoimLabel>{t("auth.password")}</UpdateMoimLabel>
        <UpdateMoimInput
          ref={newPasswordInputRef}
          type="password"
          minLength={6}
          disabled={updating}
          placeholder={t("auth.passwordPlaceholder")}
          value={state?.password}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "password")
          }
          onFocus={() => onFocus("password")}
          onBlur={() => onBlur("password")}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
            onKeyDown(e, "password")
          }
        />
        {state.passwordError && (
          <CommonInputErrorMessage>
            {t(state.passwordError)}
          </CommonInputErrorMessage>
        )}
      </UpdateMoimInputSection>
    ),
    [updating, state?.password, state?.passwordError]
  );

  const memoizedNewPasswordInput = useMemo(
    () => (
      <UpdateMoimInputSection>
        <UpdateMoimLabel>{t("profile.newPassword")}</UpdateMoimLabel>
        <UpdateMoimInput
          ref={newPasswordInputRef}
          type="password"
          minLength={6}
          disabled={updating}
          placeholder={t("auth.passwordPlaceholder")}
          value={state?.newPassword}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "newPassword")
          }
          onFocus={() => onFocus("newPassword")}
          onBlur={() => onBlur("newPassword")}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
            onKeyDown(e, "newPassword")
          }
        />
        {state.newPasswordError && (
          <CommonInputErrorMessage>
            {t(state.newPasswordError)}
          </CommonInputErrorMessage>
        )}
      </UpdateMoimInputSection>
    ),
    [updating, state?.newPassword, state?.newPasswordError]
  );

  const memoizedConfirmPasswordInput = useMemo(
    () => (
      <UpdateMoimInputSection>
        <UpdateMoimLabel>{t("profile.confirmNewPassword")}</UpdateMoimLabel>
        <UpdateMoimInput
          ref={confirmPasswordInputRef}
          type="password"
          minLength={6}
          disabled={updating}
          placeholder={t("auth.passwordPlaceholder")}
          value={state?.confirmPassword}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "confirmPassword")
          }
          onFocus={() => onFocus("confirmPassword")}
          onBlur={() => onBlur("confirmPassword")}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
            onKeyDown(e, "confirmPassword")
          }
        />
        {state.confirmPasswordError && (
          <CommonInputErrorMessage>
            {t(state.confirmPasswordError)}
          </CommonInputErrorMessage>
        )}
      </UpdateMoimInputSection>
    ),
    [updating, state?.confirmPassword, state?.confirmPasswordError]
  );

  if (!firebaseUser) return <div />;

  return (
    <UpdateMoimWrapper
      style={{
        padding: isPc
          ? `${theme.spacing.regular * 3}px ${theme.spacing.section * 2}px`
          : `${theme.spacing.section}px ${theme.spacing.regular}px`,
      }}
    >
      {memoizedEmailInput}
      {memoizedPasswordInput}
      {memoizedNewPasswordInput}
      {memoizedConfirmPasswordInput}

      <SaveButton disabled={isSubmitDisabled()} onClick={onSubmit}>
        {updating ? t("common.updating") : t("common.save")}
      </SaveButton>
    </UpdateMoimWrapper>
  );
}

export default EditAccountContainer;
