import styled, { ThemedComponentProps } from "styled-components";

export const MyPageTitle = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    fontSize: theme.font.head3,
    fontWeight: "bold",
    marginBottom: theme.spacing.middle,

    [theme.media.down["DESKTOP"]]: {
      fontSize: theme.font.body2,
    },
  })
);
