import { format } from "date-fns";
import React from "react";
import { Helmet } from "react-helmet-async";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled, { ThemedComponentProps, useTheme } from "styled-components";
import useContent from "./Content.hook";
import arrowUpIcon from "../../../assets/icons/chevron_up.svg";
import shareIcon from "../../../assets/icons/share_fill.svg";
import LazyImage from "../../common/components/LazyImage";
import NoPageContainer from "../../noPage/container/NoPageContainer";
import {
  Col,
  DefaultButton,
  Row,
  SectionWrapper,
} from "../../common/styles/commonStyles";

function Content() {
  const theme = useTheme();

  const {
    refs: { wrapperRef, bottomRef },
    models: { loading, data, showFloatingButtons },
    operations: { onThemeClick, onFieldClick, onScrollIconClick, onShareClick },
  } = useContent();

  if (loading) return <Wrapper style={{ height: "100vh" }} />;

  if (!loading && !data) return <NoPageContainer isError />;

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{data?.title}</title>
        <meta name="description" content={data?.subtitle || "" + data?.body} />
        <meta
          name="og:description"
          content={data?.subtitle || "" + data?.body}
        />
        <meta property="og:title" content={data?.title} />

        {data?.primaryMedia && (
          <meta property="og:image" content={data?.primaryMedia?.uri} />
        )}
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>
      <Wrapper ref={wrapperRef}>
        <Container>
          <TitleWrapper>
            <CreatedAt>
              {format(new Date(data?.createdAt), "yyyy.MM.dd")}
            </CreatedAt>
            <Title>{data?.title}</Title>
            {!!data?.subtitle && <Subtitle>{data?.subtitle}</Subtitle>}
          </TitleWrapper>

          {/* PRIMARY MEDIA */}
          {data?.primaryMedia && (
            <LazyImage
              alt={data?.title + (data?.subtitle || "")}
              uri={data?.primaryMedia?.uri}
              objectFit="contain"
              height="fit-content"
              style={{ marginBottom: theme.spacing.regular * 3 }}
            />
          )}

          <Body dangerouslySetInnerHTML={{ __html: data?.body }} />

          <OptionsRow ref={bottomRef}>
            {data?.fields.map((field) => (
              <Option key={field?.id} onClick={() => onFieldClick(field?.id!)}>
                {field?.name}
              </Option>
            ))}
            {data?.themes.map((theme) => (
              <Option key={theme?.id} onClick={() => onThemeClick(theme?.id!)}>
                {theme?.name}
              </Option>
            ))}
          </OptionsRow>
        </Container>
        {showFloatingButtons && (
          <FloatingButtons>
            <FloatingButton
              style={{ backgroundColor: theme.color.green }}
              onClick={onScrollIconClick}
            >
              <FloatingButtonIcon src={arrowUpIcon} />
            </FloatingButton>
            <FloatingButton onClick={onShareClick}>
              <FloatingButtonIcon src={shareIcon} />
            </FloatingButton>
          </FloatingButtons>
        )}
      </Wrapper>
    </>
  );
}

const Wrapper = styled(SectionWrapper)<any>(
  ({ theme }: ThemedComponentProps) => ({
    backgroundColor: theme.color.white,
    paddingTop: theme.spacing.section * 3,
    paddingBottom: theme.spacing.regular * 3,

    [theme.media.down["DESKTOP"]]: {
      paddingTop: theme.spacing.section * 2,
    },

    [theme.media.down["TABLET"]]: {
      paddingBottom: theme.spacing.section,
    },
  })
);

const Container = styled(Col)<any>(({ theme }: ThemedComponentProps) => ({
  maxWidth: 874,
  width: "100%",
  padding: `0px ${theme.spacing.section}px`,
  alignItems: "center",
  color: theme.color.black,

  [theme.media.down["DESKTOP"]]: {
    padding: 0,
  },
}));

const TitleWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  marginBottom: theme.spacing.regular * 3,
  textAlign: "center",

  [theme.media.down["DESKTOP"]]: {
    marginBottom: theme.spacing.section,
  },
}));

const Title = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.head2,
  fontWeight: "bold",
  marginBottom: theme.spacing.xxSmall2,
}));

const CreatedAt = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.caption2,
  marginBottom: theme.spacing.small,
}));

const Subtitle = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  fontSize: theme.font.body2,
  fontFamily: "Pretendard",
  textAlign: "left",
}));

const Body = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  textAlign: "left",
  width: "100%",
  lineHeight: 1.9,
  marginBottom: theme.spacing.regular * 3,

  img: {
    maxWidth: "100%",
  },
}));

const OptionsRow = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  justifyContent: "flex-start",
  gap: theme.spacing.small,
  alignItems: "center",
  flexWrap: "wrap",
}));

const Option = styled(DefaultButton)<any>(
  ({ theme }: ThemedComponentProps) => ({
    padding: `${theme.spacing.small}px ${theme.spacing.default}px`,
    borderRadius: 44,
    fontSize: theme.font.caption2,
    color: theme.color.green,
    border: `1px solid ${theme.color.green}`,
    lineHeight: 1,
    backgroundColor: "transparent",
    textAlign: "center",

    "&:hover": {
      backgroundColor: theme.color.green,
      color: theme.color.white,
    },
  })
);

const FloatingButtons = styled(Col)<any>(({ theme }: ThemedComponentProps) => ({
  gap: theme.spacing.default,
  position: "fixed",
  top: "50%",
  right: "5%",
  transform: "translateY(-50%)",
  zIndex: 100,

  [theme.media.down["DESKTOP"]]: {
    top: "70%",

    transform: "none",
  },

  [theme.media.down["TABLET"]]: {
    top: "80%",
  },
}));

const FloatingButton = styled(DefaultButton)<any>(
  ({ theme }: ThemedComponentProps) => ({
    width: 50,
    height: 50,
    borderRadius: 25,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `1px solid ${theme.color.border}`,
  })
);

const FloatingButtonIcon = styled.img<any>(() => ({
  width: 32,
  height: 32,
}));

export default Content;
