import { ReactChildrenProp } from "global";
import React, { createContext, useContext, useState } from "react";
import { ContentSortOption } from "../lib/apollo/graphql/generated";

interface ContentListContextValues {
  fieldId: string | null;
  themeId: string | null;
  sortOption: ContentSortOption;
}

interface ContentListContextOperations {
  onFieldChange: (fieldId: string | null) => void;
  onThemeChange: (themeId: string | null) => void;
  onSortOptionChange: (sortOption: ContentSortOption) => void;
}

const defaultValues: ContentListContextValues = {
  fieldId: null,
  themeId: null,
  sortOption: ContentSortOption.Recent,
};

const defaultOperations: ContentListContextOperations = {
  onFieldChange: () => {},
  onThemeChange: () => {},
  onSortOptionChange: () => {},
};

const ContentListContext = createContext<
  ContentListContextValues & ContentListContextOperations
>({ ...defaultValues, ...defaultOperations });

function ContentListProvider({ children }: ReactChildrenProp) {
  const [values, setValues] = useState(defaultValues);

  function onFieldChange(fieldId: string | null) {
    if (fieldId !== values.fieldId) {
      setValues((prev) => ({
        ...prev,
        fieldId,
      }));
    }
  }

  function onThemeChange(themeId: string | null) {
    if (themeId !== values.themeId) {
      setValues((prev) => ({
        ...prev,
        themeId,
      }));
    }
  }

  function onSortOptionChange(sortOption: ContentSortOption) {
    if (sortOption !== values.sortOption) {
      setValues((prev) => ({
        ...prev,
        sortOption,
      }));
    }
  }

  return (
    <ContentListContext.Provider
      value={{ ...values, onFieldChange, onSortOptionChange, onThemeChange }}
    >
      {children}
    </ContentListContext.Provider>
  );
}

export default ContentListProvider;

export function useContentListContext() {
  return useContext(ContentListContext);
}
