import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled, { ThemedComponentProps, useTheme } from "styled-components";
import useUpdateMoimDetailContainer from "./UpdateMoimDetailContainer.hook";
import { MoimStatus } from "../../../../../lib/apollo/graphql/generated";
import { useScreenWidthContext } from "../../../../../providers/ScreenWidthProvider";
import ImagePreview from "../../../../common/components/ImagePreview";
import MediaUploader from "../../../../common/components/MediaUploader";
import { Row, SaveButton } from "../../../../common/styles/commonStyles";
import {
  UpdateMoimInput,
  UpdateMoimInputSection,
  UpdateMoimLabel,
  UpdateMoimLabelRow,
  UpdateMoimSectionTitle,
  UpdateMoimSeparator,
  UpdateMoimStep,
  UpdateMoimSubtitle,
  UpdateMoimTextarea,
  UpdateMoimTitle,
  UpdateMoimWrapper,
} from "../../common/updateMoimCommonStyles";

const TITLE_LENGTH_LIMIT = 30;
const INTRODUCTION_LENGTH_LIMIT = 1000;
const DETAIL_LENGTH_LIMIT = 1000;

function UpdateMoimDetailContainer() {
  const { t } = useTranslation();
  const theme = useTheme();

  const { isPc } = useScreenWidthContext();

  const {
    refs: { coverPhotoRef, mediaRef },
    models: { state, loading, data, updateLoading },
    operations: {
      onInputChange,
      onCoverPhotoChange,
      onCoverPhotoDelete,
      onMediaChange,
      onMediaStateDelete,
      onMediaDelete,
      onSaveClick,
    },
  } = useUpdateMoimDetailContainer();

  const disabled =
    (data &&
      !(
        data?.status === MoimStatus.Editing ||
        data?.status === MoimStatus.Rejected
      )) ||
    updateLoading;

  const { coverPhotoState, title, introduction, detail, mediaState } = state;

  const memoizedCoverPhotoInput = useMemo(
    () => (
      <UpdateMoimInputSection>
        <MediaUploader
          disabled={disabled}
          wrapperStyle={{ marginBottom: theme.spacing.small }}
          inputRef={coverPhotoRef}
          label={t("updateMoimDetail.coverPhoto")}
          inputProps={{
            accept: "image/*",
            onChange: onCoverPhotoChange,
          }}
        />
        {(data?.coverPhoto?.uri || coverPhotoState) && (
          <ImagePreview
            uri={
              coverPhotoState
                ? URL.createObjectURL(coverPhotoState)!
                : data?.coverPhoto?.uri!
            }
            size={{ height: isPc ? 284 : "30vw" }}
            disabled={disabled}
            onDeleteClick={onCoverPhotoDelete}
          />
        )}
      </UpdateMoimInputSection>
    ),
    [
      coverPhotoState,
      data?.coverPhoto?.uri,
      onCoverPhotoChange,
      onCoverPhotoDelete,
    ]
  );

  const memoizedTitleInput = useMemo(
    () => (
      <UpdateMoimInputSection>
        <UpdateMoimLabelRow>
          <UpdateMoimLabel style={{ marginBottom: 0 }}>
            {t("updateMoimDetail.moimTitle")}
          </UpdateMoimLabel>
          <UpdateMoimLabel style={{ marginBottom: 0, color: "#8c8c8c" }}>
            {title?.length}/{TITLE_LENGTH_LIMIT}
            {t("common.characters")}
          </UpdateMoimLabel>
        </UpdateMoimLabelRow>
        <UpdateMoimInput
          value={title}
          disabled={disabled}
          maxLength={TITLE_LENGTH_LIMIT}
          placeholder={t("updateMoimDetail.moimTitlePlaceholder")}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "title")
          }
        />
      </UpdateMoimInputSection>
    ),
    [title, onInputChange]
  );

  const memoizedIntroductionInput = useMemo(
    () => (
      <UpdateMoimInputSection>
        <UpdateMoimLabelRow>
          <UpdateMoimLabel style={{ marginBottom: 0 }}>
            {t("updateMoimDetail.introduction")}
          </UpdateMoimLabel>
          <UpdateMoimLabel style={{ marginBottom: 0, color: "#8c8c8c" }}>
            {introduction?.length}/{INTRODUCTION_LENGTH_LIMIT}
            {t("common.characters")}
          </UpdateMoimLabel>
        </UpdateMoimLabelRow>
        <UpdateMoimTextarea
          style={{ height: 96 }}
          disabled={disabled}
          value={introduction}
          maxLength={INTRODUCTION_LENGTH_LIMIT}
          placeholder={t("updateMoimDetail.introductionPlaceholder")}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            onInputChange(e, "introduction")
          }
        />
      </UpdateMoimInputSection>
    ),
    [introduction, onInputChange]
  );

  const memoizedDetailInput = useMemo(
    () => (
      <UpdateMoimInputSection>
        <UpdateMoimLabelRow>
          <UpdateMoimLabel style={{ marginBottom: 0 }}>
            {t("updateMoimDetail.detail")}
          </UpdateMoimLabel>
          <UpdateMoimLabel style={{ marginBottom: 0, color: "#8c8c8c" }}>
            {detail?.length}/{DETAIL_LENGTH_LIMIT}
            {t("common.characters")}
          </UpdateMoimLabel>
        </UpdateMoimLabelRow>
        <UpdateMoimTextarea
          style={{ height: 120 }}
          disabled={disabled}
          value={detail}
          maxLength={DETAIL_LENGTH_LIMIT}
          placeholder={t("updateMoimDetail.detailPlaceholder")}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            onInputChange(e, "detail")
          }
        />
      </UpdateMoimInputSection>
    ),
    [detail, onInputChange]
  );

  const memoizedMediaInput = useMemo(
    () => (
      <UpdateMoimInputSection style={{ marginBottom: theme.spacing.section }}>
        <MediaUploader
          wrapperStyle={{ marginBottom: theme.spacing.small }}
          inputRef={mediaRef}
          disabled={disabled}
          label={t("updateMoimDetail.media")}
          inputProps={{
            multiple: true,
            accept: "image/*",
            onChange: onMediaChange,
          }}
        />
        {((data?.media && data?.media?.length > 0) ||
          (mediaState && mediaState?.length > 0)) && (
          <MediaRow>
            {data?.media?.map((media) => (
              <ImagePreview
                wrapperStyle={{
                  flex: 1,
                }}
                size={{ height: isPc ? 127 : "35vw" }}
                disabled={disabled}
                key={media?.id}
                uri={media?.uri!}
                onDeleteClick={() => onMediaDelete(media?.id!)}
              />
            ))}
            {mediaState &&
              mediaState?.length > 0 &&
              mediaState?.map((media, index) => (
                <ImagePreview
                  wrapperStyle={{
                    flex: 1,
                  }}
                  size={{ height: isPc ? 127 : "35vw" }}
                  disabled={disabled}
                  key={media?.name + index}
                  uri={URL.createObjectURL(media)}
                  onDeleteClick={() => onMediaStateDelete(index)}
                />
              ))}
          </MediaRow>
        )}
      </UpdateMoimInputSection>
    ),
    [mediaState, data?.media, onMediaChange, onMediaStateDelete, onMediaDelete]
  );

  if (loading) return <div />;

  return (
    <UpdateMoimWrapper>
      <UpdateMoimStep>STEP3</UpdateMoimStep>
      <UpdateMoimTitle>{t("updateMoimDetail.title")}</UpdateMoimTitle>
      <UpdateMoimSubtitle>{t("updateMoimDetail.subtitle")}</UpdateMoimSubtitle>
      <UpdateMoimSeparator />

      <UpdateMoimSectionTitle>
        {t("updateMoimDetail.detailTitle")}
      </UpdateMoimSectionTitle>

      {memoizedCoverPhotoInput}
      {memoizedTitleInput}
      {memoizedIntroductionInput}
      {memoizedDetailInput}
      {memoizedMediaInput}
      <SaveButton disabled={disabled} onClick={onSaveClick}>
        {updateLoading ? t("common.updating") : t("common.save")}
      </SaveButton>
    </UpdateMoimWrapper>
  );
}

const MediaRow = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  alignItems: "stretch",
  justifyContent: "space-between",
  gap: theme.spacing.small,

  [theme.media.down["DESKTOP"]]: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing.xxSmall2,
  },
}));

export default UpdateMoimDetailContainer;
