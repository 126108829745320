import React, { RefObject } from "react";
import styled, { ThemedComponentProps } from "styled-components";
import cameraIcon from "../../../assets/icons/camera_switch_fill.svg";
import defaultProfile from "../../../assets/icons/default_profile.svg";
import deleteIcon from "../../../assets/icons/delete_fill.svg";
import { Col } from "../styles/commonStyles";

interface AvatarProps {
  inputRef?: RefObject<HTMLInputElement>;
  diameter?: number;
  uri?: string | null;
  editable?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDeleteClick?: () => void;
}

/**
 * Avatar Component
 * @param diameter - default 100(px)
 * @param uri - uri of the picture
 * @param editable - boolean
 */
function Avatar({
  inputRef,
  diameter = 100,
  uri,
  editable = false,
  onChange,
  onDeleteClick,
}: AvatarProps) {
  function onClick() {
    if (editable) {
      inputRef?.current?.click();
    }
  }

  function onCornerClick() {
    if (editable && uri && onDeleteClick) {
      onDeleteClick();
    }
  }

  return (
    <Wrapper>
      <Button diameter={diameter} editable={editable} onClick={onClick}>
        <Image diameter={diameter} src={uri || defaultProfile} />

        <input
          ref={inputRef}
          type="file"
          accept="image/*"
          onChange={onChange}
        />
      </Button>
      {editable && (
        <Corner onClick={onCornerClick}>
          <CornerIcon src={uri ? deleteIcon : cameraIcon} />
        </Corner>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  position: "relative",
  width: "fit-content",
  height: "fit-content",
}));

interface ButtonProps extends ThemedComponentProps {
  diameter: number;
  editable?: boolean;
}

const Button = styled(Col)<any>(
  ({ theme, diameter, editable }: ButtonProps) => ({
    alignItems: "center",
    justifyContent: "center",
    cursor: editable ? "pointer" : "default",
    width: diameter,
    height: diameter,
    borderRadius: diameter / 2,
    border: `2px solid #d0d0d0`,
  })
);

const Image = styled.img<any>(({ theme, diameter }: ButtonProps) => ({
  width: diameter,
  height: diameter,
  borderRadius: diameter / 2,
  objectFit: "cover",
}));

const Corner = styled(Col)<any>(({ theme }: ThemedComponentProps) => ({
  position: "absolute",
  bottom: 0,
  right: 0,
  zIndex: 2,
  width: 26,
  height: 26,
  borderRadius: 26 / 2,
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.color.white,
  border: `2px solid #d0d0d0`,
  cursor: "pointer",
}));

const CornerIcon = styled.img<any>(() => ({
  width: 16,
  height: 16,
}));

export default Avatar;
