import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled, { ThemedComponentProps, useTheme } from "styled-components";
import useSignUpContainer from "./SignUpContainer.hook";
import checkedIcon from "../../../../assets/icons/checkbox_checked.svg";
import uncheckedIcon from "../../../../assets/icons/checkbox_unchecked.svg";
import { useAuthContext } from "../../../../providers/AuthProvider";
import Modal from "../../../common/components/Modal";
import PrivacyPolicy from "../components/PrivacyPolicy";
import {
  Col,
  CommonInput,
  CommonInputErrorMessage,
  CommonInputLabel,
  DefaultButton,
  ModalButton,
  ModalTitle,
  Row,
} from "../../../common/styles/commonStyles";
import {
  AuthMessage,
  AuthMessageButton,
} from "../../common/styles/authCommonStyles";

function SignUpContainer() {
  const theme = useTheme();

  const { t, i18n } = useTranslation();
  const { toggleSignIn, toggleSignUp } = useAuthContext();

  const {
    refs: { emailInputRef, passwordInputRef, confirmPasswordInputRef },
    models: { state },
    operations: {
      onInputChange,
      onFocus,
      onBlur,
      onKeyDown,
      toggleAgreement,
      isSubmitDisabled,
      onSignUpClick,
      togglePolicy,
    },
  } = useSignUpContainer();

  const memoizedEmailInput = useMemo(
    () => (
      <div>
        <CommonInputLabel>{t("auth.email")}</CommonInputLabel>
        <CommonInput
          ref={emailInputRef}
          type="email"
          placeholder="fland@fland.com"
          value={state.email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "email")
          }
          onFocus={() => onFocus("email")}
          onBlur={() => onBlur("email")}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
            onKeyDown(e, "email")
          }
        />
        {state.emailError && (
          <CommonInputErrorMessage>
            {t(state.emailError)}
          </CommonInputErrorMessage>
        )}
      </div>
    ),
    [state.email, state.emailError]
  );

  const memoizedPasswordInput = useMemo(
    () => (
      <div>
        <CommonInputLabel>{t("auth.password")}</CommonInputLabel>
        <CommonInput
          ref={passwordInputRef}
          type="password"
          placeholder={t("auth.passwordPlaceholder")}
          minLength={6}
          value={state.password}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "password")
          }
          onFocus={() => onFocus("password")}
          onBlur={() => onBlur("password")}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
            onKeyDown(e, "password")
          }
        />
        {state.passwordError && (
          <CommonInputErrorMessage>
            {t(state.passwordError)}
          </CommonInputErrorMessage>
        )}
      </div>
    ),
    [state.password, state.passwordError]
  );

  const memoizedConfirmPasswordInput = useMemo(
    () => (
      <div>
        <CommonInputLabel>{t("auth.confirmPassword")}</CommonInputLabel>
        <CommonInput
          ref={confirmPasswordInputRef}
          type="password"
          placeholder={t("auth.passwordPlaceholder")}
          minLength={6}
          value={state.confirmPassword}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "confirmPassword")
          }
          onFocus={() => onFocus("confirmPassword")}
          onBlur={() => onBlur("confirmPassword")}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
            onKeyDown(e, "confirmPassword")
          }
        />
        {state.confirmPasswordError && (
          <CommonInputErrorMessage>
            {t(state.confirmPasswordError)}
          </CommonInputErrorMessage>
        )}
      </div>
    ),
    [state.confirmPassword, state.confirmPasswordError]
  );

  const memoizedAggreementRow = useMemo(
    () => (
      <AgreementRow>
        <CheckboxButton onClick={toggleAgreement}>
          <CheckBoxIcon src={state.agreed ? checkedIcon : uncheckedIcon} />
        </CheckboxButton>
        {i18n.resolvedLanguage.includes("ko") ? (
          <>
            <PrivacyPolicyButton onClick={() => togglePolicy(true)}>
              {t("auth.privacyPolicy1")}
            </PrivacyPolicyButton>
            <span>{t("auth.privacyPolicy2")}</span>
          </>
        ) : (
          <>
            <span>{t("auth.privacyPolicy2")}</span>
            <PrivacyPolicyButton onClick={() => togglePolicy(true)}>
              {t("auth.privacyPolicy1")}
            </PrivacyPolicyButton>
          </>
        )}
        {state?.showPolicy && (
          <Modal
            isOpen={state?.showPolicy}
            closeModal={() => togglePolicy(false)}
          >
            <PrivacyPolicy />
          </Modal>
        )}
      </AgreementRow>
    ),
    [state.agreed, state?.showPolicy]
  );

  return (
    <>
      <div>
        <ModalTitle>{t("common.signUp")}</ModalTitle>

        <AuthMessage style={{ marginBottom: theme.spacing.middle }}>
          {t("auth.alreadyRegistered")}{" "}
          <AuthMessageButton
            onClick={() => {
              toggleSignUp(false);
              toggleSignIn(true);
            }}
          >
            {t("auth.doSignIn")}
          </AuthMessageButton>
        </AuthMessage>

        <InputWrapper>
          {memoizedEmailInput}
          {memoizedPasswordInput}
          {memoizedConfirmPasswordInput}
        </InputWrapper>

        {/* {memoizedAggreementRow} */}

        <ModalButton disabled={isSubmitDisabled()} onClick={onSignUpClick}>
          {t("common.signUp")}
        </ModalButton>
      </div>
    </>
  );
}

const InputWrapper = styled(Col)<any>(({ theme }: ThemedComponentProps) => ({
  gap: theme.spacing.default,
  marginBottom: theme.spacing.large,
}));

const AgreementRow = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  alignItems: "center",
  justifyContent: "center",
  fontSize: theme.font.caption1,
  color: theme.color.black,
  width: "100%",
  marginBottom: theme.spacing.middle,
}));

const CheckboxButton = styled(DefaultButton)<any>(
  ({ theme }: ThemedComponentProps) => ({
    width: 16,
    height: 16,
    marginRight: theme.spacing.small,
  })
);

const CheckBoxIcon = styled.img<any>(() => ({
  width: "100%",
  height: "100%",
}));

const PrivacyPolicyButton = styled(AuthMessageButton)<any>(() => ({
  fontSize: "inherit",
  margin: 0,
}));

export default SignUpContainer;
