import { useGetContentFieldsQuery } from "../../../lib/apollo/graphql/generated";

function useContentsListFields() {
  const { data, loading } = useGetContentFieldsQuery();

  return {
    models: {
      loading,
      data: data?.getContentFields,
    },
  };
}

export default useContentsListFields;
