import styled, { ThemedComponentProps } from "styled-components";
import { DefaultButton, Row } from "../../../common/styles/commonStyles";

export const UpdateMoimWrapper = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    padding: `${theme.spacing.section}px ${theme.spacing.regular * 3}px`,
    backgroundColor: theme.color.white,
    width: "100%",
    color: theme.color.black,

    [theme.media.down["DESKTOP"]]: {
      padding: `${theme.spacing.section}px ${theme.spacing.regular}px`,
    },
  })
);

export const UpdateMoimStep = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    fontSize: theme.font.body3,
    color: theme.color.green,
    fontWeight: "bold",
  })
);

export const UpdateMoimTitle = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    fontSize: theme.font.head2,
    fontWeight: "bold",

    [theme.media.down["DESKTOP"]]: {
      fontSize: theme.font.body2,
    },
  })
);

export const UpdateMoimSubtitle = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    fontSize: theme.font.body3,

    [theme.media.down["DESKTOP"]]: {
      fontSize: theme.font.caption1,
    },
  })
);

export const UpdateMoimSeparator = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    margin: `${theme.spacing.section}px 0px`,
    width: "100%",
    height: 1,
    backgroundColor: theme.color.border,
  })
);

export const UpdateMoimSectionTitle = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    fontWeight: "bold",
    fontSize: theme.font.body1,
    marginBottom: theme.spacing.middle,

    [theme.media.down["DESKTOP"]]: {
      fontSize: theme.font.body2,
      marginBottom: theme.spacing.regular,
    },
  })
);

export const UpdateMoimInputSection = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    marginBottom: theme.spacing.middle,

    [theme.media.down["DESKTOP"]]: {
      marginBottom: theme.spacing.regular,
    },
  })
);

export const UpdateMoimLabelRow = styled(Row)<any>(
  ({ theme }: ThemedComponentProps) => ({
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing.small,
  })
);

export const UpdateMoimLabel = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    fontSize: theme.font.caption1,
    marginBottom: theme.spacing.small,
  })
);

export const UpdateMoimInput = styled.input<any>(
  ({ theme }: ThemedComponentProps) => ({
    width: "100%",
    padding: `${theme.spacing.normal}px ${theme.spacing.default}px`,
    border: `1px solid #d0d0d0`,
    backgroundColor: theme.color.white,

    fontSize: theme.font.body3,

    ":placeholder": {
      color: "#8c8c8c",
    },

    ":disabled": {
      backgroundColor: theme.color.background,
    },
  })
);

export const UpdateMoimTextarea = styled.textarea<any>(
  ({ theme }: ThemedComponentProps) => ({
    width: "100%",
    padding: `${theme.spacing.normal}px ${theme.spacing.default}px`,
    border: `1px solid #d0d0d0`,
    backgroundColor: theme.color.white,
    height: 72,
    resize: "none",

    fontSize: theme.font.body3,

    ":placeholder": {
      color: "#8c8c8c",
    },

    ":disabled": {
      backgroundColor: theme.color.background,
    },

    [theme.media.down["DESKTOP"]]: {
      height: 96,
    },
  })
);

export const UpdateMoimSelectButtonsRow = styled(Row)<any>(() => ({
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  border: `1px solid #d0d0d0`,
}));

interface UpdateMoimSelectButtonProps extends ThemedComponentProps {
  selected?: boolean;
  showBorderRight?: boolean;
  disabled?: boolean;
}

export const UpdateMoimSelectButton = styled(DefaultButton)<any>(
  ({
    theme,
    selected = false,
    showBorderRight = false,
    disabled = false,
  }: UpdateMoimSelectButtonProps) => ({
    borderRight: showBorderRight ? `1px solid #d0d0d0` : "none",
    flex: 1,
    padding: `${theme.spacing.normal}px 0px`,
    textAlign: "center",
    color: theme.color.black,
    backgroundColor: selected ? theme.color.beige : theme.color.white,
    pointerEvents: disabled ? "none" : "auto",
    opacity: disabled ? 0.7 : 1,
  })
);
