import { format } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";
import styled, { ThemedComponentProps } from "styled-components";
import { MoimContentSectionProps } from "./MoimContentSection";
import { useGetMoimContent } from "../../../common/hook/useGetMoimContent";
import { Col, Row } from "../../../common/styles/commonStyles";

function MoimStickyInfo({ moim }: MoimContentSectionProps) {
  const { t } = useTranslation();

  const {
    place,
    moimStartAt,
    moimEndAt,
    genderRestriction,
    minAge,
    maxAge,
    minPersons,
    maxPersons,
  } = moim;

  const { formattedMoimStartAt, formattedMoimStartAndEndHour } =
    useGetMoimContent(null, moimStartAt, moimEndAt);

  return (
    <Wrapper>
      <TopRow>
        <Label>{t("moim.placeTitle")}</Label>
        <Value>
          {!!place?.name && <ValueStrong>{place?.name}</ValueStrong>}
          <div>
            {place?.address}
            {!!place?.addressDetail && ", " + place?.addressDetail}
          </div>
        </Value>
      </TopRow>
      <TopRow>
        <Label>{t("moim.moimStartAtTitle")}</Label>
        <Value>
          <ValueStrong>{formattedMoimStartAt}</ValueStrong>
          <div>{formattedMoimStartAndEndHour}</div>
        </Value>
      </TopRow>
      <BottomRow>
        <BottomRowCell>
          <div>
            <strong>{t("moim.genderRestrictionTitle")}</strong>
          </div>
          <div>
            {genderRestriction
              ? t("common." + genderRestriction)
              : t("common.null")}
          </div>
        </BottomRowCell>
        <BottomRowCell>
          <div>
            <strong>{t("moim.ageRestrictionTitle")}</strong>
          </div>
          <div>
            {!minAge && !maxAge
              ? t("common.null")
              : !!minAge
              ? minAge + t("common.yearOld")
              : ""}
            {!!maxAge ? " ~ " + maxAge + t("common.yearOld") : ""}
          </div>
        </BottomRowCell>
        <BottomRowCell>
          <div>
            <strong>{t("moim.personsTitle")}</strong>
          </div>
          <div>
            {!minPersons && !maxPersons
              ? t("common.null")
              : !!minPersons
              ? minPersons + t("common.persons")
              : ""}
            {!!maxPersons ? " ~ " + maxPersons + t("common.persons") : ""}
          </div>
        </BottomRowCell>
      </BottomRow>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  backgroundColor: theme.color.white,
  border: `1px solid ${theme.color.border}`,
  padding: `${theme.spacing.large}px ${theme.spacing.middle}px`,
  fontSize: theme.font.caption1,
  width: "100%",

  [theme.media.down["DESKTOP"]]: {
    marginBottom: theme.spacing.section,
    padding: `${theme.spacing.middle}px ${theme.spacing.default}px`,
  },
}));

const TopRow = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  gap: theme.spacing.default,
  alignItems: "flex-start",
  marginBottom: theme.spacing.middle,

  [theme.media.down["DESKTOP"]]: {
    marginBottom: theme.spacing.default,
  },
}));

const Label = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  color: "#8c8c8c",
  fontWeight: "bold",
  flex: 1,
}));

const Value = styled.div<any>(() => ({
  flex: 4,
}));

const ValueStrong = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.body3,
  fontWeight: "bold",
}));

const BottomRow = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  backgroundColor: "rgba(208,208,208,0.2)",
  padding: `${theme.spacing.default}px ${theme.spacing.middle}px`,

  [theme.media.down["DESKTOP"]]: {
    padding: theme.spacing.default,
  },
}));

const BottomRowCell = styled(Col)<any>(({ theme }: ThemedComponentProps) => ({
  gap: theme.spacing.xxSmall2,
  alignItems: "flex-start",
  flex: 1,
}));

export default MoimStickyInfo;
