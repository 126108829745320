import React from "react";
import styled, { ThemedComponentProps } from "styled-components";
import useDropdown from "./Dropdown.hook";
import arrowIcon from "../../../assets/icons/arrow_down_white.svg";
import { useScreenWidthContext } from "../../../providers/ScreenWidthProvider";
import { DefaultButton, Row } from "../styles/commonStyles";

interface Option {
  text: string;
  onClick: () => void;
}

interface DropdownProps {
  label: string;
  options: Option[];
}

function Dropdown({ label, options }: DropdownProps) {
  const { isPc } = useScreenWidthContext();

  const {
    refs: { dropdownRef },
    models: { open },
    operations: { toggleOpen },
  } = useDropdown();

  return (
    <Wrapper ref={dropdownRef}>
      <Label onClick={() => toggleOpen(!open)}>
        <div>{label}</div>
        {isPc && <Arrow src={arrowIcon} />}
      </Label>
      {open && (
        <MenuWrapper>
          {options?.map((option) => (
            <MenuItem
              key={option.text}
              onClick={() => {
                option.onClick();
                toggleOpen(false);
              }}
            >
              {option.text}
            </MenuItem>
          ))}
        </MenuWrapper>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(() => ({
  position: "relative",
}));

const Label = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  alignItems: "center",
  justifyContent: "space-between",
  cursor: "pointer",
  fontSize: theme.font.body3,
  color: theme.color.white,
  padding: `${theme.spacing.small}px ${theme.spacing.normal}px`,
  borderRadius: 2,

  ":hover": {
    backgroundColor: "rgba(35,35,35,0.3)",
  },

  [theme.media.down["DESKTOP"]]: {
    justifyContent: "center",
    padding: `${theme.spacing.default}px 0px`,
  },
}));

const Arrow = styled.img<any>(({ theme }: ThemedComponentProps) => ({
  color: theme.color.white,
  width: 24,
  height: 24,
}));

const MenuWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: `${theme.spacing.xxSmall2}px 0px`,
  borderRadius: 2,
  position: "absolute",
  top: "100%",
  left: 0,
  backgroundColor: theme.color.white,
  color: theme.color.black,
  minWidth: "100%",
  width: "fit-content",
  border: `1px solid ${theme.color.border}`,
}));

const MenuItem = styled(DefaultButton)<any>(
  ({ theme }: ThemedComponentProps) => ({
    width: "100%",
    textAlign: "center",
    padding: `${theme.spacing.small}px ${theme.spacing.regular}px`,
    fontSize: theme.font.body3,

    ":hover": {
      backgroundColor: theme.color.subGreen,
    },
  })
);

export default Dropdown;
