import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  Upload: any;
};

export type AdminMoimContentInput = {
  detail?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  introduction?: InputMaybe<Scalars["String"]>;
  languageCode: Scalars["String"];
  moimId: Scalars["ID"];
  price?: InputMaybe<Scalars["Float"]>;
  title?: InputMaybe<Scalars["String"]>;
};

export type Business = {
  __typename?: "Business";
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  name: Scalars["String"];
  ownerName: Scalars["String"];
  registrationMedia: Media;
  registrationNumber: Scalars["String"];
  taxEmail: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export type BusinessHour = {
  __typename?: "BusinessHour";
  breakTimeFrom?: Maybe<Scalars["String"]>;
  breakTimeTo?: Maybe<Scalars["String"]>;
  dayOfWeek: Scalars["Int"];
  from: Scalars["String"];
  id: Scalars["ID"];
  place: Place;
  to: Scalars["String"];
};

export type BusinessHourInput = {
  breakTimeFrom?: InputMaybe<Scalars["String"]>;
  breakTimeTo?: InputMaybe<Scalars["String"]>;
  dayOfWeek: Scalars["Int"];
  from: Scalars["String"];
  id?: InputMaybe<Scalars["ID"]>;
  to: Scalars["String"];
};

export type BusinessInput = {
  id?: InputMaybe<Scalars["ID"]>;
  name: Scalars["String"];
  ownerName: Scalars["String"];
  registrationMediaInput?: InputMaybe<MediaInput>;
  registrationNumber: Scalars["String"];
  taxEmail: Scalars["String"];
};

export type Community = {
  __typename?: "Community";
  body: Scalars["String"];
  createdAt: Scalars["DateTime"];
  externalLink?: Maybe<Scalars["String"]>;
  from?: Maybe<Scalars["Date"]>;
  host?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  media?: Maybe<Array<Maybe<Media>>>;
  title: Scalars["String"];
  to?: Maybe<Scalars["Date"]>;
  updatedAt: Scalars["DateTime"];
  uploader: UserProfile;
};

export type CommunityConnection = {
  __typename?: "CommunityConnection";
  edges: Array<Maybe<Community>>;
  pageInfo: PageInfo;
};

export type CommunityInput = {
  body: Scalars["String"];
  externalLink?: InputMaybe<Scalars["String"]>;
  from?: InputMaybe<Scalars["Date"]>;
  host?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  title: Scalars["String"];
  to?: InputMaybe<Scalars["Date"]>;
  uploaderId?: InputMaybe<Scalars["ID"]>;
};

export type Content = {
  __typename?: "Content";
  body: Scalars["String"];
  createdAt: Scalars["DateTime"];
  fields: Array<Maybe<Field>>;
  id: Scalars["String"];
  place: Place;
  primaryMedia?: Maybe<Media>;
  readCount: Scalars["Int"];
  subtitle?: Maybe<Scalars["String"]>;
  themes: Array<Maybe<Theme>>;
  thumbnail: Media;
  title: Scalars["String"];
  updatedAt: Scalars["DateTime"];
  uploader?: Maybe<UserProfile>;
  viewCount: Scalars["Int"];
};

export type ContentConnection = {
  __typename?: "ContentConnection";
  edges: Array<Maybe<Content>>;
  pageInfo: PageInfo;
};

export type ContentInput = {
  body: Scalars["String"];
  fieldIds: Array<InputMaybe<Scalars["ID"]>>;
  id?: InputMaybe<Scalars["String"]>;
  placeId: Scalars["ID"];
  primaryMediaInput?: InputMaybe<MediaInput>;
  subtitle?: InputMaybe<Scalars["String"]>;
  themeIds: Array<InputMaybe<Scalars["ID"]>>;
  thumbnailInput?: InputMaybe<MediaInput>;
  title: Scalars["String"];
  uploaderId?: InputMaybe<Scalars["ID"]>;
};

export type ContentRead = {
  __typename?: "ContentRead";
  city?: Maybe<Scalars["String"]>;
  content: Content;
  countryCode?: Maybe<Scalars["String"]>;
  countryName?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  ipV4Address?: Maybe<Scalars["String"]>;
  latitude?: Maybe<Scalars["Float"]>;
  longitude?: Maybe<Scalars["Float"]>;
  state?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  user?: Maybe<UserProfile>;
};

export type ContentReadInput = {
  city?: InputMaybe<Scalars["String"]>;
  countryCode?: InputMaybe<Scalars["String"]>;
  countryName?: InputMaybe<Scalars["String"]>;
  ipV4Address?: InputMaybe<Scalars["String"]>;
  latitude?: InputMaybe<Scalars["Float"]>;
  longitude?: InputMaybe<Scalars["Float"]>;
  state?: InputMaybe<Scalars["String"]>;
};

export enum ContentSortOption {
  Popular = "POPULAR",
  Recent = "RECENT",
}

export type CreateMoimFaq = {
  __typename?: "CreateMoimFaq";
  active: Scalars["Boolean"];
  answer: Scalars["String"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  languageCode: Scalars["String"];
  question: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export type CreateMoimFaqInput = {
  active: Scalars["Boolean"];
  answer: Scalars["String"];
  id?: InputMaybe<Scalars["ID"]>;
  languageCode: Scalars["String"];
  question: Scalars["String"];
};

export type EmailList = {
  __typename?: "EmailList";
  createdAt: Scalars["DateTime"];
  email: Scalars["String"];
  id: Scalars["ID"];
  updatedAt: Scalars["DateTime"];
};

export type EmailListConnection = {
  __typename?: "EmailListConnection";
  edges: Array<Maybe<EmailList>>;
  pageInfo: PageInfo;
};

export type EmailListInput = {
  email: Scalars["String"];
};

export type Field = {
  __typename?: "Field";
  iconUrl?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
  order: Scalars["Int"];
};

export type FieldInput = {
  iconUrl?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  name: Scalars["String"];
  order: Scalars["Int"];
};

export type File = {
  __typename?: "File";
  encoding: Scalars["String"];
  filename: Scalars["String"];
  mimetype: Scalars["String"];
};

export type Footer = {
  __typename?: "Footer";
  address?: Maybe<Scalars["String"]>;
  companyName?: Maybe<Scalars["String"]>;
  copyright?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  instagram?: Maybe<Scalars["String"]>;
  kakao?: Maybe<Scalars["String"]>;
};

export enum Gender {
  Female = "FEMALE",
  Male = "MALE",
}

export type HomeTopMoims = {
  __typename?: "HomeTopMoims";
  mainIntroduction?: Maybe<Scalars["String"]>;
  mainIntroductionSubtitle?: Maybe<Scalars["String"]>;
  mainIntroductionTitle?: Maybe<Scalars["String"]>;
  mainTitle?: Maybe<Scalars["String"]>;
  top: Array<Maybe<Moim>>;
};

export type Host = {
  __typename?: "Host";
  bankAccount?: Maybe<Scalars["String"]>;
  bankAccountMedia?: Maybe<Media>;
  bankName?: Maybe<Scalars["String"]>;
  business?: Maybe<Business>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  idNumber?: Maybe<Scalars["String"]>;
  introduction?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  user: UserProfile;
  verified: Scalars["Boolean"];
};

export type HostConnection = {
  __typename?: "HostConnection";
  edges: Array<Maybe<Host>>;
  pageInfo: PageInfo;
};

export type HostInput = {
  bankAccount?: InputMaybe<Scalars["String"]>;
  bankAccountMediaInput?: InputMaybe<MediaInput>;
  bankName?: InputMaybe<Scalars["String"]>;
  idNumber?: InputMaybe<Scalars["String"]>;
  introduction?: InputMaybe<Scalars["String"]>;
};

export type Job = {
  __typename?: "Job";
  iconUrl?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
  order: Scalars["Int"];
};

export type JobInput = {
  iconUrl?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  name: Scalars["String"];
  order: Scalars["Int"];
};

export type Join = {
  __typename?: "Join";
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  moim: Moim;
  status: JoinStatus;
  updatedAt: Scalars["DateTime"];
  user: UserProfile;
};

export type JoinConnection = {
  __typename?: "JoinConnection";
  edges: Array<Maybe<Join>>;
  pageInfo: PageInfo;
};

export enum JoinStatus {
  Banned = "BANNED",
  Cancelled = "CANCELLED",
  Joined = "JOINED",
}

export type MainPhrases = {
  __typename?: "MainPhrases";
  mainDescription?: Maybe<Scalars["String"]>;
  mainTitle?: Maybe<Scalars["String"]>;
};

export type Management = {
  __typename?: "Management";
  address?: Maybe<Scalars["String"]>;
  companyName?: Maybe<Scalars["String"]>;
  copyright?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  homeThemeCount?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  instagram?: Maybe<Scalars["String"]>;
  kakao?: Maybe<Scalars["String"]>;
  languageCode?: Maybe<Scalars["String"]>;
  mainDescription?: Maybe<Scalars["String"]>;
  mainIntroduction?: Maybe<Scalars["String"]>;
  mainIntroductionSubtitle?: Maybe<Scalars["String"]>;
  mainIntroductionTitle?: Maybe<Scalars["String"]>;
  mainTitle?: Maybe<Scalars["String"]>;
  refundPolicy?: Maybe<Scalars["String"]>;
};

export type ManagementInput = {
  address?: InputMaybe<Scalars["String"]>;
  companyName?: InputMaybe<Scalars["String"]>;
  copyright?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  homeThemeCount?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["Int"]>;
  instagram?: InputMaybe<Scalars["String"]>;
  kakao?: InputMaybe<Scalars["String"]>;
  languageCode?: InputMaybe<Scalars["String"]>;
  mainDescription?: InputMaybe<Scalars["String"]>;
  mainIntroduction?: InputMaybe<Scalars["String"]>;
  mainIntroductionSubtitle?: InputMaybe<Scalars["String"]>;
  mainIntroductionTitle?: InputMaybe<Scalars["String"]>;
  mainTitle?: InputMaybe<Scalars["String"]>;
  refundPolicy?: InputMaybe<Scalars["String"]>;
};

export type Media = {
  __typename?: "Media";
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  name: Scalars["String"];
  size: Scalars["Int"];
  /** This is provided only when thumbnailFile is uploaded */
  thumbnailUri?: Maybe<Scalars["String"]>;
  type: Scalars["String"];
  uri: Scalars["String"];
};

export type MediaInput = {
  file: Scalars["Upload"];
  thumbnailFile?: InputMaybe<Scalars["Upload"]>;
};

export type Moim = {
  __typename?: "Moim";
  coverPhoto?: Maybe<Media>;
  createdAt: Scalars["DateTime"];
  genderRestriction?: Maybe<Gender>;
  host: Host;
  id: Scalars["ID"];
  maxAge?: Maybe<Scalars["Int"]>;
  maxPersons?: Maybe<Scalars["Int"]>;
  media: Array<Maybe<Media>>;
  message?: Maybe<Scalars["String"]>;
  minAge?: Maybe<Scalars["Int"]>;
  minPersons?: Maybe<Scalars["Int"]>;
  moimContents?: Maybe<Array<Maybe<MoimContent>>>;
  moimEndAt?: Maybe<Scalars["DateTime"]>;
  moimStartAt?: Maybe<Scalars["DateTime"]>;
  myReview?: Maybe<Review>;
  participants: Array<Maybe<Join>>;
  place?: Maybe<Place>;
  reviews: Array<Maybe<Review>>;
  status: MoimStatus;
  themes: Array<Maybe<Theme>>;
  updatedAt: Scalars["DateTime"];
  viewCount: Scalars["Int"];
};

export type MoimBasicInput = {
  genderRestriction?: InputMaybe<Gender>;
  hostId?: InputMaybe<Scalars["ID"]>;
  id?: InputMaybe<Scalars["ID"]>;
  languageCode: Scalars["String"];
  maxAge?: InputMaybe<Scalars["Int"]>;
  maxPersons?: InputMaybe<Scalars["Int"]>;
  minAge?: InputMaybe<Scalars["Int"]>;
  minPersons?: InputMaybe<Scalars["Int"]>;
  moimEndAt?: InputMaybe<Scalars["DateTime"]>;
  moimStartAt?: InputMaybe<Scalars["DateTime"]>;
  placeId?: InputMaybe<Scalars["ID"]>;
  price?: InputMaybe<Scalars["Float"]>;
};

export type MoimConnection = {
  __typename?: "MoimConnection";
  edges: Array<Maybe<Moim>>;
  pageInfo: PageInfo;
};

export type MoimContent = {
  __typename?: "MoimContent";
  createdAt: Scalars["DateTime"];
  detail?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  introduction?: Maybe<Scalars["String"]>;
  languageCode: Scalars["String"];
  price?: Maybe<Scalars["Float"]>;
  title?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

export type MoimContentInput = {
  coverPhotoInput?: InputMaybe<MediaInput>;
  detail?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  introduction?: InputMaybe<Scalars["String"]>;
  languageCode: Scalars["String"];
  mediaInput?: InputMaybe<Array<InputMaybe<MoimMediaInput>>>;
  moimId: Scalars["ID"];
  title?: InputMaybe<Scalars["String"]>;
};

export type MoimMediaInput = {
  mediaInput: MediaInput;
  order: Scalars["Int"];
};

export enum MoimStatus {
  Accepted = "ACCEPTED",
  Editing = "EDITING",
  Finished = "FINISHED",
  LackOfParticipants = "LACK_OF_PARTICIPANTS",
  Listed = "LISTED",
  OnGoing = "ON_GOING",
  PendingListed = "PENDING_LISTED",
  Rejected = "REJECTED",
  Reviewing = "REVIEWING",
}

export type MoimThemeInput = {
  order: Scalars["Int"];
  themeId: Scalars["ID"];
};

export type Mutation = {
  __typename?: "Mutation";
  _empty?: Maybe<Scalars["Boolean"]>;
  addEmailList: EmailList;
  addMoimMedia: Moim;
  addPlaceMedia: Place;
  cancelJoinMoim: Join;
  createPlace: Place;
  /** Create a new question */
  createQuestion: Question;
  deleteBusinessHour: Place;
  /** Delete community */
  deleteCommunity: Scalars["Boolean"];
  /** Delete community's media */
  deleteCommunityMedia: Community;
  deleteContent: Scalars["Boolean"];
  deleteMoimCoverPhoto: Moim;
  deleteMoimMedia: Moim;
  deletePlaceMedia: Place;
  deleteUserAvatar: UserProfile;
  deleteUserAvatarAsAdmin: UserProfile;
  incrementContentReadCount: Content;
  joinMoim: Join;
  requestMoimVerification: Moim;
  requestToBeHost: Host;
  setAdminUserAsAdmin: Scalars["Boolean"];
  setMoimStatusAsAdmin: Moim;
  toggleHostVerifiedAsAdmin: Host;
  togglePlaceActive: Place;
  /** @deprecated No longer supported */
  toggleProviderActiveAsAdmin: Provider;
  /** Toggle question read as admin to track questions */
  toggleQuestionReadAsAdmin: Question;
  updateBusinessHours: Place;
  /**
   * Update community
   * if id is given, it updates
   * else it creates
   */
  updateCommunity: Community;
  /** Update community's media */
  updateCommunityMedia: Community;
  updateContentAsAdmin: Content;
  updateCreateMoimFaqAsAdmin: CreateMoimFaq;
  updateFieldAsAdmin: Field;
  updateHost: Host;
  updateJobAsAdmin: Job;
  updateManagementAsAdmin: Management;
  updateMoimBasic: Moim;
  updateMoimContent: Moim;
  updateMoimContentAsAdmin: Moim;
  updateOccupationAsAdmin: Occupation;
  updatePlace: Place;
  /** @deprecated No longer supported */
  updateProviderAsAdmin: Provider;
  updateReview: Review;
  updateThemeAsAdmin: Theme;
  updateUserAvatar: UserProfile;
  /**
   * Update user profile
   * if user profile is not found, add a new profile
   * or update user's profile
   */
  updateUserProfile: UserProfile;
  updateUserProfileAsAdmin: UserProfile;
  /**
   * Verify provider as admin
   * @deprecated No longer supported
   */
  verifyProviderAsAdmin: Provider;
};

export type MutationAddEmailListArgs = {
  emailListInput: EmailListInput;
};

export type MutationAddMoimMediaArgs = {
  mediaInput: Array<InputMaybe<MoimMediaInput>>;
  moimId: Scalars["ID"];
};

export type MutationAddPlaceMediaArgs = {
  placeId: Scalars["ID"];
  placeMediaInput: Array<InputMaybe<PlaceMediaInput>>;
};

export type MutationCancelJoinMoimArgs = {
  joinId: Scalars["ID"];
};

export type MutationCreatePlaceArgs = {
  placeInput: PlaceInput;
};

export type MutationCreateQuestionArgs = {
  questionInput: QuestionInput;
};

export type MutationDeleteBusinessHourArgs = {
  businessHourId: Scalars["ID"];
  placeId: Scalars["ID"];
};

export type MutationDeleteCommunityArgs = {
  communityId: Scalars["ID"];
};

export type MutationDeleteCommunityMediaArgs = {
  communityId: Scalars["ID"];
  mediaId: Scalars["ID"];
};

export type MutationDeleteContentArgs = {
  contentId: Scalars["ID"];
};

export type MutationDeleteMoimCoverPhotoArgs = {
  moimId: Scalars["ID"];
};

export type MutationDeleteMoimMediaArgs = {
  mediaId: Scalars["ID"];
  moimId: Scalars["ID"];
};

export type MutationDeletePlaceMediaArgs = {
  mediaId: Scalars["ID"];
  placeId: Scalars["ID"];
};

export type MutationDeleteUserAvatarAsAdminArgs = {
  userId: Scalars["ID"];
};

export type MutationIncrementContentReadCountArgs = {
  contentId: Scalars["ID"];
  contentReadInput: ContentReadInput;
};

export type MutationJoinMoimArgs = {
  moimId: Scalars["ID"];
};

export type MutationRequestMoimVerificationArgs = {
  moimId: Scalars["ID"];
};

export type MutationRequestToBeHostArgs = {
  businessInput?: InputMaybe<BusinessInput>;
  hostInput: HostInput;
};

export type MutationSetAdminUserAsAdminArgs = {
  userId: Scalars["ID"];
};

export type MutationSetMoimStatusAsAdminArgs = {
  message?: InputMaybe<Scalars["String"]>;
  moimId: Scalars["ID"];
  status: MoimStatus;
};

export type MutationToggleHostVerifiedAsAdminArgs = {
  hostId: Scalars["ID"];
  verified: Scalars["Boolean"];
};

export type MutationTogglePlaceActiveArgs = {
  active: Scalars["Boolean"];
  placeId: Scalars["ID"];
};

export type MutationToggleProviderActiveAsAdminArgs = {
  active: Scalars["Boolean"];
  providerId: Scalars["ID"];
};

export type MutationToggleQuestionReadAsAdminArgs = {
  questionId: Scalars["ID"];
  read: Scalars["Boolean"];
};

export type MutationUpdateBusinessHoursArgs = {
  businessHourInputs: Array<InputMaybe<BusinessHourInput>>;
  placeId: Scalars["ID"];
};

export type MutationUpdateCommunityArgs = {
  communityInput: CommunityInput;
};

export type MutationUpdateCommunityMediaArgs = {
  communityId: Scalars["ID"];
  media: Array<InputMaybe<MediaInput>>;
};

export type MutationUpdateContentAsAdminArgs = {
  contentInput: ContentInput;
};

export type MutationUpdateCreateMoimFaqAsAdminArgs = {
  createMoimFaqInput: CreateMoimFaqInput;
};

export type MutationUpdateFieldAsAdminArgs = {
  fieldInput: FieldInput;
};

export type MutationUpdateHostArgs = {
  businessInput?: InputMaybe<BusinessInput>;
  hostInput: HostInput;
};

export type MutationUpdateJobAsAdminArgs = {
  jobInput: JobInput;
};

export type MutationUpdateManagementAsAdminArgs = {
  managementInput: ManagementInput;
};

export type MutationUpdateMoimBasicArgs = {
  moimBasicInput: MoimBasicInput;
};

export type MutationUpdateMoimContentArgs = {
  moimContentInput: MoimContentInput;
};

export type MutationUpdateMoimContentAsAdminArgs = {
  adminMoimContentInput?: InputMaybe<AdminMoimContentInput>;
};

export type MutationUpdateOccupationAsAdminArgs = {
  occupationInput: OccupationInput;
};

export type MutationUpdatePlaceArgs = {
  placeInput: PlaceInput;
};

export type MutationUpdateProviderAsAdminArgs = {
  providerInput: ProviderInput;
};

export type MutationUpdateReviewArgs = {
  reviewInput: ReviewInput;
};

export type MutationUpdateThemeAsAdminArgs = {
  themeInput: ThemeInput;
};

export type MutationUpdateUserAvatarArgs = {
  avatarInput: MediaInput;
};

export type MutationUpdateUserProfileArgs = {
  userInput: UserInput;
};

export type MutationUpdateUserProfileAsAdminArgs = {
  userId?: InputMaybe<Scalars["ID"]>;
  userInput: UserInput;
};

export type MutationVerifyProviderAsAdminArgs = {
  providerId: Scalars["ID"];
  verified: Scalars["Boolean"];
};

export type Nationality = {
  __typename?: "Nationality";
  emoji?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  imageUrl?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  unicode: Scalars["String"];
  users: Array<Maybe<UserProfile>>;
};

export type Occupation = {
  __typename?: "Occupation";
  iconUrl?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
  order: Scalars["Int"];
};

export type OccupationInput = {
  iconUrl?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  name: Scalars["String"];
  order: Scalars["Int"];
};

export type PageInfo = {
  __typename?: "PageInfo";
  endCursor?: Maybe<Scalars["String"]>;
  hasNextPage: Scalars["Boolean"];
};

export type PaginationInput = {
  after?: InputMaybe<Scalars["String"]>;
  first: Scalars["Int"];
};

export type Place = {
  __typename?: "Place";
  active: Scalars["Boolean"];
  address?: Maybe<Scalars["String"]>;
  addressDetail?: Maybe<Scalars["String"]>;
  businessHours?: Maybe<Array<Maybe<BusinessHour>>>;
  createdAt: Scalars["DateTime"];
  field?: Maybe<Field>;
  id: Scalars["ID"];
  isPrivate: Scalars["Boolean"];
  latitude?: Maybe<Scalars["Float"]>;
  longitude?: Maybe<Scalars["Float"]>;
  media?: Maybe<Array<Maybe<Media>>>;
  name?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

export type PlaceConnection = {
  __typename?: "PlaceConnection";
  edges: Array<Maybe<Place>>;
  pageInfo: PageInfo;
};

export type PlaceInput = {
  address?: InputMaybe<Scalars["String"]>;
  addressDetail?: InputMaybe<Scalars["String"]>;
  fieldId?: InputMaybe<Scalars["ID"]>;
  id?: InputMaybe<Scalars["ID"]>;
  isPrivate?: InputMaybe<Scalars["Boolean"]>;
  latitude?: InputMaybe<Scalars["Float"]>;
  longitude?: InputMaybe<Scalars["Float"]>;
  name?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
};

export type PlaceMediaInput = {
  mediaInput: MediaInput;
  order: Scalars["Int"];
};

export type Provider = {
  __typename?: "Provider";
  active: Scalars["Boolean"];
  avatar?: Maybe<Media>;
  createdAt: Scalars["DateTime"];
  email: Scalars["String"];
  id: Scalars["ID"];
  name: Scalars["String"];
  ownerName: Scalars["String"];
  phone: Scalars["String"];
  registrationMedia?: Maybe<Media>;
  registrationNumber?: Maybe<Scalars["String"]>;
  superUser: UserProfile;
  type: ProviderType;
  updatedAt: Scalars["DateTime"];
  verified: Scalars["Boolean"];
};

export type ProviderConnection = {
  __typename?: "ProviderConnection";
  edges: Array<Maybe<Provider>>;
  pageInfo: PageInfo;
};

export type ProviderInput = {
  avatarInput?: InputMaybe<MediaInput>;
  email: Scalars["String"];
  id?: InputMaybe<Scalars["ID"]>;
  name: Scalars["String"];
  ownerName: Scalars["String"];
  phone: Scalars["String"];
  registrationMediaInput?: InputMaybe<MediaInput>;
  registrationNumber?: InputMaybe<Scalars["String"]>;
  superUserId?: InputMaybe<Scalars["ID"]>;
  type: ProviderType;
};

export enum ProviderType {
  Cooperate = "COOPERATE",
  Personal = "PERSONAL",
  PersonalBusiness = "PERSONAL_BUSINESS",
}

export type Query = {
  __typename?: "Query";
  _empty?: Maybe<Scalars["Boolean"]>;
  /** Get all users as admin(only admin can access) */
  getAllUsersAsAdmin: UserConnection;
  getCommonTheme: Theme;
  /** Get all communities */
  getCommunities: CommunityConnection;
  /** Get the community by given id */
  getCommunity: Community;
  getContent: Content;
  getContentFields: Array<Maybe<Field>>;
  getContentReadByContentIdAsAdmin: Array<Maybe<ContentRead>>;
  getContentThemes: Array<Maybe<Theme>>;
  getContents: ContentConnection;
  getCreateMoimFaqs?: Maybe<Array<Maybe<CreateMoimFaq>>>;
  getCreateMoimFaqsAsAdmin?: Maybe<Array<Maybe<CreateMoimFaq>>>;
  getEmailListsAsAdmin: EmailListConnection;
  getEmailListsCountAsAdmin: Scalars["Int"];
  getFields: Array<Maybe<Field>>;
  getFooter?: Maybe<Footer>;
  getHomeMoims: MoimConnection;
  getHomeRecentContents: Array<Maybe<Content>>;
  getHomeThemes: Array<Maybe<Theme>>;
  getHomeTopMoims: HomeTopMoims;
  getHost: Host;
  getHostsByVerification: HostConnection;
  getJobs: Array<Maybe<Job>>;
  /** @deprecated No longer supported */
  getMainPhrases: MainPhrases;
  getManagementsAsAdmin: Array<Maybe<Management>>;
  getMoim: Moim;
  getMoimParticipants: Array<Maybe<Join>>;
  getMoimsByStatusAsAdmin: MoimConnection;
  getMyCreatedMoims?: Maybe<MoimConnection>;
  getMyJoinedMoims: JoinConnection;
  getMyReviews: ReviewConnection;
  /** Get current UserProfile's profile */
  getMyUserProfile?: Maybe<UserProfile>;
  getOccupations: Array<Maybe<Occupation>>;
  /** Get other communities except current community by id */
  getOtherCommunities: Array<Maybe<Community>>;
  getPlace: Place;
  getPlaces: PlaceConnection;
  /** @deprecated No longer supported */
  getProvider: Provider;
  /** @deprecated No longer supported */
  getProviderTypes: Array<Maybe<ProviderType>>;
  /** @deprecated No longer supported */
  getProvidersByType: ProviderConnection;
  /** Get the question by id */
  getQuestionAsAdmin: Question;
  getQuestionTypes: Array<Maybe<QuestionType>>;
  /** Get questions from user as admin */
  getQuestionsAsAdmin: QuestionConnection;
  getRecommendedContentsByContentId: Array<Maybe<Content>>;
  getRefundPolicy?: Maybe<Scalars["String"]>;
  getThemes: Array<Maybe<Theme>>;
  /** Get current UserProfile's profile */
  getUserProfile?: Maybe<UserProfile>;
  isUniqueUsername: Scalars["Boolean"];
  searchContents: ContentConnection;
  searchFields: Array<Maybe<Field>>;
  searchNationality: Array<Maybe<Nationality>>;
  searchPlaces: PlaceConnection;
  /** @deprecated No longer supported */
  searchProvider: ProviderConnection;
  searchUser: UserConnection;
};

export type QueryGetAllUsersAsAdminArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetCommunitiesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetCommunityArgs = {
  communityId: Scalars["ID"];
};

export type QueryGetContentArgs = {
  contentId: Scalars["ID"];
};

export type QueryGetContentReadByContentIdAsAdminArgs = {
  contentId: Scalars["ID"];
};

export type QueryGetContentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  fieldId?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<ContentSortOption>;
  themeId?: InputMaybe<Scalars["ID"]>;
};

export type QueryGetCreateMoimFaqsArgs = {
  languageCode?: InputMaybe<Scalars["String"]>;
};

export type QueryGetEmailListsAsAdminArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetFooterArgs = {
  languageCode?: InputMaybe<Scalars["String"]>;
};

export type QueryGetHomeMoimsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetHomeTopMoimsArgs = {
  languageCode: Scalars["String"];
};

export type QueryGetHostArgs = {
  hostId: Scalars["ID"];
};

export type QueryGetHostsByVerificationArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  verified?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetMoimArgs = {
  moimId: Scalars["ID"];
};

export type QueryGetMoimParticipantsArgs = {
  moimId: Scalars["ID"];
};

export type QueryGetMoimsByStatusAsAdminArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<MoimStatus>;
};

export type QueryGetMyCreatedMoimsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  moimStatus?: InputMaybe<MoimStatus>;
};

export type QueryGetMyJoinedMoimsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  moimStatus?: InputMaybe<MoimStatus>;
};

export type QueryGetMyReviewsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetOtherCommunitiesArgs = {
  communityId: Scalars["ID"];
};

export type QueryGetPlaceArgs = {
  placeId: Scalars["ID"];
};

export type QueryGetPlacesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetProviderArgs = {
  providerId: Scalars["ID"];
};

export type QueryGetProvidersByTypeArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  type?: InputMaybe<ProviderType>;
};

export type QueryGetQuestionAsAdminArgs = {
  questionId: Scalars["ID"];
};

export type QueryGetQuestionsAsAdminArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  type?: InputMaybe<QuestionType>;
};

export type QueryGetRecommendedContentsByContentIdArgs = {
  contentId: Scalars["ID"];
};

export type QueryGetRefundPolicyArgs = {
  languageCode?: InputMaybe<Scalars["String"]>;
};

export type QueryGetUserProfileArgs = {
  userId: Scalars["ID"];
};

export type QueryIsUniqueUsernameArgs = {
  username: Scalars["String"];
};

export type QuerySearchContentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  query: Scalars["String"];
};

export type QuerySearchFieldsArgs = {
  query: Scalars["String"];
};

export type QuerySearchNationalityArgs = {
  query: Scalars["String"];
};

export type QuerySearchPlacesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  query: Scalars["String"];
};

export type QuerySearchProviderArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  query: Scalars["String"];
};

export type QuerySearchUserArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  query: Scalars["String"];
};

export type Question = {
  __typename?: "Question";
  body: Scalars["String"];
  createdAt: Scalars["DateTime"];
  email: Scalars["String"];
  from?: Maybe<Scalars["Date"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
  phone: Scalars["String"];
  read: Scalars["Boolean"];
  to?: Maybe<Scalars["Date"]>;
  type: QuestionType;
  updatedAt: Scalars["DateTime"];
};

export type QuestionConnection = {
  __typename?: "QuestionConnection";
  edges: Array<Maybe<Question>>;
  pageInfo: PageInfo;
};

/** From, to should be set when type is EXPERIENCE */
export type QuestionInput = {
  body: Scalars["String"];
  email: Scalars["String"];
  from?: InputMaybe<Scalars["Date"]>;
  name: Scalars["String"];
  phone: Scalars["String"];
  to?: InputMaybe<Scalars["Date"]>;
  type: QuestionType;
};

export enum QuestionType {
  Book = "BOOK",
  Collaboration = "COLLABORATION",
  Experience = "EXPERIENCE",
  Register = "REGISTER",
  Service = "SERVICE",
}

export type Review = {
  __typename?: "Review";
  body: Scalars["String"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  moim: Moim;
  rating?: Maybe<Scalars["Int"]>;
  updatedAt: Scalars["DateTime"];
  writer: UserProfile;
};

export type ReviewConnection = {
  __typename?: "ReviewConnection";
  edges: Array<Maybe<Review>>;
  pageInfo: PageInfo;
};

export type ReviewInput = {
  body: Scalars["String"];
  id?: InputMaybe<Scalars["ID"]>;
  moimId: Scalars["ID"];
  rating?: InputMaybe<Scalars["Int"]>;
};

export type Theme = {
  __typename?: "Theme";
  contents?: Maybe<Array<Maybe<Content>>>;
  iconUrl?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
  order: Scalars["Int"];
  phrase: Scalars["String"];
};

export type ThemeContentsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
};

export type ThemeInput = {
  iconUrl?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  name: Scalars["String"];
  order: Scalars["Int"];
  phrase: Scalars["String"];
};

export type UserConnection = {
  __typename?: "UserConnection";
  edges: Array<Maybe<UserProfile>>;
  pageInfo: PageInfo;
};

export type UserInput = {
  dateOfBirth?: InputMaybe<Scalars["Date"]>;
  email?: InputMaybe<Scalars["String"]>;
  gender?: InputMaybe<Gender>;
  name?: InputMaybe<Scalars["String"]>;
  nationalityId?: InputMaybe<Scalars["ID"]>;
  phone?: InputMaybe<Scalars["String"]>;
  providerId?: InputMaybe<Scalars["ID"]>;
  username: Scalars["String"];
};

export type UserProfile = {
  __typename?: "UserProfile";
  avatar?: Maybe<Media>;
  createdAt: Scalars["DateTime"];
  dateOfBirth?: Maybe<Scalars["Date"]>;
  email?: Maybe<Scalars["String"]>;
  gender?: Maybe<Gender>;
  host?: Maybe<Host>;
  id: Scalars["ID"];
  isAdmin?: Maybe<Scalars["Boolean"]>;
  joins: Array<Maybe<Join>>;
  name?: Maybe<Scalars["String"]>;
  nationality?: Maybe<Nationality>;
  phone?: Maybe<Scalars["String"]>;
  provider?: Maybe<Provider>;
  reviewCount: Scalars["Int"];
  updatedAt: Scalars["DateTime"];
  username: Scalars["String"];
};

export type GetContentFieldsQueryVariables = Exact<{ [key: string]: never }>;

export type GetContentFieldsQuery = {
  __typename?: "Query";
  getContentFields: Array<{
    __typename?: "Field";
    id: string;
    name: string;
    iconUrl?: string | null;
    order: number;
  } | null>;
};

export type GetContentThemesQueryVariables = Exact<{ [key: string]: never }>;

export type GetContentThemesQuery = {
  __typename?: "Query";
  getContentThemes: Array<{
    __typename?: "Theme";
    id: string;
    name: string;
    phrase: string;
    iconUrl?: string | null;
    order: number;
  } | null>;
};

export type GetHomeRecentContentsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetHomeRecentContentsQuery = {
  __typename?: "Query";
  getHomeRecentContents: Array<{
    __typename?: "Content";
    id: string;
    title: string;
    createdAt: any;
    themes: Array<{ __typename?: "Theme"; id: string; name: string } | null>;
    fields: Array<{ __typename?: "Field"; id: string; name: string } | null>;
  } | null>;
};

export type GetContentsQueryVariables = Exact<{
  fieldId?: InputMaybe<Scalars["ID"]>;
  themeId?: InputMaybe<Scalars["ID"]>;
  sort?: InputMaybe<ContentSortOption>;
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
}>;

export type GetContentsQuery = {
  __typename?: "Query";
  getContents: {
    __typename?: "ContentConnection";
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      endCursor?: string | null;
    };
    edges: Array<{
      __typename?: "Content";
      id: string;
      title: string;
      viewCount: number;
      readCount: number;
      createdAt: any;
      updatedAt: any;
      thumbnail: { __typename?: "Media"; id: string; uri: string };
      themes: Array<{ __typename?: "Theme"; id: string; name: string } | null>;
      fields: Array<{ __typename?: "Field"; id: string; name: string } | null>;
    } | null>;
  };
};

export type GetContentQueryVariables = Exact<{
  contentId: Scalars["ID"];
}>;

export type GetContentQuery = {
  __typename?: "Query";
  getContent: {
    __typename?: "Content";
    id: string;
    title: string;
    subtitle?: string | null;
    body: string;
    viewCount: number;
    readCount: number;
    createdAt: any;
    updatedAt: any;
    uploader?: {
      __typename?: "UserProfile";
      id: string;
      username: string;
    } | null;
    place: {
      __typename?: "Place";
      id: string;
      name?: string | null;
      address?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      phone?: string | null;
      active: boolean;
      createdAt: any;
      updatedAt: any;
      field?: { __typename?: "Field"; id: string; name: string } | null;
      media?: Array<{
        __typename?: "Media";
        id: string;
        uri: string;
      } | null> | null;
    };
    primaryMedia?: { __typename?: "Media"; id: string; uri: string } | null;
    fields: Array<{
      __typename?: "Field";
      id: string;
      name: string;
      iconUrl?: string | null;
    } | null>;
    themes: Array<{
      __typename?: "Theme";
      id: string;
      name: string;
      iconUrl?: string | null;
    } | null>;
  };
};

export type GetRecommendedContentsByContentIdQueryVariables = Exact<{
  contentId: Scalars["ID"];
}>;

export type GetRecommendedContentsByContentIdQuery = {
  __typename?: "Query";
  getRecommendedContentsByContentId: Array<{
    __typename?: "Content";
    id: string;
    title: string;
    createdAt: any;
    updatedAt: any;
    thumbnail: { __typename?: "Media"; id: string; uri: string };
    themes: Array<{ __typename?: "Theme"; id: string; name: string } | null>;
    fields: Array<{ __typename?: "Field"; id: string; name: string } | null>;
  } | null>;
};

export type IncrementContentReadCountMutationVariables = Exact<{
  contentId: Scalars["ID"];
  contentReadInput: ContentReadInput;
}>;

export type IncrementContentReadCountMutation = {
  __typename?: "Mutation";
  incrementContentReadCount: {
    __typename?: "Content";
    id: string;
    readCount: number;
  };
};

export type AddEmailListMutationVariables = Exact<{
  emailListInput: EmailListInput;
}>;

export type AddEmailListMutation = {
  __typename?: "Mutation";
  addEmailList: { __typename?: "EmailList"; id: string; email: string };
};

export type PageInfoFieldFragment = {
  __typename?: "PageInfo";
  hasNextPage: boolean;
  endCursor?: string | null;
};

export type RequestToBeHostMutationVariables = Exact<{
  hostInput: HostInput;
  businessInput?: InputMaybe<BusinessInput>;
}>;

export type RequestToBeHostMutation = {
  __typename?: "Mutation";
  requestToBeHost: {
    __typename?: "Host";
    id: string;
    user: {
      __typename?: "UserProfile";
      id: string;
      username: string;
      name?: string | null;
      email?: string | null;
      phone?: string | null;
      gender?: Gender | null;
      nationality?: {
        __typename?: "Nationality";
        id: string;
        name: string;
      } | null;
      avatar?: { __typename?: "Media"; id: string; uri: string } | null;
      host?: {
        __typename?: "Host";
        id: string;
        bankName?: string | null;
        bankAccount?: string | null;
        introduction?: string | null;
        verified: boolean;
        idNumber?: string | null;
        createdAt: any;
        updatedAt: any;
        bankAccountMedia?: {
          __typename?: "Media";
          id: string;
          name: string;
          uri: string;
        } | null;
        business?: {
          __typename?: "Business";
          id: string;
          name: string;
          ownerName: string;
          registrationNumber: string;
          taxEmail: string;
          createdAt: any;
          updatedAt: any;
          registrationMedia: {
            __typename?: "Media";
            id: string;
            name: string;
            uri: string;
          };
        } | null;
      } | null;
    };
  };
};

export type UpdateHostMutationVariables = Exact<{
  hostInput: HostInput;
  businessInput?: InputMaybe<BusinessInput>;
}>;

export type UpdateHostMutation = {
  __typename?: "Mutation";
  updateHost: {
    __typename?: "Host";
    id: string;
    user: {
      __typename?: "UserProfile";
      id: string;
      username: string;
      name?: string | null;
      email?: string | null;
      phone?: string | null;
      gender?: Gender | null;
      nationality?: {
        __typename?: "Nationality";
        id: string;
        name: string;
      } | null;
      avatar?: { __typename?: "Media"; id: string; uri: string } | null;
      host?: {
        __typename?: "Host";
        id: string;
        bankName?: string | null;
        bankAccount?: string | null;
        introduction?: string | null;
        verified: boolean;
        idNumber?: string | null;
        createdAt: any;
        updatedAt: any;
        bankAccountMedia?: {
          __typename?: "Media";
          id: string;
          name: string;
          uri: string;
        } | null;
        business?: {
          __typename?: "Business";
          id: string;
          name: string;
          ownerName: string;
          registrationNumber: string;
          taxEmail: string;
          createdAt: any;
          updatedAt: any;
          registrationMedia: {
            __typename?: "Media";
            id: string;
            name: string;
            uri: string;
          };
        } | null;
      } | null;
    };
  };
};

export type GetHomeTopMoimsQueryVariables = Exact<{
  languageCode: Scalars["String"];
}>;

export type GetHomeTopMoimsQuery = {
  __typename?: "Query";
  getHomeTopMoims: {
    __typename?: "HomeTopMoims";
    mainTitle?: string | null;
    mainIntroductionTitle?: string | null;
    mainIntroductionSubtitle?: string | null;
    mainIntroduction?: string | null;
    top: Array<{
      __typename?: "Moim";
      id: string;
      createdAt: any;
      updatedAt: any;
      status: MoimStatus;
      moimContents?: Array<{
        __typename?: "MoimContent";
        languageCode: string;
        title?: string | null;
        createdAt: any;
        updatedAt: any;
      } | null> | null;
      coverPhoto?: { __typename?: "Media"; id: string; uri: string } | null;
    } | null>;
  };
};

export type GetHomeMoimsQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
}>;

export type GetHomeMoimsQuery = {
  __typename?: "Query";
  getHomeMoims: {
    __typename?: "MoimConnection";
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      endCursor?: string | null;
    };
    edges: Array<{
      __typename?: "Moim";
      id: string;
      moimStartAt?: any | null;
      status: MoimStatus;
      createdAt: any;
      updatedAt: any;
      maxPersons?: number | null;
      minPersons?: number | null;
      place?: {
        __typename?: "Place";
        id: string;
        name?: string | null;
        address?: string | null;
        addressDetail?: string | null;
        isPrivate: boolean;
      } | null;
      coverPhoto?: { __typename?: "Media"; id: string; uri: string } | null;
      moimContents?: Array<{
        __typename?: "MoimContent";
        id: string;
        languageCode: string;
        title?: string | null;
        introduction?: string | null;
        detail?: string | null;
        price?: number | null;
        createdAt: any;
        updatedAt: any;
      } | null> | null;
    } | null>;
  };
};

export type GetMainPhrasesQueryVariables = Exact<{ [key: string]: never }>;

export type GetMainPhrasesQuery = {
  __typename?: "Query";
  getMainPhrases: {
    __typename?: "MainPhrases";
    mainTitle?: string | null;
    mainDescription?: string | null;
  };
};

export type GetFooterQueryVariables = Exact<{
  languageCode?: InputMaybe<Scalars["String"]>;
}>;

export type GetFooterQuery = {
  __typename?: "Query";
  getFooter?: {
    __typename?: "Footer";
    companyName?: string | null;
    address?: string | null;
    email?: string | null;
    instagram?: string | null;
    kakao?: string | null;
    copyright?: string | null;
  } | null;
};

export type GetRefundPolicyQueryVariables = Exact<{
  languageCode?: InputMaybe<Scalars["String"]>;
}>;

export type GetRefundPolicyQuery = {
  __typename?: "Query";
  getRefundPolicy?: string | null;
};

export type GetCreateMoimFaqsQueryVariables = Exact<{
  languageCode?: InputMaybe<Scalars["String"]>;
}>;

export type GetCreateMoimFaqsQuery = {
  __typename?: "Query";
  getCreateMoimFaqs?: Array<{
    __typename?: "CreateMoimFaq";
    id: string;
    question: string;
    answer: string;
    active: boolean;
    languageCode: string;
    createdAt: any;
    updatedAt: any;
  } | null> | null;
};

export type GetMoimQueryVariables = Exact<{
  moimId: Scalars["ID"];
}>;

export type GetMoimQuery = {
  __typename?: "Query";
  getMoim: {
    __typename?: "Moim";
    id: string;
    moimStartAt?: any | null;
    moimEndAt?: any | null;
    maxPersons?: number | null;
    minPersons?: number | null;
    genderRestriction?: Gender | null;
    minAge?: number | null;
    maxAge?: number | null;
    status: MoimStatus;
    message?: string | null;
    viewCount: number;
    createdAt: any;
    updatedAt: any;
    host: {
      __typename?: "Host";
      id: string;
      introduction?: string | null;
      user: {
        __typename?: "UserProfile";
        id: string;
        username: string;
        name?: string | null;
        email?: string | null;
        phone?: string | null;
        dateOfBirth?: any | null;
        gender?: Gender | null;
        createdAt: any;
        updatedAt: any;
        nationality?: {
          __typename?: "Nationality";
          id: string;
          name: string;
        } | null;
        avatar?: { __typename?: "Media"; id: string; uri: string } | null;
      };
    };
    place?: {
      __typename?: "Place";
      id: string;
      name?: string | null;
      address?: string | null;
      addressDetail?: string | null;
      isPrivate: boolean;
    } | null;
    moimContents?: Array<{
      __typename?: "MoimContent";
      id: string;
      languageCode: string;
      title?: string | null;
      introduction?: string | null;
      detail?: string | null;
      price?: number | null;
      createdAt: any;
      updatedAt: any;
    } | null> | null;
    coverPhoto?: {
      __typename?: "Media";
      id: string;
      name: string;
      uri: string;
    } | null;
    media: Array<{
      __typename?: "Media";
      id: string;
      name: string;
      uri: string;
    } | null>;
    participants: Array<{
      __typename?: "Join";
      id: string;
      status: JoinStatus;
      createdAt: any;
      updatedAt: any;
      moim: { __typename?: "Moim"; id: string };
      user: { __typename?: "UserProfile"; id: string };
    } | null>;
  };
};

export type GetMyJoinedMoimsQueryVariables = Exact<{
  moimStatus?: InputMaybe<MoimStatus>;
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
}>;

export type GetMyJoinedMoimsQuery = {
  __typename?: "Query";
  getMyJoinedMoims: {
    __typename?: "JoinConnection";
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      endCursor?: string | null;
    };
    edges: Array<{
      __typename?: "Join";
      id: string;
      status: JoinStatus;
      createdAt: any;
      updatedAt: any;
      moim: {
        __typename?: "Moim";
        id: string;
        moimStartAt?: any | null;
        status: MoimStatus;
        maxPersons?: number | null;
        minPersons?: number | null;
        createdAt: any;
        updatedAt: any;
        place?: {
          __typename?: "Place";
          id: string;
          name?: string | null;
          address?: string | null;
          addressDetail?: string | null;
          isPrivate: boolean;
        } | null;
        coverPhoto?: { __typename?: "Media"; id: string; uri: string } | null;
        moimContents?: Array<{
          __typename?: "MoimContent";
          id: string;
          languageCode: string;
          title?: string | null;
          introduction?: string | null;
          detail?: string | null;
          price?: number | null;
          createdAt: any;
          updatedAt: any;
        } | null> | null;
        myReview?: {
          __typename?: "Review";
          id: string;
          body: string;
          rating?: number | null;
          createdAt: any;
          updatedAt: any;
          writer: { __typename?: "UserProfile"; id: string };
          moim: { __typename?: "Moim"; id: string };
        } | null;
      };
      user: { __typename?: "UserProfile"; id: string };
    } | null>;
  };
};

export type GetMyCreatedMoimsQueryVariables = Exact<{
  moimStatus?: InputMaybe<MoimStatus>;
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
}>;

export type GetMyCreatedMoimsQuery = {
  __typename?: "Query";
  getMyCreatedMoims?: {
    __typename?: "MoimConnection";
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      endCursor?: string | null;
    };
    edges: Array<{
      __typename?: "Moim";
      id: string;
      moimStartAt?: any | null;
      status: MoimStatus;
      createdAt: any;
      updatedAt: any;
      maxPersons?: number | null;
      minPersons?: number | null;
      place?: {
        __typename?: "Place";
        id: string;
        name?: string | null;
        address?: string | null;
        addressDetail?: string | null;
        isPrivate: boolean;
      } | null;
      coverPhoto?: { __typename?: "Media"; id: string; uri: string } | null;
      moimContents?: Array<{
        __typename?: "MoimContent";
        id: string;
        languageCode: string;
        title?: string | null;
        introduction?: string | null;
        detail?: string | null;
        price?: number | null;
        createdAt: any;
        updatedAt: any;
      } | null> | null;
      participants: Array<{
        __typename?: "Join";
        id: string;
        status: JoinStatus;
        createdAt: any;
        updatedAt: any;
        user: { __typename?: "UserProfile"; id: string };
      } | null>;
    } | null>;
  } | null;
};

export type GetMoimParticipantsQueryVariables = Exact<{
  moimId: Scalars["ID"];
}>;

export type GetMoimParticipantsQuery = {
  __typename?: "Query";
  getMoim: {
    __typename?: "Moim";
    id: string;
    moimStartAt?: any | null;
    moimEndAt?: any | null;
    maxPersons?: number | null;
    minPersons?: number | null;
    genderRestriction?: Gender | null;
    minAge?: number | null;
    maxAge?: number | null;
    status: MoimStatus;
    message?: string | null;
    viewCount: number;
    createdAt: any;
    updatedAt: any;
    host: { __typename?: "Host"; id: string };
    moimContents?: Array<{
      __typename?: "MoimContent";
      id: string;
      languageCode: string;
      title?: string | null;
      introduction?: string | null;
      detail?: string | null;
      price?: number | null;
      createdAt: any;
      updatedAt: any;
    } | null> | null;
    coverPhoto?: {
      __typename?: "Media";
      id: string;
      name: string;
      uri: string;
    } | null;
    participants: Array<{
      __typename?: "Join";
      id: string;
      status: JoinStatus;
      createdAt: any;
      updatedAt: any;
      user: {
        __typename?: "UserProfile";
        id: string;
        username: string;
        name?: string | null;
        email?: string | null;
        phone?: string | null;
        dateOfBirth?: any | null;
        gender?: Gender | null;
        avatar?: { __typename?: "Media"; id: string; uri: string } | null;
      };
    } | null>;
  };
};

export type UpdateMoimBasicMutationVariables = Exact<{
  moimBasicInput: MoimBasicInput;
}>;

export type UpdateMoimBasicMutation = {
  __typename?: "Mutation";
  updateMoimBasic: {
    __typename?: "Moim";
    id: string;
    moimStartAt?: any | null;
    moimEndAt?: any | null;
    maxPersons?: number | null;
    minPersons?: number | null;
    genderRestriction?: Gender | null;
    minAge?: number | null;
    maxAge?: number | null;
    status: MoimStatus;
    message?: string | null;
    viewCount: number;
    createdAt: any;
    updatedAt: any;
    host: { __typename?: "Host"; id: string };
    place?: {
      __typename?: "Place";
      id: string;
      name?: string | null;
      address?: string | null;
      addressDetail?: string | null;
      isPrivate: boolean;
    } | null;
    moimContents?: Array<{
      __typename?: "MoimContent";
      id: string;
      languageCode: string;
      price?: number | null;
    } | null> | null;
    coverPhoto?: {
      __typename?: "Media";
      id: string;
      name: string;
      uri: string;
    } | null;
    media: Array<{
      __typename?: "Media";
      id: string;
      name: string;
      uri: string;
    } | null>;
  };
};

export type UpdateMoimContentMutationVariables = Exact<{
  moimContentInput: MoimContentInput;
}>;

export type UpdateMoimContentMutation = {
  __typename?: "Mutation";
  updateMoimContent: {
    __typename?: "Moim";
    id: string;
    moimStartAt?: any | null;
    moimEndAt?: any | null;
    maxPersons?: number | null;
    minPersons?: number | null;
    genderRestriction?: Gender | null;
    minAge?: number | null;
    maxAge?: number | null;
    status: MoimStatus;
    message?: string | null;
    viewCount: number;
    createdAt: any;
    updatedAt: any;
    host: { __typename?: "Host"; id: string };
    place?: { __typename?: "Place"; id: string } | null;
    moimContents?: Array<{
      __typename?: "MoimContent";
      id: string;
      languageCode: string;
      price?: number | null;
      title?: string | null;
      introduction?: string | null;
      detail?: string | null;
    } | null> | null;
    coverPhoto?: {
      __typename?: "Media";
      id: string;
      name: string;
      uri: string;
    } | null;
    media: Array<{
      __typename?: "Media";
      id: string;
      name: string;
      uri: string;
    } | null>;
  };
};

export type CreatePlaceMutationVariables = Exact<{
  placeInput: PlaceInput;
}>;

export type CreatePlaceMutation = {
  __typename?: "Mutation";
  createPlace: {
    __typename?: "Place";
    id: string;
    name?: string | null;
    address?: string | null;
    addressDetail?: string | null;
    isPrivate: boolean;
    createdAt: any;
    updatedAt: any;
  };
};

export type UpdatePlaceMutationVariables = Exact<{
  placeInput: PlaceInput;
}>;

export type UpdatePlaceMutation = {
  __typename?: "Mutation";
  updatePlace: {
    __typename?: "Place";
    id: string;
    name?: string | null;
    address?: string | null;
    addressDetail?: string | null;
    isPrivate: boolean;
    createdAt: any;
    updatedAt: any;
  };
};

export type DeleteMoimCoverPhotoMutationVariables = Exact<{
  moimId: Scalars["ID"];
}>;

export type DeleteMoimCoverPhotoMutation = {
  __typename?: "Mutation";
  deleteMoimCoverPhoto: {
    __typename?: "Moim";
    id: string;
    coverPhoto?: {
      __typename?: "Media";
      id: string;
      name: string;
      uri: string;
    } | null;
  };
};

export type AddMoimMediaMutationVariables = Exact<{
  moimId: Scalars["ID"];
  mediaInput: Array<InputMaybe<MoimMediaInput>> | InputMaybe<MoimMediaInput>;
}>;

export type AddMoimMediaMutation = {
  __typename?: "Mutation";
  addMoimMedia: {
    __typename?: "Moim";
    id: string;
    media: Array<{ __typename?: "Media"; id: string; uri: string } | null>;
  };
};

export type DeleteMoimMediaMutationVariables = Exact<{
  moimId: Scalars["ID"];
  mediaId: Scalars["ID"];
}>;

export type DeleteMoimMediaMutation = {
  __typename?: "Mutation";
  deleteMoimMedia: {
    __typename?: "Moim";
    id: string;
    media: Array<{ __typename?: "Media"; id: string; uri: string } | null>;
  };
};

export type RequestMoimVerificationMutationVariables = Exact<{
  moimId: Scalars["ID"];
}>;

export type RequestMoimVerificationMutation = {
  __typename?: "Mutation";
  requestMoimVerification: {
    __typename?: "Moim";
    id: string;
    status: MoimStatus;
  };
};

export type JoinMoimMutationVariables = Exact<{
  moimId: Scalars["ID"];
}>;

export type JoinMoimMutation = {
  __typename?: "Mutation";
  joinMoim: {
    __typename?: "Join";
    id: string;
    status: JoinStatus;
    createdAt: any;
    updatedAt: any;
    moim: { __typename?: "Moim"; id: string; status: MoimStatus };
    user: { __typename?: "UserProfile"; id: string };
  };
};

export type CancelJoinMoimMutationVariables = Exact<{
  joinId: Scalars["ID"];
}>;

export type CancelJoinMoimMutation = {
  __typename?: "Mutation";
  cancelJoinMoim: {
    __typename?: "Join";
    id: string;
    status: JoinStatus;
    createdAt: any;
    updatedAt: any;
    moim: { __typename?: "Moim"; id: string; status: MoimStatus };
    user: { __typename?: "UserProfile"; id: string };
  };
};

export type UpdateReviewMutationVariables = Exact<{
  reviewInput: ReviewInput;
}>;

export type UpdateReviewMutation = {
  __typename?: "Mutation";
  updateReview: {
    __typename?: "Review";
    id: string;
    body: string;
    rating?: number | null;
    createdAt: any;
    updatedAt: any;
    writer: { __typename?: "UserProfile"; id: string };
    moim: { __typename?: "Moim"; id: string };
  };
};

export type GetCommonThemeQueryVariables = Exact<{ [key: string]: never }>;

export type GetCommonThemeQuery = {
  __typename?: "Query";
  getCommonTheme: {
    __typename?: "Theme";
    id: string;
    name: string;
    phrase: string;
    iconUrl?: string | null;
    contents?: Array<{
      __typename?: "Content";
      id: string;
      title: string;
      createdAt: any;
      updatedAt: any;
      thumbnail: { __typename?: "Media"; id: string; uri: string };
      themes: Array<{ __typename?: "Theme"; id: string; name: string } | null>;
      fields: Array<{ __typename?: "Field"; id: string; name: string } | null>;
    } | null> | null;
  };
};

export type GetHomeThemesQueryVariables = Exact<{ [key: string]: never }>;

export type GetHomeThemesQuery = {
  __typename?: "Query";
  getHomeThemes: Array<{
    __typename?: "Theme";
    id: string;
    name: string;
    phrase: string;
    iconUrl?: string | null;
    contents?: Array<{
      __typename?: "Content";
      id: string;
      title: string;
      createdAt: any;
      updatedAt: any;
      thumbnail: { __typename?: "Media"; id: string; uri: string };
      themes: Array<{ __typename?: "Theme"; id: string; name: string } | null>;
      fields: Array<{ __typename?: "Field"; id: string; name: string } | null>;
    } | null> | null;
  } | null>;
};

export type GetMyInitialProfileQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyInitialProfileQuery = {
  __typename?: "Query";
  getMyUserProfile?: {
    __typename?: "UserProfile";
    id: string;
    username: string;
    name?: string | null;
    email?: string | null;
    phone?: string | null;
    dateOfBirth?: any | null;
    gender?: Gender | null;
    reviewCount: number;
    isAdmin?: boolean | null;
    createdAt: any;
    updatedAt: any;
    nationality?: {
      __typename?: "Nationality";
      id: string;
      name: string;
    } | null;
    host?: { __typename?: "Host"; id: string } | null;
    avatar?: { __typename?: "Media"; id: string; uri: string } | null;
  } | null;
};

export type GetMyUserProfileQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyUserProfileQuery = {
  __typename?: "Query";
  getMyUserProfile?: {
    __typename?: "UserProfile";
    id: string;
    username: string;
    name?: string | null;
    email?: string | null;
    phone?: string | null;
    dateOfBirth?: any | null;
    gender?: Gender | null;
    reviewCount: number;
    isAdmin?: boolean | null;
    createdAt: any;
    updatedAt: any;
    nationality?: {
      __typename?: "Nationality";
      id: string;
      name: string;
    } | null;
    host?: {
      __typename?: "Host";
      id: string;
      bankName?: string | null;
      bankAccount?: string | null;
      introduction?: string | null;
      verified: boolean;
      idNumber?: string | null;
      bankAccountMedia?: {
        __typename?: "Media";
        id: string;
        name: string;
        uri: string;
      } | null;
      business?: {
        __typename?: "Business";
        id: string;
        name: string;
        ownerName: string;
        registrationNumber: string;
        taxEmail: string;
        registrationMedia: {
          __typename?: "Media";
          id: string;
          name: string;
          uri: string;
        };
      } | null;
    } | null;
    avatar?: { __typename?: "Media"; id: string; uri: string } | null;
  } | null;
};

export type SearchNationalityQueryVariables = Exact<{
  query: Scalars["String"];
}>;

export type SearchNationalityQuery = {
  __typename?: "Query";
  searchNationality: Array<{
    __typename?: "Nationality";
    id: string;
    name: string;
    emoji?: string | null;
    imageUrl?: string | null;
  } | null>;
};

export type IsUniqueUsernameQueryVariables = Exact<{
  username: Scalars["String"];
}>;

export type IsUniqueUsernameQuery = {
  __typename?: "Query";
  isUniqueUsername: boolean;
};

export type UpdateUserProfileMutationVariables = Exact<{
  userInput: UserInput;
}>;

export type UpdateUserProfileMutation = {
  __typename?: "Mutation";
  updateUserProfile: {
    __typename?: "UserProfile";
    id: string;
    username: string;
    name?: string | null;
    email?: string | null;
    phone?: string | null;
    dateOfBirth?: any | null;
    gender?: Gender | null;
    nationality?: {
      __typename?: "Nationality";
      id: string;
      name: string;
      emoji?: string | null;
    } | null;
    host?: { __typename?: "Host"; id: string; verified: boolean } | null;
    avatar?: { __typename?: "Media"; id: string; uri: string } | null;
  };
};

export type UpdateUserAvatarMutationVariables = Exact<{
  avatarInput: MediaInput;
}>;

export type UpdateUserAvatarMutation = {
  __typename?: "Mutation";
  updateUserAvatar: {
    __typename?: "UserProfile";
    id: string;
    avatar?: { __typename?: "Media"; id: string; uri: string } | null;
  };
};

export type DeleteUserAvatarMutationVariables = Exact<{ [key: string]: never }>;

export type DeleteUserAvatarMutation = {
  __typename?: "Mutation";
  deleteUserAvatar: {
    __typename?: "UserProfile";
    id: string;
    avatar?: { __typename?: "Media"; id: string; uri: string } | null;
  };
};

export const PageInfoFieldFragmentDoc = gql`
  fragment PageInfoField on PageInfo {
    hasNextPage
    endCursor
  }
`;
export const GetContentFieldsDocument = gql`
  query GetContentFields {
    getContentFields {
      id
      name
      iconUrl
      order
    }
  }
`;

/**
 * __useGetContentFieldsQuery__
 *
 * To run a query within a React component, call `useGetContentFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContentFieldsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetContentFieldsQuery,
    GetContentFieldsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetContentFieldsQuery, GetContentFieldsQueryVariables>(
    GetContentFieldsDocument,
    options
  );
}
export function useGetContentFieldsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContentFieldsQuery,
    GetContentFieldsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContentFieldsQuery,
    GetContentFieldsQueryVariables
  >(GetContentFieldsDocument, options);
}
export type GetContentFieldsQueryHookResult = ReturnType<
  typeof useGetContentFieldsQuery
>;
export type GetContentFieldsLazyQueryHookResult = ReturnType<
  typeof useGetContentFieldsLazyQuery
>;
export type GetContentFieldsQueryResult = Apollo.QueryResult<
  GetContentFieldsQuery,
  GetContentFieldsQueryVariables
>;
export const GetContentThemesDocument = gql`
  query GetContentThemes {
    getContentThemes {
      id
      name
      phrase
      iconUrl
      order
    }
  }
`;

/**
 * __useGetContentThemesQuery__
 *
 * To run a query within a React component, call `useGetContentThemesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentThemesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentThemesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContentThemesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetContentThemesQuery,
    GetContentThemesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetContentThemesQuery, GetContentThemesQueryVariables>(
    GetContentThemesDocument,
    options
  );
}
export function useGetContentThemesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContentThemesQuery,
    GetContentThemesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContentThemesQuery,
    GetContentThemesQueryVariables
  >(GetContentThemesDocument, options);
}
export type GetContentThemesQueryHookResult = ReturnType<
  typeof useGetContentThemesQuery
>;
export type GetContentThemesLazyQueryHookResult = ReturnType<
  typeof useGetContentThemesLazyQuery
>;
export type GetContentThemesQueryResult = Apollo.QueryResult<
  GetContentThemesQuery,
  GetContentThemesQueryVariables
>;
export const GetHomeRecentContentsDocument = gql`
  query GetHomeRecentContents {
    getHomeRecentContents {
      id
      title
      themes {
        id
        name
      }
      fields {
        id
        name
      }
      createdAt
    }
  }
`;

/**
 * __useGetHomeRecentContentsQuery__
 *
 * To run a query within a React component, call `useGetHomeRecentContentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHomeRecentContentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomeRecentContentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHomeRecentContentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetHomeRecentContentsQuery,
    GetHomeRecentContentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetHomeRecentContentsQuery,
    GetHomeRecentContentsQueryVariables
  >(GetHomeRecentContentsDocument, options);
}
export function useGetHomeRecentContentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHomeRecentContentsQuery,
    GetHomeRecentContentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetHomeRecentContentsQuery,
    GetHomeRecentContentsQueryVariables
  >(GetHomeRecentContentsDocument, options);
}
export type GetHomeRecentContentsQueryHookResult = ReturnType<
  typeof useGetHomeRecentContentsQuery
>;
export type GetHomeRecentContentsLazyQueryHookResult = ReturnType<
  typeof useGetHomeRecentContentsLazyQuery
>;
export type GetHomeRecentContentsQueryResult = Apollo.QueryResult<
  GetHomeRecentContentsQuery,
  GetHomeRecentContentsQueryVariables
>;
export const GetContentsDocument = gql`
  query GetContents(
    $fieldId: ID
    $themeId: ID
    $sort: ContentSortOption
    $first: Int
    $after: String
  ) {
    getContents(
      fieldId: $fieldId
      themeId: $themeId
      sort: $sort
      first: $first
      after: $after
    ) {
      pageInfo {
        ...PageInfoField
      }
      edges {
        id
        title
        thumbnail {
          id
          uri
        }
        themes {
          id
          name
        }
        fields {
          id
          name
        }
        viewCount
        readCount
        createdAt
        updatedAt
      }
    }
  }
  ${PageInfoFieldFragmentDoc}
`;

/**
 * __useGetContentsQuery__
 *
 * To run a query within a React component, call `useGetContentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentsQuery({
 *   variables: {
 *      fieldId: // value for 'fieldId'
 *      themeId: // value for 'themeId'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetContentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetContentsQuery,
    GetContentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetContentsQuery, GetContentsQueryVariables>(
    GetContentsDocument,
    options
  );
}
export function useGetContentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContentsQuery,
    GetContentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetContentsQuery, GetContentsQueryVariables>(
    GetContentsDocument,
    options
  );
}
export type GetContentsQueryHookResult = ReturnType<typeof useGetContentsQuery>;
export type GetContentsLazyQueryHookResult = ReturnType<
  typeof useGetContentsLazyQuery
>;
export type GetContentsQueryResult = Apollo.QueryResult<
  GetContentsQuery,
  GetContentsQueryVariables
>;
export const GetContentDocument = gql`
  query GetContent($contentId: ID!) {
    getContent(contentId: $contentId) {
      id
      title
      uploader {
        id
        username
      }
      place {
        id
        name
        address
        latitude
        longitude
        phone
        active
        field {
          id
          name
        }
        media {
          id
          uri
        }
        createdAt
        updatedAt
      }
      subtitle
      primaryMedia {
        id
        uri
      }
      body
      viewCount
      readCount
      fields {
        id
        name
        iconUrl
      }
      themes {
        id
        name
        iconUrl
      }
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetContentQuery__
 *
 * To run a query within a React component, call `useGetContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentQuery({
 *   variables: {
 *      contentId: // value for 'contentId'
 *   },
 * });
 */
export function useGetContentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContentQuery,
    GetContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetContentQuery, GetContentQueryVariables>(
    GetContentDocument,
    options
  );
}
export function useGetContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContentQuery,
    GetContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetContentQuery, GetContentQueryVariables>(
    GetContentDocument,
    options
  );
}
export type GetContentQueryHookResult = ReturnType<typeof useGetContentQuery>;
export type GetContentLazyQueryHookResult = ReturnType<
  typeof useGetContentLazyQuery
>;
export type GetContentQueryResult = Apollo.QueryResult<
  GetContentQuery,
  GetContentQueryVariables
>;
export const GetRecommendedContentsByContentIdDocument = gql`
  query GetRecommendedContentsByContentId($contentId: ID!) {
    getRecommendedContentsByContentId(contentId: $contentId) {
      id
      title
      thumbnail {
        id
        uri
      }
      themes {
        id
        name
      }
      fields {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetRecommendedContentsByContentIdQuery__
 *
 * To run a query within a React component, call `useGetRecommendedContentsByContentIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecommendedContentsByContentIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecommendedContentsByContentIdQuery({
 *   variables: {
 *      contentId: // value for 'contentId'
 *   },
 * });
 */
export function useGetRecommendedContentsByContentIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRecommendedContentsByContentIdQuery,
    GetRecommendedContentsByContentIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRecommendedContentsByContentIdQuery,
    GetRecommendedContentsByContentIdQueryVariables
  >(GetRecommendedContentsByContentIdDocument, options);
}
export function useGetRecommendedContentsByContentIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRecommendedContentsByContentIdQuery,
    GetRecommendedContentsByContentIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRecommendedContentsByContentIdQuery,
    GetRecommendedContentsByContentIdQueryVariables
  >(GetRecommendedContentsByContentIdDocument, options);
}
export type GetRecommendedContentsByContentIdQueryHookResult = ReturnType<
  typeof useGetRecommendedContentsByContentIdQuery
>;
export type GetRecommendedContentsByContentIdLazyQueryHookResult = ReturnType<
  typeof useGetRecommendedContentsByContentIdLazyQuery
>;
export type GetRecommendedContentsByContentIdQueryResult = Apollo.QueryResult<
  GetRecommendedContentsByContentIdQuery,
  GetRecommendedContentsByContentIdQueryVariables
>;
export const IncrementContentReadCountDocument = gql`
  mutation IncrementContentReadCount(
    $contentId: ID!
    $contentReadInput: ContentReadInput!
  ) {
    incrementContentReadCount(
      contentId: $contentId
      contentReadInput: $contentReadInput
    ) {
      id
      readCount
    }
  }
`;
export type IncrementContentReadCountMutationFn = Apollo.MutationFunction<
  IncrementContentReadCountMutation,
  IncrementContentReadCountMutationVariables
>;

/**
 * __useIncrementContentReadCountMutation__
 *
 * To run a mutation, you first call `useIncrementContentReadCountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIncrementContentReadCountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [incrementContentReadCountMutation, { data, loading, error }] = useIncrementContentReadCountMutation({
 *   variables: {
 *      contentId: // value for 'contentId'
 *      contentReadInput: // value for 'contentReadInput'
 *   },
 * });
 */
export function useIncrementContentReadCountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IncrementContentReadCountMutation,
    IncrementContentReadCountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IncrementContentReadCountMutation,
    IncrementContentReadCountMutationVariables
  >(IncrementContentReadCountDocument, options);
}
export type IncrementContentReadCountMutationHookResult = ReturnType<
  typeof useIncrementContentReadCountMutation
>;
export type IncrementContentReadCountMutationResult =
  Apollo.MutationResult<IncrementContentReadCountMutation>;
export type IncrementContentReadCountMutationOptions =
  Apollo.BaseMutationOptions<
    IncrementContentReadCountMutation,
    IncrementContentReadCountMutationVariables
  >;
export const AddEmailListDocument = gql`
  mutation AddEmailList($emailListInput: EmailListInput!) {
    addEmailList(emailListInput: $emailListInput) {
      id
      email
    }
  }
`;
export type AddEmailListMutationFn = Apollo.MutationFunction<
  AddEmailListMutation,
  AddEmailListMutationVariables
>;

/**
 * __useAddEmailListMutation__
 *
 * To run a mutation, you first call `useAddEmailListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEmailListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEmailListMutation, { data, loading, error }] = useAddEmailListMutation({
 *   variables: {
 *      emailListInput: // value for 'emailListInput'
 *   },
 * });
 */
export function useAddEmailListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddEmailListMutation,
    AddEmailListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddEmailListMutation,
    AddEmailListMutationVariables
  >(AddEmailListDocument, options);
}
export type AddEmailListMutationHookResult = ReturnType<
  typeof useAddEmailListMutation
>;
export type AddEmailListMutationResult =
  Apollo.MutationResult<AddEmailListMutation>;
export type AddEmailListMutationOptions = Apollo.BaseMutationOptions<
  AddEmailListMutation,
  AddEmailListMutationVariables
>;
export const RequestToBeHostDocument = gql`
  mutation RequestToBeHost(
    $hostInput: HostInput!
    $businessInput: BusinessInput
  ) {
    requestToBeHost(hostInput: $hostInput, businessInput: $businessInput) {
      id
      user {
        id
        username
        name
        email
        phone
        gender
        nationality {
          id
          name
        }
        avatar {
          id
          uri
        }
        host {
          id
          bankName
          bankAccount
          bankAccountMedia {
            id
            name
            uri
          }
          introduction
          verified
          idNumber
          business {
            id
            name
            ownerName
            registrationNumber
            registrationMedia {
              id
              name
              uri
            }
            taxEmail
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export type RequestToBeHostMutationFn = Apollo.MutationFunction<
  RequestToBeHostMutation,
  RequestToBeHostMutationVariables
>;

/**
 * __useRequestToBeHostMutation__
 *
 * To run a mutation, you first call `useRequestToBeHostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestToBeHostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestToBeHostMutation, { data, loading, error }] = useRequestToBeHostMutation({
 *   variables: {
 *      hostInput: // value for 'hostInput'
 *      businessInput: // value for 'businessInput'
 *   },
 * });
 */
export function useRequestToBeHostMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestToBeHostMutation,
    RequestToBeHostMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestToBeHostMutation,
    RequestToBeHostMutationVariables
  >(RequestToBeHostDocument, options);
}
export type RequestToBeHostMutationHookResult = ReturnType<
  typeof useRequestToBeHostMutation
>;
export type RequestToBeHostMutationResult =
  Apollo.MutationResult<RequestToBeHostMutation>;
export type RequestToBeHostMutationOptions = Apollo.BaseMutationOptions<
  RequestToBeHostMutation,
  RequestToBeHostMutationVariables
>;
export const UpdateHostDocument = gql`
  mutation UpdateHost($hostInput: HostInput!, $businessInput: BusinessInput) {
    updateHost(hostInput: $hostInput, businessInput: $businessInput) {
      id
      user {
        id
        username
        name
        email
        phone
        gender
        nationality {
          id
          name
        }
        avatar {
          id
          uri
        }
        host {
          id
          bankName
          bankAccount
          bankAccountMedia {
            id
            name
            uri
          }
          introduction
          verified
          idNumber
          business {
            id
            name
            ownerName
            registrationNumber
            registrationMedia {
              id
              name
              uri
            }
            taxEmail
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export type UpdateHostMutationFn = Apollo.MutationFunction<
  UpdateHostMutation,
  UpdateHostMutationVariables
>;

/**
 * __useUpdateHostMutation__
 *
 * To run a mutation, you first call `useUpdateHostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHostMutation, { data, loading, error }] = useUpdateHostMutation({
 *   variables: {
 *      hostInput: // value for 'hostInput'
 *      businessInput: // value for 'businessInput'
 *   },
 * });
 */
export function useUpdateHostMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateHostMutation,
    UpdateHostMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateHostMutation, UpdateHostMutationVariables>(
    UpdateHostDocument,
    options
  );
}
export type UpdateHostMutationHookResult = ReturnType<
  typeof useUpdateHostMutation
>;
export type UpdateHostMutationResult =
  Apollo.MutationResult<UpdateHostMutation>;
export type UpdateHostMutationOptions = Apollo.BaseMutationOptions<
  UpdateHostMutation,
  UpdateHostMutationVariables
>;
export const GetHomeTopMoimsDocument = gql`
  query GetHomeTopMoims($languageCode: String!) {
    getHomeTopMoims(languageCode: $languageCode) {
      mainTitle
      mainIntroductionTitle
      mainIntroductionSubtitle
      mainIntroduction
      top {
        id
        moimContents {
          languageCode
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        status
        coverPhoto {
          id
          uri
        }
      }
    }
  }
`;

/**
 * __useGetHomeTopMoimsQuery__
 *
 * To run a query within a React component, call `useGetHomeTopMoimsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHomeTopMoimsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomeTopMoimsQuery({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useGetHomeTopMoimsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetHomeTopMoimsQuery,
    GetHomeTopMoimsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetHomeTopMoimsQuery, GetHomeTopMoimsQueryVariables>(
    GetHomeTopMoimsDocument,
    options
  );
}
export function useGetHomeTopMoimsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHomeTopMoimsQuery,
    GetHomeTopMoimsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetHomeTopMoimsQuery,
    GetHomeTopMoimsQueryVariables
  >(GetHomeTopMoimsDocument, options);
}
export type GetHomeTopMoimsQueryHookResult = ReturnType<
  typeof useGetHomeTopMoimsQuery
>;
export type GetHomeTopMoimsLazyQueryHookResult = ReturnType<
  typeof useGetHomeTopMoimsLazyQuery
>;
export type GetHomeTopMoimsQueryResult = Apollo.QueryResult<
  GetHomeTopMoimsQuery,
  GetHomeTopMoimsQueryVariables
>;
export const GetHomeMoimsDocument = gql`
  query GetHomeMoims($first: Int, $after: String) {
    getHomeMoims(first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        id
        place {
          id
          name
          address
          addressDetail
          isPrivate
        }
        moimStartAt
        status
        coverPhoto {
          id
          uri
        }
        moimContents {
          id
          languageCode
          title
          introduction
          detail
          price
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        maxPersons
        minPersons
      }
    }
  }
`;

/**
 * __useGetHomeMoimsQuery__
 *
 * To run a query within a React component, call `useGetHomeMoimsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHomeMoimsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomeMoimsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetHomeMoimsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetHomeMoimsQuery,
    GetHomeMoimsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetHomeMoimsQuery, GetHomeMoimsQueryVariables>(
    GetHomeMoimsDocument,
    options
  );
}
export function useGetHomeMoimsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHomeMoimsQuery,
    GetHomeMoimsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetHomeMoimsQuery, GetHomeMoimsQueryVariables>(
    GetHomeMoimsDocument,
    options
  );
}
export type GetHomeMoimsQueryHookResult = ReturnType<
  typeof useGetHomeMoimsQuery
>;
export type GetHomeMoimsLazyQueryHookResult = ReturnType<
  typeof useGetHomeMoimsLazyQuery
>;
export type GetHomeMoimsQueryResult = Apollo.QueryResult<
  GetHomeMoimsQuery,
  GetHomeMoimsQueryVariables
>;
export const GetMainPhrasesDocument = gql`
  query GetMainPhrases {
    getMainPhrases {
      mainTitle
      mainDescription
    }
  }
`;

/**
 * __useGetMainPhrasesQuery__
 *
 * To run a query within a React component, call `useGetMainPhrasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMainPhrasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMainPhrasesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMainPhrasesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMainPhrasesQuery,
    GetMainPhrasesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMainPhrasesQuery, GetMainPhrasesQueryVariables>(
    GetMainPhrasesDocument,
    options
  );
}
export function useGetMainPhrasesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMainPhrasesQuery,
    GetMainPhrasesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMainPhrasesQuery, GetMainPhrasesQueryVariables>(
    GetMainPhrasesDocument,
    options
  );
}
export type GetMainPhrasesQueryHookResult = ReturnType<
  typeof useGetMainPhrasesQuery
>;
export type GetMainPhrasesLazyQueryHookResult = ReturnType<
  typeof useGetMainPhrasesLazyQuery
>;
export type GetMainPhrasesQueryResult = Apollo.QueryResult<
  GetMainPhrasesQuery,
  GetMainPhrasesQueryVariables
>;
export const GetFooterDocument = gql`
  query GetFooter($languageCode: String) {
    getFooter(languageCode: $languageCode) {
      companyName
      address
      email
      instagram
      kakao
      copyright
    }
  }
`;

/**
 * __useGetFooterQuery__
 *
 * To run a query within a React component, call `useGetFooterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFooterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFooterQuery({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useGetFooterQuery(
  baseOptions?: Apollo.QueryHookOptions<GetFooterQuery, GetFooterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFooterQuery, GetFooterQueryVariables>(
    GetFooterDocument,
    options
  );
}
export function useGetFooterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFooterQuery,
    GetFooterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFooterQuery, GetFooterQueryVariables>(
    GetFooterDocument,
    options
  );
}
export type GetFooterQueryHookResult = ReturnType<typeof useGetFooterQuery>;
export type GetFooterLazyQueryHookResult = ReturnType<
  typeof useGetFooterLazyQuery
>;
export type GetFooterQueryResult = Apollo.QueryResult<
  GetFooterQuery,
  GetFooterQueryVariables
>;
export const GetRefundPolicyDocument = gql`
  query GetRefundPolicy($languageCode: String) {
    getRefundPolicy(languageCode: $languageCode)
  }
`;

/**
 * __useGetRefundPolicyQuery__
 *
 * To run a query within a React component, call `useGetRefundPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRefundPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRefundPolicyQuery({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useGetRefundPolicyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRefundPolicyQuery,
    GetRefundPolicyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRefundPolicyQuery, GetRefundPolicyQueryVariables>(
    GetRefundPolicyDocument,
    options
  );
}
export function useGetRefundPolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRefundPolicyQuery,
    GetRefundPolicyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRefundPolicyQuery,
    GetRefundPolicyQueryVariables
  >(GetRefundPolicyDocument, options);
}
export type GetRefundPolicyQueryHookResult = ReturnType<
  typeof useGetRefundPolicyQuery
>;
export type GetRefundPolicyLazyQueryHookResult = ReturnType<
  typeof useGetRefundPolicyLazyQuery
>;
export type GetRefundPolicyQueryResult = Apollo.QueryResult<
  GetRefundPolicyQuery,
  GetRefundPolicyQueryVariables
>;
export const GetCreateMoimFaqsDocument = gql`
  query GetCreateMoimFaqs($languageCode: String) {
    getCreateMoimFaqs(languageCode: $languageCode) {
      id
      question
      answer
      active
      languageCode
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetCreateMoimFaqsQuery__
 *
 * To run a query within a React component, call `useGetCreateMoimFaqsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreateMoimFaqsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreateMoimFaqsQuery({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useGetCreateMoimFaqsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCreateMoimFaqsQuery,
    GetCreateMoimFaqsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCreateMoimFaqsQuery,
    GetCreateMoimFaqsQueryVariables
  >(GetCreateMoimFaqsDocument, options);
}
export function useGetCreateMoimFaqsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCreateMoimFaqsQuery,
    GetCreateMoimFaqsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCreateMoimFaqsQuery,
    GetCreateMoimFaqsQueryVariables
  >(GetCreateMoimFaqsDocument, options);
}
export type GetCreateMoimFaqsQueryHookResult = ReturnType<
  typeof useGetCreateMoimFaqsQuery
>;
export type GetCreateMoimFaqsLazyQueryHookResult = ReturnType<
  typeof useGetCreateMoimFaqsLazyQuery
>;
export type GetCreateMoimFaqsQueryResult = Apollo.QueryResult<
  GetCreateMoimFaqsQuery,
  GetCreateMoimFaqsQueryVariables
>;
export const GetMoimDocument = gql`
  query GetMoim($moimId: ID!) {
    getMoim(moimId: $moimId) {
      id
      host {
        id
        introduction
        user {
          id
          username
          name
          email
          phone
          dateOfBirth
          gender
          nationality {
            id
            name
          }
          avatar {
            id
            uri
          }
          createdAt
          updatedAt
        }
      }
      place {
        id
        name
        address
        addressDetail
        isPrivate
      }
      moimContents {
        id
        languageCode
        title
        introduction
        detail
        price
        createdAt
        updatedAt
      }
      moimStartAt
      moimEndAt
      maxPersons
      minPersons
      genderRestriction
      minAge
      maxAge
      status
      message
      coverPhoto {
        id
        name
        uri
      }
      media {
        id
        name
        uri
      }
      participants {
        id
        moim {
          id
        }
        user {
          id
        }
        status
        createdAt
        updatedAt
      }
      viewCount
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetMoimQuery__
 *
 * To run a query within a React component, call `useGetMoimQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMoimQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMoimQuery({
 *   variables: {
 *      moimId: // value for 'moimId'
 *   },
 * });
 */
export function useGetMoimQuery(
  baseOptions: Apollo.QueryHookOptions<GetMoimQuery, GetMoimQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMoimQuery, GetMoimQueryVariables>(
    GetMoimDocument,
    options
  );
}
export function useGetMoimLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMoimQuery, GetMoimQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMoimQuery, GetMoimQueryVariables>(
    GetMoimDocument,
    options
  );
}
export type GetMoimQueryHookResult = ReturnType<typeof useGetMoimQuery>;
export type GetMoimLazyQueryHookResult = ReturnType<typeof useGetMoimLazyQuery>;
export type GetMoimQueryResult = Apollo.QueryResult<
  GetMoimQuery,
  GetMoimQueryVariables
>;
export const GetMyJoinedMoimsDocument = gql`
  query GetMyJoinedMoims($moimStatus: MoimStatus, $first: Int, $after: String) {
    getMyJoinedMoims(moimStatus: $moimStatus, first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        id
        moim {
          id
          place {
            id
            name
            address
            addressDetail
            isPrivate
          }
          moimStartAt
          status
          coverPhoto {
            id
            uri
          }
          moimContents {
            id
            languageCode
            title
            introduction
            detail
            price
            createdAt
            updatedAt
          }
          maxPersons
          minPersons
          myReview {
            id
            writer {
              id
            }
            moim {
              id
            }
            body
            rating
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        user {
          id
        }
        status
        createdAt
        updatedAt
      }
    }
  }
`;

/**
 * __useGetMyJoinedMoimsQuery__
 *
 * To run a query within a React component, call `useGetMyJoinedMoimsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyJoinedMoimsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyJoinedMoimsQuery({
 *   variables: {
 *      moimStatus: // value for 'moimStatus'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetMyJoinedMoimsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyJoinedMoimsQuery,
    GetMyJoinedMoimsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyJoinedMoimsQuery, GetMyJoinedMoimsQueryVariables>(
    GetMyJoinedMoimsDocument,
    options
  );
}
export function useGetMyJoinedMoimsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyJoinedMoimsQuery,
    GetMyJoinedMoimsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyJoinedMoimsQuery,
    GetMyJoinedMoimsQueryVariables
  >(GetMyJoinedMoimsDocument, options);
}
export type GetMyJoinedMoimsQueryHookResult = ReturnType<
  typeof useGetMyJoinedMoimsQuery
>;
export type GetMyJoinedMoimsLazyQueryHookResult = ReturnType<
  typeof useGetMyJoinedMoimsLazyQuery
>;
export type GetMyJoinedMoimsQueryResult = Apollo.QueryResult<
  GetMyJoinedMoimsQuery,
  GetMyJoinedMoimsQueryVariables
>;
export const GetMyCreatedMoimsDocument = gql`
  query GetMyCreatedMoims(
    $moimStatus: MoimStatus
    $first: Int
    $after: String
  ) {
    getMyCreatedMoims(moimStatus: $moimStatus, first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        id
        place {
          id
          name
          address
          addressDetail
          isPrivate
        }
        moimStartAt
        status
        coverPhoto {
          id
          uri
        }
        moimContents {
          id
          languageCode
          title
          introduction
          detail
          price
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        maxPersons
        minPersons
        participants {
          id
          user {
            id
          }
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;

/**
 * __useGetMyCreatedMoimsQuery__
 *
 * To run a query within a React component, call `useGetMyCreatedMoimsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyCreatedMoimsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyCreatedMoimsQuery({
 *   variables: {
 *      moimStatus: // value for 'moimStatus'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetMyCreatedMoimsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyCreatedMoimsQuery,
    GetMyCreatedMoimsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyCreatedMoimsQuery,
    GetMyCreatedMoimsQueryVariables
  >(GetMyCreatedMoimsDocument, options);
}
export function useGetMyCreatedMoimsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyCreatedMoimsQuery,
    GetMyCreatedMoimsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyCreatedMoimsQuery,
    GetMyCreatedMoimsQueryVariables
  >(GetMyCreatedMoimsDocument, options);
}
export type GetMyCreatedMoimsQueryHookResult = ReturnType<
  typeof useGetMyCreatedMoimsQuery
>;
export type GetMyCreatedMoimsLazyQueryHookResult = ReturnType<
  typeof useGetMyCreatedMoimsLazyQuery
>;
export type GetMyCreatedMoimsQueryResult = Apollo.QueryResult<
  GetMyCreatedMoimsQuery,
  GetMyCreatedMoimsQueryVariables
>;
export const GetMoimParticipantsDocument = gql`
  query GetMoimParticipants($moimId: ID!) {
    getMoim(moimId: $moimId) {
      id
      host {
        id
      }
      moimContents {
        id
        languageCode
        title
        introduction
        detail
        price
        createdAt
        updatedAt
      }
      moimStartAt
      moimEndAt
      maxPersons
      minPersons
      genderRestriction
      minAge
      maxAge
      status
      message
      coverPhoto {
        id
        name
        uri
      }
      participants {
        id
        user {
          id
          username
          name
          email
          phone
          dateOfBirth
          gender
          avatar {
            id
            uri
          }
        }
        status
        createdAt
        updatedAt
      }
      viewCount
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetMoimParticipantsQuery__
 *
 * To run a query within a React component, call `useGetMoimParticipantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMoimParticipantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMoimParticipantsQuery({
 *   variables: {
 *      moimId: // value for 'moimId'
 *   },
 * });
 */
export function useGetMoimParticipantsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMoimParticipantsQuery,
    GetMoimParticipantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMoimParticipantsQuery,
    GetMoimParticipantsQueryVariables
  >(GetMoimParticipantsDocument, options);
}
export function useGetMoimParticipantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMoimParticipantsQuery,
    GetMoimParticipantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMoimParticipantsQuery,
    GetMoimParticipantsQueryVariables
  >(GetMoimParticipantsDocument, options);
}
export type GetMoimParticipantsQueryHookResult = ReturnType<
  typeof useGetMoimParticipantsQuery
>;
export type GetMoimParticipantsLazyQueryHookResult = ReturnType<
  typeof useGetMoimParticipantsLazyQuery
>;
export type GetMoimParticipantsQueryResult = Apollo.QueryResult<
  GetMoimParticipantsQuery,
  GetMoimParticipantsQueryVariables
>;
export const UpdateMoimBasicDocument = gql`
  mutation UpdateMoimBasic($moimBasicInput: MoimBasicInput!) {
    updateMoimBasic(moimBasicInput: $moimBasicInput) {
      id
      host {
        id
      }
      place {
        id
        name
        address
        addressDetail
        isPrivate
      }
      moimContents {
        id
        languageCode
        price
      }
      moimStartAt
      moimEndAt
      maxPersons
      minPersons
      genderRestriction
      minAge
      maxAge
      status
      message
      viewCount
      createdAt
      updatedAt
      coverPhoto {
        id
        name
        uri
      }
      media {
        id
        name
        uri
      }
    }
  }
`;
export type UpdateMoimBasicMutationFn = Apollo.MutationFunction<
  UpdateMoimBasicMutation,
  UpdateMoimBasicMutationVariables
>;

/**
 * __useUpdateMoimBasicMutation__
 *
 * To run a mutation, you first call `useUpdateMoimBasicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMoimBasicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMoimBasicMutation, { data, loading, error }] = useUpdateMoimBasicMutation({
 *   variables: {
 *      moimBasicInput: // value for 'moimBasicInput'
 *   },
 * });
 */
export function useUpdateMoimBasicMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMoimBasicMutation,
    UpdateMoimBasicMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMoimBasicMutation,
    UpdateMoimBasicMutationVariables
  >(UpdateMoimBasicDocument, options);
}
export type UpdateMoimBasicMutationHookResult = ReturnType<
  typeof useUpdateMoimBasicMutation
>;
export type UpdateMoimBasicMutationResult =
  Apollo.MutationResult<UpdateMoimBasicMutation>;
export type UpdateMoimBasicMutationOptions = Apollo.BaseMutationOptions<
  UpdateMoimBasicMutation,
  UpdateMoimBasicMutationVariables
>;
export const UpdateMoimContentDocument = gql`
  mutation UpdateMoimContent($moimContentInput: MoimContentInput!) {
    updateMoimContent(moimContentInput: $moimContentInput) {
      id
      host {
        id
      }
      place {
        id
      }
      moimContents {
        id
        languageCode
        price
        title
        introduction
        detail
      }
      coverPhoto {
        id
        name
        uri
      }
      media {
        id
        name
        uri
      }
      moimStartAt
      moimEndAt
      maxPersons
      minPersons
      genderRestriction
      minAge
      maxAge
      status
      message
      viewCount
      createdAt
      updatedAt
    }
  }
`;
export type UpdateMoimContentMutationFn = Apollo.MutationFunction<
  UpdateMoimContentMutation,
  UpdateMoimContentMutationVariables
>;

/**
 * __useUpdateMoimContentMutation__
 *
 * To run a mutation, you first call `useUpdateMoimContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMoimContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMoimContentMutation, { data, loading, error }] = useUpdateMoimContentMutation({
 *   variables: {
 *      moimContentInput: // value for 'moimContentInput'
 *   },
 * });
 */
export function useUpdateMoimContentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMoimContentMutation,
    UpdateMoimContentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMoimContentMutation,
    UpdateMoimContentMutationVariables
  >(UpdateMoimContentDocument, options);
}
export type UpdateMoimContentMutationHookResult = ReturnType<
  typeof useUpdateMoimContentMutation
>;
export type UpdateMoimContentMutationResult =
  Apollo.MutationResult<UpdateMoimContentMutation>;
export type UpdateMoimContentMutationOptions = Apollo.BaseMutationOptions<
  UpdateMoimContentMutation,
  UpdateMoimContentMutationVariables
>;
export const CreatePlaceDocument = gql`
  mutation CreatePlace($placeInput: PlaceInput!) {
    createPlace(placeInput: $placeInput) {
      id
      name
      address
      addressDetail
      isPrivate
      createdAt
      updatedAt
    }
  }
`;
export type CreatePlaceMutationFn = Apollo.MutationFunction<
  CreatePlaceMutation,
  CreatePlaceMutationVariables
>;

/**
 * __useCreatePlaceMutation__
 *
 * To run a mutation, you first call `useCreatePlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlaceMutation, { data, loading, error }] = useCreatePlaceMutation({
 *   variables: {
 *      placeInput: // value for 'placeInput'
 *   },
 * });
 */
export function useCreatePlaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePlaceMutation,
    CreatePlaceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePlaceMutation, CreatePlaceMutationVariables>(
    CreatePlaceDocument,
    options
  );
}
export type CreatePlaceMutationHookResult = ReturnType<
  typeof useCreatePlaceMutation
>;
export type CreatePlaceMutationResult =
  Apollo.MutationResult<CreatePlaceMutation>;
export type CreatePlaceMutationOptions = Apollo.BaseMutationOptions<
  CreatePlaceMutation,
  CreatePlaceMutationVariables
>;
export const UpdatePlaceDocument = gql`
  mutation UpdatePlace($placeInput: PlaceInput!) {
    updatePlace(placeInput: $placeInput) {
      id
      name
      address
      addressDetail
      isPrivate
      createdAt
      updatedAt
    }
  }
`;
export type UpdatePlaceMutationFn = Apollo.MutationFunction<
  UpdatePlaceMutation,
  UpdatePlaceMutationVariables
>;

/**
 * __useUpdatePlaceMutation__
 *
 * To run a mutation, you first call `useUpdatePlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlaceMutation, { data, loading, error }] = useUpdatePlaceMutation({
 *   variables: {
 *      placeInput: // value for 'placeInput'
 *   },
 * });
 */
export function useUpdatePlaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePlaceMutation,
    UpdatePlaceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePlaceMutation, UpdatePlaceMutationVariables>(
    UpdatePlaceDocument,
    options
  );
}
export type UpdatePlaceMutationHookResult = ReturnType<
  typeof useUpdatePlaceMutation
>;
export type UpdatePlaceMutationResult =
  Apollo.MutationResult<UpdatePlaceMutation>;
export type UpdatePlaceMutationOptions = Apollo.BaseMutationOptions<
  UpdatePlaceMutation,
  UpdatePlaceMutationVariables
>;
export const DeleteMoimCoverPhotoDocument = gql`
  mutation DeleteMoimCoverPhoto($moimId: ID!) {
    deleteMoimCoverPhoto(moimId: $moimId) {
      id
      coverPhoto {
        id
        name
        uri
      }
    }
  }
`;
export type DeleteMoimCoverPhotoMutationFn = Apollo.MutationFunction<
  DeleteMoimCoverPhotoMutation,
  DeleteMoimCoverPhotoMutationVariables
>;

/**
 * __useDeleteMoimCoverPhotoMutation__
 *
 * To run a mutation, you first call `useDeleteMoimCoverPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMoimCoverPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMoimCoverPhotoMutation, { data, loading, error }] = useDeleteMoimCoverPhotoMutation({
 *   variables: {
 *      moimId: // value for 'moimId'
 *   },
 * });
 */
export function useDeleteMoimCoverPhotoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMoimCoverPhotoMutation,
    DeleteMoimCoverPhotoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteMoimCoverPhotoMutation,
    DeleteMoimCoverPhotoMutationVariables
  >(DeleteMoimCoverPhotoDocument, options);
}
export type DeleteMoimCoverPhotoMutationHookResult = ReturnType<
  typeof useDeleteMoimCoverPhotoMutation
>;
export type DeleteMoimCoverPhotoMutationResult =
  Apollo.MutationResult<DeleteMoimCoverPhotoMutation>;
export type DeleteMoimCoverPhotoMutationOptions = Apollo.BaseMutationOptions<
  DeleteMoimCoverPhotoMutation,
  DeleteMoimCoverPhotoMutationVariables
>;
export const AddMoimMediaDocument = gql`
  mutation AddMoimMedia($moimId: ID!, $mediaInput: [MoimMediaInput]!) {
    addMoimMedia(moimId: $moimId, mediaInput: $mediaInput) {
      id
      media {
        id
        uri
      }
    }
  }
`;
export type AddMoimMediaMutationFn = Apollo.MutationFunction<
  AddMoimMediaMutation,
  AddMoimMediaMutationVariables
>;

/**
 * __useAddMoimMediaMutation__
 *
 * To run a mutation, you first call `useAddMoimMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMoimMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMoimMediaMutation, { data, loading, error }] = useAddMoimMediaMutation({
 *   variables: {
 *      moimId: // value for 'moimId'
 *      mediaInput: // value for 'mediaInput'
 *   },
 * });
 */
export function useAddMoimMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddMoimMediaMutation,
    AddMoimMediaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddMoimMediaMutation,
    AddMoimMediaMutationVariables
  >(AddMoimMediaDocument, options);
}
export type AddMoimMediaMutationHookResult = ReturnType<
  typeof useAddMoimMediaMutation
>;
export type AddMoimMediaMutationResult =
  Apollo.MutationResult<AddMoimMediaMutation>;
export type AddMoimMediaMutationOptions = Apollo.BaseMutationOptions<
  AddMoimMediaMutation,
  AddMoimMediaMutationVariables
>;
export const DeleteMoimMediaDocument = gql`
  mutation DeleteMoimMedia($moimId: ID!, $mediaId: ID!) {
    deleteMoimMedia(moimId: $moimId, mediaId: $mediaId) {
      id
      media {
        id
        uri
      }
    }
  }
`;
export type DeleteMoimMediaMutationFn = Apollo.MutationFunction<
  DeleteMoimMediaMutation,
  DeleteMoimMediaMutationVariables
>;

/**
 * __useDeleteMoimMediaMutation__
 *
 * To run a mutation, you first call `useDeleteMoimMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMoimMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMoimMediaMutation, { data, loading, error }] = useDeleteMoimMediaMutation({
 *   variables: {
 *      moimId: // value for 'moimId'
 *      mediaId: // value for 'mediaId'
 *   },
 * });
 */
export function useDeleteMoimMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMoimMediaMutation,
    DeleteMoimMediaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteMoimMediaMutation,
    DeleteMoimMediaMutationVariables
  >(DeleteMoimMediaDocument, options);
}
export type DeleteMoimMediaMutationHookResult = ReturnType<
  typeof useDeleteMoimMediaMutation
>;
export type DeleteMoimMediaMutationResult =
  Apollo.MutationResult<DeleteMoimMediaMutation>;
export type DeleteMoimMediaMutationOptions = Apollo.BaseMutationOptions<
  DeleteMoimMediaMutation,
  DeleteMoimMediaMutationVariables
>;
export const RequestMoimVerificationDocument = gql`
  mutation RequestMoimVerification($moimId: ID!) {
    requestMoimVerification(moimId: $moimId) {
      id
      status
    }
  }
`;
export type RequestMoimVerificationMutationFn = Apollo.MutationFunction<
  RequestMoimVerificationMutation,
  RequestMoimVerificationMutationVariables
>;

/**
 * __useRequestMoimVerificationMutation__
 *
 * To run a mutation, you first call `useRequestMoimVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestMoimVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestMoimVerificationMutation, { data, loading, error }] = useRequestMoimVerificationMutation({
 *   variables: {
 *      moimId: // value for 'moimId'
 *   },
 * });
 */
export function useRequestMoimVerificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestMoimVerificationMutation,
    RequestMoimVerificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestMoimVerificationMutation,
    RequestMoimVerificationMutationVariables
  >(RequestMoimVerificationDocument, options);
}
export type RequestMoimVerificationMutationHookResult = ReturnType<
  typeof useRequestMoimVerificationMutation
>;
export type RequestMoimVerificationMutationResult =
  Apollo.MutationResult<RequestMoimVerificationMutation>;
export type RequestMoimVerificationMutationOptions = Apollo.BaseMutationOptions<
  RequestMoimVerificationMutation,
  RequestMoimVerificationMutationVariables
>;
export const JoinMoimDocument = gql`
  mutation JoinMoim($moimId: ID!) {
    joinMoim(moimId: $moimId) {
      id
      moim {
        id
        status
      }
      status
      createdAt
      updatedAt
      user {
        id
      }
    }
  }
`;
export type JoinMoimMutationFn = Apollo.MutationFunction<
  JoinMoimMutation,
  JoinMoimMutationVariables
>;

/**
 * __useJoinMoimMutation__
 *
 * To run a mutation, you first call `useJoinMoimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinMoimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinMoimMutation, { data, loading, error }] = useJoinMoimMutation({
 *   variables: {
 *      moimId: // value for 'moimId'
 *   },
 * });
 */
export function useJoinMoimMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JoinMoimMutation,
    JoinMoimMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<JoinMoimMutation, JoinMoimMutationVariables>(
    JoinMoimDocument,
    options
  );
}
export type JoinMoimMutationHookResult = ReturnType<typeof useJoinMoimMutation>;
export type JoinMoimMutationResult = Apollo.MutationResult<JoinMoimMutation>;
export type JoinMoimMutationOptions = Apollo.BaseMutationOptions<
  JoinMoimMutation,
  JoinMoimMutationVariables
>;
export const CancelJoinMoimDocument = gql`
  mutation CancelJoinMoim($joinId: ID!) {
    cancelJoinMoim(joinId: $joinId) {
      id
      moim {
        id
        status
      }
      user {
        id
      }
      status
      createdAt
      updatedAt
    }
  }
`;
export type CancelJoinMoimMutationFn = Apollo.MutationFunction<
  CancelJoinMoimMutation,
  CancelJoinMoimMutationVariables
>;

/**
 * __useCancelJoinMoimMutation__
 *
 * To run a mutation, you first call `useCancelJoinMoimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelJoinMoimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelJoinMoimMutation, { data, loading, error }] = useCancelJoinMoimMutation({
 *   variables: {
 *      joinId: // value for 'joinId'
 *   },
 * });
 */
export function useCancelJoinMoimMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelJoinMoimMutation,
    CancelJoinMoimMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelJoinMoimMutation,
    CancelJoinMoimMutationVariables
  >(CancelJoinMoimDocument, options);
}
export type CancelJoinMoimMutationHookResult = ReturnType<
  typeof useCancelJoinMoimMutation
>;
export type CancelJoinMoimMutationResult =
  Apollo.MutationResult<CancelJoinMoimMutation>;
export type CancelJoinMoimMutationOptions = Apollo.BaseMutationOptions<
  CancelJoinMoimMutation,
  CancelJoinMoimMutationVariables
>;
export const UpdateReviewDocument = gql`
  mutation UpdateReview($reviewInput: ReviewInput!) {
    updateReview(reviewInput: $reviewInput) {
      id
      writer {
        id
      }
      moim {
        id
      }
      body
      rating
      createdAt
      updatedAt
    }
  }
`;
export type UpdateReviewMutationFn = Apollo.MutationFunction<
  UpdateReviewMutation,
  UpdateReviewMutationVariables
>;

/**
 * __useUpdateReviewMutation__
 *
 * To run a mutation, you first call `useUpdateReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReviewMutation, { data, loading, error }] = useUpdateReviewMutation({
 *   variables: {
 *      reviewInput: // value for 'reviewInput'
 *   },
 * });
 */
export function useUpdateReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateReviewMutation,
    UpdateReviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateReviewMutation,
    UpdateReviewMutationVariables
  >(UpdateReviewDocument, options);
}
export type UpdateReviewMutationHookResult = ReturnType<
  typeof useUpdateReviewMutation
>;
export type UpdateReviewMutationResult =
  Apollo.MutationResult<UpdateReviewMutation>;
export type UpdateReviewMutationOptions = Apollo.BaseMutationOptions<
  UpdateReviewMutation,
  UpdateReviewMutationVariables
>;
export const GetCommonThemeDocument = gql`
  query GetCommonTheme {
    getCommonTheme {
      id
      name
      phrase
      iconUrl
      contents(limit: 6) {
        id
        title
        thumbnail {
          id
          uri
        }
        themes {
          id
          name
        }
        fields {
          id
          name
        }
        createdAt
        updatedAt
      }
    }
  }
`;

/**
 * __useGetCommonThemeQuery__
 *
 * To run a query within a React component, call `useGetCommonThemeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommonThemeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommonThemeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCommonThemeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCommonThemeQuery,
    GetCommonThemeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCommonThemeQuery, GetCommonThemeQueryVariables>(
    GetCommonThemeDocument,
    options
  );
}
export function useGetCommonThemeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCommonThemeQuery,
    GetCommonThemeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCommonThemeQuery, GetCommonThemeQueryVariables>(
    GetCommonThemeDocument,
    options
  );
}
export type GetCommonThemeQueryHookResult = ReturnType<
  typeof useGetCommonThemeQuery
>;
export type GetCommonThemeLazyQueryHookResult = ReturnType<
  typeof useGetCommonThemeLazyQuery
>;
export type GetCommonThemeQueryResult = Apollo.QueryResult<
  GetCommonThemeQuery,
  GetCommonThemeQueryVariables
>;
export const GetHomeThemesDocument = gql`
  query GetHomeThemes {
    getHomeThemes {
      id
      name
      phrase
      iconUrl
      contents(limit: 9) {
        id
        title
        thumbnail {
          id
          uri
        }
        themes {
          id
          name
        }
        fields {
          id
          name
        }
        createdAt
        updatedAt
      }
    }
  }
`;

/**
 * __useGetHomeThemesQuery__
 *
 * To run a query within a React component, call `useGetHomeThemesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHomeThemesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomeThemesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHomeThemesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetHomeThemesQuery,
    GetHomeThemesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetHomeThemesQuery, GetHomeThemesQueryVariables>(
    GetHomeThemesDocument,
    options
  );
}
export function useGetHomeThemesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHomeThemesQuery,
    GetHomeThemesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetHomeThemesQuery, GetHomeThemesQueryVariables>(
    GetHomeThemesDocument,
    options
  );
}
export type GetHomeThemesQueryHookResult = ReturnType<
  typeof useGetHomeThemesQuery
>;
export type GetHomeThemesLazyQueryHookResult = ReturnType<
  typeof useGetHomeThemesLazyQuery
>;
export type GetHomeThemesQueryResult = Apollo.QueryResult<
  GetHomeThemesQuery,
  GetHomeThemesQueryVariables
>;
export const GetMyInitialProfileDocument = gql`
  query GetMyInitialProfile {
    getMyUserProfile {
      id
      username
      name
      email
      phone
      dateOfBirth
      gender
      nationality {
        id
        name
      }
      host {
        id
      }
      avatar {
        id
        uri
      }
      reviewCount
      isAdmin
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetMyInitialProfileQuery__
 *
 * To run a query within a React component, call `useGetMyInitialProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyInitialProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyInitialProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyInitialProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyInitialProfileQuery,
    GetMyInitialProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyInitialProfileQuery,
    GetMyInitialProfileQueryVariables
  >(GetMyInitialProfileDocument, options);
}
export function useGetMyInitialProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyInitialProfileQuery,
    GetMyInitialProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyInitialProfileQuery,
    GetMyInitialProfileQueryVariables
  >(GetMyInitialProfileDocument, options);
}
export type GetMyInitialProfileQueryHookResult = ReturnType<
  typeof useGetMyInitialProfileQuery
>;
export type GetMyInitialProfileLazyQueryHookResult = ReturnType<
  typeof useGetMyInitialProfileLazyQuery
>;
export type GetMyInitialProfileQueryResult = Apollo.QueryResult<
  GetMyInitialProfileQuery,
  GetMyInitialProfileQueryVariables
>;
export const GetMyUserProfileDocument = gql`
  query GetMyUserProfile {
    getMyUserProfile {
      id
      username
      name
      email
      phone
      dateOfBirth
      gender
      nationality {
        id
        name
      }
      host {
        id
        bankName
        bankAccount
        introduction
        verified
        idNumber
        bankAccountMedia {
          id
          name
          uri
        }
        business {
          id
          name
          ownerName
          registrationNumber
          registrationMedia {
            id
            name
            uri
          }
          taxEmail
        }
      }
      avatar {
        id
        uri
      }
      reviewCount
      isAdmin
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetMyUserProfileQuery__
 *
 * To run a query within a React component, call `useGetMyUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyUserProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyUserProfileQuery,
    GetMyUserProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyUserProfileQuery, GetMyUserProfileQueryVariables>(
    GetMyUserProfileDocument,
    options
  );
}
export function useGetMyUserProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyUserProfileQuery,
    GetMyUserProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyUserProfileQuery,
    GetMyUserProfileQueryVariables
  >(GetMyUserProfileDocument, options);
}
export type GetMyUserProfileQueryHookResult = ReturnType<
  typeof useGetMyUserProfileQuery
>;
export type GetMyUserProfileLazyQueryHookResult = ReturnType<
  typeof useGetMyUserProfileLazyQuery
>;
export type GetMyUserProfileQueryResult = Apollo.QueryResult<
  GetMyUserProfileQuery,
  GetMyUserProfileQueryVariables
>;
export const SearchNationalityDocument = gql`
  query SearchNationality($query: String!) {
    searchNationality(query: $query) {
      id
      name
      emoji
      imageUrl
    }
  }
`;

/**
 * __useSearchNationalityQuery__
 *
 * To run a query within a React component, call `useSearchNationalityQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchNationalityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchNationalityQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchNationalityQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchNationalityQuery,
    SearchNationalityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchNationalityQuery,
    SearchNationalityQueryVariables
  >(SearchNationalityDocument, options);
}
export function useSearchNationalityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchNationalityQuery,
    SearchNationalityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchNationalityQuery,
    SearchNationalityQueryVariables
  >(SearchNationalityDocument, options);
}
export type SearchNationalityQueryHookResult = ReturnType<
  typeof useSearchNationalityQuery
>;
export type SearchNationalityLazyQueryHookResult = ReturnType<
  typeof useSearchNationalityLazyQuery
>;
export type SearchNationalityQueryResult = Apollo.QueryResult<
  SearchNationalityQuery,
  SearchNationalityQueryVariables
>;
export const IsUniqueUsernameDocument = gql`
  query IsUniqueUsername($username: String!) {
    isUniqueUsername(username: $username)
  }
`;

/**
 * __useIsUniqueUsernameQuery__
 *
 * To run a query within a React component, call `useIsUniqueUsernameQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsUniqueUsernameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsUniqueUsernameQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useIsUniqueUsernameQuery(
  baseOptions: Apollo.QueryHookOptions<
    IsUniqueUsernameQuery,
    IsUniqueUsernameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IsUniqueUsernameQuery, IsUniqueUsernameQueryVariables>(
    IsUniqueUsernameDocument,
    options
  );
}
export function useIsUniqueUsernameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IsUniqueUsernameQuery,
    IsUniqueUsernameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IsUniqueUsernameQuery,
    IsUniqueUsernameQueryVariables
  >(IsUniqueUsernameDocument, options);
}
export type IsUniqueUsernameQueryHookResult = ReturnType<
  typeof useIsUniqueUsernameQuery
>;
export type IsUniqueUsernameLazyQueryHookResult = ReturnType<
  typeof useIsUniqueUsernameLazyQuery
>;
export type IsUniqueUsernameQueryResult = Apollo.QueryResult<
  IsUniqueUsernameQuery,
  IsUniqueUsernameQueryVariables
>;
export const UpdateUserProfileDocument = gql`
  mutation UpdateUserProfile($userInput: UserInput!) {
    updateUserProfile(userInput: $userInput) {
      id
      username
      name
      email
      phone
      dateOfBirth
      gender
      nationality {
        id
        name
        emoji
      }
      host {
        id
        verified
      }
      avatar {
        id
        uri
      }
    }
  }
`;
export type UpdateUserProfileMutationFn = Apollo.MutationFunction<
  UpdateUserProfileMutation,
  UpdateUserProfileMutationVariables
>;

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      userInput: // value for 'userInput'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserProfileMutation,
    UpdateUserProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserProfileMutation,
    UpdateUserProfileMutationVariables
  >(UpdateUserProfileDocument, options);
}
export type UpdateUserProfileMutationHookResult = ReturnType<
  typeof useUpdateUserProfileMutation
>;
export type UpdateUserProfileMutationResult =
  Apollo.MutationResult<UpdateUserProfileMutation>;
export type UpdateUserProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserProfileMutation,
  UpdateUserProfileMutationVariables
>;
export const UpdateUserAvatarDocument = gql`
  mutation UpdateUserAvatar($avatarInput: MediaInput!) {
    updateUserAvatar(avatarInput: $avatarInput) {
      id
      avatar {
        id
        uri
      }
    }
  }
`;
export type UpdateUserAvatarMutationFn = Apollo.MutationFunction<
  UpdateUserAvatarMutation,
  UpdateUserAvatarMutationVariables
>;

/**
 * __useUpdateUserAvatarMutation__
 *
 * To run a mutation, you first call `useUpdateUserAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAvatarMutation, { data, loading, error }] = useUpdateUserAvatarMutation({
 *   variables: {
 *      avatarInput: // value for 'avatarInput'
 *   },
 * });
 */
export function useUpdateUserAvatarMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserAvatarMutation,
    UpdateUserAvatarMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserAvatarMutation,
    UpdateUserAvatarMutationVariables
  >(UpdateUserAvatarDocument, options);
}
export type UpdateUserAvatarMutationHookResult = ReturnType<
  typeof useUpdateUserAvatarMutation
>;
export type UpdateUserAvatarMutationResult =
  Apollo.MutationResult<UpdateUserAvatarMutation>;
export type UpdateUserAvatarMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserAvatarMutation,
  UpdateUserAvatarMutationVariables
>;
export const DeleteUserAvatarDocument = gql`
  mutation DeleteUserAvatar {
    deleteUserAvatar {
      id
      avatar {
        id
        uri
      }
    }
  }
`;
export type DeleteUserAvatarMutationFn = Apollo.MutationFunction<
  DeleteUserAvatarMutation,
  DeleteUserAvatarMutationVariables
>;

/**
 * __useDeleteUserAvatarMutation__
 *
 * To run a mutation, you first call `useDeleteUserAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserAvatarMutation, { data, loading, error }] = useDeleteUserAvatarMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteUserAvatarMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserAvatarMutation,
    DeleteUserAvatarMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteUserAvatarMutation,
    DeleteUserAvatarMutationVariables
  >(DeleteUserAvatarDocument, options);
}
export type DeleteUserAvatarMutationHookResult = ReturnType<
  typeof useDeleteUserAvatarMutation
>;
export type DeleteUserAvatarMutationResult =
  Apollo.MutationResult<DeleteUserAvatarMutation>;
export type DeleteUserAvatarMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserAvatarMutation,
  DeleteUserAvatarMutationVariables
>;
