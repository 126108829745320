import { format } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled, { ThemedComponentProps, useTheme } from "styled-components";
import { MoimStatus } from "../../../../lib/apollo/graphql/generated";
import { useScreenWidthContext } from "../../../../providers/ScreenWidthProvider";
import LazyImage from "../../../common/components/LazyImage";
import { useGetMoimContent } from "../../../common/hook/useGetMoimContent";
import { DefaultButton, Row } from "../../../common/styles/commonStyles";
import { getMoimcontentByLanguage } from "../../../common/utils/getMoimContentByLanguage";

interface ManageMyMoimReviewCardProps {
  moim: {
    __typename?: "Moim";
    id: string;
    moimStartAt?: any | null;
    status: MoimStatus;
    maxPersons?: number | null;
    minPersons?: number | null;
    createdAt: any;
    updatedAt: any;
    place?: {
      __typename?: "Place";
      id: string;
      name?: string | null;
      address?: string | null;
      addressDetail?: string | null;
      isPrivate: boolean;
    } | null;
    coverPhoto?: { __typename?: "Media"; id: string; uri: string } | null;
    moimContents?: Array<{
      __typename?: "MoimContent";
      id: string;
      languageCode: string;
      title?: string | null;
      introduction?: string | null;
      detail?: string | null;
      price?: number | null;
      createdAt: any;
      updatedAt: any;
    } | null> | null;
    myReview?: {
      __typename?: "Review";
      id: string;
      body: string;
      rating?: number | null;
      createdAt: any;
      updatedAt: any;
      writer: { __typename?: "UserProfile"; id: string };
      moim: { __typename?: "Moim"; id: string };
    } | null;
  };
}

function ManageMyMoimReviewCard({ moim }: ManageMyMoimReviewCardProps) {
  const { i18n, t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const { isPc } = useScreenWidthContext();

  const koreanContent = getMoimcontentByLanguage("ko", moim?.moimContents);
  const englishContent = getMoimcontentByLanguage("en", moim?.moimContents);

  const currentLanguage = i18n.resolvedLanguage;

  const currentLanguageContent =
    currentLanguage === "ko" ? koreanContent : englishContent;

  const { formattedMoimStartAt } = useGetMoimContent(
    currentLanguageContent,
    moim?.moimStartAt
  );

  return (
    <div>
      <Spacer />
      <TopRow>
        {!!moim?.coverPhoto?.uri && (
          <CoverPhoto>
            <LazyImage
              uri={moim?.coverPhoto?.uri}
              width="100%"
              height="100%"
              objectFit="cover"
            />
          </CoverPhoto>
        )}
        <div>
          <MoimTitle>{englishContent?.title}</MoimTitle>
          <MoimSubTitle>{koreanContent?.title}</MoimSubTitle>
          <MoimStartAt>{formattedMoimStartAt}</MoimStartAt>
        </div>
      </TopRow>
      {moim?.myReview ? (
        <>
          <Review>{moim?.myReview?.body}</Review>
          <ReviewCreatedAt>
            {format(new Date(moim?.myReview?.createdAt), "yy/MM/dd")}
          </ReviewCreatedAt>
        </>
      ) : (
        <ReviewButton onClick={() => navigate("/review/" + moim?.id!)}>
          {t("manageMyMoim.leaveReview")}
        </ReviewButton>
      )}
    </div>
  );
}

const Spacer = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  height: 1,
  backgroundColor: theme.color.border,
}));

const TopRow = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  padding: `${theme.spacing.middle}px 0px`,
  gap: theme.spacing.middle,
  alignItems: "center",
  position: "relative",

  [theme.media.down["DESKTOP"]]: {
    flexDirection: "column",
    paddingTop: 0,
    width: "100%",
    alignItems: "flex-start",
    gap: theme.spacing.default,
  },
}));

const CoverPhoto = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  height: 85,
  width: 129,

  [theme.media.down["DESKTOP"]]: {
    width: "100%",
    height: ((window.innerWidth - theme.spacing.regular * 2) * 155) / 335,
  },
}));

const MoimTitle = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.body2,
  fontWeight: "bold",
  textAlign: "left",

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.body3,
  },
}));

const MoimSubTitle = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.body3,
  textAlign: "left",
  marginBottom: theme.spacing.default,

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.body3,
    marginBottom: theme.spacing.normal,
  },
}));

const MoimStartAt = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.caption1,
  textAlign: "left",
  color: "#8c8c8c",
}));

const Review = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.body3,
  textAlign: "left",
  fontWeight: "bold",
}));

const ReviewCreatedAt = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.body3,
  color: "#8c8c8c",
}));

const ReviewButton = styled(DefaultButton)<any>(
  ({ theme }: ThemedComponentProps) => ({
    width: "100%",
    padding: `${theme.spacing.normal}px 0px`,
    backgroundColor: theme.color.green,
    color: theme.color.white,
    fontSize: theme.font.body3,
    textAlign: "center",
    fontWeight: "bold",
  })
);

export default ManageMyMoimReviewCard;
