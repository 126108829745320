import { useApolloClient } from "@apollo/client";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled, { ThemedComponentProps, useTheme } from "styled-components";
import rightArrowIcon from "../../../../assets/icons/chevron_right_black.svg";
import settingIcon from "../../../../assets/icons/settings.svg";
import { firebaseSignOut } from "../../../../lib/firebase";
import { useAuthContext } from "../../../../providers/AuthProvider";
import { useScreenWidthContext } from "../../../../providers/ScreenWidthProvider";
import Avatar from "../../../common/components/Avatar";
import { SCREENS } from "../../../routes/utils/router";
import { MyPageTitle } from "../common/styles/MyPageCommonStyles";
import {
  Col,
  DefaultButton,
  GreyTextButton,
  Row,
  TouchableOpacity,
} from "../../../common/styles/commonStyles";

function MyPageTopSection() {
  const client = useApolloClient();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const { isPc } = useScreenWidthContext();

  const { userProfile } = useAuthContext();

  const isHost = !!userProfile?.host?.id;

  return (
    <Wrapper>
      <TopRow>
        <MyPageTitle style={{ margin: 0 }}>{t("myPage.title")}</MyPageTitle>
        <GreyTextButton
          onClick={async () => {
            await firebaseSignOut();
            await client.clearStore();
            navigate(SCREENS.MAIN.path!, { replace: true });
          }}
        >
          {t("myPage.signOut")}
        </GreyTextButton>
      </TopRow>

      <BodyRow>
        <BodyCell>
          <ProfileRow>
            <CenterRow
              style={{
                gap: isPc ? theme.spacing.default : theme.spacing.middle,
              }}
            >
              <Avatar
                uri={userProfile?.avatar?.uri}
                editable={false}
                diameter={isPc ? 100 : 80}
              />
              <div>
                <CenterRow>
                  <Username>{userProfile?.username}</Username>
                  <DefaultButton
                    style={{ width: 20, height: 20 }}
                    onClick={() => navigate("/user/edit/" + userProfile?.id)}
                  >
                    <SettingIcon />
                  </DefaultButton>
                </CenterRow>
                <UserType>
                  {isHost ? t("myPage.host") : t("myPage.member")}
                </UserType>
              </div>
            </CenterRow>
            {isHost && isPc && (
              <ManageMoimButton
                onClick={() => navigate("/host/" + userProfile?.host?.id!)}
              >
                <ManageMoimButtonSmallText>
                  {t("myPage.host")}
                </ManageMoimButtonSmallText>
                <CenterRow>
                  <div>{t("myPage.manageMoim")}</div>
                  <RightArrow
                    style={{ marginLeft: 0, width: 16, height: 16 }}
                  />
                </CenterRow>
              </ManageMoimButton>
            )}
          </ProfileRow>
          {isHost && !isPc && (
            <ManageMoimButton
              onClick={() => navigate("/host/" + userProfile?.host?.id!)}
            >
              <div>{t("myPage.host")}</div>
              <CenterRow>
                <div>{t("myPage.manageMoim")}</div>
                <RightArrow style={{ marginLeft: 0, width: 16, height: 16 }} />
              </CenterRow>
            </ManageMoimButton>
          )}
        </BodyCell>
        <BodyCell
          style={{
            paddingLeft: isPc ? theme.spacing.section : theme.spacing.middle,
            paddingRight: isPc ? theme.spacing.section : theme.spacing.middle,
          }}
        >
          <Col
            style={{
              width: "100%",
              justifyContent: "center",
              height: "100%",
              gap: isPc ? "unset" : theme.spacing.default,
            }}
          >
            <CenterRow style={{ flex: 1 }}>
              <Label style={{ flex: 1 }}>{t("myPage.review")}</Label>
              <CenterRow style={{ flex: isPc ? 5 : 3 }}>
                <DefaultButton
                  onClick={() => navigate("/moims/user/" + userProfile?.id!)}
                >
                  <CenterRow>
                    <Label>
                      {(userProfile?.reviewCount || 0) + t("common.case")}
                    </Label>

                    <RightArrow />
                  </CenterRow>
                </DefaultButton>
              </CenterRow>
            </CenterRow>
            {/* <CenterRow style={{ flex: 1 }}>
              <Label style={{ flex: 1 }}>{t("myPage.paidHistory")}</Label>
              <CenterRow style={{ flex: isPc?5:3 }}>
                <Label>{t("common.case")}</Label>
                <DefaultButton style={{ width: 20, height: 20 }}>
                  <RightArrow />
                </DefaultButton>
              </CenterRow>
            </CenterRow> */}
          </Col>
        </BodyCell>
      </BodyRow>
    </Wrapper>
  );
}

const Wrapper = styled(Col)<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  maxWidth: theme.containerMaxWidth,
  padding: `${theme.spacing.regular * 3}px ${theme.spacing.section}px`,

  [theme.media.down["DESKTOP"]]: {
    padding: `${theme.spacing.section}px ${theme.spacing.regular}px`,
  },
}));

const TopRow = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  alignItems: "flex-end",
  justifyContent: "space-between",
  marginBottom: theme.spacing.middle,
}));

const BodyRow = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  alignItems: "stretch",
  gap: theme.spacing.default,

  [theme.media.down["DESKTOP"]]: {
    flexDirection: "column",
  },
}));

const BodyCell = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  flex: 1,
  backgroundColor: "#f2efed",
  padding: theme.spacing.middle,

  [theme.media.down["DESKTOP"]]: {
    padding: `${theme.spacing.default}px ${theme.spacing.middle}px`,
  },
}));

const ProfileRow = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  alignItems: "center",
  justifyContent: "space-between",

  [theme.media.down["DESKTOP"]]: {
    justifyContent: "flex-start",
  },
}));

const Username = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.body2,
  fontWeight: "bold",
  marginRight: theme.spacing.xxSmall2,

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.body3,
    marginRight: theme.spacing.xxSmall,
  },
}));

const SettingIcon = styled.img.attrs(() => ({
  src: settingIcon,
}))<any>(() => ({
  width: 20,
  height: 20,
}));

const UserType = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.caption1,
  marginTop: theme.spacing.xxSmall2,
}));

const ManageMoimButton = styled(DefaultButton)<any>(
  ({ theme }: ThemedComponentProps) => ({
    padding: `${theme.spacing.default}px ${theme.spacing.middle}px`,
    backgroundColor: theme.color.orange,
    textAlign: "center",
    fontSize: theme.font.body3,
    fontWeight: "bold",
    borderRadius: 4,

    [theme.media.down["DESKTOP"]]: {
      width: "100%",
      padding: theme.spacing.normal,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
      gap: theme.spacing.xxSmall2,
      marginTop: theme.spacing.default,
    },
  })
);

const ManageMoimButtonSmallText = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    fontSize: theme.font.caption2,
    fontWeight: "normal",
  })
);

const CenterRow = styled(Row)<any>(() => ({
  alignItems: "center",
}));

const Label = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.body3,
  fontWeight: "bold",

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.caption1,
  },
}));

const RightArrow = styled.img.attrs(() => ({
  src: rightArrowIcon,
}))<any>(({ theme }: ThemedComponentProps) => ({
  width: 20,
  height: 20,
  marginLeft: theme.spacing.xxSmall2,
}));

export default MyPageTopSection;
