import { useEffect, useRef, useState } from "react";

function useResizeImageHeight() {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [height, setHeight] = useState(0);

  function handleResize() {
    if (wrapperRef?.current) {
      setHeight(wrapperRef?.current.offsetWidth * 0.7);
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return {
    refs: {
      wrapperRef,
    },
    models: {
      height,
    },
  };
}

export default useResizeImageHeight;
