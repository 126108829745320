import React from "react";
import { Link } from "react-router-dom";
import styled, { ThemedComponentProps } from "styled-components";
import cryMobee from "../../../assets/icons/cry_mobee.svg";
import Layout from "../../common/components/Layout";
import { SCREENS } from "../../routes/utils/router";
import {
  SectionWrapper,
  TouchableOpacity,
} from "../../common/styles/commonStyles";

interface NoPageContainerProps {
  isError?: boolean;
}

function NoPageContainer({ isError = false }: NoPageContainerProps) {
  return isError ? (
    <SectionWrapper>
      <Mobee />
      <Title>404</Title>
      <Body>
        <strong>
          죄송합니다!
          <br />
          페이지에 문제가 발생했습니다.
        </strong>
        <br />
        <br />
        홈으로 돌아가시거나, 새로고침을 해주세요.
      </Body>
      <Link to={SCREENS.MAIN.path!}>
        <Button>홈으로</Button>
      </Link>
    </SectionWrapper>
  ) : (
    <Layout>
      <SectionWrapper>
        <Mobee />
        <Title>404</Title>
        <Body>
          <strong>
            죄송합니다!
            <br />
            페이지에 문제가 발생했습니다.
          </strong>
          <br />
          <br />
          홈으로 돌아가시거나, 새로고침을 해주세요.
        </Body>
        <Link to={SCREENS.MAIN.path!}>
          <Button>홈으로</Button>
        </Link>
      </SectionWrapper>
    </Layout>
  );
}

const Mobee = styled.img.attrs(() => ({
  src: cryMobee,
}))<any>(({ theme }: ThemedComponentProps) => ({
  marginBottom: theme.spacing.middle,
}));

const Title = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: 90,
  fontFamily: "Pretendard",
  marginBottom: theme.spacing.middle,
}));

const Body = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.body3,
  fontFamily: "Pretendard",
  textAlign: "center",
  marginBottom: theme.spacing.section * 2,
}));

const Button = styled(TouchableOpacity)<any>(
  ({ theme }: ThemedComponentProps) => ({
    backgroundColor: theme.color.green,
    width: 370,
    textAlign: "center",
    padding: `${theme.spacing.default}px 0px`,
    color: theme.color.white,
    fontSize: theme.font.body2,
    fontWeight: "bold",
    fontFamily: "Pretendard",
  })
);

export default NoPageContainer;
