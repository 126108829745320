import { format } from "date-fns";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import useUpdateMoimBasicInformationContainer from "./UpdateMoimBasicInformationContainer.hook";
import AddressInput from "../../../../common/components/AddressInput";
import { Row, SaveButton } from "../../../../common/styles/commonStyles";
import {
  Gender,
  MoimStatus,
} from "../../../../../lib/apollo/graphql/generated";
import {
  UpdateMoimInput,
  UpdateMoimInputSection,
  UpdateMoimLabel,
  UpdateMoimSectionTitle,
  UpdateMoimSelectButton,
  UpdateMoimSelectButtonsRow,
  UpdateMoimSeparator,
  UpdateMoimStep,
  UpdateMoimSubtitle,
  UpdateMoimTitle,
  UpdateMoimWrapper,
} from "../../common/updateMoimCommonStyles";

function UpdateMoimBasicInformationContainer() {
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    models: { loading, state, data, updateLoading },
    operations: {
      onInputChange,
      onIsPrivateChange,
      onAddressChange,
      onGenderRestrictionChange,
      toggleShowAgeInput,
      onAgeRestrictionChange,
      onSaveClick,
    },
  } = useUpdateMoimBasicInformationContainer();

  const disabled =
    (data &&
      !(
        data?.status === MoimStatus.Editing ||
        data?.status === MoimStatus.Rejected
      )) ||
    updateLoading;

  const {
    date,
    startTime,
    endTime,
    isPrivate,
    placeName,
    placeAddress,
    placeAddressDetail,
    price,
    genderRestriction,
    showAgeInput,
    minAge,
    maxAge,
    ageRestriction,
    minPersons,
    maxPersons,
  } = state;

  const memoizedMoimDateInput = useMemo(
    () => (
      <UpdateMoimInputSection>
        <UpdateMoimLabel>
          {t("updateMoimBasicInformation.date")}
        </UpdateMoimLabel>
        <UpdateMoimInput
          type="date"
          disabled={disabled}
          placeholder={t("updateMoimBasicInformation.datePlaceholder")}
          value={date}
          min={format(new Date(), "yyyy-MM-dd")}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "date")
          }
        />
      </UpdateMoimInputSection>
    ),
    [date, onInputChange]
  );

  const memoizedTimeInput = useMemo(
    () => (
      <UpdateMoimInputSection>
        <Row style={{ gap: theme.spacing.small }}>
          <div style={{ flex: 1 }}>
            <UpdateMoimLabel>
              {t("updateMoimBasicInformation.startTime")}
            </UpdateMoimLabel>
            <UpdateMoimInput
              type="time"
              disabled={disabled}
              placeholder={t("updateMoimBasicInformation.startTimePlaceholder")}
              value={startTime}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onInputChange(e, "startTime")
              }
            />
          </div>
          <div style={{ flex: 1 }}>
            <UpdateMoimLabel>
              {t("updateMoimBasicInformation.endTime")}
            </UpdateMoimLabel>
            <UpdateMoimInput
              type="time"
              min={startTime}
              disabled={disabled}
              placeholder={t("updateMoimBasicInformation.endTimePlaceholder")}
              value={endTime}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onInputChange(e, "endTime")
              }
            />
          </div>
        </Row>
      </UpdateMoimInputSection>
    ),
    [startTime, endTime, onInputChange]
  );

  const memoizedIsPrivateInput = useMemo(
    () => (
      <UpdateMoimInputSection>
        <UpdateMoimLabel
          style={{ fontSize: theme.font.body3, fontWeight: "bold" }}
        >
          {t("updateMoimBasicInformation.isPrivatePlace")}
        </UpdateMoimLabel>
        <UpdateMoimSelectButtonsRow>
          <UpdateMoimSelectButton
            showBorderRight
            selected={isPrivate}
            disabled={disabled}
            onClick={() => onIsPrivateChange(true)}
          >
            {t("common.yes")}
          </UpdateMoimSelectButton>
          <UpdateMoimSelectButton
            selected={!isPrivate}
            disabled={disabled}
            onClick={() => onIsPrivateChange(false)}
          >
            {t("common.no")}
          </UpdateMoimSelectButton>
        </UpdateMoimSelectButtonsRow>
      </UpdateMoimInputSection>
    ),
    [isPrivate, onIsPrivateChange]
  );

  const memoizedPlaceNameInput = useMemo(
    () => (
      <UpdateMoimInputSection>
        <UpdateMoimLabel>
          {t("updateMoimBasicInformation.placeName")}
        </UpdateMoimLabel>
        <UpdateMoimInput
          value={placeName}
          disabled={disabled}
          placeholder={t("updateMoimBasicInformation.placeNamePlaceholder")}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "placeName")
          }
        />
      </UpdateMoimInputSection>
    ),
    [placeName, onInputChange]
  );

  const memoizedPlaceAddressInput = useMemo(
    () => (
      <UpdateMoimInputSection>
        <UpdateMoimLabel>
          {t("updateMoimBasicInformation.placeAddress")}
        </UpdateMoimLabel>
        <AddressInput
          disabled={disabled}
          address={placeAddress}
          onChange={onAddressChange}
        />
        <UpdateMoimInput
          value={placeAddressDetail}
          disabled={disabled}
          placeholder={t("updateMoimBasicInformation.placeAddressPlaceholder2")}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "placeAddressDetail")
          }
        />
      </UpdateMoimInputSection>
    ),
    [placeAddress, placeAddressDetail, onAddressChange, onInputChange]
  );

  const memoizedPriceInput = useMemo(
    () => (
      <UpdateMoimInputSection style={{ marginBottom: theme.spacing.section }}>
        <UpdateMoimLabel>
          {t("updateMoimBasicInformation.price")}
        </UpdateMoimLabel>
        <UpdateMoimInput
          value={price}
          disabled={disabled}
          inputMode="numeric"
          placeholder={t("updateMoimBasicInformation.pricePlaceholder")}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "price")
          }
        />
      </UpdateMoimInputSection>
    ),
    [price, onInputChange]
  );

  const memoizedGenderRestrictionInput = useMemo(
    () => (
      <UpdateMoimInputSection>
        <UpdateMoimLabel>
          {t("updateMoimBasicInformation.genderRestriction")}
        </UpdateMoimLabel>
        <UpdateMoimSelectButtonsRow>
          <UpdateMoimSelectButton
            showBorderRight
            disabled={disabled}
            selected={genderRestriction === Gender.Male}
            onClick={() => onGenderRestrictionChange(Gender.Male)}
          >
            {t("profile.male")}
          </UpdateMoimSelectButton>
          <UpdateMoimSelectButton
            showBorderRight
            disabled={disabled}
            selected={genderRestriction === Gender.Female}
            onClick={() => onGenderRestrictionChange(Gender.Female)}
          >
            {t("profile.female")}
          </UpdateMoimSelectButton>
          <UpdateMoimSelectButton
            disabled={disabled}
            selected={genderRestriction === null}
            onClick={() => onGenderRestrictionChange(null)}
          >
            {t("common.noRestriction")}
          </UpdateMoimSelectButton>
        </UpdateMoimSelectButtonsRow>
      </UpdateMoimInputSection>
    ),
    [genderRestriction, onGenderRestrictionChange]
  );

  const memoizedAgeRestrictionInput = useMemo(
    () => (
      <UpdateMoimInputSection>
        <UpdateMoimLabel>
          {t("updateMoimBasicInformation.ageRestriction")}
        </UpdateMoimLabel>
        <UpdateMoimSelectButtonsRow>
          <UpdateMoimSelectButton
            showBorderRight
            disabled={disabled}
            selected={minAge === "20" && ageRestriction && !showAgeInput}
            onClick={() => onAgeRestrictionChange("20")}
          >
            {t("updateMoimBasicInformation.adultOnly")}
          </UpdateMoimSelectButton>
          <UpdateMoimSelectButton
            showBorderRight
            disabled={disabled}
            selected={showAgeInput}
            onClick={() => toggleShowAgeInput(true)}
          >
            {t("updateMoimBasicInformation.setAge")}
          </UpdateMoimSelectButton>
          <UpdateMoimSelectButton
            selected={!ageRestriction}
            disabled={disabled}
            onClick={() => onAgeRestrictionChange(null)}
          >
            {t("common.noRestriction")}
          </UpdateMoimSelectButton>
        </UpdateMoimSelectButtonsRow>
      </UpdateMoimInputSection>
    ),
    [showAgeInput, toggleShowAgeInput, onAgeRestrictionChange]
  );

  const memoizedAgeInput = useMemo(
    () => (
      <UpdateMoimInputSection>
        <Row style={{ gap: theme.spacing.small }}>
          <div style={{ flex: 1 }}>
            <UpdateMoimLabel>
              {t("updateMoimBasicInformation.minAge")}
            </UpdateMoimLabel>
            <UpdateMoimInput
              inputMode="numeric"
              placeholder={t("updateMoimBasicInformation.minAgePlaceholder")}
              value={minAge}
              disabled={disabled}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onInputChange(e, "minAge")
              }
            />
          </div>
          <div style={{ flex: 1 }}>
            <UpdateMoimLabel>
              {t("updateMoimBasicInformation.maxAge")}
            </UpdateMoimLabel>
            <UpdateMoimInput
              inputMode="numeric"
              placeholder={t("updateMoimBasicInformation.maxAgePlaceholder")}
              value={maxAge}
              disabled={disabled}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onInputChange(e, "maxAge")
              }
            />
          </div>
        </Row>
      </UpdateMoimInputSection>
    ),
    [minAge, maxAge, onInputChange]
  );

  const memoizedPersonsInput = useMemo(
    () => (
      <UpdateMoimInputSection style={{ marginBottom: theme.spacing.section }}>
        <Row style={{ gap: theme.spacing.small }}>
          <div style={{ flex: 1 }}>
            <UpdateMoimLabel>
              {t("updateMoimBasicInformation.minPersons")}
            </UpdateMoimLabel>
            <UpdateMoimInput
              inputMode="numeric"
              placeholder={t(
                "updateMoimBasicInformation.minPersonsPlaceholder"
              )}
              value={minPersons}
              disabled={disabled}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onInputChange(e, "minPersons")
              }
            />
          </div>
          <div style={{ flex: 1 }}>
            <UpdateMoimLabel>
              {t("updateMoimBasicInformation.maxPersons")}
            </UpdateMoimLabel>
            <UpdateMoimInput
              inputMode="numeric"
              placeholder={t(
                "updateMoimBasicInformation.maxPersonsPlaceholder"
              )}
              value={maxPersons}
              disabled={disabled}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onInputChange(e, "maxPersons")
              }
            />
          </div>
        </Row>
      </UpdateMoimInputSection>
    ),
    [minPersons, maxPersons, onInputChange]
  );

  if (loading) return <div />;

  return (
    <UpdateMoimWrapper>
      <UpdateMoimStep>STEP2</UpdateMoimStep>
      <UpdateMoimTitle>{t("updateMoimBasicInformation.title")}</UpdateMoimTitle>
      <UpdateMoimSubtitle>
        {t("updateMoimBasicInformation.subtitle")}
      </UpdateMoimSubtitle>
      <UpdateMoimSeparator />

      <UpdateMoimSectionTitle>
        {t("updateMoimBasicInformation.scheduleTitle")}
      </UpdateMoimSectionTitle>

      {memoizedMoimDateInput}
      {memoizedTimeInput}
      {memoizedIsPrivateInput}
      {memoizedPlaceNameInput}
      {memoizedPlaceAddressInput}
      {memoizedPriceInput}

      <UpdateMoimSectionTitle>
        {t("updateMoimBasicInformation.participateTitle")}
      </UpdateMoimSectionTitle>

      {memoizedGenderRestrictionInput}
      {memoizedAgeRestrictionInput}
      {showAgeInput && memoizedAgeInput}
      {memoizedPersonsInput}

      <SaveButton disabled={disabled} onClick={onSaveClick}>
        {updateLoading ? t("common.updating") : t("common.save")}
      </SaveButton>
    </UpdateMoimWrapper>
  );
}

export default UpdateMoimBasicInformationContainer;
