import { useApolloClient } from "@apollo/client";
import { onAuthStateChanged, User } from "firebase/auth";
import { ReactChildrenProp } from "global";
import React, { createContext, useContext, useEffect, useState } from "react";
import { firebaseAuth } from "../lib/firebase";
import SignInContainer from "../modules/auth/signIn/container/SignInContainer";
import SignUpContainer from "../modules/auth/signUp/container/SignUpContainer";
import Modal from "../modules/common/components/Modal";
import CreateProfileContainer from "../modules/profile/createProfile/container/CreateProfileContainer";
import {
  Gender,
  GetMyInitialProfileDocument,
  GetMyInitialProfileQuery,
  GetMyUserProfileDocument,
  useGetMyInitialProfileLazyQuery,
  UserProfile,
} from "../lib/apollo/graphql/generated";

interface AuthState {
  firebaseUser: User | null;
  userProfile?: {
    __typename?: "UserProfile";
    id: string;
    username: string;
    name?: string | null;
    email?: string | null;
    phone?: string | null;
    dateOfBirth?: any | null;
    gender?: Gender | null;
    reviewCount: number;
    isAdmin?: boolean | null;
    createdAt: any;
    updatedAt: any;
    nationality?: {
      __typename?: "Nationality";
      id: string;
      name: string;
    } | null;
    host?: { __typename?: "Host"; id: string } | null;
    avatar?: { __typename?: "Media"; id: string; uri: string } | null;
  } | null;
  showSignIn: boolean;
  showSignUp: boolean;
  showDefaultProfile: boolean;
}

interface AuthHandlers {
  toggleSignIn: (value: boolean) => void;
  toggleSignUp: (value: boolean) => void;
  toggleDefaultProfile: (value: boolean) => void;
}

const defaultState: AuthState = {
  firebaseUser: null,
  userProfile: null,
  showSignIn: false,
  showSignUp: false,
  showDefaultProfile: false,
};

const defaultValue: AuthState & AuthHandlers = {
  ...defaultState,
  toggleSignIn: (value: boolean) => {},
  toggleSignUp: (value: boolean) => {},
  toggleDefaultProfile: (value: boolean) => {},
};

const AuthContext = createContext(defaultValue);

function AuthProvider({ children }: ReactChildrenProp) {
  const client = useApolloClient();
  const [state, setState] = useState(defaultState);

  const [getMyInitialProfile, { data, loading }] =
    useGetMyInitialProfileLazyQuery({
      onCompleted: ({ getMyUserProfile }) => {
        if (!getMyUserProfile?.id) {
          setState((prev) => ({
            ...prev,
            showDefaultProfile: true,
          }));
        }
      },
    });

  useEffect(() => {
    onAuthStateChanged(firebaseAuth, async (firebaseUser) => {
      if (firebaseUser) {
        setState((prev) => ({
          ...prev,
          firebaseUser,
          showSignIn: false,
          showSignUp: false,
          showDefaultProfile: false,
        }));
        await getMyInitialProfile();
      } else {
        setState(defaultState);
      }
    });
  }, []);

  function toggleSignIn(value: boolean) {
    setState((prev) => ({
      ...prev,
      showSignIn: value,
    }));
  }

  function toggleSignUp(value: boolean) {
    setState((prev) => ({
      ...prev,
      showSignUp: value,
    }));
  }

  function toggleDefaultProfile(value: boolean) {
    setState((prev) => ({
      ...prev,
      showDefaultProfile: value,
    }));
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        userProfile: !!state?.firebaseUser ? data?.getMyUserProfile : null,
        toggleSignIn,
        toggleSignUp,
        toggleDefaultProfile,
      }}
    >
      {children}
      <Modal isOpen={state.showSignIn} closeModal={() => toggleSignIn(false)}>
        <SignInContainer />
      </Modal>
      <Modal isOpen={state.showSignUp} closeModal={() => toggleSignUp(false)}>
        <SignUpContainer />
      </Modal>
      <Modal
        isOpen={state.showDefaultProfile}
        modalProps={{
          isOpen: state.showDefaultProfile,
          shouldCloseOnEsc: false,
          shouldCloseOnOverlayClick: false,
        }}
      >
        <CreateProfileContainer />
      </Modal>
    </AuthContext.Provider>
  );
}

export default AuthProvider;

export function useAuthContext() {
  return useContext(AuthContext);
}
