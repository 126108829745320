import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetMoimQuery } from "../../../../lib/apollo/graphql/generated";

function useMoimContainer() {
  const params = useParams();
  const [stickyTop, setStickyTop] = useState<number | string>("33%");

  const carouselWrapperRef = useRef<HTMLDivElement>(null);

  const { loading, data } = useGetMoimQuery({
    variables: {
      moimId: params?.moimId!,
    },
  });

  useEffect(() => {
    if (
      !!carouselWrapperRef?.current?.getBoundingClientRect()?.top &&
      carouselWrapperRef?.current?.getBoundingClientRect()?.top !== 0
    ) {
      setTimeout(() => {
        setStickyTop(
          carouselWrapperRef?.current?.getBoundingClientRect()?.top!
        );
      }, 1);
    }

    return () => {
      setStickyTop("33%");
    };
  }, [
    data?.getMoim,
    carouselWrapperRef?.current?.getBoundingClientRect()?.top,
  ]);

  return {
    refs: {
      carouselWrapperRef,
    },
    models: {
      loading,
      data: data?.getMoim,
      stickyTop,
    },
  };
}

export default useMoimContainer;
