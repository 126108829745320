import React from "react";
import { useTranslation } from "react-i18next";
import styled, { ThemedComponentProps } from "styled-components";
import ManageMyMoimReviewCard from "./ManageMyMoimReviewCard";
import useManageMyMoimReviews from "./ManageMyMoimReviews.hook";
import { useAuthContext } from "../../../../providers/AuthProvider";

function ManageMyMoimReviews() {
  const { t } = useTranslation();

  const {
    refs: { fetchMoreRef },
    models: { loading, data },
  } = useManageMyMoimReviews();

  if (loading) return <div />;

  return (
    <Wrapper>
      <Title>{t("manageMyMoim.leftReviewsTitle")}</Title>
      <div>
        {data?.map((join) => (
          <ManageMyMoimReviewCard key={join?.id} moim={join?.moim!} />
        ))}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: `${theme.spacing.regular * 3}px ${theme.spacing.section * 2}px`,
  backgroundColor: theme.color.white,

  [theme.media.down["DESKTOP"]]: {
    padding: `${theme.spacing.section}px ${theme.spacing.regular}px`,
  },
}));

const Title = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.body1,
  fontWeight: "bold",
  marginBottom: theme.spacing.middle,
}));

export default ManageMyMoimReviews;
