import { toast } from "react-toastify";

export function copyText(text: string, message: string) {
  if (window.navigator) {
    window.navigator.clipboard.writeText(text).then(() => {
      toast(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    });
  }
}
