import { useGetContentsQuery } from "../../../lib/apollo/graphql/generated";
import { useContentListContext } from "../../../providers/ContentListProvider";

function useContentList() {
  const { fieldId, themeId, sortOption } = useContentListContext();

  const { loading, data } = useGetContentsQuery({
    variables: {
      fieldId,
      themeId,
      sort: sortOption,
      first: 8,
    },
  });

  return {
    models: {
      loading,
      data: data?.getContents?.edges,
    },
  };
}

export default useContentList;
