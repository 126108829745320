import React, { useEffect } from "react";

/**
 * custom hook to detect onClick event outside of target div
 * it is used to close dropdown menu by clicking outside when it's open
 */
function useDetectClickOutside(
  ref:
    | React.RefObject<HTMLDivElement | undefined | null>
    | React.MutableRefObject<HTMLDivElement | undefined | null>
    | null
    | undefined,
  dependency: any[],
  callback: () => void
) {
  const onClickHandler = (e: MouseEvent) => {
    // if click event was happened out of target div
    if (ref!.current && !ref?.current.contains(e.target as Node)) {
      // excute callback function
      callback();
    }
  };

  useEffect(() => {
    // add event listener to detect click event
    document.addEventListener("click", onClickHandler);

    return () => {
      document.removeEventListener("click", onClickHandler);
    };
  }, [ref, ...dependency]);
}

export default useDetectClickOutside;
