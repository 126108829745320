import { format } from "date-fns";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useTheme } from "styled-components";
import useUpdateHostProfileContainer from "./UpdateHostProfileContainer.hook";
import { Gender } from "../../../../lib/apollo/graphql/generated";
import { useScreenWidthContext } from "../../../../providers/ScreenWidthProvider";
import Avatar from "../../../common/components/Avatar";
import MediaUploader from "../../../common/components/MediaUploader";
import NationalityInput from "../../../common/components/NationalityInput";
import UpdateHostProfileBankAccountInput from "../components/UpdateHostProfileBankAccountInput";
import {
  GreyTextButton,
  SaveButton,
  VisibilityButton,
} from "../../../common/styles/commonStyles";
import {
  UpdateMoimInput,
  UpdateMoimInputSection,
  UpdateMoimLabel,
  UpdateMoimLabelRow,
  UpdateMoimSectionTitle,
  UpdateMoimSelectButton,
  UpdateMoimSelectButtonsRow,
  UpdateMoimSeparator,
  UpdateMoimStep,
  UpdateMoimSubtitle,
  UpdateMoimTextarea,
  UpdateMoimTitle,
  UpdateMoimWrapper,
} from "../../../moim/updateMoim/common/updateMoimCommonStyles";

const INTRODUCTION_LENGTH_LIMIT = 100;

function UpdateHostProfileContainer() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { pathname } = useLocation();
  const { isPc } = useScreenWidthContext();

  const {
    refs: {
      avatarInputRef,
      registrationMediaInputRef,
      bankAccountMediaInputRef,
    },
    models: { loading, data, state, updateLoading },
    operations: {
      onInputChange,
      onGenderChange,
      onNationalityChange,
      onIsBusinessChange,
      toggleViewIdNumber,
      onBankNameChange,
      onMediaChange,
      onAvatarDelete,
      onSaveClick,
    },
  } = useUpdateHostProfileContainer();

  const {
    username,
    name,
    phone,
    dateOfBirth,
    gender,
    nationality,
    introduction,
    idNumber,
    viewIdNumber,
    isBusiness,
    ownerName,
    businessName,
    registrationNumber,
    taxEmail,
    bankName,
    bankAccount,
    avatarState,
    registrationMediaState,
    bankAccountMediaState,
  } = state;

  const isHost = !!data?.host?.id;
  const isEditProfile = pathname.includes("/user/edit/");

  const memoizedAvatarInput = useMemo(
    () => (
      <UpdateMoimInputSection>
        <UpdateMoimLabel>{t("profile.avatar")}</UpdateMoimLabel>
        <Avatar
          inputRef={avatarInputRef}
          editable
          uri={
            avatarState ? URL.createObjectURL(avatarState) : data?.avatar?.uri
          }
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onMediaChange(e, "avatarState")
          }
          onDeleteClick={onAvatarDelete}
        />
      </UpdateMoimInputSection>
    ),
    [avatarState, data?.avatar?.uri, onMediaChange, onAvatarDelete]
  );

  const memoizedUsernameInput = useMemo(
    () => (
      <UpdateMoimInputSection>
        <UpdateMoimLabel>{t("profile.username")}</UpdateMoimLabel>
        <UpdateMoimInput
          disabled={!isEditProfile}
          defaultValue={username}
          placeholder={t("profile.usernamePlaceholder")}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "username")
          }
        />
      </UpdateMoimInputSection>
    ),
    [username]
  );

  const memoizedPhoneInput = useMemo(
    () => (
      <UpdateMoimInputSection>
        <UpdateMoimLabel>{t("profile.phone")}</UpdateMoimLabel>
        <UpdateMoimInput
          disabled={!isEditProfile}
          defaultValue={phone}
          placeholder={t("profile.phonePlaceholder")}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "phone")
          }
        />
        {!isEditProfile && (
          <UpdateMoimLabel>
            {t("updateHostProfile.unchangeableText")}
          </UpdateMoimLabel>
        )}
      </UpdateMoimInputSection>
    ),
    [phone]
  );

  const memoizedEmailInput = useMemo(
    () => (
      <UpdateMoimInputSection>
        <UpdateMoimLabel>{t("profile.email")}</UpdateMoimLabel>
        <UpdateMoimInput disabled defaultValue={data?.email} />
      </UpdateMoimInputSection>
    ),
    [data?.email]
  );

  const memoizedNameInput = useMemo(
    () => (
      <UpdateMoimInputSection>
        <UpdateMoimLabel>{t("profile.name")}</UpdateMoimLabel>
        <UpdateMoimInput
          value={name}
          placeholder={t("profile.namePlaceholder")}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "name")
          }
        />
      </UpdateMoimInputSection>
    ),
    [name, onInputChange]
  );

  const memoizedBirthInput = useMemo(
    () => (
      <UpdateMoimInputSection>
        <UpdateMoimLabel>{t("profile.dateOfBirth")}</UpdateMoimLabel>
        <UpdateMoimInput
          type="date"
          value={dateOfBirth}
          max={format(new Date(), "yyyy-MM-dd")}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "dateOfBirth")
          }
        />
      </UpdateMoimInputSection>
    ),
    [dateOfBirth, onInputChange]
  );

  const memoizedGenderInput = useMemo(
    () => (
      <UpdateMoimInputSection>
        <UpdateMoimLabel>{t("profile.gender")}</UpdateMoimLabel>
        <UpdateMoimSelectButtonsRow>
          <UpdateMoimSelectButton
            showBorderRight
            selected={gender === Gender.Male}
            onClick={() => onGenderChange(Gender.Male)}
          >
            {t("profile.male")}
          </UpdateMoimSelectButton>
          <UpdateMoimSelectButton
            selected={gender === Gender.Female}
            onClick={() => onGenderChange(Gender.Female)}
          >
            {t("profile.female")}
          </UpdateMoimSelectButton>
        </UpdateMoimSelectButtonsRow>
      </UpdateMoimInputSection>
    ),
    [gender, onGenderChange]
  );

  const memoizedNationalityInput = useMemo(
    () => (
      <UpdateMoimInputSection>
        <NationalityInput value={nationality} onChange={onNationalityChange} />
      </UpdateMoimInputSection>
    ),
    [nationality]
  );

  const memoizedIntroductionInput = useMemo(
    () => (
      <UpdateMoimInputSection style={{ marginBottom: theme.spacing.section }}>
        <UpdateMoimLabelRow>
          <UpdateMoimLabel style={{ marginBottom: 0 }}>
            {t("profile.introduction")}
          </UpdateMoimLabel>
          <UpdateMoimLabel style={{ marginBottom: 0, color: "#8c8c8c" }}>
            {introduction?.length}/{INTRODUCTION_LENGTH_LIMIT}
            {t("common.characters")}
          </UpdateMoimLabel>
        </UpdateMoimLabelRow>
        <UpdateMoimTextarea
          value={introduction}
          maxLength={INTRODUCTION_LENGTH_LIMIT}
          placeholder={t("profile.introductionPlaceholder")}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            onInputChange(e, "introduction")
          }
        />
      </UpdateMoimInputSection>
    ),
    [introduction, onInputChange]
  );

  const memoizedIsBusinessButtons = useMemo(
    () => (
      <UpdateMoimInputSection>
        <UpdateMoimLabel>
          {t("updateHostProfile.isBusinessTitle")}
        </UpdateMoimLabel>
        <UpdateMoimSelectButtonsRow>
          <UpdateMoimSelectButton
            disabled={!!data?.host?.business?.id}
            showBorderRight
            selected={isBusiness}
            onClick={() => onIsBusinessChange(true)}
          >
            {t("common.yes")}
          </UpdateMoimSelectButton>
          <UpdateMoimSelectButton
            disabled={!!data?.host?.business?.id}
            selected={!isBusiness}
            onClick={() => onIsBusinessChange(false)}
          >
            {t("common.no")}
          </UpdateMoimSelectButton>
        </UpdateMoimSelectButtonsRow>
      </UpdateMoimInputSection>
    ),
    [isBusiness, data?.host?.business?.id, onIsBusinessChange]
  );

  const memoizedIdNumberInput = useMemo(
    () => (
      <UpdateMoimInputSection>
        <UpdateMoimLabel>{t("updateHostProfile.idNumber")}</UpdateMoimLabel>
        <div style={{ position: "relative" }}>
          <UpdateMoimInput
            type={viewIdNumber ? "text" : "password"}
            value={idNumber}
            placeholder="000000-0000000"
            maxLength={14}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onInputChange(e, "idNumber")
            }
          />
          <VisibilityButton
            visible={viewIdNumber}
            onClick={toggleViewIdNumber}
          />
        </div>
      </UpdateMoimInputSection>
    ),
    [idNumber, viewIdNumber, toggleViewIdNumber, onInputChange]
  );

  const memoizedOwnerNameInput = useMemo(
    () => (
      <UpdateMoimInputSection>
        <UpdateMoimLabel>
          {t("updateHostProfile.businessOwnerName")}
        </UpdateMoimLabel>
        <UpdateMoimInput
          value={ownerName}
          placeholder={t("updateHostProfile.businessOwnerNamePlaceholder")}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "ownerName")
          }
        />
      </UpdateMoimInputSection>
    ),
    [ownerName, onInputChange]
  );

  const memoizedBusinessNameInput = useMemo(
    () => (
      <UpdateMoimInputSection>
        <UpdateMoimLabel>{t("updateHostProfile.businessName")}</UpdateMoimLabel>
        <UpdateMoimInput
          value={businessName}
          placeholder={t("updateHostProfile.businessNamePlaceholder")}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "businessName")
          }
        />
      </UpdateMoimInputSection>
    ),
    [businessName, onInputChange]
  );

  const memoizedBusinessRegistrationNumberInput = useMemo(
    () => (
      <UpdateMoimInputSection>
        <UpdateMoimLabel>
          {t("updateHostProfile.registrationNumber")}
        </UpdateMoimLabel>
        <UpdateMoimInput
          value={registrationNumber}
          placeholder="000-00-00000"
          maxLength={13}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "registrationNumber")
          }
        />
      </UpdateMoimInputSection>
    ),
    [registrationNumber, onInputChange]
  );

  const memoizedBusinessRegistrationMediaInput = useMemo(
    () => (
      <MediaUploader
        inputRef={registrationMediaInputRef}
        label={t("updateHostProfile.businessRegistration")}
        fileName={
          registrationMediaState?.name ||
          data?.host?.business?.registrationMedia?.name
        }
        inputProps={{
          onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
            onMediaChange(e, "registrationMediaState"),
        }}
      />
    ),
    [registrationMediaState, data?.host?.business?.registrationMedia]
  );

  const memoizedBusinessTaxEmailInput = useMemo(
    () => (
      <UpdateMoimInputSection style={{ marginBottom: theme.spacing.section }}>
        <UpdateMoimLabel>{t("updateHostProfile.taxEmail")}</UpdateMoimLabel>
        <UpdateMoimInput
          type="email"
          value={taxEmail}
          placeholder={t("profile.email")}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "taxEmail")
          }
        />
      </UpdateMoimInputSection>
    ),
    [taxEmail, onInputChange]
  );

  const memoizedAccountInput = useMemo(
    () => (
      <UpdateMoimInputSection>
        <UpdateHostProfileBankAccountInput
          bankName={bankName}
          bankAccount={bankAccount}
          onBankNameChange={onBankNameChange}
          onBankAccountChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "bankAccount")
          }
        />
      </UpdateMoimInputSection>
    ),
    [bankName, bankAccount, onBankNameChange, onInputChange]
  );

  const memoizedBankAccountMediaInput = useMemo(
    () => (
      <MediaUploader
        wrapperStyle={{ marginBottom: theme.spacing.section }}
        inputRef={bankAccountMediaInputRef}
        label={t("updateHostProfile.bankMedia")}
        fileName={
          bankAccountMediaState?.name || data?.host?.bankAccountMedia?.name
        }
        inputProps={{
          onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
            onMediaChange(e, "bankAccountMediaState"),
        }}
      />
    ),
    [bankAccountMediaState, data?.host?.bankAccountMedia?.name]
  );

  if (loading) return <div />;

  return (
    <UpdateMoimWrapper
      style={{
        padding: !isEditProfile
          ? isPc
            ? `${theme.spacing.section}px ${theme.spacing.regular * 3}px`
            : `${theme.spacing.section}px ${theme.spacing.regular}px`
          : isPc
          ? `${theme.spacing.regular * 3}px ${theme.spacing.section * 2}px`
          : `${theme.spacing.section}px ${theme.spacing.regular}px`,
      }}
    >
      {!isEditProfile && (
        <>
          <UpdateMoimStep>STEP1</UpdateMoimStep>
          <UpdateMoimTitle>{t("updateHostProfile.title")}</UpdateMoimTitle>
          <UpdateMoimSubtitle>
            {t("updateHostProfile.subtitle")}
          </UpdateMoimSubtitle>
          <UpdateMoimSeparator />
        </>
      )}

      {/* BASIC INFORMATION */}
      <UpdateMoimSectionTitle>
        {t("updateHostProfile.basicInfoSectionTitle")}
      </UpdateMoimSectionTitle>

      {memoizedAvatarInput}
      {memoizedUsernameInput}
      {memoizedPhoneInput}
      {memoizedEmailInput}
      {(!isEditProfile || (isEditProfile && isHost)) && memoizedNameInput}
      {memoizedBirthInput}
      {memoizedGenderInput}
      {memoizedNationalityInput}
      {(!isEditProfile || (isEditProfile && isHost)) &&
        memoizedIntroductionInput}

      {(!isEditProfile || (isEditProfile && isHost)) && (
        <>
          <UpdateMoimInputSection>
            <UpdateMoimSectionTitle style={{ marginBottom: 0 }}>
              {t("updateHostProfile.isBusinessTitle")}
            </UpdateMoimSectionTitle>
            <UpdateMoimLabel>
              {t("updateHostProfile.isBusinessSubtitle")}
            </UpdateMoimLabel>
          </UpdateMoimInputSection>

          {memoizedIsBusinessButtons}
          {isBusiness ? (
            <>
              <UpdateMoimSectionTitle>
                {t("updateHostProfile.businessInformationTitle")}
              </UpdateMoimSectionTitle>
              {memoizedOwnerNameInput}
              {memoizedBusinessNameInput}
              {memoizedBusinessRegistrationNumberInput}
              {memoizedBusinessRegistrationMediaInput}
              {memoizedBusinessTaxEmailInput}
            </>
          ) : (
            memoizedIdNumberInput
          )}

          <UpdateMoimInputSection>
            <UpdateMoimSectionTitle>
              {t("updateHostProfile.bankInformationTitle")}
            </UpdateMoimSectionTitle>
            <UpdateMoimLabel>
              <div>{t("updateHostProfile.accountNumber")}</div>
              <div>{t("updateHostProfile.accountNumberPersonalBusiness")}</div>
              <div>{t("updateHostProfile.accountNumberCooperation")}</div>
            </UpdateMoimLabel>
          </UpdateMoimInputSection>

          {memoizedAccountInput}
          {memoizedBankAccountMediaInput}
        </>
      )}

      {!(!isEditProfile || (isEditProfile && isHost)) && (
        <div style={{ height: theme.spacing.default }} />
      )}
      <SaveButton disabled={updateLoading} onClick={onSaveClick}>
        {t(updateLoading ? "common.updating" : "common.save")}
      </SaveButton>

      {isEditProfile && (
        <GreyTextButton style={{ marginTop: theme.spacing.section }}>
          {t("common.resign")}
        </GreyTextButton>
      )}
    </UpdateMoimWrapper>
  );
}

export default UpdateHostProfileContainer;
