export function getMoimcontentByLanguage(
  languageCode: string,
  moimContents?: any[] | null
) {
  if (!moimContents) {
    return null;
  }

  return moimContents?.find(
    (content) => content?.languageCode === languageCode
  );
}
