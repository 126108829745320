import { useEffect, useRef, useState } from "react";

function useContentsListContainer() {
  const contentsRowRef = useRef<HTMLDivElement>(null);
  const [minHeight, setMinHeight] = useState("100%");

  useEffect(() => {
    setMinHeight(`calc(100vh - ${contentsRowRef?.current?.offsetTop}px)`);
  }, [contentsRowRef?.current?.offsetTop]);

  return {
    refs: {
      contentsRowRef,
    },
    models: {
      minHeight,
    },
  };
}

export default useContentsListContainer;
