import React from "react";
import { ScrollRestoration } from "react-router-dom";
import styled, { ThemedComponentProps } from "styled-components";
import useContentsListContainer from "./ContentsListContainer.hook";
import { useScreenWidthContext } from "../../../providers/ScreenWidthProvider";
import ContentThumbnail from "../../common/components/ContentThumbnail";
import Layout from "../../common/components/Layout";
import { Grid, Row, SectionWrapper } from "../../common/styles/commonStyles";
import ContentList from "../components/ContentList";
import ContentListSortOptions from "../components/ContentListSortOptions";
import ContentListThemes from "../components/ContentListThemes";
import ContentsListFields from "../components/ContentsListFields";

function ContentsListContainer() {
  const { isPc } = useScreenWidthContext();

  const {
    refs: { contentsRowRef },
    models: { minHeight },
  } = useContentsListContainer();

  return (
    <Layout>
      <ScrollRestoration />
      <Wrapper>
        {/* <TitleEng>All Contents</TitleEng>
        <Title>전체글보기</Title> */}
        <ContentsListFields />
        {!isPc && <ContentListThemes />}
        {/* <ContentListSortOptions /> */}

        <ContentsRowWrapper
          ref={contentsRowRef}
          style={{
            minHeight,
          }}
        >
          {isPc && <ContentListThemes />}
          <ContentList />
        </ContentsRowWrapper>
      </Wrapper>
    </Layout>
  );
}

const Wrapper = styled(SectionWrapper)<any>(
  ({ theme }: ThemedComponentProps) => ({
    padding: `${theme.spacing.section}px ${theme.spacing.regular}px`,
  })
);

const TitleEng = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontFamily: "PortLligatSlab",
  fontSize: theme.font.en17,
  color: theme.color.green,
}));

const Title = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.head2,
  color: theme.color.black,
  marginBottom: theme.spacing.section,
  fontWeight: "bold",
}));

const ContentsRowWrapper = styled(Row)<any>(
  ({ theme }: ThemedComponentProps) => ({
    alignItems: "stretch",
    gap: theme.spacing.regular,
    justifyContent: "space-between",
    width: "100%",
  })
);

export default ContentsListContainer;
