import React from "react";
import { useTranslation } from "react-i18next";
import styled, { ThemedComponentProps } from "styled-components";
import LazyImage from "./LazyImage";
import { useScreenWidthContext } from "../../../providers/ScreenWidthProvider";
import { MoimStatusText } from "../../moim/common/styles/commonMoimStyles";
import { useGetMoimContent } from "../hook/useGetMoimContent";
import { Row } from "../styles/commonStyles";
import { getMoimcontentByLanguage } from "../utils/getMoimContentByLanguage";
import {
  Gender,
  JoinStatus,
  MoimStatus,
} from "../../../lib/apollo/graphql/generated";

interface MoimDetailTopRowProps {
  moimContents?: Array<{
    __typename?: "MoimContent";
    id: string;
    languageCode: string;
    title?: string | null;
    introduction?: string | null;
    detail?: string | null;
    price?: number | null;
    createdAt: any;
    updatedAt: any;
  } | null> | null;

  coverPhoto?: {
    __typename?: "Media";
    id: string;
    name: string;
    uri: string;
  } | null;

  moimStartAt?: any | null;
  moimEndAt?: any | null;

  status: MoimStatus;

  message?: string | null;
}

function MoimDetailTopRow({
  moimContents,
  coverPhoto,
  moimStartAt,
  moimEndAt,
  status,
  message,
}: MoimDetailTopRowProps) {
  const { i18n, t } = useTranslation();

  const { isPc } = useScreenWidthContext();

  const koreanContent = getMoimcontentByLanguage("ko", moimContents);
  const englishContent = getMoimcontentByLanguage("en", moimContents);

  const currentLanguage = i18n.resolvedLanguage;

  const currentLanguageContent =
    currentLanguage === "ko" ? koreanContent : englishContent;

  const { purePrice } = useGetMoimContent(
    currentLanguageContent,
    moimStartAt,
    moimEndAt
  );

  return (
    <>
      {isPc && <Spacer />}
      <TopRow>
        {!!coverPhoto?.uri && (
          <CoverPhoto>
            <LazyImage
              uri={coverPhoto?.uri}
              width="100%"
              height="100%"
              objectFit="cover"
            />
          </CoverPhoto>
        )}
        <div>
          <MoimStatusText status={status}>{t("moim." + status)}</MoimStatusText>
          <MoimTitle>{englishContent?.title}</MoimTitle>
          <MoimSubTitle>{koreanContent?.title}</MoimSubTitle>
          {/* <TopRowPrice>{purePrice}</TopRowPrice> */}
        </div>
      </TopRow>
      <Spacer />
    </>
  );
}

const Spacer = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  height: 1,
  backgroundColor: theme.color.border,
}));

const TopRow = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  padding: `${theme.spacing.middle}px 0px`,
  gap: theme.spacing.middle,
  alignItems: "center",
  position: "relative",

  [theme.media.down["DESKTOP"]]: {
    flexDirection: "column",
    paddingTop: 0,
    width: "100%",
    alignItems: "flex-start",
  },
}));

const CoverPhoto = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  height: 142,
  width: 216,

  [theme.media.down["DESKTOP"]]: {
    width: "100%",
    height: ((window.innerWidth - theme.spacing.regular * 2) * 220) / 335,
  },
}));

const MoimTitle = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.body2,
  fontWeight: "bold",
  textAlign: "left",

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.body2,
    marginTop: -theme.spacing.small,
  },
}));

const MoimSubTitle = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.body3,
  textAlign: "left",
  marginBottom: theme.spacing.default,

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.body3,
    marginBottom: theme.spacing.normal,
  },
}));

const TopRowPrice = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.body3,
  textAlign: "left",
  fontWeight: "bold",

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.body3,
  },
}));

export default MoimDetailTopRow;
