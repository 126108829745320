import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetFooterQuery } from "../../../lib/apollo/graphql/generated";

function useFooter() {
  const { i18n } = useTranslation();

  const [showInfo, setShowInfo] = useState(false);

  const { loading, data } = useGetFooterQuery({
    variables: {
      languageCode: i18n.resolvedLanguage,
    },
  });

  function toggleShowInfo() {
    setShowInfo(!showInfo);
  }

  return {
    models: {
      loading,
      data: data?.getFooter,
      showInfo,
    },
    operations: {
      toggleShowInfo,
    },
  };
}

export default useFooter;
