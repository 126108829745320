import React from "react";
import { useNavigate } from "react-router-dom";
import styled, { ThemedComponentProps } from "styled-components";
import useContentRecommendSection from "./ContentRecommendSection.hook";
import rabbit from "../../../assets/images/jump_rabbit.webp";
import { useScreenWidthContext } from "../../../providers/ScreenWidthProvider";
import { COMMON_ALT } from "../../../utilities/constants";
import Carousel from "../../common/components/Carousel";
import MainThemeThumbnail from "../../common/components/MainThemeThumbnail";
import { Grid, SectionWrapper } from "../../common/styles/commonStyles";
import { SCREENS } from "../../routes/utils/router";

function ContentRecommendSection() {
  const navigate = useNavigate();
  const { isPc } = useScreenWidthContext();

  const {
    models: { loading, data },
  } = useContentRecommendSection();

  return data && data?.length > 0 ? (
    <Wrapper>
      <TitleWrapper>
        <TitleEng>Recommend</TitleEng>
        <Title>다른 글도 읽어보세요!</Title>
      </TitleWrapper>

      {isPc ? (
        <>
          <ItemsWrapper>
            <Rabbit />
            {data?.map((content) => (
              <MainThemeThumbnail key={content?.id} content={content!} />
            ))}
          </ItemsWrapper>
        </>
      ) : (
        <CarouselWrapper>
          <Rabbit />
          <Carousel
            settings={{
              slidesToShow: 1,
              infinite: true,
              centerMode: true,
            }}
          >
            {data?.map((content) => (
              <MainThemeThumbnail key={content?.id} content={content!} />
            ))}
          </Carousel>
        </CarouselWrapper>
      )}
      <Button onClick={() => navigate(SCREENS.CONTENTS_LIST.path!)}>
        목록
      </Button>
    </Wrapper>
  ) : null;
}

const Wrapper = styled(SectionWrapper)<any>(
  ({ theme }: ThemedComponentProps) => ({
    backgroundColor: theme.color.white,
  })
);

const TitleWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  marginBottom: theme.spacing.middle,
  textAlign: "center",
}));

const TitleEng = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontFamily: "PortLligatSlab",
  fontSize: theme.font.en17,
  color: theme.color.green,
}));

const Title = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontFamily: "Pretendard",
  fontSize: theme.font.body2,
  color: theme.color.black,
}));

const ItemsWrapper = styled(Grid)<any>(({ theme }: ThemedComponentProps) => ({
  position: "relative",
  gridTemplateColumns: "repeat(3, 1fr)",
  gridTemplateRows: "auto",
  gap: theme.spacing.regular,
  width: "100%",
  alignItems: "stretch",
  marginBottom: theme.spacing.section * 2,
}));

const Rabbit = styled.img.attrs(() => ({
  src: rabbit,
  alt: COMMON_ALT,
}))<any>(({ theme }: ThemedComponentProps) => ({
  position: "absolute",
  bottom: "100%",
  right: "3%",
  height: 84,
  objectFit: "contain",
  zIndex: 2,
  transform: "translateY(40%)",

  [theme.media.down["TABLET"]]: {
    height: 69,
  },
}));

const CarouselWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  position: "relative",
  width: `calc(100% + ${theme.spacing.regular * 2}px)`,
  margin: `0px ${-theme.spacing.regular}px`,
  marginBottom: theme.spacing.regular * 3,

  [theme.media.down["TABLET"]]: {
    width: "100%",
    margin: 0,
    marginBottom: theme.spacing.section,
  },
}));

const Button = styled.button<any>(({ theme }: ThemedComponentProps) => ({
  backgroundColor: theme.color.green,
  width: 80,
  height: 80,
  borderRadius: 40,
  color: theme.color.white,
  textAlign: "center",
  fontSize: theme.font.body3,
  fontFamily: "Pretendard",
  fontWeight: "bold",
}));

export default ContentRecommendSection;
