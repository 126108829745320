import { differenceInHours } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled, { ThemedComponentProps, useTheme } from "styled-components";
import useParticipantsContainer from "./ParticipantsContainer.hook";
import { useScreenWidthContext } from "../../../../providers/ScreenWidthProvider";
import Layout from "../../../common/components/Layout";
import MoimDetailTopRow from "../../../common/components/MoimDetailTopRow";
import ParticipantCard from "../components/ParticipantCard";
import {
  DefaultButton,
  Grid,
  SectionWrapper,
} from "../../../common/styles/commonStyles";

function ParticipantsContainer() {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const { isPc } = useScreenWidthContext();

  const {
    models: { loading, data },
  } = useParticipantsContainer();

  if (loading) return <div />;

  const showPersonalInfo =
    differenceInHours(new Date(data?.moimStartAt), new Date()) < 24;

  return (
    <Layout>
      <Wrapper>
        <Title>{t("participants.title")}</Title>
        <MoimDetailTopRow
          moimContents={data?.moimContents}
          coverPhoto={data?.coverPhoto}
          moimStartAt={data?.moimStartAt}
          moimEndAt={data?.moimEndAt}
          status={data?.status!}
          message={data?.message}
        />
        <SectionTitle>{t("participants.participantsTitle")}</SectionTitle>
        <CardWrapper>
          {data?.participants?.map((participant, index) => (
            <ParticipantCard
              key={participant?.id}
              participant={participant!}
              index={index}
              showPersonalInfo={showPersonalInfo}
            />
          ))}
        </CardWrapper>
        <Spacer />
        <BackButton onClick={() => navigate("/host/" + data?.host?.id!)}>
          {t("common.back")}
        </BackButton>
      </Wrapper>
    </Layout>
  );
}

const Wrapper = styled(SectionWrapper)<any>(
  ({ theme }: ThemedComponentProps) => ({
    maxWidth: 800,
    padding: `${theme.spacing.section * 2}px 0px`,
    alignItems: "flex-start",

    [theme.media.down["DESKTOP"]]: {
      padding: `${theme.spacing.middle}px ${theme.spacing.regular}px`,
    },
  })
);

const Title = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.head2,
  fontWeight: "bold",
  marginBottom: theme.spacing.section,
  textAlign: "left",

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.body1,
  },
}));

const SectionTitle = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.body1,
  fontWeight: "bold",
  marginTop: theme.spacing.section,
  marginBottom: theme.spacing.middle,
  textAlign: "left",

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.body2,
    marginTop: theme.spacing.middle,
  },
}));

const Spacer = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  height: 1,
  backgroundColor: theme.color.border,
}));

const CardWrapper = styled(Grid)<any>(({ theme }: ThemedComponentProps) => ({
  alignItems: "stretch",
  gap: theme.spacing.default,
  gridAutoRows: "auto",
  gridTemplateColumns: "repeat(3, 1fr)",
  width: "100%",
  paddingBottom: theme.spacing.section,

  [theme.media.down["DESKTOP"]]: {
    gridTemplateColumns: "1fr",
    gap: theme.spacing.small,
    paddingBottom: theme.spacing.middle,
  },
}));

const BackButton = styled(DefaultButton)<any>(
  ({ theme }: ThemedComponentProps) => ({
    width: 296,
    padding: `${theme.spacing.normal}px 0px`,
    backgroundColor: theme.color.green,
    textAlign: "center",
    fontSize: theme.font.body3,
    fontWeight: "bold",
    color: theme.color.white,
    margin: "0px auto",
    marginTop: theme.spacing.section,

    [theme.media.down["DESKTOP"]]: {
      width: "100%",
    },
  })
);

export default ParticipantsContainer;
