import React from "react";
import { useNavigate } from "react-router-dom";
import styled, { ThemedComponentProps } from "styled-components";
import { ContentThumbnailProps } from "./ContentThumbnail";
import LazyImage from "./LazyImage";
import useResizeImageHeight from "../hook/useResizeImageHeight";
import { Col, DefaultButton, NewTag, Row } from "../styles/commonStyles";
import { showNewTag } from "../utils/showNewTag";

function MainThemeThumbnail({ content }: ContentThumbnailProps) {
  const navigate = useNavigate();

  const {
    refs: { wrapperRef },
    models: { height },
  } = useResizeImageHeight();

  return (
    <Wrapper ref={wrapperRef} onClick={() => navigate("/" + content?.id!)}>
      {showNewTag(content?.createdAt) && <NewTag alt={content?.title} />}

      <LazyImage
        uri={content?.thumbnail?.uri!}
        alt={content?.title}
        height={height}
        style={{
          borderTopLeftRadius: 24,
          borderTopRightRadius: 24,
        }}
      />
      <TitleWrapper>
        <Title>{content?.title || ""}</Title>
      </TitleWrapper>
    </Wrapper>
  );
}

const Wrapper = styled(DefaultButton)<any>(
  ({ theme }: ThemedComponentProps) => ({
    flex: 1,
    display: "flex",
    flexDirection: "column",
    border: `1px solid ${theme.color.border}`,
    borderRadius: 24,
    color: theme.color.black,

    "&:hover": {
      div: {
        backgroundColor: theme.color.green,
        color: theme.color.white,
      },
    },

    [theme.media.down["DESKTOP"]]: {
      margin: `0px ${theme.spacing.regular / 2}px`,
    },

    [theme.media.down["TABLET"]]: {
      height: "100%",
    },
  })
);

const TitleWrapper = styled(Col)<any>(({ theme }: ThemedComponentProps) => ({
  backgroundColor: theme.color.white,
  padding: `${theme.spacing.default}px 0px`,
  borderBottomLeftRadius: 24,
  borderBottomRightRadius: 24,
  alignItems: "center",
  justifyContent: "center",
  flex: 1,
}));

const Title = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  width: "80%",
  justifyContent: "center",
  fontSize: theme.font.body2,

  height: "100%",
  alignItems: "center",

  [theme.media.down["TABLET"]]: {
    width: "90%",
    fontSize: theme.font.body3,
  },
}));

export default MainThemeThumbnail;
