import { useApolloClient } from "@apollo/client";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import styled, { ThemedComponentProps } from "styled-components";
import useNavigationBar from "./NavigationBar.hook";
import burgerIcon from "../../../assets/icons/burger.svg";
import closeIcon from "../../../assets/icons/close_fill.svg";
import logoEng from "../../../assets/icons/logo_white_eng.svg";
import logo from "../../../assets/icons/logo_white.svg";
import { GetMyInitialProfileDocument } from "../../../lib/apollo/graphql/generated";
import { useAuthContext } from "../../../providers/AuthProvider";
import { useScreenWidthContext } from "../../../providers/ScreenWidthProvider";
import { COMMON_ALT } from "../../../utilities/constants";
import Dropdown from "../../common/components/Dropdown";
import { SCREENS } from "../utils/router";
import {
  DefaultButton,
  Row,
  TouchableOpacity,
} from "../../common/styles/commonStyles";

function NavigationBar() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const client = useApolloClient();

  const { isPc } = useScreenWidthContext();

  const { firebaseUser, userProfile, toggleSignIn, toggleSignUp } =
    useAuthContext();

  const {
    models: { showMobileMenu },
    operations: { toggleShowMobileMenu },
  } = useNavigationBar();

  const currentLanguage = i18n.resolvedLanguage;

  return (
    <Wrapper>
      <NavigationWrapper>
        <LeftButtons>
          <Link to={SCREENS.MAIN.path!}>
            <Logo src={i18n.resolvedLanguage === "ko" ? logo : logoEng} />
          </Link>
        </LeftButtons>
        <RightButtons>
          {isPc && (
            <Dropdown
              label={
                t("navigation.language") +
                " " +
                t("navigation." + i18n.resolvedLanguage)
              }
              options={[
                {
                  text: t("navigation.ko"),
                  onClick: () => i18n.changeLanguage("ko"),
                },
                {
                  text: t("navigation.en"),
                  onClick: () => i18n.changeLanguage("en"),
                },
              ]}
            />
          )}
          {isPc && (
            <AuthButtons>
              <TouchableOpacity
                onClick={() => {
                  if (currentLanguage === "ko") {
                    window.open(
                      "https://vivid-sunflower-b15.notion.site/0932addafe5f4381ab4a3c4a55f86bb1",
                      "_blank"
                    );
                  } else {
                    window.open(
                      "https://vivid-sunflower-b15.notion.site/FLAND-is-92592c52fdd449e89338d534b6946545",
                      "_blank"
                    );
                  }
                }}
              >
                {t("navigation.about")}
              </TouchableOpacity>
            </AuthButtons>
          )}
          {isPc &&
            (!userProfile ? (
              <AuthButtons>
                <TouchableOpacity onClick={() => toggleSignIn(true)}>
                  {t("common.signIn")}
                </TouchableOpacity>
                <div>|</div>
                <TouchableOpacity onClick={() => toggleSignUp(true)}>
                  {t("common.signUp")}
                </TouchableOpacity>
              </AuthButtons>
            ) : (
              <AuthButtons>
                <TouchableOpacity
                  onClick={async () => {
                    let userId = userProfile?.id;

                    if (!userProfile) {
                      const { data } = await client.query({
                        query: GetMyInitialProfileDocument,
                      });
                      userId = data?.getMyUserProfile?.id;
                    }

                    navigate(`/user/${userId}`);
                  }}
                >
                  {t("navigation.myPage")}
                </TouchableOpacity>
              </AuthButtons>
            ))}

          <MoimButton
            onClick={() => {
              if (userProfile) {
                navigate(SCREENS.UPDATE_MOIM_INTRODUCTION.path!);
              } else {
                toggleSignIn(true);
              }
            }}
          >
            {t("navigation.openMoim")}
          </MoimButton>

          {!isPc && (
            <MobileMenuToggleButton
              onClick={() => toggleShowMobileMenu(!showMobileMenu)}
            >
              <MobileMenuToggleButtonIcon
                src={showMobileMenu ? closeIcon : burgerIcon}
              />
            </MobileMenuToggleButton>
          )}
        </RightButtons>
      </NavigationWrapper>

      {/* MOBILE MENU */}
      {showMobileMenu && (
        <MobileMenuWrapper>
          <MobileMenuTop>
            <MobileMenu
              onClick={() => {
                if (currentLanguage === "ko") {
                  window.open(
                    "https://vivid-sunflower-b15.notion.site/0932addafe5f4381ab4a3c4a55f86bb1",
                    "_blank"
                  );
                } else {
                  window.open(
                    "https://vivid-sunflower-b15.notion.site/FLAND-is-92592c52fdd449e89338d534b6946545",
                    "_blank"
                  );
                }
              }}
            >
              {t("navigation.about")}
            </MobileMenu>
            <MobileMenu
              onClick={() => {
                navigate(SCREENS.MAIN.path!);
              }}
            >
              {t("navigation.findMoim")}
            </MobileMenu>
            <MobileMenu
              onClick={() => {
                if (userProfile) {
                  navigate(SCREENS.UPDATE_MOIM_INTRODUCTION.path!);
                } else {
                  toggleSignIn(true);
                }
              }}
            >
              {t("navigation.openMoim")}
            </MobileMenu>
            <MobileMenu
              onClick={() => {
                if (userProfile) {
                  navigate(`/user/${userProfile?.id}`);
                } else {
                  toggleSignIn(true);
                }
              }}
            >
              {!userProfile
                ? t("common.signIn") + "/" + t("common.signUp")
                : t("navigation.myPage")}
            </MobileMenu>
          </MobileMenuTop>
          <Dropdown
            label={
              t("navigation.language") +
              " " +
              t("navigation." + i18n.resolvedLanguage)
            }
            options={[
              {
                text: t("navigation.ko"),
                onClick: () => i18n.changeLanguage("ko"),
              },
              {
                text: t("navigation.en"),
                onClick: () => i18n.changeLanguage("en"),
              },
            ]}
          />
        </MobileMenuWrapper>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.header<any>(({ theme }: ThemedComponentProps) => ({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  height: theme.navigationHeight,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.color.white,
  paddingLeft: theme.spacing.regular,
  paddingRight: theme.spacing.regular,
  zIndex: 100,
  backgroundColor: theme.color.green,

  [theme.media.down["DESKTOP"]]: {
    height: theme.mobileNavigationHeight,
  },
}));

const NavigationWrapper = styled.nav<any>(
  ({ theme }: ThemedComponentProps) => ({
    maxWidth: theme.containerMaxWidth,
    height: "100%",
    width: "100%",
    padding: `0px ${theme.spacing.regular}px`,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    [theme.media.down["DESKTOP"]]: {
      padding: 0,
    },
  })
);

const Logo = styled.img.attrs(() => ({
  alt: COMMON_ALT,
}))<any>(({ theme }: ThemedComponentProps) => ({
  height: 40,
  verticalAlign: "middle",

  [theme.media.down["DESKTOP"]]: {
    height: 28,
  },
}));

const LinkButton = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: theme.spacing.normal,
}));

const LeftButtons = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  alignItems: "center",
  gap: theme.spacing.default,
  justifyContent: "flex-start",
}));

const RightButtons = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  alignItems: "center",
  justifyContent: "flex-end",

  [theme.media.down["DESKTOP"]]: {
    gap: theme.spacing.default,
  },
}));

const AuthButtons = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  alignItems: "center",
  padding: `${theme.spacing.small}px ${theme.spacing.normal}px`,
  fontSize: theme.font.body3,
  color: theme.color.white,
  gap: theme.spacing.xxSmall,
}));

const MoimButton = styled(DefaultButton)<any>(
  ({ theme }: ThemedComponentProps) => ({
    padding: `${theme.spacing.small}px ${theme.spacing.section}px`,
    backgroundColor: theme.color.white,
    borderRadius: 2,
    fontSize: theme.font.body3,
    fontWeight: "bold",
    color: theme.color.green,
    marginLeft: theme.spacing.small,

    ":hover": {
      backgroundColor: theme.color.subGreen,
    },

    [theme.media.down["DESKTOP"]]: {
      padding: `${theme.spacing.xxSmall2}px ${theme.spacing.default}px`,
      fontSize: theme.font.caption1,
    },
  })
);

const MobileMenuToggleButton = styled(TouchableOpacity)<any>(() => ({
  width: 28,
  height: 28,
}));

const MobileMenuToggleButtonIcon = styled.img<any>(() => ({
  width: 28,
  height: 28,
}));

const MobileMenuWrapper = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    position: "absolute",
    top: "100%",
    left: 0,
    right: 0,
    backgroundColor: theme.color.green,
    borderBottom: `1px solid ${theme.color.white}`,
  })
);

const MobileMenuTop = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: `${theme.spacing.regular}px 0px`,
  borderBottom: `1px solid ${theme.color.white}`,
}));

const MobileMenu = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  textAlign: "center",
  padding: `${theme.spacing.default}px 0px`,
  fontSize: theme.font.body3,
}));
export default NavigationBar;
