import { ReactChildrenProp } from "global";
import React, { useCallback, useRef, useState } from "react";
import styled, { ThemedComponentProps } from "styled-components";
import { Col } from "../styles/commonStyles";

function HorizontalScrollView({
  children,
}: {
  children: (dragging: boolean) => React.ReactNode;
}) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const currentScrollX = useRef<number>(0);
  const mousePosition = useRef<number>(0);

  const [isMouseDown, setIsMouseDown] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const onMouseDown = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    mousePosition.current = e.clientX;
    setIsMouseDown(true);
  }, []);

  const onMouseUp = useCallback(() => {
    window.requestAnimationFrame(() => {
      setIsDragging(false);
      setIsMouseDown(false);
    });
  }, []);

  function onMouseMove(e: React.MouseEvent) {
    if (wrapperRef?.current) {
      const diff = mousePosition.current - e.clientX;

      const movedEnough = Math.abs(diff) > 5;

      if (isMouseDown && movedEnough) {
        setIsDragging(true);
      }

      if (isDragging && movedEnough) {
        mousePosition.current = e.clientX;
        wrapperRef.current.scrollLeft += diff;
      }
    }
  }

  return (
    <Wrapper
      ref={wrapperRef}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseMove={onMouseMove}
    >
      {children(isDragging)}
    </Wrapper>
  );
}

const Wrapper = styled.ul<any>(({ theme }: ThemedComponentProps) => ({
  listStyle: "none",
  whiteSpace: "nowrap",
  overflowX: "auto",
  padding: `${theme.spacing.normal}px ${theme.spacing.middle}px`,
  margin: 0,

  msOverflowStyle: "none",
  scrollbarWidth: "none",

  "::-webkit-scrollbar": {
    display: "none",
  },

  [theme.media.down["TABLET"]]: {
    padding: `${theme.spacing.normal}px ${theme.spacing.default}px`,
  },
}));

export default HorizontalScrollView;
