import { useRef, useState } from "react";
import { firebaseSignUp } from "../../../../lib/firebase";
import { validateEmail } from "../../../common/utils/validateEmail";

function useSignUpContainer() {
  const emailInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const confirmPasswordInputRef = useRef<HTMLInputElement>(null);

  const [state, setState] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    agreed: true,
    emailError: "",
    passwordError: "",
    confirmPasswordError: "",
    showPolicy: false,
  });

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>, key: string) {
    const { value } = e.target;

    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function onFocus(key: string) {
    if (key === "email" && state.emailError) {
      setState((prev) => ({
        ...prev,
        emailError: "",
      }));
    }

    if (key === "password" && state.passwordError) {
      setState((prev) => ({
        ...prev,
        passwordError: "",
      }));
    }

    if (key === "confirmPassword" && state.confirmPasswordError) {
      setState((prev) => ({
        ...prev,
        confirmPasswordError: "",
      }));
    }
  }

  function onBlur(key: string) {
    if (key === "email" && !validateEmail(state.email)) {
      setState((prev) => ({
        ...prev,
        emailError: "error.emailError",
      }));
    }

    if (key === "password" && !(state.password.length > 5)) {
      setState((prev) => ({
        ...prev,
        passwordError: "error.passwordLengthError",
      }));
    }

    if (key === "confirmPassword") {
      if (!(state.confirmPassword.length > 5)) {
        setState((prev) => ({
          ...prev,
          confirmPasswordError: "error.passwordLengthError",
        }));
      } else if (state.password !== state.confirmPassword) {
        setState((prev) => ({
          ...prev,
          confirmPasswordError: "error.confirmPasswordNotMatchedError",
        }));
      }
    }
  }

  function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>, key: string) {
    if (e.key === "Enter") {
      if (key === "email") {
        passwordInputRef?.current?.focus();
      } else if (key === "password") {
        confirmPasswordInputRef?.current?.focus();
      } else {
        if (!isSubmitDisabled()) {
          onSignUpClick();
        }
      }
    }
  }

  function toggleAgreement() {
    setState((prev) => ({
      ...prev,
      agreed: !state.agreed,
    }));
  }

  function togglePolicy(value: boolean) {
    setState((prev) => ({
      ...prev,
      showPolicy: value,
    }));
  }

  function isSubmitDisabled() {
    const isValidEmail = validateEmail(state.email) && !state.emailError;
    const isValidPassword = state.password.length > 5 && !state.passwordError;
    const isValidConfirmPassword =
      state.confirmPassword.length > 5 &&
      state.password === state.confirmPassword &&
      !state.passwordError;

    const isAggreed = state.agreed;

    return !(
      isValidEmail &&
      isValidPassword &&
      isValidConfirmPassword &&
      isAggreed
    );
  }

  async function onSignUpClick() {
    try {
      await firebaseSignUp(state.email, state.password);
    } catch (error: any) {
      setState((prev) => ({
        ...prev,
        emailError: "authError." + error.code,
      }));
    }
  }

  return {
    refs: {
      emailInputRef,
      passwordInputRef,
      confirmPasswordInputRef,
    },
    models: {
      state,
    },
    operations: {
      onInputChange,
      onFocus,
      onBlur,
      onKeyDown,
      toggleAgreement,
      isSubmitDisabled,
      onSignUpClick,
      togglePolicy,
    },
  };
}

export default useSignUpContainer;
