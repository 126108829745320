import React, { CSSProperties, RefObject } from "react";
import { useTranslation } from "react-i18next";
import styled, { ThemedComponentProps } from "styled-components";
import uploadIcon from "../../../assets/icons/upload.svg";
import { Row } from "../styles/commonStyles";

interface MediaUploaderProps {
  label: string;
  disabled?: boolean;
  wrapperStyle?: CSSProperties;
  labelStyle?: CSSProperties;
  inputRef?: RefObject<HTMLInputElement>;
  fileName?: string;
  inputProps?: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
}

function MediaUploader({
  wrapperStyle,
  label,
  labelStyle,
  inputRef,
  fileName,
  inputProps,
  disabled = false,
}: MediaUploaderProps) {
  const { t } = useTranslation();
  return (
    <Wrapper style={wrapperStyle ? wrapperStyle : {}}>
      <Label style={labelStyle ? labelStyle : {}}>{label}</Label>
      <InputButton
        disabled={disabled}
        onClick={() => inputRef?.current?.click()}
      >
        <UploadIcon />
        <div>{fileName || t("common.uploadImage")}</div>
        <input
          ref={inputRef}
          type="file"
          accept="image/*, .pdf"
          {...inputProps}
        />
      </InputButton>
      <Label style={{ marginBottom: 0 }}>
        {t("common.uploadImageWarning")}
      </Label>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  marginBottom: theme.spacing.middle,
  color: theme.color.black,
}));

const Label = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.caption1,
  marginBottom: theme.spacing.small,
}));

interface InputButtonProps extends ThemedComponentProps {
  disabled?: boolean;
}

const InputButton = styled(Row)<any>(
  ({ theme, disabled }: InputButtonProps) => ({
    width: "100%",
    padding: `${theme.spacing.normal}px ${theme.spacing.default}px`,
    border: `1px solid #d0d0d0`,
    backgroundColor: theme.color.white,
    cursor: "pointer",
    fontSize: theme.font.body3,
    alignItems: "center",
    marginBottom: theme.spacing.small,

    opacity: disabled ? 0.7 : 1,
    pointerEvents: disabled ? "none" : "auto",
  })
);

const UploadIcon = styled.img.attrs(() => ({
  src: uploadIcon,
}))<any>(({ theme }: ThemedComponentProps) => ({
  width: 20,
  height: 20,
  marginRight: theme.spacing.xxSmall2,
}));

export default MediaUploader;
