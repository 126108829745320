import { useTranslation } from "react-i18next";
import { useGetCreateMoimFaqsQuery } from "../../../../../lib/apollo/graphql/generated";

function useUpdateMoimIntroContainer() {
  const { i18n } = useTranslation();
  const { loading, data } = useGetCreateMoimFaqsQuery({
    variables: {
      languageCode: i18n.resolvedLanguage === "ko" ? "ko" : "en",
    },
  });

  return {
    models: {
      loading,
      data: data?.getCreateMoimFaqs,
    },
  };
}

export default useUpdateMoimIntroContainer;
