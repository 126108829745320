import { initializeApp } from "firebase/app";
import {
  EmailAuthProvider,
  createUserWithEmailAndPassword,
  getAuth,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth();

export async function firebaseSignUp(email: string, password: string) {
  await createUserWithEmailAndPassword(firebaseAuth, email, password);
}

export async function firebaseSignIn(email: string, password: string) {
  await signInWithEmailAndPassword(firebaseAuth, email, password);
}

export async function firebaseSignOut() {
  try {
    await signOut(firebaseAuth);
  } catch (error) {
    console.log(error);
  }
}

export async function sendFirebasePasswordResetEmail(email: string) {
  await sendPasswordResetEmail(firebaseAuth, email);
}

export async function updateFirebasePassword(password: string) {
  if (firebaseAuth.currentUser) {
    await updatePassword(firebaseAuth.currentUser, password);
  }
}

export async function firebaseReAuthenticate(currentPassword: string) {
  const { currentUser } = firebaseAuth;
  if (currentUser) {
    const credential = EmailAuthProvider.credential(
      currentUser?.email!,
      currentPassword
    );
    await reauthenticateWithCredential(currentUser, credential);
  }
}

/**
 * It first checks if token is expired.
 * If expired, it return refreshed access token.
 * Otherwise, returns token from cache.
 */
export async function getFirebaseIdToken() {
  return await firebaseAuth.currentUser?.getIdToken();
}

export const FIREBASE_AUTH_ERROR_CODES = {
  "auth/user-not-found": "auth/user-not-found",
  "auth/invalid-password": "auth/invalid-password",
};
