import React from "react";
import styled, { ThemedComponentProps } from "styled-components";
import useContentsListFields from "./ContentsListFields.hook";
import { useContentListContext } from "../../../providers/ContentListProvider";
import HorizontalScrollView from "../../common/components/HozontalScrollView";
import { DefaultButton, Row } from "../../common/styles/commonStyles";

function ContentsListFields() {
  const { fieldId, onFieldChange } = useContentListContext();

  const {
    models: { loading, data },
  } = useContentsListFields();

  return (
    <Wrapper>
      <HorizontalScrollView>
        {(dragging) => (
          <>
            <FieldButton
              isActive={!fieldId}
              onClick={() => !dragging && onFieldChange(null)}
            >
              전체 공간
            </FieldButton>
            {data?.map((field) => (
              <FieldButton
                key={field?.id}
                isActive={fieldId === field?.id}
                onClick={() => !dragging && onFieldChange(field?.id!)}
              >
                {field?.name}
              </FieldButton>
            ))}
          </>
        )}
      </HorizontalScrollView>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  borderTop: `1px solid ${theme.color.green}`,
  borderBottom: `1px solid ${theme.color.green}`,
  marginBottom: theme.spacing.section,
}));

interface FieldButtonProps extends ThemedComponentProps {
  isActive?: boolean;
}

const FieldButton = styled.li<any>(
  ({ theme, isActive = false }: FieldButtonProps) => ({
    cursor: "pointer",
    display: "inline-block",
    padding: `${theme.spacing.small}px ${theme.spacing.default}px`,
    width: "fit-content",
    borderRadius: 58,
    fontSize: theme.font.body3,
    color: isActive ? theme.color.white : theme.color.green,
    border: `1px solid ${theme.color.green}`,
    lineHeight: 1,
    backgroundColor: isActive ? theme.color.green : "transparent",
    textAlign: "center",
    margin: `0px ${theme.spacing.small}px`,

    [theme.media.down["TABLET"]]: {
      margin: `0px ${theme.spacing.xxSmall2}px`,
    },
  })
);

export default ContentsListFields;
