import styled, { ThemedComponentProps } from "styled-components";
import { SectionWrapper } from "../../common/styles/commonStyles";

export const MainWrapper = styled(SectionWrapper)<any>(
  ({ theme }: ThemedComponentProps) => ({
    padding: `${theme.spacing.regular * 3}px 0px`,

    [theme.media.down["DESKTOP"]]: {
      padding: 0,
    },
  })
);

export const MainSpacer = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    width: "100%",
    height: theme.spacing.xxSmall,
    backgroundColor: theme.color.green,
  })
);
