import React from "react";
import { Helmet } from "react-helmet-async";
import useMoimContainer from "./MoimContainer.hook";
import { useScreenWidthContext } from "../../../../providers/ScreenWidthProvider";
import AsideContainer from "../../../common/components/AsideContainer";
import Layout from "../../../common/components/Layout";
import { getMoimcontentByLanguage } from "../../../common/utils/getMoimContentByLanguage";
import MoimContentSection from "../components/MoimContentSection";
import MoimStickyJoin from "../components/MoimStickyJoin";
import MoimStickySection from "../components/MoimStickySection";

function MoimContainer() {
  const { isPc } = useScreenWidthContext();

  const {
    refs: { carouselWrapperRef },
    models: { loading, data, stickyTop },
  } = useMoimContainer();

  if (loading || !data) return <div />;

  const koreanContent = getMoimcontentByLanguage("ko", data?.moimContents);

  const englishContent = getMoimcontentByLanguage("en", data?.moimContents);

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{koreanContent?.title + " | " + englishContent?.title}</title>
        <meta
          name="description"
          content={koreanContent?.title + " | " + englishContent?.title}
        />
        <meta
          name="og:description"
          content={koreanContent?.title + " | " + englishContent?.title}
        />
        <meta
          property="og:title"
          content={koreanContent?.title + " | " + englishContent?.title}
        />

        {data?.coverPhoto?.uri && (
          <meta property="og:image" content={data?.coverPhoto?.uri} />
        )}
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>
      {isPc ? (
        <Layout>
          <AsideContainer
            stickyTop={stickyTop}
            left={
              <MoimContentSection
                carouselWrapperRef={carouselWrapperRef}
                moim={data}
              />
            }
            right={<MoimStickySection moim={data} />}
          />
        </Layout>
      ) : (
        <Layout>
          <MoimContentSection moim={data} />
          <MoimStickyJoin moim={data} />
        </Layout>
      )}
    </>
  );
}

export default MoimContainer;
