import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled, { ThemedComponentProps } from "styled-components";
import useUpdateMoimNavigationBar from "./UpdateMoimNavigationBar.hook";
import UpdateMoimRequestModal from "./UpdateMoimRequestModal";
import viewIcon from "../../../../../assets/icons/eye.svg";
import logoEng from "../../../../../assets/icons/logo_white_eng.svg";
import logo from "../../../../../assets/icons/logo_white.svg";
import { useScreenWidthContext } from "../../../../../providers/ScreenWidthProvider";
import { COMMON_ALT } from "../../../../../utilities/constants";
import Dropdown from "../../../../common/components/Dropdown";
import Modal from "../../../../common/components/Modal";
import { DefaultButton, Row } from "../../../../common/styles/commonStyles";
import { SCREENS } from "../../../../routes/utils/router";
import {
  Gender,
  MoimStatus,
} from "../../../../../lib/apollo/graphql/generated";

interface UpdateMoimNavigationBarProps {
  moim?: {
    __typename?: "Moim";
    id: string;
    moimStartAt?: any | null;
    moimEndAt?: any | null;
    maxPersons?: number | null;
    minPersons?: number | null;
    genderRestriction?: Gender | null;
    minAge?: number | null;
    maxAge?: number | null;
    status: MoimStatus;
    message?: string | null;
    viewCount: number;
    createdAt: any;
    updatedAt: any;
    host: {
      __typename?: "Host";
      id: string;
      introduction?: string | null;
      user: {
        __typename?: "UserProfile";
        id: string;
        username: string;
        name?: string | null;
        email?: string | null;
        phone?: string | null;
        dateOfBirth?: any | null;
        gender?: Gender | null;
        createdAt: any;
        updatedAt: any;
        nationality?: {
          __typename?: "Nationality";
          id: string;
          name: string;
        } | null;
        avatar?: { __typename?: "Media"; id: string; uri: string } | null;
      };
    };
    place?: {
      __typename?: "Place";
      id: string;
      name?: string | null;
      address?: string | null;
      addressDetail?: string | null;
      isPrivate: boolean;
    } | null;
    moimContents?: Array<{
      __typename?: "MoimContent";
      id: string;
      languageCode: string;
      title?: string | null;
      introduction?: string | null;
      detail?: string | null;
      price?: number | null;
      createdAt: any;
      updatedAt: any;
    } | null> | null;
    coverPhoto?: {
      __typename?: "Media";
      id: string;
      name: string;
      uri: string;
    } | null;
    media: Array<{
      __typename?: "Media";
      id: string;
      name: string;
      uri: string;
    } | null>;
  };
  loading?: boolean;
  isNew?: boolean;

  isRequestDisabled?: boolean;
}

function UpdateMoimNavigationBar({
  moim,
  loading,
  isNew,
  isRequestDisabled,
}: UpdateMoimNavigationBarProps) {
  const { t, i18n } = useTranslation();
  const { isPc } = useScreenWidthContext();

  const {
    models: { showModal },
    operations: { onSubmit, toggleShowModal },
  } = useUpdateMoimNavigationBar({
    isNew,
    moimId: moim?.id,
    status: moim?.status,
  });

  return (
    <>
      <Wrapper>
        <NavigationWrapper>
          <Link to={SCREENS.MAIN.path!}>
            <Logo src={i18n.resolvedLanguage === "ko" ? logo : logoEng} />
          </Link>

          <RightButtons>
            {isPc && (
              <Dropdown
                label={
                  t("navigation.language") +
                  " " +
                  t("navigation." + i18n.resolvedLanguage)
                }
                options={[
                  {
                    text: t("navigation.ko"),
                    onClick: () => i18n.changeLanguage("ko"),
                  },
                  {
                    text: t("navigation.en"),
                    onClick: () => i18n.changeLanguage("en"),
                  },
                ]}
              />
            )}

            {/* <ViewButton>
            <ViewIcon />
            <div>{t("updateMoimNavigation.view")}</div>
          </ViewButton> */}
            <SubmitButton
              disabled={loading || isRequestDisabled}
              onClick={() => toggleShowModal(true)}
            >
              {moim?.status === MoimStatus.Reviewing
                ? t("updateMoimNavigation.underReview")
                : t("updateMoimNavigation.requestMoim")}
            </SubmitButton>
          </RightButtons>
        </NavigationWrapper>
      </Wrapper>
      {showModal && (
        <Modal isOpen={showModal} closeModal={() => toggleShowModal(false)}>
          <UpdateMoimRequestModal
            status={moim?.status}
            onCancel={() => toggleShowModal(false)}
            onSubmit={onSubmit}
          />
        </Modal>
      )}
    </>
  );
}

const Wrapper = styled.header<any>(({ theme }: ThemedComponentProps) => ({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  height: theme.navigationHeight,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.color.white,
  paddingLeft: theme.spacing.regular,
  paddingRight: theme.spacing.regular,
  zIndex: 100,
  backgroundColor: theme.color.green,

  [theme.media.down["DESKTOP"]]: {
    height: theme.mobileNavigationHeight,
  },
}));

const NavigationWrapper = styled.nav<any>(
  ({ theme }: ThemedComponentProps) => ({
    maxWidth: theme.containerMaxWidth,

    height: "100%",
    width: "100%",
    padding: `0px ${theme.spacing.regular}px`,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    [theme.media.down["DESKTOP"]]: {
      padding: 0,
    },
  })
);

const Logo = styled.img.attrs(() => ({
  alt: COMMON_ALT,
}))<any>(({ theme }: ThemedComponentProps) => ({
  height: 40,
  objectFit: "contain",
  verticalAlign: "middle",

  [theme.media.down["DESKTOP"]]: {
    height: 28,
  },
}));

const RightButtons = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  alignItems: "center",
  gap: theme.spacing.default,
  justifyContent: "flex-end",
}));

const ViewButton = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  cursor: "pointer",
  alignItems: "center",
  gap: theme.spacing.small,
  padding: `${theme.spacing.small}px ${theme.spacing.middle}px`,
  border: `1px solid ${theme.color.white}`,
  backgroundColor: "transparent",
  color: theme.color.white,
  fontSize: theme.font.body3,
}));

const ViewIcon = styled.img.attrs(() => ({
  src: viewIcon,
}))<any>(() => ({
  width: 16,
  height: 16,
}));

interface SubmitButtonProps extends ThemedComponentProps {
  disabled?: boolean;
}

const SubmitButton = styled(DefaultButton)<any>(
  ({ theme, disabled }: SubmitButtonProps) => ({
    padding: `${theme.spacing.small}px ${theme.spacing.middle}px`,
    backgroundColor: disabled ? theme.color.border : theme.color.white,
    borderRadius: 2,
    fontSize: theme.font.body3,
    fontWeight: "bold",
    color: disabled ? "#808080" : theme.color.green,
    pointerEvents: disabled ? "none" : "auto",

    ":hover": {
      backgroundColor: disabled ? theme.color.border : theme.color.subGreen,
    },

    [theme.media.down["DESKTOP"]]: {
      padding: `${theme.spacing.xxSmall2}px ${theme.spacing.default}px`,
      fontSize: theme.font.caption1,
    },
  })
);

export default UpdateMoimNavigationBar;
