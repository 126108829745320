import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled, { ThemedComponentProps, useTheme } from "styled-components";
import arrowIcon from "../../../assets/icons/chevron_right_black.svg";
import { MoimStatus } from "../../../lib/apollo/graphql/generated";
import { useScreenWidthContext } from "../../../providers/ScreenWidthProvider";
import LazyImage from "../../common/components/LazyImage";
import { Col, DefaultButton, Row } from "../../common/styles/commonStyles";
import { getMoimcontentByLanguage } from "../../common/utils/getMoimContentByLanguage";

export interface MainTopThumbnailProps {
  moim: {
    __typename?: "Moim";
    id: string;
    createdAt: any;
    updatedAt: any;
    status: MoimStatus;
    moimContents?: Array<{
      __typename?: "MoimContent";
      languageCode: string;
      title?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null> | null;
    coverPhoto?: { __typename?: "Media"; id: string; uri: string } | null;
  };
}

function MainTopThumbnail({ moim }: MainTopThumbnailProps) {
  const theme = useTheme();
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const { isPc, screenWidth } = useScreenWidthContext();
  const { moimContents, coverPhoto } = moim;

  const currentLanguage = i18n.resolvedLanguage;
  const otherLanguage = i18n.resolvedLanguage.includes("ko") ? "en" : "ko";
  const currentLanguageContent = getMoimcontentByLanguage(
    currentLanguage,
    moimContents
  );

  const otherLanguageContent = getMoimcontentByLanguage(
    otherLanguage,
    moimContents
  );

  return coverPhoto?.uri ? (
    isPc ? (
      <Wrapper
        style={{
          height:
            screenWidth > theme.containerMaxWidth
              ? (theme.containerMaxWidth * 518 * 0.7) / 960
              : (screenWidth * 518 * 0.7) / 960,
        }}
      >
        <CoverPhotoBackground src={coverPhoto?.uri} />
        <CoverPhotoLayer />
        <ContentThumbnail>
          <LazyImage
            uri={coverPhoto?.uri}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
          <BottomRow>
            <Title>
              <div>{otherLanguageContent?.title}</div>
              <div>{currentLanguageContent?.title}</div>
            </Title>
            <DetailButton onClick={() => navigate("/moim/" + moim?.id)}>
              <DetailButtonRow>
                <DetailButtonTextWrapper>
                  <DetailButtonStrongText>
                    {currentLanguage.includes("ko") ? "자세히" : "Detail"}
                  </DetailButtonStrongText>
                  <div>
                    {currentLanguage.includes("ko") ? "Detail" : "자세히"}
                  </div>
                </DetailButtonTextWrapper>

                <Arrow />
              </DetailButtonRow>
            </DetailButton>
          </BottomRow>
        </ContentThumbnail>
      </Wrapper>
    ) : (
      <MobileWrapper>
        <LazyImage
          uri={coverPhoto?.uri}
          style={{
            width: "100%",
            height: (window.innerWidth * 281) / 375,
          }}
        />
        <Title>
          <div>{currentLanguageContent?.title}</div>
          <div>{otherLanguageContent?.title}</div>
        </Title>
        <div style={{ padding: `0px ${theme.spacing.regular}px` }}>
          <DetailButton onClick={() => navigate("/moim/" + moim?.id)}>
            <DetailButtonRow>
              <DetailButtonTextWrapper>
                <DetailButtonStrongText>
                  {currentLanguage.includes("ko") ? "자세히" : "Detail"}
                </DetailButtonStrongText>
                <div>
                  {currentLanguage.includes("ko") ? "Detail" : "자세히"}
                </div>
              </DetailButtonTextWrapper>

              <Arrow />
            </DetailButtonRow>
          </DetailButton>
        </div>
      </MobileWrapper>
    )
  ) : null;
}

const Wrapper = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  position: "relative",
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
}));

const CoverPhotoBackground = styled.img<any>(() => ({
  width: "100%",
  height: "80%",
  objectFit: "cover",
}));

const CoverPhotoLayer = styled.div<any>(() => ({
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  left: 0,
  right: 0,
  height: "80.5%",
  backgroundColor: "rgba(35,35,35,0.7)",
  zIndex: 1,
}));

const ContentThumbnail = styled.div<any>(() => ({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: "15%",
  right: "15%",
  width: "70%",
  zIndex: 2,
}));

const BottomRow = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  position: "absolute",
  bottom: 0,
  left: 0,
  right: 0,
  padding: theme.spacing.section,
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
}));

const Title = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  fontSize: theme.font.body1,
  color: theme.color.white,
  textShadow: `${theme.color.black} 1px 0 1px`,
  textAlign: "left",

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.body2,
    padding: `${theme.spacing.default}px ${theme.spacing.regular}px`,
    minHeight: 152,
  },
}));

const DetailButton = styled(DefaultButton)<any>(
  ({ theme }: ThemedComponentProps) => ({
    padding: `${theme.spacing.small}px ${theme.spacing.regular}px`,
    backgroundColor: theme.color.orange,
    border: `1px solid ${theme.color.black}`,
    color: theme.color.black,
    width: 143,

    [theme.media.down["DESKTOP"]]: {
      width: "100%",
    },
  })
);

const DetailButtonRow = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  alignItems: "center",
  justifyContent: "space-between",
  fontSize: theme.font.caption1,
}));

const DetailButtonTextWrapper = styled(Col)<any>(
  ({ theme }: ThemedComponentProps) => ({
    alignItems: "flex-start",
    flex: 1,

    [theme.media.down["DESKTOP"]]: {
      flexDirection: "row",
      alignItems: "center",
      gap: theme.spacing.small,
    },
  })
);

const DetailButtonStrongText = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    fontSize: theme.font.body3,
    fontWeight: "bold",
  })
);

const Arrow = styled.img.attrs(() => ({
  src: arrowIcon,
}))<any>(() => ({
  width: 15,
  height: 15,
}));

const MobileWrapper = styled.div<any>(() => ({
  width: "100%",
}));

export default MainTopThumbnail;
