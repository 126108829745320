import { createGlobalStyle, DefaultTheme, Font } from "styled-components";
import { getMediaQueryStrings } from "./mediaQueries";

export const theme: DefaultTheme = {
  navigationHeight: 70,
  mobileNavigationHeight: 44,
  containerMaxWidth: 1440,
  contentMaxWidth: 1280,
  color: {
    error: "#F6421C",
    beige: "#fdeace",
    orange: "#ff9a36",
    green: "#005346",
    subGreen: "#c8edbb",
    background: "#f8f5f2",
    black: "#232323",
    border: "#bfbfbf",
    white: "#ffffff",
  },
  font: {
    head1: 40,
    head2: 32,
    head3: 28,
    body1: 24,
    body2: 20,
    body3: 16,
    en17: 17,
    caption1: 14,
    caption2: 12,
  },
  spacing: {
    xxSmall: 2,
    xxSmall2: 4,
    small: 8,
    normal: 12,
    default: 16,
    regular: 20,
    middle: 24,
    large: 32,
    section: 40,
  },

  ...getMediaQueryStrings(),
};

export const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  body {
    font-family: Pretendard;
    line-height: 1.5;
    word-break: keep-all;
    color: #232323;
  }

  body * {
    white-space: pre-line;
  }

  button {
    cursor: pointer;
    outline: none;
    border: none;
    font-size: inherit;
  }

  button:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  input {
    outline: none;
    border: none;
    font-size: inherit;
  }

  input[type="file"] {
    visibility: hidden;
    width:0px;
    height:0px;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  /* Set content font-families */
  .ql-font-Pretendard {
    font-family: "Pretendard";
  }

  
`;
