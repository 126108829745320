import React from "react";
import Layout from "../../common/components/Layout";
import { MainSpacer } from "../common/mainCommonStyles";
import MainMoimsSection from "../components/MainMoimsSection";
import MainTopSection from "../components/MainTopSection";

function MainContainer() {
  return (
    <Layout>
      <MainTopSection />
      <MainSpacer />
      <MainMoimsSection />
    </Layout>
  );
}

export default MainContainer;
