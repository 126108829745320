import imageCompression from "browser-image-compression";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { IMAGE_COMPRESSION_OPTIONS } from "../../../../../utilities/constants";
import {
  MoimContentInput,
  MoimMediaInput,
  useAddMoimMediaMutation,
  useDeleteMoimCoverPhotoMutation,
  useDeleteMoimMediaMutation,
  useGetMoimLazyQuery,
  useUpdateMoimContentMutation,
} from "../../../../../lib/apollo/graphql/generated";

interface State {
  coverPhotoState: File | null;
  title: string;
  introduction: string;
  detail: string;
  mediaState: File[] | null;
}

const defaultState: State = {
  coverPhotoState: null,
  title: "",
  introduction: "",
  detail: "",
  mediaState: null,
};

function useUpdateMoimDetailContainer() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { moimId } = useParams();

  const coverPhotoRef = useRef<HTMLInputElement>(null);
  const mediaRef = useRef<HTMLInputElement>(null);

  const [state, setState] = useState(defaultState);

  const [getMoim, { loading, data }] = useGetMoimLazyQuery({
    onCompleted: (data) => {
      const { moimContents } = data?.getMoim;

      const content = moimContents?.find(
        (content) => content?.languageCode === i18n.resolvedLanguage
      );

      setState((prev) => ({
        ...prev,
        title: content?.title || "",
        introduction: content?.introduction || "",
        detail: content?.detail || "",
      }));
    },
  });

  const [deleteMoimCoverPhoto] = useDeleteMoimCoverPhotoMutation();
  const [deleteMoimMedia] = useDeleteMoimMediaMutation();
  const [addMoimMedia] = useAddMoimMediaMutation();
  const [updateMoimContent, { loading: updateLoading }] =
    useUpdateMoimContentMutation({
      onCompleted: () => {
        setState((prev) => ({
          ...prev,
          coverPhotoState: null,
          mediaState: null,
        }));
      },
    });

  useEffect(() => {
    if (moimId && moimId !== "new") {
      getMoim({
        variables: {
          moimId,
        },
      });
    }
  }, [moimId]);

  function onInputChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: string
  ) {
    const { value } = e.target;

    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function onCoverPhotoChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { files } = e.target;

    if (files?.[0] && files?.[0]?.size > 1048576 * 3) {
      alert("Size limit is 3MB");
      return;
    }

    setState((prev) => ({
      ...prev,
      coverPhotoState: files?.[0]!,
    }));
  }

  function onCoverPhotoDelete() {
    if (state?.coverPhotoState) {
      setState((prev) => ({
        ...prev,
        coverPhotoState: null,
      }));
    } else if (data?.getMoim?.coverPhoto && moimId !== "new") {
      deleteMoimCoverPhoto({
        variables: {
          moimId: moimId!,
        },
      });
    }
  }

  function onMediaChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { files } = e.target;

    const media: File[] = [];

    const limit =
      3 -
      (state?.mediaState?.length || 0) -
      (data?.getMoim?.media?.length || 0);

    if (files) {
      const editedFiles = [...files]?.slice(0, limit);
      for (let file of editedFiles) {
        if (file?.size > 1048576 * 10) {
          alert("Size limit is 10MB");
          return;
        }

        media.push(file);
      }
    }

    setState((prev) => ({
      ...prev,
      mediaState: media,
    }));
  }

  function onMediaStateDelete(index: number) {
    if (!state?.mediaState || !state?.mediaState[index]) {
      return;
    }

    const editedMediaState = [
      ...[...state.mediaState]?.slice(0, index),
      ...[...state?.mediaState]?.slice(index + 1),
    ];

    setState((prev) => ({
      ...prev,
      mediaState: editedMediaState,
    }));
  }

  function onMediaDelete(mediaId: string) {
    deleteMoimMedia({
      variables: {
        moimId: moimId!,
        mediaId,
      },
    });
  }

  async function onSaveClick() {
    const { coverPhotoState, title, introduction, detail, mediaState } = state;

    const content = data?.getMoim?.moimContents?.find(
      (content) => content?.languageCode === i18n.resolvedLanguage
    );

    const moimContentInput: MoimContentInput = {
      id: content?.id || null,
      moimId: moimId!,
      languageCode: i18n.resolvedLanguage,
      title,
      introduction,
      detail,
      coverPhotoInput: null,
      mediaInput: null,
    };

    if (coverPhotoState) {
      const compressedFile = await imageCompression(
        coverPhotoState,
        IMAGE_COMPRESSION_OPTIONS
      );

      moimContentInput.coverPhotoInput = { file: compressedFile };
    }

    if (mediaState && mediaState?.length > 0) {
      const compressedFiles = [];

      for await (let file of mediaState) {
        const compressedFile = await imageCompression(
          file,
          IMAGE_COMPRESSION_OPTIONS
        );
        compressedFiles.push(compressedFile);
      }

      let firstOrder = 0;

      if (data?.getMoim?.media) {
        firstOrder = data?.getMoim?.media?.length;
      }

      const mediaInput: MoimMediaInput[] = [];

      for await (let file of compressedFiles) {
        mediaInput.push({ mediaInput: { file }, order: firstOrder });
        firstOrder++;
      }

      moimContentInput.mediaInput = mediaInput;
    }

    await updateMoimContent({
      variables: {
        moimContentInput,
      },
    });
  }

  return {
    refs: {
      coverPhotoRef,
      mediaRef,
    },
    models: {
      state,
      loading,
      data: data?.getMoim,
      updateLoading,
    },
    operations: {
      onInputChange,
      onCoverPhotoChange,
      onMediaChange,
      onCoverPhotoDelete,
      onMediaStateDelete,
      onMediaDelete,
      onSaveClick,
    },
  };
}

export default useUpdateMoimDetailContainer;
