import { useState } from "react";
import { useInView } from "react-intersection-observer";
import { useLocation } from "react-router-dom";
import { MOIM_STATUS } from "../../../../utilities/constants";
import {
  MoimStatus,
  useGetMyJoinedMoimsQuery,
} from "../../../../lib/apollo/graphql/generated";

function useMyPageMoimList() {
  const { pathname } = useLocation();

  const [fetchingMore, setFetchingMore] = useState(false);

  const { ref: fetchMoreRef } = useInView({
    threshold: 0.2,
    onChange: onEndReached,
  });

  const { loading, data, fetchMore } = useGetMyJoinedMoimsQuery({
    variables: {
      first: 6,
      moimStatus: pathname.includes("done") ? MoimStatus.Finished : null,
    },
  });

  function onEndReached(inView: boolean) {
    if (
      inView &&
      !fetchingMore &&
      data?.getMyJoinedMoims?.pageInfo?.hasNextPage
    ) {
      setFetchingMore(true);

      fetchMore({
        variables: {
          first: 6,
          moimStatus: pathname.includes("done") ? MoimStatus.Finished : null,
          after: data?.getMyJoinedMoims?.pageInfo?.endCursor,
        },
      }).finally(() => {
        setFetchingMore(false);
      });
    }
  }

  return {
    refs: {
      fetchMoreRef,
    },
    models: {
      loading,
      data: data?.getMyJoinedMoims?.edges,
    },
  };
}

export default useMyPageMoimList;
