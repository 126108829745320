import React from "react";
import { useAuthContext } from "../../../../providers/AuthProvider";
import Layout from "../../../common/components/Layout";
import MyPageMoimsSection from "../components/MyPageMoimsSection";
import MyPageTopSection from "../components/MyPageTopSection";

function MyPageContainer() {
  const { userProfile } = useAuthContext();

  if (!userProfile) return <div />;

  return (
    <Layout>
      <MyPageTopSection />
      <MyPageMoimsSection />
    </Layout>
  );
}

export default MyPageContainer;
