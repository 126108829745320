import styled, { ThemedComponentProps } from "styled-components";

export const AuthMessage = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    fontSize: theme.font.body3,
    color: theme.color.black,
    textAlign: "center",
  })
);

export const AuthMessageButton = styled.span<any>(
  ({ theme }: ThemedComponentProps) => ({
    cursor: "pointer",
    fontWeight: "bold",
    textDecoration: "underline",
  })
);
