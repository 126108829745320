import { QuestionType } from "../lib/apollo/graphql/generated";

export const QUESTION_TYPE_TEXT: { [key in QuestionType]: string } = {
  BOOK: "공간예약문의",
  COLLABORATION: "제휴협업 문의",
  EXPERIENCE: "체험 문의",
  REGISTER: "공간신청 문의",
  SERVICE: "서비스 문의",
};

export const COMMON_ALT =
  "F'LAND, fland, 프랜드, 공간 가치, 공간 커뮤니티, 공간, 맛집";

export const MINIMUM_USERNAME_CHARACTERS = 2;

export const IMAGE_COMPRESSION_OPTIONS = {
  maxSizeMB: 0.5,
};

export const BANK_LIST = [
  {
    code: "001",
    name: "한국은행",
  },
  {
    code: "002",
    name: "산업은행",
  },
  {
    code: "003",
    name: "기업은행",
  },
  {
    code: "004",
    name: "국민은행",
  },
  {
    code: "007",
    name: "수협중앙회",
  },
  {
    code: "008",
    name: "수출입은행",
  },
  {
    code: "011",
    name: "농협은행",
  },
  {
    code: "012",
    name: "지역농.축협",
  },
  {
    code: "020",
    name: "우리은행",
  },
  {
    code: "023",
    name: "SC은행",
  },
  {
    code: "027",
    name: "한국씨티은행",
  },
  {
    code: "031",
    name: "대구은행",
  },
  {
    code: "032",
    name: "부산은행",
  },
  {
    code: "034",
    name: "광주은행",
  },
  {
    code: "035",
    name: "제주은행",
  },
  {
    code: "037",
    name: "전북은행",
  },
  {
    code: "039",
    name: "경남은행",
  },
  {
    code: "045",
    name: "새마을금고중앙회",
  },
  {
    code: "048",
    name: "신협중앙회",
  },
  {
    code: "050",
    name: "상호저축은행",
  },
  {
    code: "052",
    name: "모건스탠리은행",
  },
  {
    code: "054",
    name: "HSBC은행",
  },
  {
    code: "055",
    name: "도이치은행",
  },
  {
    code: "056",
    name: "알비에스피엘씨은행",
  },
  {
    code: "057",
    name: "제이피모간체이스은행",
  },
  {
    code: "058",
    name: "미즈호은행",
  },
  {
    code: "059",
    name: "미쓰비시도쿄UFJ은행",
  },
  {
    code: "060",
    name: "BOA은행",
  },
  {
    code: "061",
    name: "비엔피파리바은행",
  },
  {
    code: "062",
    name: "중국공상은행",
  },
  {
    code: "063",
    name: "중국은행",
  },
  {
    code: "064",
    name: "산림조합중앙회",
  },
  {
    code: "065",
    name: "대화은행",
  },
  {
    code: "066",
    name: "교통은행",
  },
  {
    code: "071",
    name: "우체국",
  },
  {
    code: "076",
    name: "신용보증기금",
  },
  {
    code: "077",
    name: "기술보증기금",
  },
  {
    code: "081",
    name: "KEB하나은행",
  },
  {
    code: "088",
    name: "신한은행",
  },
  {
    code: "089",
    name: "케이뱅크",
  },
  {
    code: "090",
    name: "카카오뱅크",
  },
  {
    code: "093",
    name: "한국주택금융공사",
  },
  {
    code: "094",
    name: "서울보증보험",
  },
  {
    code: "095",
    name: "경찰청",
  },
  {
    code: "096",
    name: "한국전자금융(주)",
  },
  {
    code: "099",
    name: "금융결제원",
  },
  {
    code: "209",
    name: "유안타증권",
  },
  {
    code: "218",
    name: "현대증권",
  },
  {
    code: "221",
    name: "골든브릿지투자증권",
  },
  {
    code: "222",
    name: "한양증권",
  },
  {
    code: "223",
    name: "리딩투자증권",
  },
  {
    code: "224",
    name: "BNK투자증권",
  },
  {
    code: "225",
    name: "IBK투자증권",
  },
  {
    code: "226",
    name: "KB투자증권",
  },
  {
    code: "227",
    name: "KTB투자증권",
  },
  {
    code: "230",
    name: "미래에셋증권",
  },
  {
    code: "238",
    name: "대우증권",
  },
  {
    code: "240",
    name: "삼성증권",
  },
  {
    code: "243",
    name: "한국투자증권",
  },
  {
    code: "247",
    name: "NH투자증권",
  },
  {
    code: "261",
    name: "교보증권",
  },
  {
    code: "262",
    name: "하이투자증권",
  },
  {
    code: "263",
    name: "HMC투자증권",
  },
  {
    code: "264",
    name: "키움증권",
  },
  {
    code: "265",
    name: "이베스트투자증권",
  },
  {
    code: "266",
    name: "SK증권",
  },
  {
    code: "267",
    name: "대신증권",
  },
  {
    code: "269",
    name: "한화투자증권",
  },
  {
    code: "270",
    name: "하나대투증권",
  },
  {
    code: "278",
    name: "신한금융투자",
  },
  {
    code: "279",
    name: "DB금융투자",
  },
  {
    code: "280",
    name: "유진투자증권",
  },
  {
    code: "287",
    name: "메리츠종합금융증권",
  },
  {
    code: "289",
    name: "NH투자증권",
  },
  {
    code: "290",
    name: "부국증권",
  },
  {
    code: "291",
    name: "신영증권",
  },
  {
    code: "292",
    name: "엘아이지투자증권",
  },
  {
    code: "293",
    name: "한국증권금융",
  },
  {
    code: "294",
    name: "펀드온라인코리아",
  },
  {
    code: "295",
    name: "우리종합금융",
  },
  {
    code: "296",
    name: "삼성선물",
  },
  {
    code: "297",
    name: "외환선물",
  },
  {
    code: "298",
    name: "현대선물",
  },
];

export const PAY_METHOD = {
  CREDIT_CARD: "CREDIT_CARD",
  KAKAOPAY: "KAKAOPAY",
};

export const JOIN_STATUS = {
  JOINED: "JOINED",
  CANCELLED: "CANCELLED",
  BANNED: "BANNED",
};

export const MOIM_STATUS = {
  EDITING: "EDITING",
  REVIEWING: "REVIEWING",
  REJECTED: "REJECTED",
  ACCEPTED: "ACCEPTED",

  PENDING_LISTED: "PENDING_LISTED",

  LISTED: "LISTED",
  LACK_OF_PARTICIPANTS: "LACK_OF_PARTICIPANTS",
  ON_GOING: "ON_GOING",
  FINISHED: "FINISHED",
};
