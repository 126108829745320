import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../../../providers/AuthProvider";
import {
  GetMyJoinedMoimsDocument,
  MoimStatus,
  useGetMoimQuery,
  useUpdateReviewMutation,
} from "../../../../lib/apollo/graphql/generated";

function useWriteReviewContainer() {
  const params = useParams();
  const navigate = useNavigate();
  const [body, setBody] = useState("");

  const { userProfile, toggleSignIn } = useAuthContext();

  const { loading, data } = useGetMoimQuery({
    variables: {
      moimId: params?.moimId!,
    },
  });

  const [updateReview, { loading: updating }] = useUpdateReviewMutation({
    onCompleted: () => {
      navigate("/moims/user/" + userProfile?.id!);
    },
    refetchQueries: () => [
      {
        query: GetMyJoinedMoimsDocument,
        variables: {
          moimStatus: MoimStatus.Finished,
          first: 6,
        },
      },
    ],
  });

  useEffect(() => {
    if (!userProfile) {
      toggleSignIn(true);
    }
  }, [userProfile]);

  function onBodyChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const { value } = e.target;

    setBody(value);
  }

  function onSubmit() {
    updateReview({
      variables: {
        reviewInput: {
          moimId: params?.moimId!,
          body,
        },
      },
    });
  }

  return {
    models: {
      body,
      loading,
      data: data?.getMoim,
      updating,
    },
    operations: {
      onBodyChange,
      onSubmit,
    },
  };
}

export default useWriteReviewContainer;
