import styled, { ThemedComponentProps } from "styled-components";
import { MoimStatus } from "../../../../lib/apollo/graphql/generated";

interface MoimStatusTextProps extends ThemedComponentProps {
  status: MoimStatus;
}

export const MoimStatusText = styled.div<any>(
  ({ theme, status }: MoimStatusTextProps) => ({
    padding: `${theme.spacing.xxSmall2}px ${theme.spacing.default}px`,
    fontSize: theme.font.caption1,
    fontWeight: "bold",
    backgroundColor: getStatusBackgroundColor(status),
    width: "fit-content",
    marginBottom: theme.spacing.middle,

    [theme.media.down["DESKTOP"]]: {
      fontSize: theme.font.caption2,
    },
  })
);

export const MoimSectionTitle = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    fontSize: theme.font.body1,
    fontWeight: "bold",
    marginBottom: theme.spacing.small,

    [theme.media.down["DESKTOP"]]: {
      fontSize: theme.font.body2,
    },
  })
);

export const MoimSectionValue = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    fontSize: theme.font.body3,
    lineHeight: "24px",
    marginBottom: theme.spacing.section * 2,

    [theme.media.down["DESKTOP"]]: {
      marginBottom: theme.spacing.section,
    },
  })
);

function getStatusBackgroundColor(status: MoimStatus) {
  switch (status) {
    case MoimStatus.Listed: {
      return "#ff9a36";
    }

    default: {
      return "#bfbfbf";
    }
  }
}
