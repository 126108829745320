import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled, { ThemedComponentProps } from "styled-components";
import useMyPageMoimList from "./MyPageMoimList.hook";
import MoimThumbnail from "../../../common/components/MoimThumbnail";
import { SCREENS } from "../../../routes/utils/router";
import {
  DefaultButton,
  FetchMoreButton,
  Grid,
} from "../../../common/styles/commonStyles";

function MyPageMoimList() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    refs: { fetchMoreRef },
    models: { loading, data },
  } = useMyPageMoimList();

  if (loading) return <div />;

  return !!data && data?.length > 0 ? (
    <Wrapper>
      {data?.map((join) => (
        <MoimThumbnail key={join?.id} moim={join?.moim!} />
      ))}

      <FetchMoreButton ref={fetchMoreRef} />
    </Wrapper>
  ) : (
    <>
      <NoneText>{t("myPage.noneText")}</NoneText>
      <GoToListButton onClick={() => navigate(SCREENS.MAIN.path!)}>
        {t("myPage.goToList")}
      </GoToListButton>
    </>
  );
}

const Wrapper = styled(Grid)<any>(({ theme }: ThemedComponentProps) => ({
  padding: `${theme.spacing.middle}px 0px`,
  width: "100%",
  gridTemplateColumns: "repeat(3, 1fr)",
  gap: `${theme.spacing.section}px ${theme.spacing.default}px`,
  gridAutoRows: "auto",

  [theme.media.down["DESKTOP"]]: {
    gridTemplateColumns: "1fr",
    gap: theme.spacing.section,
  },
}));

const NoneText = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  textAlign: "center",
  margin: `${theme.spacing.section}px 0px`,
  fontSize: theme.font.body3,
  fontWeight: "bold",
}));

const GoToListButton = styled(DefaultButton)<any>(
  ({ theme }: ThemedComponentProps) => ({
    width: 247,
    padding: `${theme.spacing.small}px 0px`,
    textAlign: "center",
    fontSize: theme.font.body3,
    color: theme.color.white,
    fontWeight: "bold",
    backgroundColor: theme.color.green,
    margin: "0px auto",
  })
);

export default MyPageMoimList;
