import React from "react";
import styled, { ThemedComponentProps } from "styled-components";
import { Row } from "../styles/commonStyles";

interface AsideContainerProps {
  left: React.ReactNode;
  right: React.ReactNode;
  stickyTop?: string | number;
}

function AsideContainer({ left, right, stickyTop }: AsideContainerProps) {
  return (
    <Wrapper>
      <Left>{left}</Left>
      <Right>
        <Aside $top={stickyTop}>{right}</Aside>
      </Right>
    </Wrapper>
  );
}

const Wrapper = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  maxWidth: theme.containerMaxWidth,
  position: "relative",
  padding: `0px ${theme.spacing.section}px`,
}));

const Left = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "60%",
  marginRight: theme.spacing.regular,
}));

const Right = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  position: "relative",
  width: `calc(40% - ${theme.spacing.regular}px)`,
  paddingBottom: theme.spacing.section,
}));

interface AsideProps extends ThemedComponentProps {
  $top?: string | number;
}

const Aside = styled.div<any>(({ theme, $top = "8%" }: AsideProps) => ({
  position: "sticky",
  top: $top,
}));

export default AsideContainer;
