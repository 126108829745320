import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  Gender,
  useCreatePlaceMutation,
  useGetMoimLazyQuery,
  useUpdateMoimBasicMutation,
  useUpdatePlaceMutation,
} from "../../../../../lib/apollo/graphql/generated";

interface State {
  date: string;
  startTime: string;
  endTime: string;
  isPrivate: boolean;
  placeName: string;
  placeAddress: string;
  placeAddressDetail: string;
  price: string;
  genderRestriction: Gender | null;
  showAgeInput: boolean;
  ageRestriction: boolean;
  minAge: string | null;
  maxAge: string | null;
  minPersons: string | null;
  maxPersons: string | null;
}

const defaultState: State = {
  date: format(new Date(), "yyyy-MM-dd"),
  startTime: "",
  endTime: "",
  isPrivate: false,
  placeName: "",
  placeAddress: "",
  placeAddressDetail: "",
  price: "",
  genderRestriction: null,
  showAgeInput: false,
  ageRestriction: false,
  minAge: "",
  maxAge: "",
  minPersons: "",
  maxPersons: "",
};

function useUpdateMoimBasicInformationContainer() {
  const navigate = useNavigate();
  const { moimId } = useParams();
  const { i18n } = useTranslation();

  const [state, setState] = useState(defaultState);

  const [getMoim, { loading, data }] = useGetMoimLazyQuery({
    onCompleted: (data) => {
      const {
        place,
        moimStartAt,
        moimEndAt,
        moimContents,
        genderRestriction,
        minAge,
        maxAge,
        minPersons,
        maxPersons,
      } = data?.getMoim;

      setState((prev) => ({
        ...prev,
        date: moimStartAt
          ? format(new Date(moimStartAt), "yyyy-MM-dd")
          : format(new Date(), "yyyy-MM-dd"),
        startTime: moimStartAt ? format(new Date(moimStartAt), "HH:mm:ss") : "",
        endTime: moimEndAt ? format(new Date(moimEndAt), "HH:mm:ss") : "",
        isPrivate: place?.isPrivate || false,
        placeName: place?.name || "",
        placeAddress: place?.address || "",
        placeAddressDetail: place?.addressDetail || "",
        genderRestriction: genderRestriction || null,
        minAge: minAge?.toString() || "",
        maxAge: maxAge?.toString() || "",
        minPersons: minPersons?.toString() || "",
        maxPersons: maxPersons?.toString() || "",
        showAgeInput: !!minAge && !!maxAge,
        ageRestriction: !(!minAge && !maxAge),
        price:
          moimContents
            ?.find((content) => content?.languageCode === i18n.resolvedLanguage)
            ?.price?.toString() || "",
      }));
    },
  });

  const [updateMoimBasic, { loading: updateLoading }] =
    useUpdateMoimBasicMutation({
      onCompleted: ({ updateMoimBasic }) => {
        if (moimId === "new") {
          navigate(`/moim/${updateMoimBasic?.id}/info`, { replace: true });
        }
      },
    });
  const [createPlace] = useCreatePlaceMutation();
  const [updatePlace] = useUpdatePlaceMutation();

  useEffect(() => {
    if (moimId && moimId !== "new") {
      getMoim({
        variables: {
          moimId,
        },
      });
    }
  }, [moimId]);

  function onInputChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: string
  ) {
    const { value } = e.target;

    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function onIsPrivateChange(isPrivate: boolean) {
    setState((prev) => ({
      ...prev,
      isPrivate,
    }));
  }

  function onAddressChange(address: string) {
    setState((prev) => ({
      ...prev,
      placeAddress: address,
    }));
  }

  function onGenderRestrictionChange(genderRestriction: null | Gender) {
    setState((prev) => ({
      ...prev,
      genderRestriction,
    }));
  }

  function toggleShowAgeInput(showAgeInput: boolean) {
    setState((prev) => ({
      ...prev,
      showAgeInput,
      ageRestriction: true,
      minAge: "",
      maxAge: "",
    }));
  }

  function onAgeRestrictionChange(ageRestriction: string | null) {
    if (ageRestriction === "20") {
      setState((prev) => ({
        ...prev,
        ageRestriction: true,
        showAgeInput: false,
        minAge: "20",
        maxAge: "",
      }));
    } else if (ageRestriction === null) {
      setState((prev) => ({
        ...prev,
        ageRestriction: false,
        showAgeInput: false,
        minAge: "",
        maxAge: "",
      }));
    }
  }

  async function onSaveClick() {
    const {
      date,
      startTime,
      endTime,
      isPrivate,
      placeName,
      placeAddress,
      placeAddressDetail,
      price,
      genderRestriction,
      showAgeInput,
      minAge,
      maxAge,
      ageRestriction,
      minPersons,
      maxPersons,
    } = state;

    const placeInput = {
      address: placeAddress,
      addressDetail: placeAddressDetail,
      isPrivate,
      name: placeName,
    };

    let placeId = data?.getMoim?.place?.id;

    if (data?.getMoim?.place?.id) {
      await updatePlace({
        variables: {
          placeInput: {
            ...placeInput,
            id: data?.getMoim?.place?.id,
          },
        },
      });
    } else {
      const placeData = await createPlace({
        variables: {
          placeInput,
        },
      });

      placeId = placeData?.data?.createPlace?.id;
    }

    await updateMoimBasic({
      variables: {
        moimBasicInput: {
          id: moimId === "new" ? null : moimId,
          languageCode: i18n.resolvedLanguage,
          placeId,
          moimStartAt:
            !!date && !!startTime
              ? new Date(date + "," + startTime).toISOString()
              : null,
          moimEndAt:
            !!date && !!endTime
              ? new Date(date + "," + endTime).toISOString()
              : null,
          minAge: minAge ? Number(minAge) : null,
          maxAge: maxAge ? Number(maxAge) : null,
          price: price ? Number(price) : null,
          genderRestriction,
          minPersons: minPersons ? Number(minPersons) : null,
          maxPersons: maxPersons ? Number(maxPersons) : null,
        },
      },
    });
  }

  return {
    models: {
      loading,
      state,
      data: data?.getMoim,
      updateLoading,
    },
    operations: {
      onInputChange,
      onIsPrivateChange,
      onAddressChange,
      onGenderRestrictionChange,
      toggleShowAgeInput,
      onAgeRestrictionChange,
      onSaveClick,
    },
  };
}

export default useUpdateMoimBasicInformationContainer;
