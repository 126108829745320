import React, { useEffect, useRef, useState } from "react";
import LazyImage from "../../../common/components/LazyImage";

interface MoimContentImageProps {
  uri: string;
  onClick?: () => void;
}

function MoimContentImage({ uri, onClick }: MoimContentImageProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (ref?.current) {
      setHeight((ref?.current?.clientWidth * 9) / 16);
    }
  }, [ref?.current?.clientWidth]);

  return (
    <div
      ref={ref}
      style={{ cursor: !!onClick ? "pointer" : "default" }}
      onClick={onClick}
    >
      <LazyImage width="100%" height={height} objectFit="cover" uri={uri} />
    </div>
  );
}

export default MoimContentImage;
