import React from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import styled, { ThemedComponentProps, useTheme } from "styled-components";
import { useAuthContext } from "../../../../providers/AuthProvider";
import { useScreenWidthContext } from "../../../../providers/ScreenWidthProvider";
import Layout from "../../../common/components/Layout";
import { Col, Tab, Tabs } from "../../../common/styles/commonStyles";
import { MyPageTitle } from "../../myPage/common/styles/MyPageCommonStyles";

function ManageHostMoimContainer() {
  const { t } = useTranslation();
  const params = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();

  const { isPc } = useScreenWidthContext();

  const { userProfile } = useAuthContext();

  if (!userProfile) return <div />;

  return (
    <Layout>
      <Wrapper>
        {!isPc ? (
          <div
            style={{
              paddingLeft: theme.spacing.default,
              marginTop: theme.spacing.regular,
            }}
          >
            <MyPageTitle>{t("manageHostMoim.title")}</MyPageTitle>
          </div>
        ) : (
          <MyPageTitle>{t("manageHostMoim.title")}</MyPageTitle>
        )}
        <Tabs>
          <Tab
            isActive={!pathname.includes("done")}
            onClick={() => navigate("/host/" + params?.userId)}
          >
            {t("myPage.onGoingMoimTitle")}
          </Tab>
          <Tab
            isActive={pathname.includes("done")}
            onClick={() => navigate("/host/" + params?.userId + "/done")}
          >
            {t("myPage.finishedMoimTitle")}
          </Tab>
        </Tabs>
        <Outlet />
      </Wrapper>
    </Layout>
  );
}

const Wrapper = styled(Col)<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  maxWidth: theme.containerMaxWidth,
  padding: `${theme.spacing.regular * 3}px ${theme.spacing.section}px`,

  [theme.media.down["DESKTOP"]]: {
    padding: 0,
    paddingBottom: theme.spacing.section,
  },
}));

export default ManageHostMoimContainer;
