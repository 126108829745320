import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ThemedComponentProps } from "styled-components";
import { MoimContentSectionProps } from "./MoimContentSection";
import shareIcon from "../../../../assets/icons/share_fill_black.svg";
import { useCancelJoinMoimMutation } from "../../../../lib/apollo/graphql/generated";
import { useAuthContext } from "../../../../providers/AuthProvider";
import { JOIN_STATUS } from "../../../../utilities/constants";
import { useGetMoimContent } from "../../../common/hook/useGetMoimContent";
import { DefaultButton, Row } from "../../../common/styles/commonStyles";
import { copyText } from "../../../common/utils/copyText";
import { getMoimcontentByLanguage } from "../../../common/utils/getMoimContentByLanguage";

function MoimStickyJoin({ moim }: MoimContentSectionProps) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { userProfile, toggleSignIn } = useAuthContext();

  const [cancelJoinMoim] = useCancelJoinMoimMutation();

  const { moimContents, moimStartAt, participants, maxPersons } = moim;

  const content = getMoimcontentByLanguage(i18n.resolvedLanguage, moimContents);

  const { price, joinDeadline } = useGetMoimContent(content, moimStartAt);

  const isFull = !!maxPersons && participants?.length >= maxPersons;

  const activeParticipants = [...participants]?.filter(
    (participant) => participant?.status === JOIN_STATUS.JOINED
  );

  const isJoined = activeParticipants?.find(
    (participant) => participant?.user?.id === userProfile?.id
  );

  return (
    <Wrapper>
      <Price>{price}</Price>
      <JoinAvailable>
        {i18n.resolvedLanguage !== "ko" && t("moim.joinAvailable") + " "}
        {joinDeadline}
        {" " + i18n.resolvedLanguage === "ko" && t("moim.joinAvailable")}
      </JoinAvailable>
      <BottomRow>
        <ShareButton
          onClick={() => {
            copyText(window.location.href, t("common.copyText"));
          }}
        >
          <ShareIcon />
          <div>{t("common.share")}</div>
        </ShareButton>
        <JoinButton
          onClick={() => {
            if (!userProfile) {
              toggleSignIn(true);
            } else if (isJoined) {
              const confirm = window.confirm(t("moim.sureToCancelJoinMoim"));

              if (confirm) {
                cancelJoinMoim({
                  variables: {
                    joinId: isJoined?.id!,
                  },
                });
              }
            } else {
              navigate("/join/" + moim?.id);
            }
          }}
        >
          {isFull
            ? t("common.full")
            : !!isJoined
            ? t("common.cancelJoin")
            : t("common.join")}
        </JoinButton>
      </BottomRow>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  backgroundColor: theme.color.white,
  border: `1px solid ${theme.color.border}`,
  padding: `${theme.spacing.large}px ${theme.spacing.middle}px`,
  fontSize: theme.font.body3,
  width: "100%",

  [theme.media.down["DESKTOP"]]: {
    position: "sticky",
    bottom: 0,
    left: 0,
    right: 0,

    padding: `${theme.spacing.default}px ${theme.spacing.regular}px`,
  },
}));

const Price = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.body2,
  fontWeight: "bold",
  marginBottom: theme.spacing.small,

  [theme.media.down["DESKTOP"]]: {
    marginBottom: 0,
  },
}));

const JoinAvailable = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.caption2,
  marginBottom: theme.spacing.middle,

  [theme.media.down["DESKTOP"]]: {
    marginBottom: theme.spacing.default,
  },
}));

const BottomRow = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  gap: theme.spacing.xxSmall2,
}));

const ShareButton = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  cursor: "pointer",
  padding: theme.spacing.default,
  alignItems: "center",
  justifyContent: "center",
  gap: theme.spacing.xxSmall2,

  backgroundColor: theme.color.background,
  border: `1px solid ${theme.color.black}`,

  [theme.media.down["DESKTOP"]]: {
    padding: `${theme.spacing.normal}px ${theme.spacing.default}px`,
  },
}));

const ShareIcon = styled.img.attrs(() => ({
  src: shareIcon,
}))<any>(() => ({
  width: 20,
  height: 20,
}));

const JoinButton = styled(DefaultButton)<any>(
  ({ theme }: ThemedComponentProps) => ({
    flex: 1,
    padding: theme.spacing.default,
    fontWeight: "bold",
    backgroundColor: theme.color.orange,
    border: `1px solid ${theme.color.black}`,
    textAlign: "center",

    [theme.media.down["DESKTOP"]]: {
      padding: `${theme.spacing.normal}px ${theme.spacing.default}px`,
    },
  })
);

export default MoimStickyJoin;
