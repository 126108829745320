import styled, { ThemedComponentProps } from "styled-components";
import eye from "../../../assets/icons/eye_black.svg";
import eyeOff from "../../../assets/icons/eye_off.svg";
import newTag from "../../../assets/icons/new_rabbit.svg";

export const Row = styled.div<any>(() => ({
  display: "flex",
  flexDirection: "row",
}));

export const Col = styled.div<any>(() => ({
  display: "flex",
  flexDirection: "column",
}));

export const Grid = styled.div<any>(() => ({
  display: "grid",
}));

export const DefaultButton = styled.div<any>(({ isDisabled }: any) => ({
  cursor: "pointer",

  opacity: isDisabled ? 0.5 : 1,
  pointerEvents: isDisabled ? "none" : "auto",
}));

export const TouchableOpacity = styled(DefaultButton)(() => ({
  ":hover": {
    opacity: 0.5,
  },
}));

interface SaveButtonProps extends ThemedComponentProps {
  disabled?: boolean;
}

export const SaveButton = styled(DefaultButton)<any>(
  ({ theme, disabled }: SaveButtonProps) => ({
    width: "100%",
    textAlign: "center",
    padding: `${theme.spacing.normal}px 0px`,
    backgroundColor: theme.color.green,
    fontSize: theme.font.body3,
    color: theme.color.white,
    opacity: disabled ? 0.7 : 1,
    pointerEvents: disabled ? "none" : "auto",
  })
);

interface SectionWrapperProps extends ThemedComponentProps {
  $backgroundColor?: string;
}

export const SectionWrapper = styled(Col)<any>(
  ({
    theme,
    $backgroundColor = theme.color.background,
  }: SectionWrapperProps) => ({
    alignItems: "center",
    width: "100%",
    maxWidth: theme.contentMaxWidth,
    backgroundColor: $backgroundColor,
    padding: `${theme.spacing.section * 2}px ${theme.spacing.regular}px`,

    [theme.media.down["DESKTOP"]]: {
      padding: `${(theme.spacing.section * 3) / 2}px ${
        theme.spacing.regular
      }px`,
    },

    [theme.media.down["TABLET"]]: {
      padding: `${theme.spacing.section}px ${theme.spacing.regular}px`,
    },
  })
);

export const NewTag = styled.img.attrs(() => ({
  src: newTag,
}))<any>(({ theme }: ThemedComponentProps) => ({
  position: "absolute",
  top: theme.spacing.default,
  left: theme.spacing.default,
  zIndex: 3,
}));

export const ModalTitle = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    textAlign: "center",
    fontSize: theme.font.body1,
    fontWeight: "bold",
    color: theme.color.black,
    marginBottom: theme.spacing.middle,
  })
);

export const CommonInputLabel = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    fontSize: theme.font.caption1,
    color: theme.color.black,
    marginBottom: theme.spacing.xxSmall2,
  })
);

interface CommonInputProps extends ThemedComponentProps {
  error?: boolean;
}

export const CommonInput = styled.input<any>(
  ({ theme, error }: CommonInputProps) => ({
    minWidth: 386,
    width: "100%",
    backgroundColor: theme.color.white,
    border: `1px solid ${!error ? "#d0d0d0" : theme.color.error}`,
    padding: `${theme.spacing.normal}px ${theme.spacing.default}px`,
    fontSize: theme.font.body3,
    color: theme.color.black,

    ":placeholder": {
      color: "#808080",
    },

    ":focus": {
      borderColor: theme.color.green,
    },

    ":invalid": {
      borderColor: theme.color.error,
    },

    [theme.media.down["DESKTOP"]]: {
      minWidth: `calc(100vw - ${theme.spacing.regular * 2}px)`,
    },
  })
);

export const CommonInputErrorMessage = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    marginTop: theme.spacing.xxSmall2,
    color: theme.color.error,
    fontSize: theme.font.caption1,
    textAlign: "left",
  })
);

interface ModalButtonProps extends ThemedComponentProps {
  disabled?: boolean;
}

export const ModalButton = styled(DefaultButton)<any>(
  ({ theme, disabled }: ModalButtonProps) => ({
    width: "100%",
    padding: theme.spacing.normal,
    color: theme.color.white,
    backgroundColor: theme.color.green,
    fontSize: theme.font.body3,
    textAlign: "center",
    pointerEvents: disabled ? "none" : "auto",
    opacity: disabled ? 0.5 : 1,
  })
);

interface VisibilityButtonProps extends ThemedComponentProps {
  visible?: boolean;
}

export const VisibilityButton = styled.img.attrs(
  ({ visible }: VisibilityButtonProps) => ({
    src: visible ? eyeOff : eye,
  })
)<any>(({ theme }: VisibilityButtonProps) => ({
  width: 20,
  height: 20,
  cursor: "pointer",
  position: "absolute",
  right: theme.spacing.default,
  top: "50%",
  transform: "translateY(-50%)",
}));

export const FetchMoreButton = styled.div<any>(() => ({
  width: 1,
  height: 1,
  visibility: "hidden",
}));

export const Tabs = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  alignItems: "center",
  width: "100%",
  backgroundColor: theme.color.white,
}));

interface TabProps extends ThemedComponentProps {
  isActive?: boolean;
}

export const Tab = styled(DefaultButton)<any>(
  ({ theme, isActive }: TabProps) => ({
    flex: 1,
    textAlign: "center",
    padding: `${theme.spacing.default}px 0px`,
    fontSize: theme.font.body3,
    fontWeight: isActive ? "bold" : "normal",
    borderBottom: isActive
      ? `2px solid ${theme.color.green}`
      : `1px solid ${theme.color.border}`,
  })
);

export const GreyTextButton = styled(TouchableOpacity)<any>(
  ({ theme }: ThemedComponentProps) => ({
    fontSize: theme.font.caption1,
    color: "#8c8c8c",
  })
);
