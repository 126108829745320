import React, { CSSProperties } from "react";
import styled, { ThemedComponentProps } from "styled-components";
import deleteIcon from "../../../assets/icons/circle_delete.svg";
import { DefaultButton } from "../styles/commonStyles";

interface ImagePreviewProps {
  uri: string;
  wrapperStyle?: CSSProperties;
  size?: { width?: string | number; height?: string | number };
  disabled?: boolean;
  onDeleteClick?: () => void;
}

function ImagePreview({
  uri,
  wrapperStyle = {},
  size,
  disabled = false,
  onDeleteClick,
}: ImagePreviewProps) {
  return (
    <Wrapper style={wrapperStyle} $width={size?.width} $height={size?.height}>
      <Image $width={size?.width} $height={size?.height} src={uri} />
      <DeleteButton disabled={disabled} onClick={onDeleteClick}>
        <DeleteIcon />
      </DeleteButton>
    </Wrapper>
  );
}

interface WrapperProps extends ThemedComponentProps {
  $width?: string | number;
  $height?: string | number;
}

const Wrapper = styled.div<any>(
  ({ theme, $width = "100%", $height = 284 }: WrapperProps) => ({
    width: $width,
    height: $height,
    position: "relative",
  })
);

const Image = styled.img<any>(
  ({ $width = "100%", $height = 284 }: WrapperProps) => ({
    width: $width,
    height: $height,
    objectFit: "cover",
    verticalAlign: "middle",
  })
);

interface DeleteButtonProps extends ThemedComponentProps {
  disabled?: boolean;
}

const DeleteButton = styled(DefaultButton)<any>(
  ({ theme, disabled }: DeleteButtonProps) => ({
    position: "absolute",
    right: theme.spacing.small,
    bottom: theme.spacing.small,
    width: 32,
    height: 32,

    opacity: disabled ? 0.7 : 1,
    pointerEvents: disabled ? "none" : "auto",
  })
);

const DeleteIcon = styled.img.attrs(() => ({
  src: deleteIcon,
}))<any>(() => ({
  width: "100%",
  height: "100%",
}));

export default ImagePreview;
