import { ReactChildrenProp } from "global";
import React from "react";
import { Helmet } from "react-helmet-async";
import { ScrollRestoration } from "react-router-dom";
import styled, { ThemedComponentProps } from "styled-components";
import Footer from "./Footer";
import SubscribeSection from "./SubscribeSection";
import backIcon from "../../../assets/icons/chevron_up.svg";
import NavigationBar from "../../routes/components/NavigationBar";
import useAnalytics from "../hook/useAnalytics";
import { Col, Row, TouchableOpacity } from "../styles/commonStyles";

interface LayoutProps extends ReactChildrenProp {
  showNavigationBar?: boolean;
  customNavigationBar?: React.ReactNode;
}

const GTAG_ID = process.env.REACT_APP_MEASUREMENT_ID;

function Layout({
  children,
  showNavigationBar = true,
  customNavigationBar,
}: LayoutProps) {
  // send gtag page view event by location changes
  useAnalytics();

  return (
    <>
      <Helmet prioritizeSeoTags>
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${GTAG_ID}`}
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', '${GTAG_ID}');
          `}
        </script>
      </Helmet>
      <ScrollRestoration />
      <Wrapper>
        {showNavigationBar ? (
          <NavigationBar />
        ) : !!customNavigationBar ? (
          customNavigationBar
        ) : null}
        <Content showNavigationBar={showNavigationBar}>
          {children}
          {/* <SubscribeSection /> */}
        </Content>
        <Footer />
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: theme.color.green,
  position: "relative",

  [theme.media.down["TABLET"]]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

interface ContentProps extends ThemedComponentProps {
  showNavigationBar?: boolean;
}

const Content = styled(Col)(({ theme, showNavigationBar }: ContentProps) => ({
  width: "100%",
  paddingTop: showNavigationBar ? theme.navigationHeight : 0,
  alignItems: "center",
  backgroundColor: theme.color.background,

  [theme.media.down["DESKTOP"]]: {
    paddingTop: showNavigationBar ? theme.mobileNavigationHeight : 0,
  },
}));

const MobileNavBar = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  position: "relative",
  alignItems: "center",
  justifyContent: "center",
  padding: `${theme.spacing.normal}px 0px`,
  fontSize: theme.font.body3,
  fontWeight: "bold",
  backgroundColor: theme.color.green,
  color: theme.color.white,
  width: "100%",
}));

const BackButton = styled(TouchableOpacity)<any>(
  ({ theme }: ThemedComponentProps) => ({
    position: "absolute",
    left: theme.spacing.regular,
    top: "50%",
    transform: "translateY(-50%)",
    width: 20,
    height: 20,
  })
);

const BackIcon = styled.img.attrs(() => ({
  src: backIcon,
}))<any>(() => ({
  width: "100%",
  height: "100%",
  transform: "rotate(-90deg)",
}));

export default Layout;
