import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  MoimStatus,
  useGetMoimLazyQuery,
  useRequestMoimVerificationMutation,
} from "../../../../../lib/apollo/graphql/generated";

function useUpdateMoimNavigationBar({
  isNew,
  status,
  moimId,
}: {
  isNew?: boolean;
  status?: MoimStatus;
  moimId?: string;
}) {
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);

  const [requestMoimVerification] = useRequestMoimVerificationMutation({
    onCompleted: ({ requestMoimVerification }) => {
      if (requestMoimVerification) {
        navigate("/moim/" + requestMoimVerification?.id);
      }
    },
  });

  function toggleShowModal(show: boolean) {
    setShowModal(show);
  }

  function onSubmit() {
    if (isNew) {
      return;
    }
    if (status === MoimStatus.Editing || status === MoimStatus.Rejected) {
      requestMoimVerification({
        variables: {
          moimId: moimId!,
        },
      });
    } else {
      toggleShowModal(false);
    }
  }

  return {
    models: {
      showModal,
    },
    operations: {
      onSubmit,
      toggleShowModal,
    },
  };
}

export default useUpdateMoimNavigationBar;
