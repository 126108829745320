import { useRef, useState } from "react";
import { validateEmail } from "../../../common/utils/validateEmail";
import {
  FIREBASE_AUTH_ERROR_CODES,
  firebaseSignIn,
  sendFirebasePasswordResetEmail,
} from "../../../../lib/firebase";

function useSignInContainer() {
  const emailInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);

  const [state, setState] = useState({
    email: "",
    password: "",
    emailError: "",
    passwordError: "",
  });

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>, key: string) {
    const { value } = e.target;

    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function onFocus(key: string) {
    if (key === "email" && state.emailError) {
      setState((prev) => ({
        ...prev,
        emailError: "",
      }));
    }

    if (key === "password" && state.passwordError) {
      setState((prev) => ({
        ...prev,
        passwordError: "",
      }));
    }
  }

  function onBlur(key: string) {
    if (key === "email" && !validateEmail(state.email)) {
      setState((prev) => ({
        ...prev,
        emailError: "error.emailError",
      }));
    }
    if (key === "password" && !(state.password.length > 5)) {
      setState((prev) => ({
        ...prev,
        passwordError: "error.passwordLengthError",
      }));
    }
  }

  function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>, key: string) {
    if (e.key === "Enter") {
      if (key === "email") {
        passwordInputRef?.current?.focus();
      } else {
        if (!isSubmitDisabled()) {
          onSignInClick();
        }
      }
    }
  }

  async function onFindPasswordClick() {
    const { email, emailError } = state;
    if (validateEmail(email) && !emailError) {
      try {
        await sendFirebasePasswordResetEmail(email);
      } catch (error: any) {
        setState((prev) => ({
          ...prev,
          emailError: "authError." + error.code,
        }));
      }
    } else {
      setState((prev) => ({
        ...prev,
        emailError: "error.findPasswordEmailError",
      }));
    }
  }

  function isSubmitDisabled() {
    const isValidEmail = validateEmail(state.email) && !state.emailError;
    const isValidPassword = state.password.length > 5 && !state.passwordError;

    return !(isValidEmail && isValidPassword);
  }

  async function onSignInClick() {
    try {
      await firebaseSignIn(state.email, state.password);
    } catch (error: any) {
      if (error.code?.includes("auth/wrong-password")) {
        setState((prev) => ({
          ...prev,
          passwordError: "authError." + error.code,
        }));
      } else {
        setState((prev) => ({
          ...prev,
          emailError: "authError." + error.code,
          passwordError: "authError." + error.code,
        }));
      }
    }
  }

  return {
    refs: {
      emailInputRef,
      passwordInputRef,
    },
    models: {
      state,
    },
    operations: {
      onInputChange,
      onFocus,
      onBlur,
      onKeyDown,
      onFindPasswordClick,
      isSubmitDisabled,
      onSignInClick,
    },
  };
}

export default useSignInContainer;
