import React from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import styled, { ThemedComponentProps, useTheme } from "styled-components";
import { useAuthContext } from "../../../../providers/AuthProvider";
import { useScreenWidthContext } from "../../../../providers/ScreenWidthProvider";
import Layout from "../../../common/components/Layout";
import { Col, Tab, Tabs } from "../../../common/styles/commonStyles";
import { MyPageTitle } from "../../myPage/common/styles/MyPageCommonStyles";

function EditProfileContainer() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();

  const { isPc } = useScreenWidthContext();

  const { userProfile } = useAuthContext();

  if (!userProfile) return <div />;

  return (
    <Layout>
      <Wrapper>
        {!isPc ? (
          <div
            style={{
              paddingLeft: theme.spacing.default,
              marginTop: theme.spacing.regular,
            }}
          >
            <MyPageTitle>{t("editProfile.title")}</MyPageTitle>
          </div>
        ) : (
          <MyPageTitle>{t("editProfile.title")}</MyPageTitle>
        )}
        <Content>
          <Tabs>
            <Tab
              isActive={!pathname.includes("account")}
              onClick={() => navigate("/user/edit/" + userProfile?.id)}
            >
              {t("editProfile.profileTitle")}
            </Tab>
            <Tab
              isActive={pathname.includes("account")}
              onClick={() =>
                navigate("/user/edit/" + userProfile?.id + "/account")
              }
            >
              {t("editProfile.accountTitle")}
            </Tab>
          </Tabs>
          <Outlet />
        </Content>
      </Wrapper>
    </Layout>
  );
}

const Wrapper = styled(Col)<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  maxWidth: 760,
  padding: `${theme.spacing.regular * 3}px 0px`,

  [theme.media.down["DESKTOP"]]: {
    padding: 0,
  },
}));

const Content = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  backgroundColor: theme.color.white,
}));

export default EditProfileContainer;
