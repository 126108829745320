import { format } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";
import styled, { ThemedComponentProps } from "styled-components";
import { Gender, JoinStatus } from "../../../../lib/apollo/graphql/generated";
import Avatar from "../../../common/components/Avatar";
import { Col, Row } from "../../../common/styles/commonStyles";
import { calculateAge } from "../../../common/utils/calculateAge";
import { hidePersonalInfo } from "../../../common/utils/hidePersonalInfo";

interface ParticipantCardProps {
  participant: {
    __typename?: "Join";
    id: string;
    status: JoinStatus;
    createdAt: any;
    updatedAt: any;
    user: {
      __typename?: "UserProfile";
      id: string;
      username: string;
      name?: string | null;
      email?: string | null;
      phone?: string | null;
      dateOfBirth?: any | null;
      gender?: Gender | null;
      avatar?: { __typename?: "Media"; id: string; uri: string } | null;
    };
  };
  index: number;
  showPersonalInfo: boolean;
}

function ParticipantCard({
  participant,
  index,
  showPersonalInfo,
}: ParticipantCardProps) {
  const { t } = useTranslation();

  const { user, createdAt } = participant;

  return (
    <div>
      <Top>{index + 1}</Top>
      <Body>
        <AvatarRow>
          <Avatar uri={user?.avatar?.uri} diameter={42} />
          <div>
            <Username>{user?.username}</Username>
            <GenderAge>
              {t("common." + user?.gender)}
              {!!user?.dateOfBirth &&
                "|" + calculateAge(user?.dateOfBirth) + t("common.yearOld")}
            </GenderAge>
          </div>
        </AvatarRow>

        <ValueCell>
          <ValueRow>
            <Label>{t("profile.phone")}</Label>
            <Value>
              {user?.phone
                ? showPersonalInfo
                  ? user?.phone
                  : hidePersonalInfo(user?.phone)
                : "-"}
            </Value>
          </ValueRow>
          <ValueRow>
            <Label>{t("profile.email")}</Label>
            <Value>
              {user?.email
                ? showPersonalInfo
                  ? user?.email
                  : hidePersonalInfo(user?.email)
                : "-"}
            </Value>
          </ValueRow>
          <ValueRow>
            <Label>{t("participants.purchasedAt")}</Label>
            <Value>{format(new Date(createdAt), "yy/MM/dd HH:mm")}</Value>
          </ValueRow>
        </ValueCell>
      </Body>
    </div>
  );
}

const Top = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: `${theme.spacing.xxSmall2}px 0px`,
  backgroundColor: theme.color.green,
  color: theme.color.white,
  textAlign: "center",
  fontSize: theme.font.caption1,
  fontWeight: "bold",
}));

const Body = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: theme.spacing.middle,
  border: `1px solid #d0d0d0`,
  borderTop: "none",
  backgroundColor: theme.color.white,
}));

const AvatarRow = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  alignItems: "center",
  gap: theme.spacing.small,
  marginBottom: theme.spacing.default,
}));

const Username = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.body3,
  fontWeight: "bold",
}));

const GenderAge = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.caption2,
  color: "#8c8c8c",
}));

const ValueCell = styled(Col)<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  gap: theme.spacing.small,
}));

const ValueRow = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  alignItems: "center",
  gap: theme.spacing.normal,
}));

const Label = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.body3,
  fontWeight: "bold",
  textAlign: "left",
  flex: 1,
}));

const Value = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.caption1,
  flex: 2,
}));

export default ParticipantCard;
