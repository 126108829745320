import { createBrowserRouter } from "react-router-dom";
import { Screens } from "./router.interface";
import ContentContainer from "../../content/container/ContentContainer";
import ContentsListContainer from "../../contentsList/container/ContentsListContainer";
import MainContainer from "../../main/container/MainContainer";
import JoinMoimContainer from "../../moim/joinMoim/container/JoinMoimContainer";
import JoinMoimDoneContainer from "../../moim/joinMoimDone/container/JoinMoimDoneContainer";
import MoimContainer from "../../moim/moim/container/MoimContainer";
import ParticipantsContainer from "../../moim/participants/container/ParticipantsContainer";
import UpdateMoimContainer from "../../moim/updateMoim/updateMoim/container/UpdateMoimContainer";
import UpdateMoimBasicInformationContainer from "../../moim/updateMoim/updateMoimBasicInformation/container/UpdateMoimBasicInformationContainer";
import UpdateMoimDetailContainer from "../../moim/updateMoim/updateMoimDetail/container/UpdateMoimDetailContainer";
import UpdateMoimIntroContainer from "../../moim/updateMoim/updateMoimIntro/container/UpdateMoimIntroContainer";
import NoPageContainer from "../../noPage/container/NoPageContainer";
import EditAccountContainer from "../../profile/editAccount/container/EditAccountContainer";
import EditProfileContainer from "../../profile/editProfile/container/EditProfileContainer";
import ManageHostMoimList from "../../profile/manageHostMoim/components/ManageHostMoimList";
import ManageHostMoimContainer from "../../profile/manageHostMoim/container/ManageHostMoimContainer";
import ManageMyMoimReviews from "../../profile/manageMyMoim/components/ManageMyMoimReviews";
import ManageMyMoimContainer from "../../profile/manageMyMoim/container/ManageMyMoimContainer";
import MyPageMoimList from "../../profile/myPage/components/MyPageMoimList";
import MyPageContainer from "../../profile/myPage/container/MyPageContainer";
import UpdateHostProfileContainer from "../../profile/updateHostProfile/container/UpdateHostProfileContainer";
import WriteReviewContainer from "../../profile/writeReview/container/WriteReviewContainer";

export const SCREENS: Screens = {
  MAIN: {
    path: "/",
    element: <MainContainer />,
  },
  CONTENTS_LIST: {
    path: "/contents",
    element: <ContentsListContainer />,
  },
  CONTENT: {
    path: "/:contentId",
    element: <ContentContainer />,
  },
  MOIM: {
    path: "/moim/:moimId",
    element: <MoimContainer />,
  },
  PARTICIPANTS: {
    path: "/moim/participants/:moimId",
    element: <ParticipantsContainer />,
  },
  JOIN_MOIM: {
    path: "/join/:moimId",
    element: <JoinMoimContainer />,
  },
  JOIN_MOIM_DONE: {
    path: "/joined/:moimId",
    element: <JoinMoimDoneContainer />,
  },
  UPDATE_MOIM_INTRODUCTION: {
    path: "/about_moim",
    element: <UpdateMoimIntroContainer />,
  },
  UPDATE_MOIM: {
    path: "/moim",
    element: <UpdateMoimContainer />,
    children: [
      {
        path: ":moimId/host",
        element: <UpdateHostProfileContainer />,
      },
      {
        path: ":moimId/info",
        element: <UpdateMoimBasicInformationContainer />,
      },
      {
        path: ":moimId/detail",
        element: <UpdateMoimDetailContainer />,
      },
    ],
  },
  MY_PAGE: {
    path: "/user/:userId",
    element: <MyPageContainer />,
    children: [
      { path: "", element: <MyPageMoimList /> },
      { path: "done", element: <MyPageMoimList /> },
    ],
  },

  HOST_MOIM_LIST: {
    path: "/host/:userId",
    element: <ManageHostMoimContainer />,
    children: [
      { path: "", element: <ManageHostMoimList /> },
      { path: "done", element: <ManageHostMoimList /> },
    ],
  },

  EDIT_PROFILE: {
    path: "/user/edit/:userId",
    element: <EditProfileContainer />,
    children: [
      { path: "", element: <UpdateHostProfileContainer /> },
      { path: "account", element: <EditAccountContainer /> },
    ],
  },

  MANAGE_MY_MOIM: {
    path: "/moims/user/:userId",
    element: <ManageMyMoimContainer />,
    children: [
      {
        path: "",
        element: <ManageMyMoimReviews />,
      },
    ],
  },

  WRITE_REVIEW: {
    path: "/review/:moimId/",
    element: <WriteReviewContainer />,
  },

  404: {
    path: "*",
    element: <NoPageContainer />,
  },
};

const router = createBrowserRouter(Object.values(SCREENS));

export default router;
