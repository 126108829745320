import React from "react";
import ContentLoader from "react-content-loader";
import styled, { ThemedComponentProps } from "styled-components";
import useContentList from "./ContentList.hook";
import ContentThumbnail from "../../common/components/ContentThumbnail";
import { Grid } from "../../common/styles/commonStyles";

function ContentList() {
  const {
    models: { loading, data },
  } = useContentList();

  return (
    <Wrapper>
      {data?.map((content) => (
        <ContentThumbnail key={content?.id} content={content!} />
      ))}
    </Wrapper>
  );
}

const Wrapper = styled(Grid)<any>(({ theme }: ThemedComponentProps) => ({
  flex: 4,
  gridTemplateColumns: `repeat(2,1fr)`,
  gridAutoRows: "auto",
  gap: `${theme.spacing.regular}px ${theme.spacing.section}px`,
  height: "100%",
  overflowY: "auto",

  [theme.media.down["DESKTOP"]]: {
    flex: 1,
    gridTemplateColumns: "repeat(1,1fr)",
    gap: theme.spacing.section,
  },

  [theme.media.down["TABLET"]]: {
    margin: `0px ${-theme.spacing.regular / 2}px`, // DUE TO MARGIN OF THUMBNAIL
  },
}));

export default ContentList;
