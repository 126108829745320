import imageCompression from "browser-image-compression";
import { format } from "date-fns";
import { useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { SingleValue } from "react-select";
import { IMAGE_COMPRESSION_OPTIONS } from "../../../../utilities/constants";
import { checkIsPdf } from "../../../common/utils/checkIsPdf";
import {
  BusinessInput,
  Gender,
  HostInput,
  useDeleteUserAvatarMutation,
  useGetMyUserProfileQuery,
  useRequestToBeHostMutation,
  useUpdateHostMutation,
  useUpdateUserAvatarMutation,
  useUpdateUserProfileMutation,
} from "../../../../lib/apollo/graphql/generated";

interface State {
  username: string;
  name: string;
  phone: string;
  avatarState: any;
  dateOfBirth: string;
  gender: Gender | null;
  nationality: null | { label: string; value: string };
  introduction: string;
  idNumber: string;
  viewIdNumber: boolean;
  ownerName: string;
  businessName: string;
  registrationNumber: string;
  taxEmail: string;
  registrationMediaState: any;
  bankName: SingleValue<{ label: string; value: string }> | null;
  bankAccount: string;
  bankAccountMediaState: any;
  isBusiness: boolean;
}

const defaultState: State = {
  username: "",
  name: "",
  phone: "",
  avatarState: null,
  dateOfBirth: format(new Date(), "yyyy-MM-dd"),
  gender: null,
  nationality: null,
  introduction: "",
  idNumber: "",
  viewIdNumber: true,
  ownerName: "",
  businessName: "",
  registrationNumber: "",
  taxEmail: "",
  registrationMediaState: null,
  bankName: null,
  bankAccount: "",
  bankAccountMediaState: null,
  isBusiness: false,
};

function useUpdateHostProfileContainer() {
  const { pathname } = useLocation();

  const avatarInputRef = useRef<HTMLInputElement>(null);
  const registrationMediaInputRef = useRef<HTMLInputElement>(null);
  const bankAccountMediaInputRef = useRef<HTMLInputElement>(null);

  const [state, setState] = useState<State>(defaultState);
  const [updateLoading, setUpdateLoading] = useState(false);

  const { loading, data } = useGetMyUserProfileQuery({
    onCompleted: (data) => {
      if (data?.getMyUserProfile) {
        const {
          username,
          name,
          phone,
          dateOfBirth,
          gender,
          nationality,
          host,
        } = data?.getMyUserProfile;

        setState((prev) => ({
          ...prev,
          username: username || "",
          name: name || "",
          phone: phone || "",
          dateOfBirth: dateOfBirth || format(new Date(), "yyyy-MM-dd"),
          gender: gender || null,
          nationality: nationality
            ? { label: nationality?.name, value: nationality?.id }
            : null,
          introduction: host?.introduction || "",
          idNumber: host?.idNumber || "",
          ownerName: host?.business?.ownerName || "",
          businessName: host?.business?.name || "",
          registrationNumber: host?.business?.registrationNumber || "",
          taxEmail: host?.business?.taxEmail || "",
          bankName: host?.bankName
            ? { label: host?.bankName, value: host?.bankName }
            : null,
          bankAccount: host?.bankAccount || "",
          isBusiness: !!host?.business,
        }));
      }
    },
  });

  const [updateUserProfile] = useUpdateUserProfileMutation();
  const [updateHost] = useUpdateHostMutation();
  const [requestToBeHost] = useRequestToBeHostMutation();
  const [updateUserAvatar] = useUpdateUserAvatarMutation();
  const [deleteUserAvatar] = useDeleteUserAvatarMutation();

  function onInputChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: string
  ) {
    const { value } = e.target;

    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function onGenderChange(gender: null | Gender) {
    setState((prev) => ({
      ...prev,
      gender,
    }));
  }

  function onNationalityChange(
    newValue: SingleValue<{ label: string; value: string }>
  ) {
    setState((prev) => ({
      ...prev,
      nationality: newValue,
    }));
  }

  function onIsBusinessChange(newValue: boolean) {
    setState((prev) => ({
      ...prev,
      isBusiness: newValue,
    }));
  }

  function toggleViewIdNumber() {
    setState((prev) => ({
      ...prev,
      viewIdNumber: !state.viewIdNumber,
    }));
  }

  function onBankNameChange(
    newValue: SingleValue<{ label: string; value: string }>
  ) {
    setState((prev) => ({
      ...prev,
      bankName: newValue,
    }));
  }

  function onMediaChange(e: React.ChangeEvent<HTMLInputElement>, key: string) {
    const { files } = e.target;

    if (files?.[0] && files?.[0]?.size > 1048576 * 10) {
      alert("Size limit is 10MB");
      return;
    }

    setState((prev) => ({
      ...prev,
      [key]: files?.[0],
    }));
  }

  function onAvatarDelete() {
    if (data?.getMyUserProfile?.avatar?.id) {
      deleteUserAvatar();
    }

    if (state?.avatarState) {
      setState((prev) => ({
        ...prev,
        avatarState: null,
      }));
    }
  }

  async function onSaveClick() {
    setUpdateLoading(true);

    const {
      username,
      name,
      phone,
      dateOfBirth,
      gender,
      nationality,
      introduction,
      idNumber,
      viewIdNumber,
      isBusiness,
      ownerName,
      businessName,
      registrationNumber,
      taxEmail,
      bankName,
      bankAccount,
      avatarState,
      registrationMediaState,
      bankAccountMediaState,
    } = state;

    if (avatarState) {
      const file = await imageCompression(
        avatarState,
        IMAGE_COMPRESSION_OPTIONS
      );

      await updateUserAvatar({
        variables: {
          avatarInput: {
            file,
          },
        },
      });
    }

    await updateUserProfile({
      variables: {
        userInput: {
          username,
          name,
          phone,
          dateOfBirth,
          gender,
          nationalityId: nationality?.value,
        },
      },
    });

    const isHost = !!data?.getMyUserProfile?.host?.id;
    const isEditProfile = pathname.includes("/user/edit/");

    if (isEditProfile && !isHost) return;

    const hostInput: HostInput = {
      bankAccount,
      bankName: bankName?.value,
      idNumber,
      introduction,
      bankAccountMediaInput: null,
    };

    if (bankAccountMediaState) {
      const file = checkIsPdf(bankAccountMediaState)
        ? bankAccountMediaState
        : await imageCompression(
            bankAccountMediaState,
            IMAGE_COMPRESSION_OPTIONS
          );

      hostInput.bankAccountMediaInput = {
        file,
      };
    }

    let businessInput: BusinessInput | null = null;

    if (isBusiness) {
      businessInput = {
        id: data?.getMyUserProfile?.host?.business?.id || null,
        name: businessName,
        ownerName,
        registrationNumber,
        taxEmail,
        registrationMediaInput: null,
      };

      if (registrationMediaState) {
        const file = checkIsPdf(registrationMediaState)
          ? registrationMediaState
          : await imageCompression(
              registrationMediaState,
              IMAGE_COMPRESSION_OPTIONS
            );

        businessInput.registrationMediaInput = { file };
      }
    }

    if (data?.getMyUserProfile?.host?.id) {
      await updateHost({
        variables: {
          hostInput,
          businessInput,
        },
      });
    } else {
      await requestToBeHost({
        variables: {
          hostInput,
          businessInput,
        },
      });
    }

    setUpdateLoading(false);
  }

  return {
    refs: {
      avatarInputRef,
      registrationMediaInputRef,
      bankAccountMediaInputRef,
    },
    models: {
      loading,
      data: data?.getMyUserProfile,
      state,
      updateLoading,
    },
    operations: {
      onInputChange,
      onGenderChange,
      onNationalityChange,
      onIsBusinessChange,
      toggleViewIdNumber,
      onBankNameChange,
      onMediaChange,
      onAvatarDelete,
      onSaveClick,
    },
  };
}

export default useUpdateHostProfileContainer;
