import React from "react";
import DaumPostcodeEmbed from "react-daum-postcode";
import { useTranslation } from "react-i18next";
import styled, { ThemedComponentProps, useTheme } from "styled-components";
import useAddressInput from "./AddressInput.hook";
import closeIcon from "../../../assets/icons/close_fill_black.svg";
import { DefaultButton } from "../styles/commonStyles";

interface AddressInputProps {
  address: string;
  disabled?: boolean;
  onChange: (address: string) => void;
}

function AddressInput({
  address,
  disabled = false,
  onChange,
}: AddressInputProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    refs: { overlayRef },
    models: { showPopup },
    operations: { toggleShowPopup },
  } = useAddressInput();

  return (
    <div ref={overlayRef}>
      <Wrapper disabled={disabled} onClick={() => toggleShowPopup(true)}>
        <div style={{ color: address ? theme.color.black : "#8c8c8c" }}>
          {address || t("updateMoimBasicInformation.placeAddressPlaceholder1")}
        </div>
      </Wrapper>
      {showPopup && (
        <PopupWrapper>
          <CloseButton onClick={() => toggleShowPopup(false)}>
            <CloseIcon />
          </CloseButton>
          <DaumPostcodeEmbed
            style={{ width: "100%", height: "100%" }}
            onComplete={({ address }) => {
              onChange(address);
              toggleShowPopup(false);
            }}
          />
        </PopupWrapper>
      )}
    </div>
  );
}

interface WrapperProps extends ThemedComponentProps {
  disabled?: boolean;
}

const Wrapper = styled(DefaultButton)<any>(
  ({ theme, disabled }: WrapperProps) => ({
    width: "100%",
    padding: `${theme.spacing.normal}px ${theme.spacing.default}px`,
    border: `1px solid #d0d0d0`,
    backgroundColor: disabled ? theme.color.background : theme.color.white,

    fontSize: theme.font.body3,
    marginBottom: theme.spacing.xxSmall2,
    pointerEvents: disabled ? "none" : "auto",
  })
);

const PopupWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  maxWidth: "90vw",
  height: "70%",
  maxHeight: "80vh",
  padding: `${theme.spacing.section}px 0px`,
  backgroundColor: theme.color.white,

  [theme.media.down["DESKTOP"]]: {
    top: "20%",
    left: theme.spacing.regular,
    right: theme.spacing.regular,
    transform: "none",
    width: `calc(100vw - ${theme.spacing.regular * 2}px)`,
    maxWidth: `calc(100vw - ${theme.spacing.regular * 2}px)`,
  },
}));

const CloseButton = styled(DefaultButton)<any>(
  ({ theme }: ThemedComponentProps) => ({
    position: "absolute",
    top: theme.spacing.small,
    right: theme.spacing.small,
  })
);

const CloseIcon = styled.img.attrs(() => ({
  src: closeIcon,
}))<any>(() => ({
  width: 24,
  height: 24,
}));

export default AddressInput;
