import { useEffect, useRef } from "react";
import { Location, useLocation } from "react-router-dom";

function useAnalytics() {
  const location = useLocation();

  const historyRef = useRef<Location | null>(null);

  useEffect(() => {
    if (historyRef?.current?.pathname !== location?.pathname) {
      historyRef.current = location;
      if ((window as any)?.gtag) {
        (window as any)?.gtag("event", "page_view", {
          page_title: document.title,
          page_path: location.pathname,
        });
      }
    }
  }, [location]);
}

export default useAnalytics;
