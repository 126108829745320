import React from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import styled, { ThemedComponentProps } from "styled-components";
import useUpdateMoimContainer from "./UpdateMoimContainer.hook";
import { MoimStatus } from "../../../../../lib/apollo/graphql/generated";
import Layout from "../../../../common/components/Layout";
import { Row, SectionWrapper } from "../../../../common/styles/commonStyles";
import UpdateMoimNavigationBar from "../components/UpdateMoimNavigationBar";
import UpdateMoimLinks, {
  DESKTOP_UPDATE_MOIM_LINK_BUTTON_WIDTH,
} from "../components/UpdateMoimLinks";

function UpdateMoimContainer() {
  const { t } = useTranslation();

  const {
    models: { isNew, loading, data },
    operations: {
      isStep1Completed,
      isStep2Completed,
      isStep3Completed,
      isRequestDisabled,
    },
  } = useUpdateMoimContainer();

  if (loading) return <div />;

  return (
    <Layout
      showNavigationBar={false}
      customNavigationBar={
        <UpdateMoimNavigationBar
          loading={loading}
          moim={data}
          isNew={isNew}
          isRequestDisabled={isRequestDisabled()}
        />
      }
    >
      <Wrapper>
        <ContentWrapper>
          <UpdateMoimLinks
            isStep1Completed={isStep1Completed()}
            isStep2Completed={isStep2Completed()}
            isStep3Completed={isStep3Completed()}
          />
          <OutletWrapper>
            {data?.status === MoimStatus.Rejected && (
              <RejectedMessageWrapper>
                <div style={{ fontWeight: "bold", color: "#f6421c" }}>
                  {t("updateMoim.rejectedTitle")}
                </div>
                <div>{data?.message}</div>
              </RejectedMessageWrapper>
            )}
            <Outlet />
          </OutletWrapper>
        </ContentWrapper>
      </Wrapper>
    </Layout>
  );
}

const Wrapper = styled(SectionWrapper)<any>(
  ({ theme }: ThemedComponentProps) => ({
    paddingTop: theme.navigationHeight + theme.spacing.section * 2,
    paddingBottom: theme.spacing.section * 2,

    [theme.media.down["DESKTOP"]]: {
      padding: 0,
      paddingTop: theme.mobileNavigationHeight,
    },
  })
);

const ContentWrapper = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  width: "70%",
  alignItems: "flex-start",
  justifyContent: "flex-end",
  gap: theme.spacing.regular,
  position: "relative",

  [theme.media.down["DESKTOP"]]: {
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 0,
  },
}));

const OutletWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: `calc(100% - ${DESKTOP_UPDATE_MOIM_LINK_BUTTON_WIDTH}px)`,
  position: "relative",

  [theme.media.down["DESKTOP"]]: {
    width: "100%",
  },
}));

const RejectedMessageWrapper = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    width: "100%",
    padding: `${theme.spacing.normal}px 0px`,
    backgroundColor: "rgba(246, 66, 28, 0.05)",
    border: `1px solid #f6421c`,
    fontSize: theme.font.caption1,
    textAlign: "center",
    marginBottom: theme.spacing.default,
  })
);

export default UpdateMoimContainer;
