import React from "react";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import AsyncSelect from "react-select/async";
import styled, { ThemedComponentProps, useTheme } from "styled-components";
import useUpdateHostProfileBankAccountInput from "./UpdateHostProfileBankAccountInput.hook";
import { BANK_LIST } from "../../../../utilities/constants";
import { Row } from "../../../common/styles/commonStyles";
import { UpdateMoimInput } from "../../../moim/updateMoim/common/updateMoimCommonStyles";

interface UpdateHostProfileBankAccountInputProps {
  bankName: { label: string; value: string } | null;
  bankAccount: string;
  onBankNameChange: (
    newValue: SingleValue<{
      label: string;
      value: string;
    }>
  ) => void;
  onBankAccountChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function UpdateHostProfileBankAccountInput({
  bankName = null,
  bankAccount,
  onBankNameChange,
  onBankAccountChange,
}: UpdateHostProfileBankAccountInputProps) {
  const theme = useTheme();
  const { t } = useTranslation();

  const {
    operations: { onInputChange },
  } = useUpdateHostProfileBankAccountInput();

  const defaultOptions = BANK_LIST.map((bank) => ({
    label: bank.name,
    value: bank.name,
  }));

  return (
    <Wrapper>
      <Left>
        <AsyncSelect
          styles={{
            container: (baseStyle) => ({
              ...baseStyle,
              fontSize: theme.font.body3,
              width: "100%",
              flexWrap: "nowrap",
            }),
            control: (baseStyle) => ({
              ...baseStyle,
              borderRadius: 0,
              height: 48,
              minWidth: 0,
            }),
            valueContainer: (baseStyle) => ({
              ...baseStyle,
              paddingLeft: theme.spacing.default,
              paddingTop: theme.spacing.normal,
              paddingBottom: theme.spacing.normal,
            }),
            input: (baseStyle) => ({
              ...baseStyle,
              flexGrow: 0,
              margin: 0,
              padding: 0,
            }),
            menuList: (baseStyle) => ({
              ...baseStyle,
              maxHeight: 150,
              fontSize: theme.font.body3,
            }),
          }}
          defaultOptions={defaultOptions}
          value={bankName}
          placeholder={t("updateHostProfile.bankNamePlaceholder")}
          loadOptions={onInputChange}
          onChange={onBankNameChange}
        />
      </Left>
      <Right>
        <UpdateMoimInput
          style={{ height: "100%" }}
          value={bankAccount}
          placeholder={t("updateHostProfile.accountNumber")}
          onChange={onBankAccountChange}
        />
      </Right>
    </Wrapper>
  );
}

const Wrapper = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  alignItems: "stretch",
  gap: theme.spacing.small,
}));

const Left = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: 160,
}));

const Right = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  flex: 2,
  height: 48,
}));

export default UpdateHostProfileBankAccountInput;
