import { format } from "date-fns";
import React, { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import styled, { ThemedComponentProps } from "styled-components";
import useCreateProfileContainer from "./CreateProfileContainer.hook";
import checkedIcon from "../../../../assets/icons/checkbox_checked.svg";
import uncheckedIcon from "../../../../assets/icons/checkbox_unchecked.svg";
import { Gender } from "../../../../lib/apollo/graphql/generated";
import { MINIMUM_USERNAME_CHARACTERS } from "../../../../utilities/constants";
import NationalityInput from "../../../common/components/NationalityInput";
import {
  Col,
  CommonInput,
  CommonInputErrorMessage,
  CommonInputLabel,
  ModalButton,
  ModalTitle,
  Row,
} from "../../../common/styles/commonStyles";

function CreateProfileContainer() {
  const { t } = useTranslation();

  const {
    refs: { usernameInputRef, phoneInputRef },
    models: { state },
    operations: {
      onInputChange,
      onGenderChange,
      onNationalityChange,
      onUsernameFocus,
      onUsernameBlur,
      isSubmitDisabled,
      onSubmit,
    },
  } = useCreateProfileContainer();

  const memoizedUsernameInput = useMemo(
    () => (
      <div>
        <CommonInputLabel>{t("profile.username")}</CommonInputLabel>
        <CommonInput
          ref={usernameInputRef}
          placeholder={t("profile.usernamePlaceholder")}
          value={state.username}
          minLength={MINIMUM_USERNAME_CHARACTERS}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "username")
          }
          onFocus={onUsernameFocus}
          onBlur={onUsernameBlur}
        />
        {state.usernameError && (
          <CommonInputErrorMessage>
            {t(state.usernameError)}
          </CommonInputErrorMessage>
        )}
      </div>
    ),
    [state.username, state.usernameError]
  );

  const memoizedPhoneInput = useMemo(
    () => (
      <div>
        <CommonInputLabel>{t("profile.phone")}</CommonInputLabel>
        <CommonInput
          ref={phoneInputRef}
          type="tel"
          placeholder={t("profile.phonePlaceholder")}
          value={state.phone}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "phone")
          }
        />
      </div>
    ),
    [state.phone]
  );

  const memoizedBirthInput = useMemo(
    () => (
      <div>
        <CommonInputLabel>{t("profile.dateOfBirth")}</CommonInputLabel>
        <CommonInput
          type="date"
          value={state.dateOfBirth}
          max={format(new Date(), "yyyy-MM-dd")}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "dateOfBirth")
          }
        />
      </div>
    ),
    [state.dateOfBirth]
  );

  const memoizedGenderInput = useMemo(
    () => (
      <div>
        <CommonInputLabel>{t("profile.gender")}</CommonInputLabel>

        <GendersRow>
          <GenderRow onClick={() => onGenderChange(Gender.Male)}>
            <CheckBoxIcon
              src={state.gender === Gender.Male ? checkedIcon : uncheckedIcon}
            />
            <div>{t("profile.male")}</div>
          </GenderRow>
          <GenderRow onClick={() => onGenderChange(Gender.Female)}>
            <CheckBoxIcon
              src={state.gender === Gender.Female ? checkedIcon : uncheckedIcon}
            />
            <div>{t("profile.female")}</div>
          </GenderRow>
        </GendersRow>
      </div>
    ),
    [state.gender]
  );

  const memoizedNationalityInput = useMemo(
    () => (
      <NationalityInput
        value={state.nationality}
        onChange={onNationalityChange}
      />
    ),
    [state.nationality]
  );

  return (
    <div>
      <TopMessage>{t("createProfile.topMessage")}</TopMessage>
      <ModalTitle>
        <Trans i18nKey="createProfile.title" />
      </ModalTitle>
      <InputWrapper>
        {memoizedUsernameInput}
        {memoizedPhoneInput}
        {memoizedBirthInput}
        {memoizedGenderInput}
        {memoizedNationalityInput}
      </InputWrapper>
      <ModalButton disabled={isSubmitDisabled()} onClick={onSubmit}>
        {t("common.save")}
      </ModalButton>
    </div>
  );
}

const TopMessage = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.caption1,
  color: theme.color.black,
  marginBottom: theme.spacing.small,
  textAlign: "center",
}));

const InputWrapper = styled(Col)<any>(({ theme }: ThemedComponentProps) => ({
  gap: theme.spacing.default,
  marginBottom: theme.spacing.large,
}));

const GendersRow = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  gap: theme.spacing.middle,
  alignItems: "center",
}));

const GenderRow = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  cursor: "pointer",
  alignItems: "center",
  gap: theme.spacing.xxSmall2,
  fontSize: theme.font.body3,
  color: theme.color.black,
}));

const CheckBoxIcon = styled.img<any>(() => ({
  width: 16,
  height: 16,
}));

export default CreateProfileContainer;
