import React from "react";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import AsyncSelect from "react-select/async";
import { useTheme } from "styled-components";
import useNationalityInput from "./NationalityInput.hook";
import { CommonInputLabel } from "../styles/commonStyles";

interface NationalityInputProps {
  value: SingleValue<{ label: string; value: string }>;
  onChange: (
    newValue: SingleValue<{
      label: string;
      value: string;
    }>
  ) => void;
}

function NationalityInput({ value, onChange }: NationalityInputProps) {
  const theme = useTheme();
  const { t } = useTranslation();

  const {
    models: { data, loading },
    operations: { onInputChange },
  } = useNationalityInput();

  const defaultOptions = loading
    ? []
    : data?.map((nationality) => ({
        label: (nationality?.emoji || "") + " " + nationality?.name,
        value: nationality?.id!,
      }));

  return (
    <div>
      <CommonInputLabel>{t("profile.nationality")}</CommonInputLabel>
      <AsyncSelect
        styles={{
          control: (baseStyle) => ({
            ...baseStyle,
            borderRadius: 0,
          }),
          valueContainer: (baseStyle) => ({
            ...baseStyle,
            paddingLeft: theme.spacing.default,
            paddingTop: theme.spacing.normal,
            paddingBottom: theme.spacing.normal,
          }),
          input: (baseStyle) => ({
            ...baseStyle,
            margin: 0,
            padding: 0,
          }),
          menuList: (baseStyle) => ({
            ...baseStyle,
            maxHeight: 150,
            fontSize: theme.font.body3,
          }),
        }}
        value={value}
        defaultOptions={defaultOptions}
        placeholder={t("profile.nationalityPlaceholder")}
        onChange={onChange}
        loadOptions={onInputChange}
      />
    </div>
  );
}

export default NationalityInput;
