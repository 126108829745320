import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled, { ThemedComponentProps, useTheme } from "styled-components";
import Layout from "../../../common/components/Layout";
import { Col, DefaultButton } from "../../../common/styles/commonStyles";
import { SCREENS } from "../../../routes/utils/router";

function JoinMoimDoneContainer() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Layout>
      <Wrapper>
        <Title>{t("joinMoimDone.title")}</Title>
        <Subtitle>{t("joinMoimDone.subtitle")}</Subtitle>
        <Button
          style={{
            backgroundColor: theme.color.green,
            color: theme.color.white,
          }}
          onClick={() => {
            navigate(SCREENS.MAIN.path!);
          }}
        >
          {t("joinMoimDone.goHome")}
        </Button>
        <Button
          style={{
            backgroundColor: theme.color.white,
            color: theme.color.black,
            border: `1px solid ${theme.color.black}`,
          }}
        >
          {t("joinMoimDone.viewJoinedMoims")}
        </Button>
      </Wrapper>
    </Layout>
  );
}

const Wrapper = styled(Col)<any>(({ theme }: ThemedComponentProps) => ({
  height: `calc(100vh - ${theme.navigationHeight}px)`,
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.color.background,

  [theme.media.down["DESKTOP"]]: {
    height: `calc(100vh - ${theme.mobileNavigationHeight}px)`,
    padding: `0px ${theme.spacing.regular}px`,
  },
}));

const Title = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.body1,
  fontWeight: "bold",
  marginBottom: theme.spacing.default,

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.body2,
    marginBottom: theme.spacing.small,
  },
}));

const Subtitle = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.body3,
  marginBottom: theme.spacing.section * 2,

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.caption1,
    marginBottom: theme.spacing.section,
  },
}));

const Button = styled(DefaultButton)<any>(
  ({ theme }: ThemedComponentProps) => ({
    padding: `${theme.spacing.normal}px 0px`,
    textAlign: "center",
    width: 353,
    fontSize: theme.font.body3,
    fontWeight: "bold",
    marginBottom: theme.spacing.middle,

    [theme.media.down["DESKTOP"]]: {
      marginBottom: theme.spacing.small,
    },
  })
);

export default JoinMoimDoneContainer;
