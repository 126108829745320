import { BANK_LIST } from "../../../../utilities/constants";

function useUpdateHostProfileBankAccountInput() {
  async function onInputChange(inputValue: string) {
    return new Promise<{ label: string; value: string }[]>((resolve) => {
      const filteredBankList = BANK_LIST.filter((bank) =>
        bank.name.includes(inputValue)
      );

      const options = filteredBankList.map((bank) => ({
        label: bank.name,
        value: bank.name,
      }));

      resolve(options);
    });
  }

  return {
    operations: {
      onInputChange,
    },
  };
}

export default useUpdateHostProfileBankAccountInput;
