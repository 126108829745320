import { useRef, useState } from "react";
import useDetectClickOutside from "../hook/useDetectClickOutside";

function useAddressInput() {
  const overlayRef = useRef<HTMLDivElement>(null);

  const [showPopup, setShowPopup] = useState(false);

  useDetectClickOutside(overlayRef, [showPopup], () => toggleShowPopup(false));

  function toggleShowPopup(show: boolean) {
    setShowPopup(show);
  }

  return {
    refs: {
      overlayRef,
    },

    models: {
      showPopup,
    },
    operations: {
      toggleShowPopup,
    },
  };
}

export default useAddressInput;
