import { ReactChildrenProp } from "global";
import React from "react";
import ReactModal from "react-modal";
import styled, { ThemedComponentProps, useTheme } from "styled-components";
import closeIcon from "../../../assets/icons/close_fill_black.svg";
import { useScreenWidthContext } from "../../../providers/ScreenWidthProvider";
import { TouchableOpacity } from "../styles/commonStyles";

interface ModalProps extends ReactChildrenProp {
  isOpen: boolean;
  modalProps?: ReactModal.Props;
  closeModal?: () => void;
}

/**
 * modal component
 * props reference : http://reactcommunity.org/react-modal/
 */
function Modal({ children, isOpen, modalProps, closeModal }: ModalProps) {
  const theme = useTheme();

  const { isPc } = useScreenWidthContext();

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={{
        overlay: {
          zIndex: 10000,
          backgroundColor: "rgba(35, 35, 35, 0.4)",
        },
        content: {
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          width: "fit-content",
          height: "fit-content",
          padding: `${
            isPc ? theme.spacing.regular * 3 : theme.spacing.section
          }px ${isPc ? theme.spacing.section : theme.spacing.default}px ${
            isPc ? theme.spacing.section : theme.spacing.default
          }px`,
        },
      }}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      {...modalProps}
    >
      {!!closeModal && (
        <CloseButton onClick={closeModal}>
          <CloseIcon />
        </CloseButton>
      )}
      {children}
    </ReactModal>
  );
}

const CloseButton = styled(TouchableOpacity)<any>(
  ({ theme }: ThemedComponentProps) => ({
    position: "absolute",
    top: theme.spacing.default,
    right: theme.spacing.default,
  })
);

const CloseIcon = styled.img.attrs(() => ({
  src: closeIcon,
}))<any>(() => ({
  width: 28,
  height: 28,
}));

ReactModal.setAppElement("#root");

export default Modal;
