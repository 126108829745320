import React, { useState } from "react";
import styled, { ThemedComponentProps } from "styled-components";
import arrowIcon from "../../../../../assets/icons/arrow_right_s_fill.svg";
import { Row } from "../../../../common/styles/commonStyles";

interface UpdateMoimIntroFaqProps {
  faq: {
    __typename?: "CreateMoimFaq";
    id: string;
    question: string;
    answer: string;
    active: boolean;
    languageCode: string;
    createdAt: any;
    updatedAt: any;
  };
}

function UpdateMoimIntroFaq({ faq }: UpdateMoimIntroFaqProps) {
  const [show, setShow] = useState(false);

  return (
    <div>
      <Wrapper onClick={() => setShow(!show)}>
        <ArrowIcon show={show} />
        <div>{faq?.question}</div>
      </Wrapper>
      {show && <Answer>{faq?.answer}</Answer>}
    </div>
  );
}

const Wrapper = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  alignItems: "center",
  width: "100%",
  fontSize: theme.font.body3,
  fontWeight: "bold",
  cursor: "pointer",
}));

interface ArrowIconProps extends ThemedComponentProps {
  show?: boolean;
}

const ArrowIcon = styled.img.attrs(() => ({
  src: arrowIcon,
}))<any>(({ theme, show }: ArrowIconProps) => ({
  width: 24,
  height: 24,
  transform: show ? "rotate(90deg)" : "rotate(0deg)",
}));

const Answer = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.body3,
  fontWeight: "bold",
  lineHeight: "24px",
  textAlign: "left",
  width: "100%",
}));

export default UpdateMoimIntroFaq;
