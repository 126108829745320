import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled, { ThemedComponentProps } from "styled-components";
import useUpdateMoimIntroContainer from "./UpdateMoimIntroContainer.hook";
import arrowIcon from "../../../../../assets/icons/arrow_right_black.svg";
import benefit1Image from "../../../../../assets/images/moim_benefit1.webp";
import benefit2Image from "../../../../../assets/images/moim_benefit2.webp";
import benefit3Image from "../../../../../assets/images/moim_benefit3.webp";
import benefit4Image from "../../../../../assets/images/moim_benefit4.webp";
import topImageMobile from "../../../../../assets/images/moim_intro_top_mobile.webp";
import topImage from "../../../../../assets/images/moim_intro_top.webp";
import Layout from "../../../../common/components/Layout";
import { SCREENS } from "../../../../routes/utils/router";
import UpdateMoimIntroFaq from "../components/UpdateMoimIntroFaq";
import {
  Col,
  DefaultButton,
  Grid,
  Row,
} from "../../../../common/styles/commonStyles";

const PROCESS = [
  {
    id: 1,
    text: "updateMoimIntro.process1",
  },
  {
    id: 101,
    text: "arrow",
  },
  {
    id: 2,
    text: "updateMoimIntro.process2",
  },
  {
    id: 102,
    text: "arrow",
  },
  {
    id: 3,
    text: "updateMoimIntro.process3",
  },
  {
    id: 103,
    text: "arrow",
  },
  {
    id: 4,
    text: "updateMoimIntro.process4",
  },
];

const BENEFIT = [
  { text: "updateMoimIntro.benefit1", image: benefit1Image },
  { text: "updateMoimIntro.benefit2", image: benefit2Image },
  { text: "updateMoimIntro.benefit3", image: benefit3Image },
  { text: "updateMoimIntro.benefit4", image: benefit4Image },
];

function UpdateMoimIntroContainer() {
  const { t } = useTranslation();

  const {
    models: { loading, data },
  } = useUpdateMoimIntroContainer();

  if (loading) return <div />;

  return (
    <Layout>
      <Wrapper>
        <Top>{t("updateMoimIntro.top")}</Top>
        <BottomWrapper>
          <SectionTitle>{t("updateMoimIntro.processTitle")}</SectionTitle>
          <ProcessWrapper>
            {PROCESS.map((process) =>
              process.text.includes("arrow") ? (
                <RightArrow key={process.id} />
              ) : (
                <ProcessCard key={process.id}>
                  <ProcessStep>STEP{process.id}</ProcessStep>
                  <div>{t(process.text)}</div>
                </ProcessCard>
              )
            )}
          </ProcessWrapper>

          <SectionTitle>{t("updateMoimIntro.benefitTitle")}</SectionTitle>
          <BenefitWrapper>
            {BENEFIT.map((benefit, index) => (
              <BenefitCard key={benefit.text + index}>
                <BenefitImage src={benefit.image} />
                <div>{t(benefit.text)}</div>
              </BenefitCard>
            ))}
          </BenefitWrapper>

          {!!data && data?.length > 0 && (
            <>
              <SectionTitle>{t("updateMoimIntro.faqTitle")}</SectionTitle>
              <FaqWrapper>
                {data?.map((faq) => (
                  <UpdateMoimIntroFaq key={faq?.id} faq={faq!} />
                ))}
              </FaqWrapper>
            </>
          )}
        </BottomWrapper>

        <Link to={SCREENS.UPDATE_MOIM.path + "/new" + "/host"}>
          <SubmitButton>{t("updateMoimIntro.submitTitle")}</SubmitButton>
        </Link>
      </Wrapper>
    </Layout>
  );
}

const Wrapper = styled.div<any>(() => ({
  position: "relative",
  width: "100%",
}));

const Top = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  background: `url(${topImage})`,
  width: "100%",
  height: 240,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  textAlign: "center",
  fontSize: theme.font.head2,
  fontWeight: "bold",
  lineHeight: "48px",
  alignItems: "center",
  justifyContent: "center",
  color: theme.color.white,
  marginBottom: theme.spacing.regular * 3,
  marginTop: -theme.spacing.xxSmall2,
  textShadow: `${theme.color.black} 1px 0 1px`,

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.body1,
  },
}));

const BottomWrapper = styled(Col)<any>(({ theme }: ThemedComponentProps) => ({
  margin: "0px auto",
  maxWidth: 800,
  width: "100%",
  alignItems: "center",

  [theme.media.down["DESKTOP"]]: {
    padding: `${theme.spacing.section}px ${theme.spacing.regular}px`,
  },
}));

const SectionTitle = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.body1,
  fontWeight: "bold",
  marginBottom: theme.spacing.section,
  textAlign: "center",

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.body2,
  },
}));

const ProcessWrapper = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  alignItems: "center",
  width: "100%",
  marginBottom: theme.spacing.section * 3,

  [theme.media.down["DESKTOP"]]: {
    flexDirection: "column",
    marginBottom: theme.spacing.regular * 3,
  },
}));

const ProcessCard = styled(Col)<any>(({ theme }: ThemedComponentProps) => ({
  flex: 1,
  padding: `${theme.spacing.regular}px 0px`,
  alignItems: "center",
  backgroundColor: theme.color.white,
  border: `1px solid ${theme.color.border}`,
  gap: theme.spacing.xxSmall,
  fontSize: theme.font.body3,
  fontWeight: "bold",
  textAlign: "center",

  [theme.media.down["DESKTOP"]]: {
    width: 180,
  },
}));

const ProcessStep = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.caption2,
  color: theme.color.orange,
  fontWeight: "bold",
}));

const RightArrow = styled.img.attrs(() => ({
  src: arrowIcon,
}))<any>(({ theme }: ThemedComponentProps) => ({
  width: 24,
  height: 24,

  [theme.media.down["DESKTOP"]]: {
    transform: "rotate(90deg)",
  },
}));

const BenefitWrapper = styled(Grid)<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  gridTemplateColumns: "repeat(2,1fr)",
  gridAutoRows: "auto",
  marginBottom: theme.spacing.section * 3,
  gap: `${theme.spacing.section}px ${theme.spacing.middle}px`,

  [theme.media.down["DESKTOP"]]: {
    gridTemplateColumns: "1fr",
    gap: theme.spacing.default,
    marginBottom: theme.spacing.regular * 3,
  },
}));

const BenefitCard = styled(Col)<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.body3,
  alignItems: "center",
  fontWeight: "bold",
  lineHeight: "24px",
  gap: theme.spacing.middle,
  textAlign: "center",

  [theme.media.down["DESKTOP"]]: {
    gap: theme.spacing.default,
  },
}));

const BenefitImage = styled.img<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  height: 198,
  objectFit: "cover",

  [theme.media.down["DESKTOP"]]: {
    height: 170,
  },
}));

const FaqWrapper = styled(Col)<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  gap: theme.spacing.middle,
  marginBottom: theme.spacing.section * 3,

  [theme.media.down["DESKTOP"]]: {
    marginBottom: theme.spacing.regular * 3,
  },
}));

const SubmitButton = styled(DefaultButton)<any>(
  ({ theme }: ThemedComponentProps) => ({
    position: "sticky",
    bottom: "4%",
    margin: "0px auto",
    width: "100%",
    maxWidth: 800,
    backgroundColor: theme.color.orange,
    padding: `${theme.spacing.normal}px 0px`,
    fontSize: theme.font.body3,
    fontWeight: "bold",
    textAlign: "center",
    boxShadow: "0px 0px 6px rgba(0,0,0,0.15)",
    marginBottom: theme.spacing.middle,

    [theme.media.down["DESKTOP"]]: {
      marginBottom: 0,
    },
  })
);

export default UpdateMoimIntroContainer;
