import { isAfter } from "date-fns";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled, { ThemedComponentProps, useTheme } from "styled-components";
import LazyImage from "./LazyImage";
import { SCREENS } from "../../routes/utils/router";
import useResizeImageHeight from "../hook/useResizeImageHeight";
import { DefaultButton, NewTag } from "../styles/commonStyles";
import { showNewTag } from "../utils/showNewTag";

export interface ContentThumbnailProps {
  content?: {
    __typename?: "Content";
    id: string;
    title: string;
    createdAt: any;
    updatedAt: any;
    thumbnail: { __typename?: "Media"; id: string; uri: string };
    themes: Array<{ __typename?: "Theme"; id: string; name: string } | null>;
    fields: Array<{ __typename?: "Field"; id: string; name: string } | null>;
  };
}

function ContentThumbnail({ content }: ContentThumbnailProps) {
  const theme = useTheme();
  const navigate = useNavigate();

  const {
    refs: { wrapperRef },
    models: { height },
  } = useResizeImageHeight();

  return (
    <Wrapper ref={wrapperRef} onClick={() => navigate("/" + content?.id!)}>
      {showNewTag(content?.createdAt) && <NewTag alt={content?.title} />}
      <LazyImage
        uri={content?.thumbnail?.uri!}
        alt={content?.title}
        height={height}
        style={{
          borderRadius: 24,
          border: `1px solid ${theme.color.border}`,
        }}
      />
      <Title>{content?.title || ""}</Title>
    </Wrapper>
  );
}

const Wrapper = styled(DefaultButton)<any>(
  ({ theme }: ThemedComponentProps) => ({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: theme.spacing.small,

    "&:hover": {
      transform: "scale(0.98)",
      transition: "transform 0.05s linear",
    },

    [theme.media.down["TABLET"]]: {
      margin: `0px ${theme.spacing.regular / 2}px`,
    },
  })
);

const Title = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.body2,
  fontWeight: "bold",
  color: theme.color.black,
  flex: 1,

  [theme.media.down["TABLET"]]: {
    margin: `0px auto`,
    width: "90%",
    textAlign: "center",
    fontSize: theme.font.body3,
  },
}));

export default ContentThumbnail;
