import { differenceInHours, differenceInMinutes } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";
import styled, { ThemedComponentProps } from "styled-components";
import MoimContentImage from "./MoimContentImage";
import useMoimContentSection from "./MoimContentSection.hook";
import MoimStickyInfo from "./MoimStickyInfo";
import squareArrowLeft from "../../../../assets/icons/squre_left.svg";
import squareArrowRight from "../../../../assets/icons/squre_right.svg";
import { useScreenWidthContext } from "../../../../providers/ScreenWidthProvider";
import Avatar from "../../../common/components/Avatar";
import Carousel from "../../../common/components/Carousel";
import { DefaultButton, Grid, Row } from "../../../common/styles/commonStyles";
import { getMoimcontentByLanguage } from "../../../common/utils/getMoimContentByLanguage";
import {
  Gender,
  JoinStatus,
  MoimStatus,
} from "../../../../lib/apollo/graphql/generated";
import {
  MoimSectionTitle,
  MoimSectionValue,
  MoimStatusText,
} from "../../common/styles/commonMoimStyles";

export interface MoimContentSectionProps {
  moim: {
    __typename?: "Moim";
    id: string;
    moimStartAt?: any | null;
    moimEndAt?: any | null;
    maxPersons?: number | null;
    minPersons?: number | null;
    genderRestriction?: Gender | null;
    minAge?: number | null;
    maxAge?: number | null;
    status: MoimStatus;
    message?: string | null;
    viewCount: number;
    createdAt: any;
    updatedAt: any;
    host: {
      __typename?: "Host";
      id: string;
      introduction?: string | null;
      user: {
        __typename?: "UserProfile";
        id: string;
        username: string;
        name?: string | null;
        email?: string | null;
        phone?: string | null;
        dateOfBirth?: any | null;
        gender?: Gender | null;
        createdAt: any;
        updatedAt: any;
        nationality?: {
          __typename?: "Nationality";
          id: string;
          name: string;
        } | null;
        avatar?: { __typename?: "Media"; id: string; uri: string } | null;
      };
    };
    place?: {
      __typename?: "Place";
      id: string;
      name?: string | null;
      address?: string | null;
      addressDetail?: string | null;
      isPrivate: boolean;
    } | null;
    moimContents?: Array<{
      __typename?: "MoimContent";
      id: string;
      languageCode: string;
      title?: string | null;
      introduction?: string | null;
      detail?: string | null;
      price?: number | null;
      createdAt: any;
      updatedAt: any;
    } | null> | null;
    coverPhoto?: {
      __typename?: "Media";
      id: string;
      name: string;
      uri: string;
    } | null;
    media: Array<{
      __typename?: "Media";
      id: string;
      name: string;
      uri: string;
    } | null>;
    participants: Array<{
      __typename?: "Join";
      id: string;
      status: JoinStatus;
      createdAt: any;
      updatedAt: any;
      moim: { __typename?: "Moim"; id: string };
      user: { __typename?: "UserProfile"; id: string };
    } | null>;
  };
  carouselWrapperRef?: React.RefObject<HTMLDivElement>;
}

function MoimContentSection({
  moim,
  carouselWrapperRef,
}: MoimContentSectionProps) {
  const { t, i18n } = useTranslation();
  const { isPc } = useScreenWidthContext();

  const {
    status,
    moimContents,
    coverPhoto,
    media,
    moimStartAt,
    moimEndAt,
    host,
  } = moim;

  const content = getMoimcontentByLanguage(i18n.resolvedLanguage, moimContents);

  const otherLanguageContent = getMoimcontentByLanguage(
    i18n.resolvedLanguage === "ko" ? "en" : "ko",
    moimContents
  );

  const {
    refs: { carouselRef },
    models: { refundPolicy },
    operations: { onThumbnailClick, onArrowClick },
  } = useMoimContentSection();

  return (
    <Wrapper>
      <div>
        <MoimStatusText status={status}>{t("moim." + status)}</MoimStatusText>
        <Title>{content?.title}</Title>
        {!!otherLanguageContent?.title && (
          <Subtitle>{otherLanguageContent?.title}</Subtitle>
        )}
      </div>
      {!!coverPhoto?.uri && (
        <CarouselWrapper ref={carouselWrapperRef}>
          <Carousel
            carouselRef={carouselRef}
            settings={{
              slidesToShow: 1,
            }}
          >
            <MoimContentImage uri={coverPhoto?.uri} />
            {media &&
              media?.map((item) => (
                <MoimContentImage key={item?.id} uri={item?.uri!} />
              ))}
          </Carousel>

          <>
            <LeftArrow onClick={() => onArrowClick("left")}>
              <CarouselArrowIcon src={squareArrowLeft} />
            </LeftArrow>
            <RightArrow onClick={() => onArrowClick("right")}>
              <CarouselArrowIcon src={squareArrowRight} />
            </RightArrow>
          </>
        </CarouselWrapper>
      )}
      {isPc ? (
        !!coverPhoto?.uri && (
          <Thumbnails
            style={{ gridTemplateColumns: `repeat(${1 + media?.length}, 1fr)` }}
          >
            <MoimContentImage
              uri={coverPhoto?.uri}
              onClick={() => onThumbnailClick(0)}
            />
            {media &&
              media?.map((item, index) => (
                <MoimContentImage
                  key={item?.id}
                  uri={item?.uri!}
                  onClick={() => onThumbnailClick(index + 1)}
                />
              ))}
          </Thumbnails>
        )
      ) : (
        <MoimStickyInfo moim={moim} />
      )}
      <MoimSectionTitle>{t("moim.introductionTitle")}</MoimSectionTitle>
      <MoimSectionValue>{content?.introduction}</MoimSectionValue>
      <MoimSectionTitle>
        {t("moim.detailTitle")}
        {!!moimStartAt &&
          !!moimEndAt &&
          `(${
            differenceInHours(new Date(moimEndAt), new Date(moimStartAt), {
              roundingMethod: "round",
            }) > 0
              ? differenceInHours(new Date(moimEndAt), new Date(moimStartAt), {
                  roundingMethod: "round",
                }) + t("common.hours")
              : differenceInMinutes(
                  new Date(moimEndAt),
                  new Date(moimStartAt),
                  {
                    roundingMethod: "round",
                  }
                ) + t("common.minutes")
          })`}
      </MoimSectionTitle>
      <MoimSectionValue>{content?.detail}</MoimSectionValue>
      <MoimSectionTitle>{t("moim.hostTitle")}</MoimSectionTitle>
      <MoimSectionValue>
        <HostRow>
          {!!host?.user?.avatar?.uri && (
            <Avatar uri={host?.user?.avatar?.uri} diameter={64} />
          )}
          <div>
            <div>
              <strong>{host?.user?.username}</strong>
            </div>
            <div>{host?.user?.nationality?.name}</div>
          </div>
        </HostRow>
        <div>{host?.introduction}</div>
      </MoimSectionValue>
      {/* <MoimSectionTitle>{t("moim.refundTitle")}</MoimSectionTitle>
      <MoimSectionValue>{refundPolicy}</MoimSectionValue> */}
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: `${theme.spacing.section * 2}px 0px`,

  [theme.media.down["DESKTOP"]]: {
    padding: `${theme.spacing.section}px ${theme.spacing.regular}px`,
    width: "100%",
  },
}));

const Title = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.head2,
  fontWeight: "bold",
  marginBottom: theme.spacing.small,

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.body1,
  },
}));

const Subtitle = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.body1,
  marginBottom: theme.spacing.section,

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.body3,
    marginBottom: theme.spacing.regular,
  },
}));

const CarouselWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  position: "relative",

  [theme.media.down["DESKTOP"]]: {
    margin: `0px ${-theme.spacing.regular}px`,
    width: `calc(100% + ${theme.spacing.regular * 2}px)`,
    marginBottom: theme.spacing.section,
  },
}));

const LeftArrow = styled(DefaultButton)<any>(
  ({ theme }: ThemedComponentProps) => ({
    position: "absolute",
    top: "50%",
    left: "5%",
    transform: "translateY(-50%)",
    width: 50,
    height: 50,

    [theme.media.down["DESKTOP"]]: {
      left: theme.spacing.regular,
      width: 28,
      height: 28,
    },
  })
);

const CarouselArrowIcon = styled.img<any>(() => ({
  width: "100%",
  height: "100%",
}));

const RightArrow = styled(DefaultButton)<any>(
  ({ theme }: ThemedComponentProps) => ({
    position: "absolute",
    top: "50%",
    right: "5%",
    transform: "translateY(-50%)",
    width: 50,
    height: 50,

    [theme.media.down["DESKTOP"]]: {
      right: theme.spacing.regular,
      width: 28,
      height: 28,
    },
  })
);

const Thumbnails = styled(Grid)<any>(({ theme }: ThemedComponentProps) => ({
  alignItems: "center",
  gap: theme.spacing.default,
  width: "100%",
  marginBottom: theme.spacing.section * 2,
}));

const HostRow = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  alignItems: "center",
  gap: theme.spacing.default,
  marginBottom: theme.spacing.default,
}));

export default MoimContentSection;
