import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  firebaseReAuthenticate,
  updateFirebasePassword,
} from "../../../../lib/firebase";

function useEditAccountContainer() {
  const { t } = useTranslation();

  const newPasswordInputRef = useRef<HTMLInputElement>(null);
  const confirmPasswordInputRef = useRef<HTMLInputElement>(null);

  const [state, setState] = useState({
    password: "",
    newPassword: "",
    confirmPassword: "",
    passwordError: "",
    newPasswordError: "",
    confirmPasswordError: "",
  });

  const [updating, setUpdating] = useState(false);

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>, key: string) {
    const { value } = e.target;

    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function onFocus(key: string) {
    if (key === "password" && !!state?.passwordError) {
      setState((prev) => ({
        ...prev,
        passwordError: "",
      }));
    }

    if (key === "newPassword" && !!state?.newPasswordError) {
      setState((prev) => ({
        ...prev,
        newPasswordError: "",
      }));
    }

    if (key === "confirmPassword" && !!state?.confirmPasswordError) {
      setState((prev) => ({
        ...prev,
        confirmPasswordError: "",
      }));
    }
  }

  async function onBlur(key: string) {
    if (key === "password" && !(state.password.length > 5)) {
      setState((prev) => ({
        ...prev,
        passwordError: "error.passwordLengthError",
      }));
    }

    if (key === "password" && state?.password?.length > 5) {
      try {
        await firebaseReAuthenticate(state?.password);
      } catch (error: any) {
        if (error?.message.includes("wrong")) {
          setState((prev) => ({
            ...prev,
            passwordError: "error.wrongPassword",
          }));
        }
      }
    }

    if (key === "newPassword" && !(state.newPassword.length > 5)) {
      setState((prev) => ({
        ...prev,
        newPasswordError: "error.passwordLengthError",
      }));
    }

    if (key === "confirmPassword") {
      if (!(state.confirmPassword.length > 5)) {
        setState((prev) => ({
          ...prev,
          confirmPasswordError: "error.passwordLengthError",
        }));
      } else if (state.newPassword !== state.confirmPassword) {
        setState((prev) => ({
          ...prev,
          confirmPasswordError: "error.confirmPasswordNotMatchedError",
        }));
      }
    }
  }

  function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>, key: string) {
    if (e.key === "Enter") {
      if (key === "password") {
        newPasswordInputRef?.current?.focus();
      } else if (key === "newPassword") {
        confirmPasswordInputRef?.current?.focus();
      } else {
        if (!isSubmitDisabled()) {
          onSubmit();
        }
      }
    }
  }

  function isSubmitDisabled() {
    const isValidPassword = state.password.length > 5 && !state.passwordError;
    const isValidNewPassword =
      state.newPassword.length > 5 && !state.newPasswordError;
    const isValidConfirmPassword =
      state.confirmPassword.length > 5 &&
      state.newPassword === state.confirmPassword &&
      !state.newPasswordError;

    return !(isValidPassword && isValidNewPassword && isValidConfirmPassword);
  }

  async function onSubmit() {
    setUpdating(true);
    try {
      await updateFirebasePassword(state?.newPassword);
      console.log("here");
      alert(t("editProfile.updateDone"));
    } catch (error) {
      console.log(error);
    }

    setUpdating(false);
  }

  return {
    refs: {
      newPasswordInputRef,
      confirmPasswordInputRef,
    },
    models: {
      state,
      updating,
    },
    operations: {
      onInputChange,
      onFocus,
      onBlur,
      onKeyDown,
      isSubmitDisabled,
      onSubmit,
    },
  };
}

export default useEditAccountContainer;
