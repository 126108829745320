import {
  useSearchNationalityLazyQuery,
  useSearchNationalityQuery,
} from "../../../lib/apollo/graphql/generated";

function useNationalityInput() {
  const { data, loading, error } = useSearchNationalityQuery({
    variables: {
      query: "",
    },
  });

  const [searchNationality] = useSearchNationalityLazyQuery();

  async function onInputChange(inputValue: string) {
    return new Promise<{ label: string; value: string }[]>(async (resolve) => {
      const { data } = await searchNationality({
        variables: {
          query: inputValue,
        },
      });

      let options = [{ label: "", value: "" }];

      if (data?.searchNationality && data?.searchNationality?.length > 0) {
        options = data?.searchNationality?.map((nationality) => ({
          label: (nationality?.emoji || "") + " " + nationality?.name,
          value: nationality?.id!,
        }));
      }

      resolve(options);
    });
  }

  return {
    models: {
      data: data?.searchNationality,
      loading,
    },
    operations: {
      onInputChange,
    },
  };
}

export default useNationalityInput;
