import { ApolloProvider } from "@apollo/client";
import React from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "styled-components";
import App from "./App";
import "./index.css";
import client from "./lib/apollo";
import "./lib/i18n";
import AuthProvider from "./providers/AuthProvider";
import ContentListProvider from "./providers/ContentListProvider";
import ScreenWidthProvider from "./providers/ScreenWidthProvider";
import reportWebVitals from "./reportWebVitals";
import { GlobalStyle, theme } from "./utilities/theme";

const app: any = (
  <React.StrictMode>
    <HelmetProvider>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <GlobalStyle />
            <ScreenWidthProvider>
              <ContentListProvider>
                <App />
              </ContentListProvider>
            </ScreenWidthProvider>
          </AuthProvider>
        </ThemeProvider>
      </ApolloProvider>
    </HelmetProvider>
    <ToastContainer />
  </React.StrictMode>
);

ReactDOM.hydrateRoot(document.getElementById("root") as Element, app);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
