import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled, { ThemedComponentProps } from "styled-components";
import useWriteReviewContainer from "./WriteReviewContainer.hook";
import { useAuthContext } from "../../../../providers/AuthProvider";
import { useScreenWidthContext } from "../../../../providers/ScreenWidthProvider";
import Layout from "../../../common/components/Layout";
import MoimDetailTopRow from "../../../common/components/MoimDetailTopRow";
import { Col, DefaultButton, Row } from "../../../common/styles/commonStyles";
import { MyPageTitle } from "../../myPage/common/styles/MyPageCommonStyles";

const MAX_LENGTH = 150;

function WriteReviewContainer() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isPc } = useScreenWidthContext();

  const { userProfile } = useAuthContext();

  const {
    models: { loading, data, body, updating },
    operations: { onBodyChange, onSubmit },
  } = useWriteReviewContainer();

  if (!userProfile) return <div />;

  if (loading) return <div />;

  return (
    <Layout>
      <Wrapper>
        <MyPageTitle>{t("writeReview.title")}</MyPageTitle>
        <MoimDetailTopRow
          coverPhoto={data?.coverPhoto}
          moimContents={data?.moimContents}
          moimStartAt={data?.moimStartAt}
          status={data?.status!}
        />

        <LabelRow>
          <div>
            <strong>{t("writeReview.label")}</strong>
          </div>
          <div style={{ color: "#8c8c8c" }}>
            {body?.length || 0}/{MAX_LENGTH}
            {t("common.characters")}
          </div>
        </LabelRow>
        <TextArea
          placeholder={t("writeReview.placeholder")}
          value={body}
          onChange={onBodyChange}
        />
        <SubmitButton
          disabled={updating || body?.length < 1}
          onClick={onSubmit}
        >
          {t("writeReview.done")}
        </SubmitButton>
      </Wrapper>
    </Layout>
  );
}

const Wrapper = styled(Col)<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  maxWidth: 800,
  padding: `${theme.spacing.regular * 3}px 0px`,

  [theme.media.down["DESKTOP"]]: {
    padding: `${theme.spacing.middle}px ${theme.spacing.regular}px`,
  },
}));

const LabelRow = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  alignItems: "center",
  justifyContent: "space-between",
  fontSize: theme.font.caption1,
  marginTop: theme.spacing.section,
}));

const TextArea = styled.textarea<any>(({ theme }: ThemedComponentProps) => ({
  padding: `${theme.spacing.normal}px ${theme.spacing.default}px`,
  backgroundColor: theme.color.white,
  width: "100%",
  height: 120,
  resize: "none",
  fontSize: theme.font.body3,
  lineHeight: "24px",
  marginBottom: theme.spacing.regular * 3,
  outline: "none",
  border: `1px solid #d0d0d0`,
}));

interface SubmitButtonProps extends ThemedComponentProps {
  disabled?: boolean;
}

const SubmitButton = styled(DefaultButton)<any>(
  ({ theme, disabled }: SubmitButtonProps) => ({
    width: 300,
    backgroundColor: theme.color.green,
    padding: `${theme.spacing.normal}px 0px`,
    textAlign: "center",
    fontSize: theme.font.body3,
    fontWeight: "bold",
    color: theme.color.white,
    margin: "0px auto",
    pointerEvents: disabled ? "none" : "auto",
    opacity: disabled ? 0.7 : 1,

    [theme.media.down["DESKTOP"]]: {
      width: "100%",
    },
  })
);

export default WriteReviewContainer;
