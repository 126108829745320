import { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  MoimStatus,
  useGetMoimLazyQuery,
  useGetMyUserProfileQuery,
} from "../../../../../lib/apollo/graphql/generated";

function useUpdateMoimContainer() {
  const { moimId } = useParams();

  const isNew = moimId === "new";

  const { loading: profileLoading, data: profileData } =
    useGetMyUserProfileQuery();

  const [getMoim, { data, loading }] = useGetMoimLazyQuery();

  useEffect(() => {
    if (!isNew) {
      getMoim({
        variables: {
          moimId: moimId!,
        },
      });
    }
  }, [moimId]);

  function isStep1Completed() {
    if (profileData?.getMyUserProfile?.host) {
      const { username, name, phone, dateOfBirth, gender, nationality, host } =
        profileData?.getMyUserProfile;

      return (
        !!username &&
        !!name &&
        !!phone &&
        !!dateOfBirth &&
        !!gender &&
        !!nationality?.name &&
        !!host?.introduction &&
        !!host?.bankName &&
        !!host?.bankAccount &&
        !!host?.bankAccountMedia?.uri &&
        (!!host?.idNumber ||
          (!!host?.business?.id &&
            !!host?.business?.name &&
            !!host?.business?.ownerName &&
            !!host?.business?.registrationMedia?.uri &&
            !!host?.business?.registrationNumber &&
            !!host?.business?.taxEmail))
      );
    }

    return false;
  }

  function isStep2Completed() {
    if (!isNew && data?.getMoim) {
      const { moimStartAt, moimEndAt, place, moimContents } = data?.getMoim;

      return (
        !!moimStartAt &&
        !!moimEndAt &&
        !!place?.id &&
        !!place?.name &&
        !!place?.address &&
        (!!moimContents?.[0]?.price || !!moimContents?.[1]?.price)
      );
    }

    return false;
  }

  function isStep3Completed() {
    if (!isNew && data?.getMoim) {
      const { coverPhoto, moimContents, media } = data?.getMoim;

      return (
        !!coverPhoto &&
        (!!moimContents?.[0]?.title || !!moimContents?.[1]?.title) &&
        (!!moimContents?.[0]?.introduction ||
          !!moimContents?.[1]?.introduction) &&
        (!!moimContents?.[0]?.detail || !!moimContents?.[1]?.detail) &&
        media?.length > 0
      );
    }

    return false;
  }

  function isRequestDisabled() {
    if (
      !data?.getMoim ||
      (data?.getMoim &&
        !(
          data?.getMoim?.status === MoimStatus.Editing ||
          data?.getMoim?.status === MoimStatus.Rejected
        ))
    ) {
      return true;
    }

    return !(isStep1Completed() && isStep2Completed() && isStep3Completed());
  }

  return {
    models: {
      isNew,
      loading: loading || profileLoading,
      data: data?.getMoim,
    },
    operations: {
      isStep1Completed,
      isStep2Completed,
      isStep3Completed,
      isRequestDisabled,
    },
  };
}

export default useUpdateMoimContainer;
