import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled, { ThemedComponentProps, useTheme } from "styled-components";
import useSignInContainer from "./SignInContainer.hook";
import { useAuthContext } from "../../../../providers/AuthProvider";
import {
  Col,
  CommonInput,
  CommonInputErrorMessage,
  CommonInputLabel,
  ModalButton,
  ModalTitle,
  TouchableOpacity,
} from "../../../common/styles/commonStyles";
import {
  AuthMessage,
  AuthMessageButton,
} from "../../common/styles/authCommonStyles";

function SignInContainer() {
  const theme = useTheme();

  const { t } = useTranslation();
  const { toggleSignUp, toggleSignIn } = useAuthContext();

  const {
    refs: { emailInputRef, passwordInputRef },
    models: { state },
    operations: {
      onInputChange,
      onFocus,
      onBlur,
      onKeyDown,
      onFindPasswordClick,
      isSubmitDisabled,
      onSignInClick,
    },
  } = useSignInContainer();

  const memoizedEmailInput = useMemo(
    () => (
      <div>
        <CommonInputLabel>{t("auth.email")}</CommonInputLabel>
        <CommonInput
          ref={emailInputRef}
          type="email"
          placeholder="fland@fland.com"
          value={state.email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "email")
          }
          onFocus={() => onFocus("email")}
          onBlur={() => onBlur("email")}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
            onKeyDown(e, "email")
          }
        />
        {state.emailError && (
          <CommonInputErrorMessage>
            {t(state.emailError)}
          </CommonInputErrorMessage>
        )}
      </div>
    ),
    [state.email, state.emailError]
  );

  const memoizedPasswordInput = useMemo(
    () => (
      <div>
        <CommonInputLabel>{t("auth.password")}</CommonInputLabel>
        <CommonInput
          ref={passwordInputRef}
          type="password"
          placeholder={t("auth.passwordPlaceholder")}
          minLength={6}
          value={state.password}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e, "password")
          }
          onFocus={() => onFocus("password")}
          onBlur={() => onBlur("password")}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
            onKeyDown(e, "password")
          }
        />
        {state.passwordError && (
          <CommonInputErrorMessage>
            {t(state.passwordError)}
          </CommonInputErrorMessage>
        )}
      </div>
    ),
    [state.password, state.passwordError]
  );

  return (
    <div>
      <ModalTitle>{t("common.signIn")}</ModalTitle>
      <InputWrapper>
        {memoizedEmailInput}
        {memoizedPasswordInput}
        <FindPasswordButton onClick={onFindPasswordClick}>
          {t("auth.findPassword")}
        </FindPasswordButton>
      </InputWrapper>
      <ModalButton disabled={isSubmitDisabled()} onClick={onSignInClick}>
        {t("common.signIn")}
      </ModalButton>
      <AuthMessage style={{ marginTop: theme.spacing.section }}>
        {t("auth.notRegisteredYet")}{" "}
        <AuthMessageButton
          onClick={() => {
            toggleSignIn(false);
            toggleSignUp(true);
          }}
        >
          {t("auth.doSignUp")}
        </AuthMessageButton>
      </AuthMessage>
    </div>
  );
}

const InputWrapper = styled(Col)<any>(({ theme }: ThemedComponentProps) => ({
  gap: theme.spacing.default,
  marginBottom: theme.spacing.large,
}));

const FindPasswordButton = styled(TouchableOpacity)<any>(
  ({ theme }: ThemedComponentProps) => ({
    fontSize: theme.font.caption1,
    color: theme.color.black,
    textAlign: "right",
  })
);

export default SignInContainer;
