import React from "react";
import { useTranslation } from "react-i18next";
import styled, { ThemedComponentProps, useTheme } from "styled-components";
import useMainTopSection from "./MainTopSection.hook";
import MainTopThumbnail from "./MainTopThumbnail";
import arrowRight from "../../../assets/icons/arrow_right_black.svg";
import mobeeHat from "../../../assets/icons/mobee_hat.svg";
import squareArrowLeft from "../../../assets/icons/squre_left.svg";
import squareArrowRight from "../../../assets/icons/squre_right.svg";
import { useScreenWidthContext } from "../../../providers/ScreenWidthProvider";
import Carousel from "../../common/components/Carousel";
import { Col, DefaultButton, Row } from "../../common/styles/commonStyles";
import { MainWrapper } from "../common/mainCommonStyles";

const STEPS = [
  {
    en: "FIND or CREATE K",
    ko: "맘에 드는 K를 찾거나\n직접 만들어보세요",
  },
  {
    en: null,
  },
  {
    en: "Apply K",
    ko: "일정을 확인하고\n신청하세요",
  },
  {
    en: null,
  },
  {
    en: "Experience K",
    ko: "그날의 K를\n경험하세요",
  },
  {
    en: null,
  },
  {
    en: "Find Another K",
    ko: "또다른 K를\n찾아보세요",
  },
];

function MainTopSection() {
  const theme = useTheme();
  const { t } = useTranslation();

  const { isPc } = useScreenWidthContext();

  const {
    refs: { carouselRef },
    models: { loading, data },
    operations: { onArrowClick },
  } = useMainTopSection();

  if (loading) return <div />;

  return (
    <Wrapper>
      <MainWrapper $backgroundColor="transparent">
        <Title>{data?.mainTitle}</Title>
        {data?.top && data?.top?.length > 0 && (
          <CarouselWrapper>
            <Carousel
              carouselRef={carouselRef}
              settings={{
                slidesToShow: 1,
                infinite: true,
                dots: !isPc,
              }}
            >
              {data?.top?.map((moim) => (
                <MainTopThumbnail key={moim?.id} moim={moim!} />
              ))}
            </Carousel>
            {isPc && (
              <>
                <LeftArrow onClick={() => onArrowClick("left")}>
                  <CarouselArrowIcon src={squareArrowLeft} />
                </LeftArrow>
                <RightArrow onClick={() => onArrowClick("right")}>
                  <CarouselArrowIcon src={squareArrowRight} />
                </RightArrow>
              </>
            )}
          </CarouselWrapper>
        )}

        <IntroductionRow>
          <div>
            <IntroductionTitleRow>
              <div>{data?.mainIntroductionTitle}</div>
              <Mobee />
            </IntroductionTitleRow>
            <IntroductionSubtitle>
              {data?.mainIntroductionSubtitle}
            </IntroductionSubtitle>
          </div>
          <Introduction>{data?.mainIntroduction}</Introduction>
        </IntroductionRow>

        <StepsSection>
          <StepsTitle style={{ textAlign: "center" }}>
            {t("main.stepsTitle")}
          </StepsTitle>
          <StepsRow>
            {STEPS.map((step, index) =>
              step?.en ? (
                <StepWrapper key={index}>
                  <StepEn>{step?.en}</StepEn>
                  <StepKo>{step?.ko}</StepKo>
                </StepWrapper>
              ) : (
                <StepArrow key={index}>
                  <StepArrowIcon />
                </StepArrow>
              )
            )}
          </StepsRow>
        </StepsSection>
      </MainWrapper>
    </Wrapper>
  );
}

const Wrapper = styled(Col)(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  alignItems: "center",
  background: `linear-gradient(to bottom, ${theme.color.green} 45%, ${theme.color.background} 30%)`,
  padding: `0px ${theme.spacing.section}px`,

  [theme.media.down["DESKTOP"]]: {
    background: theme.color.green,
    padding: 0,
  },
}));

const Title = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  fontSize: 48,
  fontWeight: "bold",
  color: theme.color.white,
  textAlign: "center",
  textShadow: `${theme.color.black} 1px 0 1px`,

  [theme.media.down["DESKTOP"]]: {
    marginTop: theme.spacing.default,
    fontSize: theme.font.body2,
  },
}));

const CarouselWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  position: "relative",
  width: "100%",
  padding: `${theme.spacing.regular * 3}px 0px`,

  [theme.media.down["DESKTOP"]]: {
    width: "100%",
    padding: `${theme.spacing.default}px 0px`,
    paddingBottom: theme.spacing.default + theme.spacing.middle,
  },
}));

const LeftArrow = styled(DefaultButton)<any>(() => ({
  position: "absolute",
  top: "50%",
  left: "13%",
  transform: "translateY(-50%)",
  width: 50,
  height: 50,
}));

const CarouselArrowIcon = styled.img<any>(() => ({
  width: "100%",
  height: "100%",
}));

const RightArrow = styled(DefaultButton)<any>(() => ({
  position: "absolute",
  top: "50%",
  right: "13%",
  transform: "translateY(-50%)",
  width: 50,
  height: 50,
}));

const IntroductionRow = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  alignItems: "flex-end",
  padding: `${theme.spacing.regular * 3}px 0px`,
  justifyContent: "space-between",
  width: "100%",

  [theme.media.down["DESKTOP"]]: {
    backgroundColor: theme.color.background,
    padding: `${theme.spacing.section}px ${theme.spacing.regular}px`,
    flexDirection: "column",
    alignItems: "flex-start",
  },
}));

const IntroductionTitleRow = styled(Row)<any>(
  ({ theme }: ThemedComponentProps) => ({
    alignItems: "flex-end",
    gap: theme.spacing.small,
    marginBottom: theme.spacing.default,
    fontSize: 40,
    color: theme.color.black,
    textAlign: "left",
    fontWeight: "bold",

    [theme.media.down["DESKTOP"]]: {
      fontSize: theme.font.head3,
    },
  })
);

const Mobee = styled.img.attrs(() => ({
  src: mobeeHat,
}))<any>(({ theme }: ThemedComponentProps) => ({
  width: 52,
  height: 68,

  [theme.media.down["DESKTOP"]]: {
    width: 43,
    height: 56,
  },
}));

const IntroductionSubtitle = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    fontSize: theme.font.body2,
    fontWeight: "bold",

    [theme.media.down["DESKTOP"]]: {
      fontSize: theme.font.body3,
      marginBottom: theme.spacing.section,
    },
  })
);

const Introduction = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "40%",
  fontSize: theme.font.body3,

  [theme.media.down["DESKTOP"]]: {
    width: "100%",
  },
}));

const StepsSection = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  padding: `${theme.spacing.regular * 3}px 0px`,

  [theme.media.down["DESKTOP"]]: {
    padding: theme.spacing.regular,
    backgroundColor: theme.color.background,
  },
}));

const StepsTitle = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.body2,
  fontWeight: "bold",
  textAlign: "left",
  marginBottom: theme.spacing.default,

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.body3,
  },
}));

const StepsRow = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  alignItems: "stretch",
  justifyContent: "space-between",
  gap: theme.spacing.default,

  [theme.media.down["DESKTOP"]]: {
    flexDirection: "column",
    gap: theme.spacing.xxSmall2,
  },
}));

const StepArrow = styled(Col)<any>(() => ({
  alignItems: "center",
  justifyContent: "center",
}));

const StepArrowIcon = styled.img.attrs(() => ({
  src: arrowRight,
}))<any>(({ theme }: ThemedComponentProps) => ({
  width: 24,
  height: 24,

  [theme.media.down["DESKTOP"]]: {
    transform: "rotate(90deg)",
  },
}));

const StepWrapper = styled(Col)<any>(({ theme }: ThemedComponentProps) => ({
  flex: 1,
  alignItems: "center",
  border: `1px solid ${theme.color.border}`,
  gap: theme.spacing.xxSmall2,
  padding: `${theme.spacing.default}px ${theme.spacing.section}px`,
  backgroundColor: theme.color.white,

  [theme.media.down["DESKTOP"]]: {
    padding: theme.spacing.default,
  },
}));

const StepEn = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.body3,
  fontWeight: "bold",
}));

const StepKo = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.caption1,
  textAlign: "center",
}));

export default MainTopSection;
