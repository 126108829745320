import { ReactChildrenProp } from "global";
import React from "react";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

interface CarouselProps extends ReactChildrenProp {
  carouselRef?: React.RefObject<Slider>;
  settings?: Settings;
}

/**
 * Carousel component using react-slick
 * props: https://react-slick.neostack.com/docs/
 */
function Carousel({ children, carouselRef, settings = {} }: CarouselProps) {
  const carouselSettings = {
    dots: false,
    infinite: true,
    arrows: false,
    ...settings,
  };

  return (
    <Slider ref={carouselRef} {...carouselSettings}>
      {children}
    </Slider>
  );
}

export default Carousel;
