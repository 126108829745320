import React from "react";
import { useTranslation } from "react-i18next";
import styled, { ThemedComponentProps } from "styled-components";
import useFooter from "./Footer.hook";
import arrowIcon from "../../../assets/icons/chevron_up.svg";
import instagram from "../../../assets/icons/instagram.svg";
import kakao from "../../../assets/icons/kakao_talk.svg";
import logoEng from "../../../assets/icons/logo_white_eng.svg";
import logo from "../../../assets/icons/logo_white.svg";
import mobee from "../../../assets/icons/mobee_white.svg";
import { COMMON_ALT } from "../../../utilities/constants";
import { Col, Row, TouchableOpacity } from "../styles/commonStyles";

function Footer() {
  const { t, i18n } = useTranslation();
  const {
    models: { loading, data, showInfo },
    operations: { toggleShowInfo },
  } = useFooter();

  if (loading) return null;

  return (
    <Wrapper>
      <Mobee />
      <Logo src={i18n.resolvedLanguage === "ko" ? logo : logoEng} />

      <InfoWrapper>
        <InfoToggle onClick={toggleShowInfo}>
          <div>{t("footer.businessInformation")}</div>
          <Arrow show={showInfo} />
        </InfoToggle>
        {showInfo && (
          <InfoBox>
            {!!data?.companyName && <div>{data?.companyName}</div>}
            {!!data?.address && <div>{data?.address}</div>}
          </InfoBox>
        )}
      </InfoWrapper>

      {!!data?.email && (
        <EmailWrapper>
          <div>{t("footer.email")}</div>
          <a href={`mailto:${data?.email}`}>{data?.email}</a>
        </EmailWrapper>
      )}

      <SNSWrapper>
        {!!data?.instagram && (
          <SNSButton href={data?.instagram} target="_blank">
            <SNSIcon src={instagram} alt={data?.instagram} />
          </SNSButton>
        )}
        {!!data?.kakao && (
          <SNSButton href={data?.kakao} target="_blank">
            <SNSIcon src={kakao} alt={data?.kakao} />
          </SNSButton>
        )}
      </SNSWrapper>

      {!!data?.copyright && <Bottom>{data?.copyright}</Bottom>}
    </Wrapper>
  );
}

const Wrapper = styled(Col)<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  maxWidth: theme.containerMaxWidth,
  alignItems: "center",
  paddingTop: theme.spacing.section * 2,
  paddingBottom: theme.spacing.small,
  color: theme.color.white,

  [theme.media.down["TABLET"]]: {
    paddingTop: theme.spacing.section,
  },
}));

const Mobee = styled.img.attrs(() => ({
  src: mobee,
  alt: COMMON_ALT,
}))<any>(({ theme }: ThemedComponentProps) => ({
  width: 70,
  objectFit: "contain",
  marginBottom: theme.spacing.default,

  [theme.media.down["TABLET"]]: {
    width: 52,
  },
}));

const Logo = styled.img<any>(({ theme }: ThemedComponentProps) => ({
  width: 143,
  objectFit: "contain",
  marginBottom: theme.spacing.default,

  [theme.media.down["TABLET"]]: {
    width: 96,
  },
}));

const InfoWrapper = styled(Col)<any>(({ theme }: ThemedComponentProps) => ({
  marginBottom: theme.spacing.section,
  alignItems: "center",
}));

const InfoToggle = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  cursor: "pointer",
  gap: theme.spacing.xxSmall2,
  alignItems: "center",
  justifyContent: "center",
  fontFamily: "Pretendard",
  fontSize: theme.font.caption1,
}));

interface ArrowProps extends ThemedComponentProps {
  show?: boolean;
}

const Arrow = styled.img.attrs(() => ({
  src: arrowIcon,
  alt: COMMON_ALT,
}))<any>(({ theme, show }: ArrowProps) => ({
  width: 16,
  height: 16,
  transform: `rotate(${show ? "0deg" : "180deg"})`,
}));

const InfoBox = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: `${theme.spacing.normal}px 0px`,
  fontSize: theme.font.caption2,
  fontFamily: "Pretendard",
  textAlign: "center",
}));

const EmailWrapper = styled(Col)<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.body3,
  fontFamily: "Pretendard",
  marginBottom: theme.spacing.middle,
  alignItems: "center",
}));

const SNSWrapper = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  alignItems: "center",
  justifyContent: "center",
  gap: theme.spacing.default,

  marginBottom: theme.spacing.section * 2,

  [theme.media.down["TABLET"]]: {
    marginBottom: theme.spacing.section,
  },
}));

const SNSButton = styled.a<any>(({ theme }: ThemedComponentProps) => ({
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  border: `1px solid ${theme.color.white}`,
  width: 54,
  height: 54,
  borderRadius: 54 / 2,
  cursor: "pointer",

  "&:hover": {
    opacity: 0.5,
  },
}));

const SNSIcon = styled.img<any>(({ theme }: ThemedComponentProps) => ({
  width: 24,
  height: 24,
}));

const Bottom = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  paddingTop: theme.spacing.default,
  paddingBottom: theme.spacing.default,
  borderTop: `1px solid ${theme.color.white}`,
  fontSize: theme.font.caption2,
  textAlign: "center",
}));

export default Footer;
