import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useParams } from "react-router-dom";
import styled, { ThemedComponentProps } from "styled-components";
import { useScreenWidthContext } from "../../../../../providers/ScreenWidthProvider";
import { Col, Row } from "../../../../common/styles/commonStyles";

export const DESKTOP_UPDATE_MOIM_LINK_BUTTON_WIDTH = 287;

interface UpdateMoimLinksProps {
  isStep1Completed: boolean;
  isStep2Completed: boolean;
  isStep3Completed: boolean;
}

function UpdateMoimLinks({
  isStep1Completed,
  isStep2Completed,
  isStep3Completed,
}: UpdateMoimLinksProps) {
  const location = useLocation();
  const params = useParams();
  const { isPc } = useScreenWidthContext();

  const isNew = params?.moimId === "new";

  const { t } = useTranslation();

  return (
    <Wrapper>
      <LinkButton isActive={location.pathname.includes("host")}>
        <Link to={params?.moimId + "/host"}>
          <LinkButtonStep>Step1</LinkButtonStep>
          <LinkButtonTextRow>
            <div>{t("updateMoim.updateMoimLink1")}</div>
            <LinkButtonStatusChip>
              {t(
                isStep1Completed
                  ? "updateMoim.completed"
                  : "updateMoim.notCompleted"
              )}
            </LinkButtonStatusChip>
          </LinkButtonTextRow>
        </Link>
      </LinkButton>
      <LinkButton isActive={location.pathname.includes("info")}>
        <Link to={params?.moimId + "/info"}>
          <LinkButtonStep>Step2</LinkButtonStep>
          <LinkButtonTextRow>
            <div>{t("updateMoim.updateMoimLink2")}</div>
            <LinkButtonStatusChip>
              {t(
                isStep2Completed
                  ? "updateMoim.completed"
                  : "updateMoim.notCompleted"
              )}
            </LinkButtonStatusChip>
          </LinkButtonTextRow>
        </Link>
      </LinkButton>
      <LinkButton isActive={location.pathname.includes("detail")}>
        <Link
          style={{
            pointerEvents: isNew ? "none" : "auto",
            opacity: isNew ? 0.5 : 1,
          }}
          to={params?.moimId + "/detail"}
        >
          <LinkButtonStep>Step3</LinkButtonStep>
          <LinkButtonTextRow>
            <div>{t("updateMoim.updateMoimLink3")}</div>
            <LinkButtonStatusChip>
              {t(
                isStep3Completed
                  ? "updateMoim.completed"
                  : "updateMoim.notCompleted"
              )}
            </LinkButtonStatusChip>
          </LinkButtonTextRow>
        </Link>
      </LinkButton>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  position: "sticky",
  display: "inline-block",
  top: theme.navigationHeight + theme.spacing.section * 2,
  marginTop: -theme.navigationHeight,

  [theme.media.down["DESKTOP"]]: {
    position: "sticky",
    width: "100%",
    display: "flex",
    alignItems: "stretch",
    justifyContent: "space-between",
    marginTop: 0,
    top: theme.mobileNavigationHeight,
    zIndex: 100,
  },
}));

interface LinkButtonProps extends ThemedComponentProps {
  isActive?: boolean;
}

const LinkButton = styled.div<any>(({ theme, isActive }: LinkButtonProps) => ({
  padding: `${theme.spacing.normal}px ${theme.spacing.default}px`,
  fontSize: theme.font.caption1,
  border: `1px solid ${isActive ? theme.color.green : theme.color.border}`,
  fontWeight: isActive ? "bold" : "normal",
  color: isActive ? theme.color.white : theme.color.black,
  backgroundColor: isActive ? theme.color.green : theme.color.white,
  textAlign: "left",
  width: DESKTOP_UPDATE_MOIM_LINK_BUTTON_WIDTH,
  marginBottom: theme.spacing.small,

  [theme.media.down["DESKTOP"]]: {
    flex: 1,
    width: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 0,
    textAlign: "center",
    padding: `${theme.spacing.default}px 0px`,
    marginBottom: 0,
    backgroundColor: theme.color.white,
    border: "none",
    borderBottom: isActive
      ? `2px solid ${theme.color.green}`
      : `1px solid ${theme.color.border}`,
    color: theme.color.green,
  },
}));

const LinkButtonStep = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.caption2,
}));

const LinkButtonTextRow = styled(Row)<any>(
  ({ theme }: ThemedComponentProps) => ({
    alignItems: "center",
    gap: theme.spacing.xxSmall2,

    [theme.media.down["DESKTOP"]]: {
      flexDirection: "column",
    },
  })
);

interface LinkButtonStatusChipProps extends ThemedComponentProps {
  isDone?: boolean;
}

const LinkButtonStatusChip = styled.div<any>(
  ({ theme, isDone }: LinkButtonStatusChipProps) => ({
    padding: `${theme.spacing.xxSmall}px ${theme.spacing.small}px`,
    backgroundColor: isDone ? theme.color.white : theme.color.background,
    fontSize: theme.font.caption2,
    color: theme.color.green,
    borderRadius: 16,
    fontWeight: "normal",

    [theme.media.down["DESKTOP"]]: {
      padding: `${theme.spacing.xxSmall}px ${theme.spacing.xxSmall2}px`,
    },
  })
);

export default UpdateMoimLinks;
