import { useParams } from "react-router-dom";
import { useGetMoimParticipantsQuery } from "../../../../lib/apollo/graphql/generated";

function useParticipantsContainer() {
  const params = useParams();

  const { loading, data } = useGetMoimParticipantsQuery({
    variables: {
      moimId: params?.moimId!,
    },
    fetchPolicy: "network-only",
  });

  return {
    models: {
      loading,
      data: data?.getMoim,
    },
  };
}

export default useParticipantsContainer;
