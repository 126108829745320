import { useState } from "react";

function useNavigationBar() {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  function toggleShowMobileMenu(show: boolean) {
    setShowMobileMenu(show);
  }

  return {
    models: {
      showMobileMenu,
    },
    operations: {
      toggleShowMobileMenu,
    },
  };
}

export default useNavigationBar;
