import { useParams } from "react-router-dom";
import { useGetRecommendedContentsByContentIdQuery } from "../../../lib/apollo/graphql/generated";

function useContentRecommendSection() {
  const params = useParams();

  const { loading, data } = useGetRecommendedContentsByContentIdQuery({
    variables: {
      contentId: params?.contentId!,
    },
  });

  return {
    models: {
      loading,
      data: data?.getRecommendedContentsByContentId?.slice(0, 3),
    },
  };
}

export default useContentRecommendSection;
