import { format, isBefore } from "date-fns";
import { useRef, useState } from "react";
import { SingleValue } from "react-select";
import { useAuthContext } from "../../../../providers/AuthProvider";
import { MINIMUM_USERNAME_CHARACTERS } from "../../../../utilities/constants";
import {
  Gender,
  GetMyInitialProfileDocument,
} from "../../../../lib/apollo/graphql/generated";
import {
  useIsUniqueUsernameLazyQuery,
  useUpdateUserProfileMutation,
} from "../../../../lib/apollo/graphql/generated";

interface CreateProfileState {
  username: string;
  usernameError: string;
  phone: string;
  dateOfBirth: string;
  gender: null | Gender;
  nationality: null | { label: string; value: string };
}

function useCreateProfileContainer() {
  const { toggleDefaultProfile } = useAuthContext();

  const usernameInputRef = useRef<HTMLInputElement>(null);
  const phoneInputRef = useRef<HTMLInputElement>(null);

  const [isUniqueUsername] = useIsUniqueUsernameLazyQuery();

  const [updateUserProfile] = useUpdateUserProfileMutation({
    onCompleted: () => {
      toggleDefaultProfile(false);
    },
    refetchQueries: () => [
      {
        query: GetMyInitialProfileDocument,
      },
    ],
  });

  const [state, setState] = useState<CreateProfileState>({
    username: "",
    usernameError: "",
    phone: "",
    dateOfBirth: format(new Date(), "yyyy-MM-dd"),
    gender: null,
    nationality: null,
  });

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>, key: string) {
    const { value } = e.target;

    if (key === "phone" && isNaN(Number(value))) {
      return;
    }

    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function onGenderChange(gender: null | Gender) {
    setState((prev) => ({
      ...prev,
      gender,
    }));
  }

  function onNationalityChange(
    newValue: SingleValue<{ label: string; value: string }>
  ) {
    setState((prev) => ({
      ...prev,
      nationality: newValue,
    }));
  }

  function onUsernameFocus() {
    if (state.usernameError) {
      setState((prev) => ({
        ...prev,
        usernameError: "",
      }));
    }
  }

  async function onUsernameBlur() {
    const { data } = await isUniqueUsername({
      variables: {
        username: state.username,
      },
    });

    if (data?.isUniqueUsername === false) {
      setState((prev) => ({
        ...prev,
        usernameError: "profile.usernameError",
      }));
    }
  }

  function isSubmitDisabled() {
    const { username, usernameError, phone, dateOfBirth, gender, nationality } =
      state;

    const isValidUsername =
      username?.length >= MINIMUM_USERNAME_CHARACTERS && !usernameError;
    const isValidPhone = phone?.length > 10;
    const isValidDateOfBirth = isBefore(new Date(dateOfBirth), new Date());
    const isValidGender = gender !== null;
    const isValidNationality = nationality !== null;

    return !(
      isValidUsername &&
      isValidPhone &&
      isValidDateOfBirth &&
      isValidGender &&
      isValidNationality
    );
  }

  function onSubmit() {
    const { username, phone, dateOfBirth, gender, nationality } = state;

    const userInput = {
      username,
      dateOfBirth,
      gender,
      phone,
      nationalityId: nationality?.value,
    };

    updateUserProfile({
      variables: {
        userInput,
      },
    });
  }

  return {
    refs: {
      usernameInputRef,
      phoneInputRef,
    },
    models: {
      state,
    },
    operations: {
      onInputChange,
      onGenderChange,
      onNationalityChange,
      onUsernameFocus,
      onUsernameBlur,
      isSubmitDisabled,
      onSubmit,
    },
  };
}

export default useCreateProfileContainer;
