import React from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import styled, { ThemedComponentProps } from "styled-components";
import { MyPageTitle } from "../common/styles/MyPageCommonStyles";
import {
  Col,
  DefaultButton,
  Row,
  Tab,
  Tabs,
} from "../../../common/styles/commonStyles";

function MyPageMoimsSection() {
  const { t } = useTranslation();
  const params = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <Wrapper>
      <MyPageTitle>{t("myPage.moimTitle")}</MyPageTitle>
      <Tabs>
        <Tab
          isActive={!pathname.includes("done")}
          onClick={() => navigate("/user/" + params?.userId)}
        >
          {t("myPage.onGoingMoimTitle")}
        </Tab>
        <Tab
          isActive={pathname.includes("done")}
          onClick={() => navigate("/user/" + params?.userId + "/done")}
        >
          {t("myPage.finishedMoimTitle")}
        </Tab>
      </Tabs>
      <Outlet />
    </Wrapper>
  );
}

const Wrapper = styled(Col)<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  maxWidth: theme.containerMaxWidth,
  padding: `${theme.spacing.regular * 3}px ${theme.spacing.section}px`,

  [theme.media.down["DESKTOP"]]: {
    padding: `${theme.spacing.section}px ${theme.spacing.regular}px`,
    paddingTop: 0,
  },
}));

export default MyPageMoimsSection;
