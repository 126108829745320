import { useEffect, useState } from "react";
import { useGetContentThemesQuery } from "../../../lib/apollo/graphql/generated";

function useContentListThemes() {
  const [showOverlay, setShowOverlay] = useState(false);

  const { loading, data } = useGetContentThemesQuery();

  useEffect(() => {
    if (showOverlay) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showOverlay]);

  return {
    models: {
      loading,
      data: data?.getContentThemes,
      showOverlay,
    },

    operations: {
      setShowOverlay,
    },
  };
}

export default useContentListThemes;
