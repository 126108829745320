import React, { useState } from "react";
import styled, { ThemedComponentProps } from "styled-components";
import useContentListThemes from "./ContentListThemes.hook";
import arrowIcon from "../../../assets/icons/caret_down.svg";
import closeIcon from "../../../assets/icons/close_fill.svg";
import { useContentListContext } from "../../../providers/ContentListProvider";
import { useScreenWidthContext } from "../../../providers/ScreenWidthProvider";
import { COMMON_ALT } from "../../../utilities/constants";
import { Col, Row, TouchableOpacity } from "../../common/styles/commonStyles";

function ContentListThemes() {
  const { isPc } = useScreenWidthContext();

  const { themeId, onThemeChange } = useContentListContext();

  const {
    models: { loading, data, showOverlay },
    operations: { setShowOverlay },
  } = useContentListThemes();

  return isPc ? (
    <Wrapper>
      <Theme isActive={!themeId} onClick={() => onThemeChange(null)}>
        전체 주제
      </Theme>

      {data?.map((theme) => (
        <Theme
          key={theme?.id}
          isActive={themeId === theme?.id!}
          onClick={() => onThemeChange(theme?.id!)}
        >
          {theme?.name}
        </Theme>
      ))}
    </Wrapper>
  ) : (
    <>
      <MobileWrapper onClick={() => setShowOverlay(true)}>
        <div>
          {themeId
            ? data?.find((theme) => theme?.id === themeId)?.name
            : "전체 주제"}
        </div>
        <Icon />
      </MobileWrapper>
      {showOverlay && (
        <OverlayWrapper>
          <Overlay>
            <Close onClick={() => setShowOverlay(false)}>
              <CloseIcon />
            </Close>
            <OverlayMenu>
              <Theme
                isActive={!themeId}
                onClick={() => {
                  onThemeChange(null);
                  setShowOverlay(false);
                }}
              >
                전체 주제
              </Theme>

              {data?.map((theme) => (
                <Theme
                  key={theme?.id}
                  isActive={themeId === theme?.id!}
                  onClick={() => {
                    onThemeChange(theme?.id!);
                    setShowOverlay(false);
                  }}
                >
                  {theme?.name}
                </Theme>
              ))}
            </OverlayMenu>
          </Overlay>
        </OverlayWrapper>
      )}
    </>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  flex: 1,
  backgroundColor: theme.color.green,
  borderRadius: 24,
  padding: `${theme.spacing.large}px 0px`,
  fontSize: theme.font.body3,
  color: theme.color.white,
}));

interface ThemeProps extends ThemedComponentProps {
  isActive?: boolean;
}

const Theme = styled(TouchableOpacity)<any>(
  ({ theme, isActive }: ThemeProps) => ({
    width: "100%",
    padding: `${theme.spacing.small}px ${theme.spacing.default}px`,
    fontWeight: isActive ? "bold" : "normal",
    backgroundColor: isActive ? "rgba(0,0,0,0.3)" : "transparent",

    [theme.media.down["DESKTOP"]]: {
      textAlign: "center",
      padding: `${theme.spacing.normal}px ${theme.spacing.small}px`,
    },
  })
);

const MobileWrapper = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  padding: `${theme.spacing.normal}px ${theme.spacing.middle}px`,
  backgroundColor: theme.color.white,
  borderRadius: 8,
  fontSize: theme.font.body2,
  color: theme.color.black,
  alignItems: "center",
  width: "100%",
  justifyContent: "space-between",
  marginBottom: theme.spacing.section,

  [theme.media.down["TABLET"]]: {
    fontSize: theme.font.body3,
  },
}));

const Icon = styled.img.attrs(() => ({
  src: arrowIcon,
  alt: COMMON_ALT,
}))<any>(() => ({
  width: 24,
  height: 24,
}));

const OverlayWrapper = styled(Row)<any>(() => ({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  justifyContent: "center",
  zIndex: 100,
  opacity: 1,
}));

const Overlay = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  position: "absolute",
  top: "25%",
  bottom: "15%",
  backgroundColor: theme.color.green,
  color: theme.color.white,
  width: "70%",
  borderRadius: 28,
  padding: `${theme.spacing.section}px 0px`,
}));

const OverlayMenu = styled(Col)<any>(({ theme }: ThemedComponentProps) => ({
  height: "100%",
  alignItems: "center",
  overflowY: "auto",
  marginTop: theme.spacing.small,
}));

const Close = styled(TouchableOpacity)<any>(
  ({ theme }: ThemedComponentProps) => ({
    position: "absolute",
    top: theme.spacing.default,
    right: theme.spacing.default,
  })
);

const CloseIcon = styled.img.attrs(() => ({
  src: closeIcon,
  alt: COMMON_ALT,
}))<any>(() => ({
  width: 28,
  height: 28,
}));

export default ContentListThemes;
