import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled, { ThemedComponentProps, useTheme } from "styled-components";
import useJoinMoimContainer from "./JoinMoimContainer.hook";
import { useAuthContext } from "../../../../providers/AuthProvider";
import { useScreenWidthContext } from "../../../../providers/ScreenWidthProvider";
import Layout from "../../../common/components/Layout";
import LazyImage from "../../../common/components/LazyImage";
import MoimDetailTopRow from "../../../common/components/MoimDetailTopRow";
import { useGetMoimContent } from "../../../common/hook/useGetMoimContent";
import { getMoimcontentByLanguage } from "../../../common/utils/getMoimContentByLanguage";
import {
  Col,
  DefaultButton,
  Row,
  SectionWrapper,
} from "../../../common/styles/commonStyles";
import {
  MoimSectionTitle,
  MoimStatusText,
} from "../../common/styles/commonMoimStyles";

function JoinMoimContainer() {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const { userProfile } = useAuthContext();
  const { isPc } = useScreenWidthContext();

  const navigate = useNavigate();

  const {
    models: { loading, data, policyData, payMethod, agreed, joinLoading },
    operations: {
      onPayMethodClick,
      onAgreeClick,
      isJoinButtonDisabled,
      onJoinClick,
    },
  } = useJoinMoimContainer();

  const currentLanguage = i18n.resolvedLanguage;

  const koreanContent = getMoimcontentByLanguage("ko", data?.moimContents);
  const englishContent = getMoimcontentByLanguage("en", data?.moimContents);

  const currentLanguageContent =
    currentLanguage === "ko" ? koreanContent : englishContent;
  const otherLanguageContent =
    currentLanguage === "ko" ? englishContent : koreanContent;

  const {
    price,
    purePrice,
    formattedMoimStartAt,
    formattedMoimStartAndEndHour,
  } = useGetMoimContent(
    currentLanguageContent,
    data?.moimStartAt,
    data?.moimEndAt
  );

  if (loading || !userProfile) return <div />;

  return (
    <Layout>
      <Wrapper>
        <Title>{t("joinMoim.title")}</Title>
        <MoimDetailTopRow
          moimContents={data?.moimContents}
          coverPhoto={data?.coverPhoto}
          moimStartAt={data?.moimStartAt}
          moimEndAt={data?.moimEndAt}
          status={data?.status!}
          message={data?.message}
        />
        <BodySection>
          <BodySectionCell>
            <MoimSectionTitle>{t("joinMoim.infoTitle")}</MoimSectionTitle>
            <MiddleRow>
              <MiddleRowLabel>{t("moim.placeTitle")}</MiddleRowLabel>
              <MiddleRowValueCell>
                <MiddleRowValue>
                  <strong>{data?.place?.name}</strong>
                </MiddleRowValue>
                <div>
                  {data?.place?.address +
                    (data?.place?.addressDetail
                      ? ", " + data?.place?.addressDetail
                      : "")}
                </div>
              </MiddleRowValueCell>
            </MiddleRow>
            <MiddleRow>
              <MiddleRowLabel>{t("moim.moimStartAtTitle")}</MiddleRowLabel>
              <MiddleRowValueCell>
                <MiddleRowValue>
                  <strong>{formattedMoimStartAt}</strong>
                </MiddleRowValue>
                <div>{formattedMoimStartAndEndHour}</div>
              </MiddleRowValueCell>
            </MiddleRow>

            <MiddleRow>
              <MiddleRowLabel>{t("joinMoim.moimRules")}</MiddleRowLabel>
              <MiddleRowValueCell>
                <MiddleRow style={{ marginBottom: theme.spacing.small }}>
                  <MiddleRowValue style={{ marginRight: theme.spacing.small }}>
                    <strong>{t("moim.genderRestrictionTitle")}</strong>
                  </MiddleRowValue>
                  <MiddleRowValue>
                    {data?.genderRestriction
                      ? t("common." + data?.genderRestriction)
                      : t("common.null")}
                  </MiddleRowValue>
                </MiddleRow>
                <MiddleRow style={{ marginBottom: theme.spacing.small }}>
                  <MiddleRowValue style={{ marginRight: theme.spacing.small }}>
                    <strong>{t("moim.ageRestrictionTitle")}</strong>
                  </MiddleRowValue>
                  <MiddleRowValue>
                    {!data?.minAge && !data?.maxAge
                      ? t("common.null")
                      : !!data?.minAge
                      ? data?.minAge + t("common.yearOld")
                      : ""}
                    {!!data?.maxAge
                      ? " ~ " + data?.maxAge + t("common.yearOld")
                      : ""}
                  </MiddleRowValue>
                </MiddleRow>
                <MiddleRow>
                  <MiddleRowValue style={{ marginRight: theme.spacing.small }}>
                    <strong>{t("moim.personsTitle")}</strong>
                  </MiddleRowValue>
                  <MiddleRowValue>
                    {!data?.minPersons && !data?.maxPersons
                      ? t("common.null")
                      : !!data?.minPersons
                      ? data?.minPersons + t("common.persons")
                      : ""}
                    {!!data?.maxPersons
                      ? " ~ " + data?.maxPersons + t("common.persons")
                      : ""}
                  </MiddleRowValue>
                </MiddleRow>
              </MiddleRowValueCell>
            </MiddleRow>
          </BodySectionCell>
          {!isPc && <Spacer />}
          <BodySectionCell>
            <MoimSectionTitle>
              {t("joinMoim.participateTitle")}
            </MoimSectionTitle>
            <MiddleRow>
              <MiddleRowValue style={{ flex: 1 }}>
                <strong>{t("profile.username")}</strong>
              </MiddleRowValue>
              <MiddleRowValue style={{ flex: isPc ? 4 : 2 }}>
                {userProfile?.username}
              </MiddleRowValue>
            </MiddleRow>

            <MiddleRow>
              <MiddleRowValue style={{ flex: 1 }}>
                <strong>{t("profile.phone")}</strong>
              </MiddleRowValue>
              <MiddleRowValue style={{ flex: isPc ? 4 : 2 }}>
                {userProfile?.phone}
              </MiddleRowValue>
            </MiddleRow>
            <MiddleRow>
              <MiddleRowValue style={{ flex: 1 }}>
                <strong>{t("profile.email")}</strong>
              </MiddleRowValue>
              <MiddleRowValue style={{ flex: isPc ? 4 : 2 }}>
                {userProfile?.email}
              </MiddleRowValue>
            </MiddleRow>
          </BodySectionCell>
        </BodySection>
        <Spacer />
        {/* <MoimSectionTitle
          style={{
            marginTop: isPc ? theme.spacing.section : theme.spacing.middle,
          }}
        >
          {t("moim.refundTitle")}
        </MoimSectionTitle>
        <MiddleRowValue
          style={{
            marginBottom: isPc ? theme.spacing.section : theme.spacing.middle,
          }}
        >
          {policyData}
        </MiddleRowValue> */}
        {/* <Spacer /> */}
        {/* <BottomSection>
          <BottomRow>
            <MoimSectionTitle style={{ margin: 0 }}>
              {t("joinMoim.price")}
            </MoimSectionTitle>
            <MoimSectionTitle style={{ margin: 0 }}>
              {purePrice}
            </MoimSectionTitle>
          </BottomRow>
          <BottomRow>
            <MoimSectionTitle style={{ margin: 0 }}>
              {t("joinMoim.payMethod")}
            </MoimSectionTitle>
            <PayMethodButtons>
              <PayMethodButton
                active={payMethod === PAY_METHOD.CREDIT_CARD}
                onClick={() => onPayMethodClick(PAY_METHOD.CREDIT_CARD)}
              >
                {t("joinMoim.creditCard")}
              </PayMethodButton>
              <PayMethodButton
                active={payMethod === PAY_METHOD.KAKAOPAY}
                onClick={() => onPayMethodClick(PAY_METHOD.KAKAOPAY)}
              >
                {t("joinMoim.kakaopay")}
              </PayMethodButton>
            </PayMethodButtons>
          </BottomRow>
        </BottomSection> */}
        <Spacer />
        {/* <AgreeRow>
          <AgreeIcon
            src={agreed ? checkedIcon : uncheckedIcon}
            onClick={onAgreeClick}
          />
          <div>
            <strong>{t("joinMoim.privacyPolicy")}</strong>
          </div>
          <AgreeViewButton>{t("common.view")}</AgreeViewButton>
        </AgreeRow> */}
        {/* TODO: CHANGE BUTTON TO THIS */}
        {/* <PayButton disabled={!agreed}>{t("common.pay")}</PayButton> */}
        <PayButton
          disabled={joinLoading || isJoinButtonDisabled()}
          onClick={onJoinClick}
        >
          {t("common.join")}
        </PayButton>
      </Wrapper>
    </Layout>
  );
}

const Wrapper = styled(SectionWrapper)<any>(
  ({ theme }: ThemedComponentProps) => ({
    maxWidth: 800,
    padding: `${theme.spacing.section * 2}px 0px`,
    alignItems: "flex-start",

    [theme.media.down["DESKTOP"]]: {
      padding: `${theme.spacing.middle}px ${theme.spacing.regular}px`,
    },
  })
);

const Title = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.head2,
  fontWeight: "bold",
  marginBottom: theme.spacing.section,
  textAlign: "left",

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.body1,
  },
}));

const Spacer = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  height: 1,
  backgroundColor: theme.color.border,
}));

const TopRow = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  padding: `${theme.spacing.middle}px 0px`,
  gap: theme.spacing.middle,
  alignItems: "center",
  position: "relative",

  [theme.media.down["DESKTOP"]]: {
    flexDirection: "column",
    paddingTop: 0,
    width: "100%",
    alignItems: "flex-start",
  },
}));

const CoverPhoto = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  height: 142,
  width: 216,

  [theme.media.down["DESKTOP"]]: {
    width: "100%",
    height: ((window.innerWidth - theme.spacing.regular * 2) * 220) / 335,
  },
}));

const MoimTitle = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.body2,
  fontWeight: "bold",
  textAlign: "left",

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.body2,
    marginTop: -theme.spacing.small,
  },
}));

const MoimSubTitle = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.body3,
  textAlign: "left",
  marginBottom: theme.spacing.default,

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.body3,
    marginBottom: theme.spacing.normal,
  },
}));

const TopRowPrice = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.body3,
  textAlign: "left",
  fontWeight: "bold",

  [theme.media.down["DESKTOP"]]: {
    fontSize: theme.font.body3,
  },
}));

const BodySection = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  padding: `${theme.spacing.section}px 0px`,
  gap: theme.spacing.small,
  alignItems: "flex-start",

  [theme.media.down["DESKTOP"]]: {
    flexDirection: "column",
    padding: 0,
  },
}));

const BodySectionCell = styled(Col)<any>(({ theme }: ThemedComponentProps) => ({
  flex: 1,

  gap: theme.spacing.default,
  alignItems: "flex-start",

  [theme.media.down["DESKTOP"]]: {
    padding: `${theme.spacing.middle}px 0px`,
    width: "100%",
  },
}));

const MiddleRow = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  alignItems: "flex-start",
  width: "100%",
}));

const MiddleRowLabel = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  color: "#8c8c8c",
  fontWeight: "bold",
  flex: 1,
  fontSize: theme.font.caption1,
}));

const MiddleRowValueCell = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    flex: 4,
    fontSize: theme.font.caption1,
  })
);

const MiddleRowValue = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: theme.font.body3,

  [theme.media.down["DESKTOP"]]: {
    marginBottom: theme.spacing.xxSmall2,
  },
}));

const BottomSection = styled(Col)<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  gap: theme.spacing.middle,
  padding: `${theme.spacing.section}px 0px`,
}));

const BottomRow = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  alignItems: "center",
  justifyContent: "space-between",
}));

const PayMethodButtons = styled(Row)<any>(
  ({ theme }: ThemedComponentProps) => ({
    alignItems: "center",
    gap: theme.spacing.small,
  })
);

interface PayMethodButtonProps extends ThemedComponentProps {
  active?: boolean;
}

const PayMethodButton = styled(DefaultButton)<any>(
  ({ theme, active }: PayMethodButtonProps) => ({
    padding: `${theme.spacing.normal}px ${theme.spacing.section}px`,
    border: `1px solid ${theme.color.black}`,
    backgroundColor: active ? theme.color.green : theme.color.white,
    color: active ? theme.color.white : theme.color.black,
    fontWeight: "bold",
    fontSize: theme.font.body3,
  })
);

const AgreeRow = styled(Row)<any>(({ theme }: ThemedComponentProps) => ({
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  gap: theme.spacing.xxSmall2,
  fontSize: theme.font.body3,
  color: theme.color.black,
  padding: `${theme.spacing.section}px 0px`,
}));

const AgreeIcon = styled.img<any>(({ theme }: ThemedComponentProps) => ({
  width: 16,
  height: 16,
}));

const AgreeViewButton = styled(DefaultButton)<any>(
  ({ theme }: ThemedComponentProps) => ({
    textDecoration: "underline",
  })
);

interface PayButtonProps extends ThemedComponentProps {
  disabled?: boolean;
}

const PayButton = styled(DefaultButton)<any>(
  ({ theme, disabled }: PayButtonProps) => ({
    position: "sticky",
    padding: `${theme.spacing.normal}px 0px`,
    textAlign: "center",
    backgroundColor: theme.color.orange,
    width: 296,
    fontSize: theme.font.body3,
    fontWeight: "bold",
    color: theme.color.black,
    margin: "0px auto",
    pointerEvents: disabled ? "none" : "auto",
    opacity: disabled ? 0.7 : 1,
    marginTop: theme.spacing.section,
    marginBottom: theme.spacing.section * 2,
    bottom: theme.spacing.section,

    [theme.media.down["DESKTOP"]]: {
      width: "100%",
      marginBottom: theme.spacing.middle,
      bottom: theme.spacing.middle,
    },
  })
);

export default JoinMoimContainer;
