import React from "react";
import styled, { ThemedComponentProps } from "styled-components";
import { MoimContentSectionProps } from "./MoimContentSection";
import MoimStickyInfo from "./MoimStickyInfo";
import MoimStickyJoin from "./MoimStickyJoin";
import { Col } from "../../../common/styles/commonStyles";

function MoimStickySection({ moim }: MoimContentSectionProps) {
  return (
    <Wrapper>
      <MoimStickyInfo moim={moim} />
      <MoimStickyJoin moim={moim} />
    </Wrapper>
  );
}

const Wrapper = styled(Col)<any>(({ theme }: ThemedComponentProps) => ({
  gap: theme.spacing.small,
}));

export default MoimStickySection;
