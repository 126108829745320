import { useRef } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import { useGetRefundPolicyQuery } from "../../../../lib/apollo/graphql/generated";

function useMoimContentSection() {
  const carouselRef = useRef<Slider>(null);

  const { i18n } = useTranslation();

  const { data } = useGetRefundPolicyQuery({
    variables: {
      languageCode: i18n.resolvedLanguage,
    },
  });

  function onThumbnailClick(index: number) {
    carouselRef?.current?.slickGoTo(index);
  }

  function onArrowClick(direction: "left" | "right") {
    if (direction === "left") {
      carouselRef?.current?.slickPrev();
    } else {
      carouselRef?.current?.slickNext();
    }
  }

  return {
    refs: {
      carouselRef,
    },
    models: {
      refundPolicy: data?.getRefundPolicy,
    },
    operations: {
      onThumbnailClick,
      onArrowClick,
    },
  };
}

export default useMoimContentSection;
