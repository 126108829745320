import { useRef, useState } from "react";
import useDetectClickOutside from "../hook/useDetectClickOutside";

function useDropdown() {
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState(false);

  useDetectClickOutside(dropdownRef, [open], () => toggleOpen(false));

  function toggleOpen(open: boolean) {
    setOpen(open);
  }

  return {
    refs: {
      dropdownRef,
    },
    models: {
      open,
    },
    operations: {
      toggleOpen,
    },
  };
}

export default useDropdown;
